import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ArfStatus,
  BidContractStatus,
  ResourceType,
  TaskAccessoryType,
  TaskReviewStatus,
  TaskStatus,
  UserType,
} from 'src/app/enums';
import {
  AppRoutingService,
  ArfsService,
  AuthService,
  BugReportService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  ReviewRevisionService,
} from 'src/app/services';
import { UpdateReviewerService } from 'src/app/services/update-reviewer.service';
import {
  BugReport,
  Invoice,
  Product,
  ProjectTenant,
  Quote,
  ReviewChain,
  Task,
  TaskAccessoryData,
  UhatFileReference,
} from 'src/app/types';
import { ArfCustodyChain } from 'src/app/types/arf-custody-chain';
import { ChangeOrderModalComponent, ProposalRequestDialogComponent } from 'src/app/workspaces/construction/components';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import {
  Bid,
  BidPackage,
  ChangeOrder,
  ProjectConstruction,
  ProposalRequest,
} from 'src/app/workspaces/construction/types';
import { InvoiceReviewButtonsComponent } from '../invoice-review-buttons/invoice-review-buttons.component';

/**
 * The Tasks Accessory is a component that is intended to be used in the Project Task Panel.
 * It is a facade that depending on its type, can display buttons or objects with various styles and functions.
 * Its primary use is to allow a variety of behaviours - from within the task panel, produced from a single JSON string (stored in database).
 */
@Component({
  selector: 'app-task-accessory',
  templateUrl: './task-accessory.component.html',
  styleUrls: ['./task-accessory.component.scss'],
})
export class TaskAccessoryComponent implements OnInit, OnChanges {
  @Input() public data: TaskAccessoryData;

  @Input() public task: Task;

  @Input() public showExtras = true;

  @Input() public custodyChain: ArfCustodyChain;

  @Input() public startReview: boolean;
  @Output() public reviewChanged = new EventEmitter();
  @Output() public taskChanged = new EventEmitter();

  @ViewChild('invoiceButtons', { static: true })
  private invoiceButtons: InvoiceReviewButtonsComponent;
  private _currentTaskSubscription;

  public type: TaskAccessoryType;

  public currentActiveReviewChainItem: ReviewChain;
  public currentUserReviewChainItem: ReviewChain;
  public reviewEnabledForUser = true;
  public userIsCurrentReviewer;
  public isReviewerAdmin: boolean;

  public currentPM: number;

  public isLocked: boolean;
  public isLoading: boolean;

  public prId: number;
  public proposalRequest: ProposalRequest;
  public pco: ChangeOrder;

  public quote: Quote;
  public tenant: ProjectTenant;

  // A get in this way allows us to use the Enum in the HTML template. Otherwise it cannot be done.
  public get TaskAccessoryType() {
    return TaskAccessoryType;
  }

  constructor(
    private arfService: ArfsService,
    private displayReviewersService: DisplayReviewersService,
    private modalService: ModalService,
    private routingService: AppRoutingService,
    public productService: ProductService,
    private projectService: ProjectService,
    public projectTenantService: ProjectTenantService,
    public authService: AuthService,
    private fileService: FileService,
    private reviewRevisionService: ReviewRevisionService,
    private progressIndicatorService: ProgressIndicatorService,
    private snackbar: MatSnackBar,
    private errorService: BugReportService,
    private dialog: MatDialog,
    private updateReviewService: UpdateReviewerService,
    private _taskService: ProjectTaskService
  ) {}

  private bidContractFields = [
    'type_id',
    'executed_datetime',
    'vendor_signed_datetime',
    'sent_to_vendor_datetime',
    'status_id',
    'insurance_requirement_ids',
  ];
  private bidFields = [
    `bid_contract{${this.bidContractFields.join(',')}}`,
    'bid_package_id',
    'contract_revision',
    'timeline_revision',
    'contract_task_id',
    'trade{name}',
    'project{bid_packages{number}}',
  ];

  private proposalRequestFields = [
    'code',
    'title',
    'description',
    'due_date',
    'files',
    'reason_id',
    'reason_name',
    'local_index',
    'project_id',
  ];

  private changeOrderFields = [
    'code',
    'proposal_request_id',
    'short_description',
    'summary',
    'trade_name',
    'company_name',
    'status_id',
    'cost_change',
    'time_change_amount',
    'time_change_unit',
    'files',
    'local_index',
    'wm_arch_task_id',
    'wm_arch_status_id',
    'tenant_task_id',
    'tenant_status_id',
    'cfmo_task_id',
    'cfmo_status_id',
    'need_wm_arch_approval',
    'need_tenant_approval',
    'need_cfmo_approval',
    'funding_source_tenant_id',
    'funding_source_name',
  ];

  public quoteFields: string[] = [
    'asset_tagged',
    'arf_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data,description}',
    'arf_approval_task_id',
    'arf_saved_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data,description}',
    'arf_saved_approval_task_id',
    'arf_purchase_type',
    'arf_revision',
    'code',
    `company{id,name}`,
    'contact_id',
    'description',
    `subtotal`,
    `tax`,
    'fiscal_year',
    'files',
    'quote_file{id,name}',
    `quote_items{id,project_product{id,is_taxable,selected_quote_item_id,tenant_id},total_price}`, // don't use quoteItemFields here or we'll get a loop
  ];

  public quoteItemFields: string[] = [
    'project_product_id',
    'status_id',
    'unit_price',
    'total_price',
    `quote{company{name}}`,
    'is_awarded',
  ];

  public productFields = ['name', 'description', 'unit_price'];

  public projectProductFields: string[] = [
    `name`,
    `description`,
    `quantity`,
    `is_taxable`,
    `is_enabled`,
    `product{${this.productFields.join(',')}}`,
    `quote_items{${this.quoteItemFields.join(',')}}`,
    `selected_quote_item_id`,
    `selected_quote_item{quote_id,total_price}`,
    `rank`,
    'sub_cost_code_budget{id,label,code,cost_code{label,code}}',
  ];

  public tenantFields = [
    `project_products{${this.projectProductFields.join(',')}}`,
    `type_id`,
    `tenant_name`,
    `shipping_budget`,
    `tax_budget`,
    'budget_tenant_approval_task_id',
  ];

  private projectFields = [
    'building_code',
    'cfmo_id',
    'cfmo_first_name',
    'cfmo_last_name',
    'cfmo{first_name,last_name}',
    'code',
    'dfs',
    'end_date',
    'floor_code',
    'ico',
    'module{name,workspace_type_id}',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'scope_of_work',
    `tenants{${this.tenantFields.join(',')}}`,
    'title',
    'workspace_manager_id',
    'workspace_manager_first_name',
    'workspace_manager_last_name',
    'architect_id',
    'engineer_ids',
    'project_schedule_file_id',
    'engineer_ids',
    'capx_budget{description}',
  ];

  bid: Bid;
  bidPackage: BidPackage;
  invoice: Invoice;
  project: ProjectConstruction;
  needsToBeRevised = true;
  currentUserId: number;

  async ngOnInit() {
    this.isLoading = true;
    this.isLocked = !!this.task.is_locked;
    // if (this.data?.reviewChain?.length) {
    //   this.data.reviewChain = this.displayReviewersService.removeUserInfoFromReviewChain(this.data.reviewChain);
    // }

    // Since the data is stored in the database as a string we should convert it to a JSON object if needed.
    this.convertJsonToObject();

    let project;
    if (this.task?.project_id) {
      project =
        (this.type !== TaskAccessoryType.Arf &&
          this.task?.project_id === this.projectService.currentSelectedProject?.id &&
          this.projectService.currentSelectedProject) ||
        (await this.projectService.getProjectById(this.task.project_id, this.projectFields).toPromise());

      // order the tenants project products by rank
      project.tenants = project?.tenants?.map((projectTenant: ProjectTenant) => {
        projectTenant?.project_products?.sort((product1: Product, product2: Product) => {
          if (product1.rank > product2.rank) {
            return 1;
          }
          if (product1.rank < product2.rank) {
            return -1;
          }
          return 0;
        });

        return projectTenant;
      });
    }

    this.currentPM = project?.project_manager_id;
    this.project = project;

    await this.getDataByReviewType();

    this.isLoading = false;
  }

  public async getDataByReviewType() {
    if (this.type === TaskAccessoryType.ProposalRequest) {
      this.prId = this.data.parentId || this.data.id;
      const proposalRequest = await this.projectService
        .getProposalRequests([{ type: 'field', field: 'id', value: this.prId.toString() }], this.proposalRequestFields)
        .toPromise();
      this.proposalRequest = proposalRequest[0];
    }

    if (this.type === TaskAccessoryType.ChangeOrder) {
      const coId = this.data.parentId || this.data.id;
      const cos = await this.projectService
        .getChangeOrders([{ type: 'field', field: 'id', value: coId.toString() }], this.changeOrderFields)
        .toPromise();
      this.pco = cos[0];
    }

    if (this.type === TaskAccessoryType.Invoice) {
      const id = this.data?.parentId || this.data?.id;
      await this.projectService
        .getInvoices([{ type: 'field', field: 'id', value: id.toString() }])
        .toPromise()
        .then((invoices) => {
          if (invoices?.length) {
            this.invoice = invoices[0];
          }
        });
    }

    if (this.type === TaskAccessoryType.Arf && this.data?.parentId && this.task.project_id) {
      this.quote = await this.productService.getQuoteById(this.data?.parentId, this.quoteFields).toPromise();

      const tenantId = this.quote?.quote_items.find((item) => item.project_product?.tenant_id)?.project_product
        ?.tenant_id;
      this.tenant = this.project?.tenants.find((tenant) => tenant.id === tenantId);
    }

    if (
      [TaskAccessoryType.BidContract, TaskAccessoryType.ProjectTimeline].includes(this.data?.type) &&
      this.data?.parentId
    ) {
      this.bid = await this.projectService.getBidById(this.data?.parentId, this.bidFields).toPromise();
      this.bidPackage = this.bid?.project?.bid_packages?.find((bp) => bp.id === this.bid.bid_package_id);
      this.needsToBeRevised = this.bid?.bid_contract?.status_id === BidContractStatus.RevisionRequested;
    } else {
      this.needsToBeRevised = false;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // The INIT hook does not run a second time if switching from one invoice task to another. Because of this we are doing the initialize
    // logic in the on changes hook as well.
    this.convertJsonToObject();
    if (this.data.isReviewItem || this.data.type === TaskAccessoryType.ProposalRequest) {
      this.currentUserId = this.authService.getLoggedInUser().id;

      if (this.type === TaskAccessoryType.Arf && this.data?.parentId && !this.task.project_id && !this.custodyChain) {
        const arf = await this.arfService
          .getArfById(this.data.parentId, [this.arfService.arfCustodyChainField])
          .toPromise();
        this.custodyChain = arf.custody_chain;
      }

      this.isReviewerAdmin = this.displayReviewersService.getIfUserIsReviewAdmin(
        this.data?.reviewChain,
        this.project,
        this.custodyChain
      );

      this.isLocked = !!this.task.is_locked;
      const taskIsAssignedToCurrentUser = +this.task.assigned_user_id === +this.currentUserId;
      if (this.data.reviewChain) {
        this.currentActiveReviewChainItem = this.data.reviewChain.find(
          (item) => item.status === TaskReviewStatus.Pending
        );
        this.currentUserReviewChainItem = this.data.reviewChain.find((item) => item.id === this.currentUserId);
        this.userIsCurrentReviewer =
          this.currentActiveReviewChainItem && this.currentActiveReviewChainItem.id === this.currentUserId;
        // 3/19/20 removed the requirement that the assigned user be the review user, as this doesn't make sense to me
        const reviewIsRejected = !!this.data.reviewChain.find(
          (reviewer) => reviewer.status === TaskReviewStatus.Rejected
        );
        const taskIsComplete = this.task.status_id === TaskStatus.Complete;
        this.reviewEnabledForUser =
          (this.userIsCurrentReviewer || this.isReviewerAdmin) && !reviewIsRejected && !taskIsComplete;
      } else {
        this.reviewEnabledForUser = taskIsAssignedToCurrentUser;
      }
    }
  }

  onDestroy() {
    // clean up
    this._currentTaskSubscription.unsubscribe();
  }

  get isSignatureReview() {
    return this.authService.reviewHasDigitalSignatures(this.data.type);
  }

  get UserType() {
    return UserType;
  }

  get BidContractStatus() {
    return BidContractStatus;
  }
  public convertJsonToObject() {
    if (typeof this.data === 'string') {
      this.data = JSON.parse(this.data) as TaskAccessoryData;
    }
    this.type = this.data.type;
  }

  // emits a review event for the parent
  private emitReviewChange() {
    // Subscribes to review changes
    // need to sub when so that when the data updates, we emit events
    this._currentTaskSubscription = this._taskService.taskSelectedEvent.subscribe(async (task) => {
      this.taskChanged.emit(task);
      this.reviewChanged.emit(task);
    });
  }

  public async openAddPCOModal() {
    this.dialog
      .open(ChangeOrderModalComponent, {
        width: '600px',
        data: {
          proposalRequest: this.proposalRequest,
          currentUser: this.authService.getLoggedInUser(),
          changeOrderFields: this.changeOrderFields,
          selected_company_id: this.authService.getLoggedInUser().company_id,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === true) {
          this.snackbar.open('Change Order added!');
          this.updateReviewService.reassignUser(this.task, this.data, TaskReviewStatus.Approved);
          this.projectService.createNote(ResourceType.Task, this.task.id, 'Added Proposed Change Order').subscribe();
        }
      });
  }

  public async openArfReviewModal() {
    this.modalService
      .openArfReviewModal(this.project, this.quote, this.task, this.tenant)
      .subscribe(async (approvalResult: any) => {
        if (approvalResult) {
          const isApproved = approvalResult.approvalStatus !== TaskReviewStatus.Rejected;
          const comment = approvalResult.approvalComment;
          const files = approvalResult.attachedFiles || [];
          const reviewFiles = approvalResult.reviewFiles || [];

          if (!isApproved) {
            await this.reviseReviewProcess(comment, files, approvalResult.approvalStatus);
          } else {
            const updatedReviewChain = await this.updateReview(
              approvalResult.approvalStatus,
              comment,
              files,
              reviewFiles
            );

            if (
              this.task?.parent_id &&
              this.task?.parent_resource_type_id === ResourceType.AcquisitionRequestForm &&
              !updatedReviewChain.find((r) => [TaskReviewStatus.Rejected, TaskReviewStatus.Pending].includes(r.status))
            ) {
              await this.arfService.updateArf(this.task.parent_id, { status_id: ArfStatus.ReviewComplete }).toPromise();
            }
          }
          this.emitReviewChange();
        }
      });
  }

  public async openViewPRModal() {
    this.dialog.open(ProposalRequestDialogComponent, {
      width: '500px',
      data: {
        proposalRequest: this.proposalRequest,
        preSelectedTags: [{ id: 3 }],
        can_edit: false,
      },
    });
  }

  /**
   * Opens the invoice modal to display the invoice with the given id
   * @param id Id of the invoice to display
   */

  public openChangeOrderModal() {
    const showReviewDialog = this.data.isReviewItem && this.reviewEnabledForUser;
    this.modalService
      .openViewChangeOrderModal(this.data.parentId ?? this.data.id, showReviewDialog)
      .subscribe((approvalResult) => {
        if (approvalResult) {
          this.emitReviewChange();
          const approvalStatus = approvalResult.approvalStatus;
          const comment = approvalResult.approvalComment;
          const files = approvalResult.attachedFiles;
          this.updateReview(approvalStatus, comment, [], files);
        }
      });
  }

  // Start using once the Key Controls dialog is created
  public async openKeyControlsReviewModal() {
    await this.modalService.openViewKeyControlsDialog(this.project?.id, this.data?.parentId).toPromise();
  }

  public openBidContractDialog() {
    this.modalService
      .openEditBidContractDialog(this.bid?.bid_contract || { bid_id: this.data.parentId }, this.data)
      .subscribe(async (res) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating review...');
          await this.updateReviewService.updateBidContractReviewer(res, this.task, this.bid, this.data);
          this.progressIndicatorService.close();
          this.emitReviewChange();
        }
      });
  }

  public openProjectScheduleContractReview() {
    this.modalService.openProjectScheduleContractDialog(this.project, this.task).subscribe(async (res) => {
      if (res) {
        this.emitReviewChange();
        if (res.approval === TaskReviewStatus.Approved) {
          await this.updateReview(res.approval, res.comment, res.commentFiles, res.reviewFiles);
        } else {
          await this.updateReviewService.createTaskApprovalActivity(this.task, res.approval, res.comment, res.files);
          await this.reviewRevisionService.bidContractRevision(this.bid, false, res.comment);
        }
      }
    });
  }

  public async openTaskReviewModal() {
    let openReviewDialog = true;
    if (this.isReviewerAdmin && !this.userIsCurrentReviewer) {
      openReviewDialog = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Replace Reviewers',
          headerText: 'Expedite Review',
          descriptionText: `There are reviewers in front of you. Are you sure you want to expedite this review and review on the others' behalf?`,
          confirmationButtonText: 'Expedite Review',
        })
        .toPromise();
    }

    if (openReviewDialog) {
      this.modalService.openTaskReviewModal(this.task, this.project).subscribe(async (approvalResult) => {
        if (approvalResult) {
          const isApproved = approvalResult.approvalStatus !== TaskReviewStatus.Rejected;
          const comment = approvalResult.approvalComment;
          const files = approvalResult.attachedFiles || [];

          if (!isApproved && this.type !== TaskAccessoryType.Other) {
            await this.reviseReviewProcess(comment, files, approvalResult.approvalStatus);
          } else {
            await this.updateReview(approvalResult.approvalStatus, comment, files);
          }
          this.emitReviewChange();

          if (!this.data.reviewChain || !this.authService.getLoggedInUser().id) {
            const errorSnack = this.snackbar.open(`An error occurred`, 'Report', {
              duration: 10000,
              panelClass: ['action'],
            });
            errorSnack.onAction().subscribe(() => {
              this.snackbar.open(`This error has been reported and will be fixed as soon as possible!`);
              const reportToSend: BugReport = {
                subject: 'Snackbar Report',
                inquiry_type_id: 4, // id 4 = snackbar report
                should_contact: 1,
                message: `Message: Error when reassigning an approval chain\nUser ID: ${
                  this.authService.getLoggedInUser().id
                }\nData: ${this.data}`,
                route: `Task Accessory Component`,
                follower_ids: '[]',
              };
              this.errorService.logError(reportToSend, true);
            });
            return;
          }
        }
      });
    }
  }

  public async openSubmittalsReviewDialog() {
    this.modalService.openTaskReviewModal(this.task, this.project).subscribe(async (approvalResult) => {
      if (approvalResult) {
        const isApproved = approvalResult.approvalStatus !== TaskReviewStatus.Rejected;
        const comment = approvalResult.approvalComment;
        const files = approvalResult.attachedFiles || [];
        const projectEngineers = (this.project?.engineer_ids && JSON.parse(this.project.engineer_ids)) || null;
        const engineersInReview = !!this.data?.reviewChain.find((reviewer) => projectEngineers?.includes(reviewer.id));

        if (!isApproved && this.authService.currentUser.id === this.project.architect_id && engineersInReview) {
          await this.updateReviewService.reassignToPreviousReviewer(this.task, this.data);
          await this.updateReviewService.createTaskApprovalActivity(
            this.task,
            approvalResult.approvalStatus,
            comment,
            files
          );
        } else {
          await this.updateReview(approvalResult.approvalStatus, comment, files);
        }
        this.emitReviewChange();
      }
    });
  }

  private async reviseReviewProcess(comment, files, approvalStatus) {
    await this.updateReviewService.createTaskApprovalActivity(
      this.task,
      approvalStatus,
      `The review process was reset:<br />` + `${comment}`,
      files
    );

    switch (this.type) {
      case TaskAccessoryType.PEB:
        await this.reviewRevisionService.pebInternalRevision(
          null,
          false,
          null,
          this.task.id,
          files,
          this.task.project_id
        );
        break;
      case TaskAccessoryType.CB:
        await this.reviewRevisionService.cbInternalRevision(
          null,
          false,
          null,
          this.task.id,
          files,
          this.task.project_id
        );
        break;
      case TaskAccessoryType.Punchlist:
        await this.reviewRevisionService.punchlistInternalRevision(
          null,
          false,
          null,
          this.task.id,
          files,
          this.task.project_id
        );
        break;
      case TaskAccessoryType.Arf:
        if (this.task?.project_id) {
          await this.reviewRevisionService.InternalARFRevision(
            null,
            false,
            null,
            this.task.id,
            files,
            this.task.project_id
          );
        } else {
          await this.reviewRevisionService.arfRevision(null, false, null, this.task.id, files);
        }
        break;
      case TaskAccessoryType.BidContract:
        await this.projectService
          .updateBid(this.data?.parentId, { contract_revision: this.bid?.contract_revision + 1 }, ['contract_revision'])
          .toPromise();
        await this.projectService
          .updateBidContract(this.bid.bid_contract?.id, {
            status_id: BidContractStatus.SentToVendor,
          })
          .toPromise();
        await this.updateReviewService.reassignToPreviousReviewer(this.task, this.data);
        break;
      case TaskAccessoryType.ProjectTimeline:
        await this.updateReviewService.reassignToPreviousReviewer(this.task, this.data);
        break;
      default:
        await this.updateReview(approvalStatus, comment, files);
        break;
    }
  }

  private async updateReview(
    approvalStatus: TaskReviewStatus,
    comment: string = null,
    commentFiles: UhatFileReference[] = [],
    reviewFiles = []
  ) {
    const updatedReviewChain = await this.updateReviewService.reassignUser(
      this.task,
      this.data,
      approvalStatus,
      comment,
      commentFiles,
      reviewFiles
    );
    await this.updateReviewService.createTaskApprovalActivity(this.task, approvalStatus, comment, [
      ...commentFiles,
      ...reviewFiles,
    ]);

    return updatedReviewChain;
  }

  private addSignature(file) {
    if (this.currentActiveReviewChainItem.needs_signature && !this.authService.reviewedByVendor(this.data?.type)) {
      // Need to add an additional page with the signatures
      // If the review has already been signed by someone the last page
      // Needs to be removed and the updated page added
    }
  }

  public gotoPebPage() {
    this.convertJsonToObject();
    const tenantIdToReview = this.data.tenantId;
    this.routingService.gotoPebPage(tenantIdToReview);
  }

  public gotoCbPage() {
    this.convertJsonToObject();
    const tenantIdToReview = this.data.tenantId;
    this.routingService.gotoCbPage(tenantIdToReview);
  }

  public gotoPunchlistPage() {
    this.convertJsonToObject();
    this.routingService.gotoPunchlistPage();
  }
}

/**
 * Data that the TaskAccessory Component can work with. In the future we may want to abstract the different types of Accessories out to
 * their own objects. For now, this is okay. 1/16/2020 Logan Spencer
 */
// export interface TaskAccessoryData {
//   type: TaskAccessoryType;
//   id?: number;
//   tenantId?: number;
//   isReviewItem?: boolean;
//   reviewChain?: number[];
// }

/**
 * Utility class for easily creating data of different types for use as Task Accessories
 */
export class TaskAccessoryDataFactory {
  /**
   * Create Task Accessory Data for Invoice Viewing
   */
  public static createInvoiceData(
    invoiceId: number,
    requiresReview: boolean,
    reviewIds: { id: number; status: TaskReviewStatus }[]
  ): TaskAccessoryData {
    return {
      type: TaskAccessoryType.Invoice,
      parentId: invoiceId,
      isReviewItem: requiresReview,
      reviewChain: reviewIds,
    };
  }

  public static createPebApprovalData(tenantId: number): TaskAccessoryData {
    return { type: TaskAccessoryType.PEB, tenantId };
  }

  // copied from the original (so as to not break the original function as it is used in one place). hopefully this function replaces it at some point
  public static createPebReviewApprovalData(
    tenantId: number,
    requiresReview: boolean,
    reviewIds: { id: number; status: TaskReviewStatus }[]
  ): TaskAccessoryData {
    return { type: TaskAccessoryType.PEB, isReviewItem: requiresReview, reviewChain: reviewIds };
  }

  // copied from the original (so as to not break the original function as it is used in one place). hopefully this function replaces it at some point
  public static createPunchlistReviewApprovalData(
    requiresReview: boolean,
    reviewIds: { id: number; status: TaskReviewStatus }[]
  ): TaskAccessoryData {
    return {
      type: TaskAccessoryType.Punchlist,
      isReviewItem: requiresReview,
      reviewChain: reviewIds,
    };
  }

  public static createChangeOrderData(changeOrderId: number, requiresReview: boolean): TaskAccessoryData {
    return {
      type: TaskAccessoryType.ChangeOrder,
      parentId: changeOrderId,
      isReviewItem: requiresReview,
    };
  }

  public static createCbReviewApprovalData(
    tenantId: number,
    requiresReview: boolean,
    reviewIds: { id: number; status: TaskReviewStatus }[]
  ): TaskAccessoryData {
    return { type: TaskAccessoryType.CB, isReviewItem: requiresReview, reviewChain: reviewIds };
  }
}
