import { Component, OnDestroy, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { AppProperties } from 'src/app-properties';

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.scss'],
})
export class UpdateAvailableComponent implements OnInit, OnDestroy {
  updateFound = false;
  updateDelay = false;
  delayTime = 15 * 60 * 1000;
  updateCheckFrequency = 60 * 1000;
  timerSubscription;
  constructor() {}

  async ngOnInit(): Promise<void> {
    if (AppProperties.version) {
      this.timerSubscription = timer(this.updateCheckFrequency, this.updateCheckFrequency).subscribe(() => {
        if (this.updateDelay || this.updateFound) return;
        fetch('/assets/build-version', { cache: 'reload' })
          .then((response) => response.text())
          .then((version: any) => {
            if (version && AppProperties.version !== version) {
              this.updateFound = true;
            }
          })
          .catch(() => {});
      });
    }
  }

  async reload() {
    await fetch('/', { cache: 'reload' }).catch(() => {});
    window.location.reload();
  }

  cancel() {
    this.updateFound = false;
    this.updateDelay = true;
    setTimeout(() => {
      this.updateDelay = false;
    }, this.delayTime);
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
}
