<app-base-dialog [title]="'Create PEB Section'" (closeDialog)="close()"></app-base-dialog>
<div class="d-flex flex-column align-items-center view-key-controls-dialog p-5 mx-auto" *ngIf="isLoading">
  <mat-spinner color="accent" [diameter]="40"></mat-spinner>
</div>
<form
  *ngIf="!isLoading"
  [formGroup]="sectionFormGroup"
  class="peb-section-dialog"
  id="peb-section-dialog"
  data-test-id="peb-section-dialog"
>
  <mat-tab-group
    mat-stretch-tabs
    [(selectedIndex)]="selectedSectionIndex"
    (selectedTabChange)="sectionTabChanged($event)"
    animationDuration="0ms"
  >
    <mat-tab [label]="s.isNew ? '+ Section' : s.name" *ngFor="let s of sections">
      <div class="d-flex flex-column full-width">
        <p class="dkgray">
          For multiple tenants, separate by section. Each section will be reviewed by the representative, individually.
          Only one department can be added to each section (in addition to UHAT).
        </p>
        <div class="row mb-3 align-items-top">
          <div class="col-6">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Department Name*</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input
                matInput
                type="text"
                required="true"
                formControlName="department"
                (ngModelChange)="departmentChanged()"
                [matAutocomplete]="departmentAuto"
                placeholder="Search Departments"
              />
              <mat-autocomplete #departmentAuto="matAutocomplete" [displayWith]="departmentValueMapper">
                <mat-option *ngFor="let d of filteredDepartments" [value]="d">{{ d.name }}</mat-option>
              </mat-autocomplete>
              <mat-icon matPrefix>search</mat-icon>
              <mat-hint>This is usually the tenant department occupying the space.</mat-hint>
              <mat-error *ngIf="department.errors?.required">Department is required</mat-error>
              <mat-error *ngIf="department.errors?.alreadyExists">Department is already a tenant</mat-error>
              <mat-error *ngIf="department.errors?.invalidDepartment">Must be an existing department</mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Representative Name*</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input
                matInput
                type="text"
                required="true"
                formControlName="representative"
                (ngModelChange)="representativeChanged()"
                [matAutocomplete]="representativeAuto"
                placeholder="Search People"
              />
              <mat-autocomplete #representativeAuto="matAutocomplete" [displayWith]="representativeValueMapper">
                <mat-option *ngFor="let u of filteredRepresentatives" [value]="u">
                  {{ u.first_name }} {{ u.last_name }}
                </mat-option>
                <mat-option
                  (click)="createUser()"
                  class="ltblue"
                  *ngIf="
                    filteredRepresentatives &&
                    (!representative.value || (representative.value && !representative.value.id))
                  "
                >
                  <i class="la la-plus"></i> &nbsp; Create Representative
                </mat-option>
              </mat-autocomplete>
              <mat-icon matPrefix>search</mat-icon>
              <mat-hint>This person will be responsible for reviewing items for this dept.</mat-hint>
              <mat-error *ngIf="representative.errors?.required">Representative is required</mat-error>
              <mat-error *ngIf="representative.errors?.invalidRepresentative">Must be an existing user</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-2 align-items-top">
          <div class="col-6">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Construction SF</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input placeholder="Construction SF" matInput formControlName="construction_sqft" />
              <mat-icon matPrefix class="dkgray">domain</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-6">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Occupied SF</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input placeholder="Occupied SF" matInput formControlName="occupied_sqft" />
              <mat-icon matPrefix class="dkgray">domain</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex mb-0 align-items-top">
          <div class="mr-auto d-flex flex-column">
            <h6 class="m-0 dkblue font-weight-semibold mb-0">Funding Source Columns</h6>
            <p class="dkgray">Select all funding sources that will apply relating to this tenant section.</p>
          </div>
          <mat-checkbox class="dkblue wrap-label p-3" [checked]="false" formControlName="needs_tenant_approval">
            <h6 class="dkblue m-0 font-weight-semibold">Include Tenant Review</h6>
          </mat-checkbox>
        </div>
        <div class="card-columns">
          <div class="card br-10 border border-gray p-2 px-4 mb-3" *ngFor="let t of fundingSourceTypes">
            <mat-checkbox
              [checked]="(s?.selectedFundingSourceIds ?? []).indexOf(t.id) > -1"
              (change)="fundingSourceChanged(s, t, $event.checked)"
            >
              <p class="pl-2 primary font-weight-semibold mb-0 full-width">
                {{ t.name }}
                <span class="dkgray font-weight-normal" *ngIf="t.name !== t.abbreviation">({{ t.abbreviation }})</span>
              </p>
            </mat-checkbox>
            <p class="pl-4 ml-3 dkgray mb-0 full-width">
              {{ t.description }}
            </p>
          </div>
        </div>
        <div>
          <button mat-flat-button class="float-right ml-2" color="success" type="button" (click)="submitPEBSection(s)">
            {{ s.tenant_id ? 'Save' : 'Create' }}
          </button>
          <button mat-flat-button class="float-right gray" type="button" (click)="close()">Cancel</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
