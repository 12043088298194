import { ApplicationRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF, pdf } from '@progress/kendo-drawing';
import { refresh } from 'aos';
import { ceil, floor, sumBy } from 'lodash';
import * as moment from 'moment';
import { ArfPurchaseTypeId, QuoteItemStatus, ResourceType, TaskAccessoryType, TaskReviewStatus } from '../../enums';
import {
  ArfsService,
  AuthService,
  DisplayReviewersService,
  FileService,
  ProductService,
  UserService,
} from '../../services';
import { Arf, Company, Module, Project, ProjectTenant, Quote, Task, TaskAccessoryData, User } from '../../types';

@Component({
  selector: 'app-arf-export',
  templateUrl: './arf-export.component.html',
  styleUrls: ['./arf-export.component.scss'],
})
export class ArfExportComponent implements OnChanges {
  constructor(
    public arfService: ArfsService,
    public authService: AuthService,
    private appRef: ApplicationRef,
    public displayReviewers: DisplayReviewersService,
    public fileService: FileService,
    public productService: ProductService,
    private snackbar: MatSnackBar,
    private userService: UserService
  ) {}

  @Input() arf: Arf;
  @Input() include: any;
  @Input() project: Project;
  @Input() selectedQuote: Quote;
  @Input() selectedTenant: ProjectTenant;
  @Input() runProductFilter: number;

  @ViewChild('arfFile', { static: false }) arfFile;

  public datesSigned = {};
  public reviewChain: TaskAccessoryData['reviewChain'];
  public reviewTask: Task;
  public purchaseReason: string;

  public company: Company;
  public code: string;
  public module: Module;
  public title: string;
  public purchaseTypeId: number;
  public assetTagged: number | boolean;
  public products: any;
  public total: number;
  public revision: number;
  public workspaceManager: User;
  public cto: User;
  public cfmo: User;
  public ceo: User;
  private usersToGet: number[] = [];
  public fiscalYear: number | string;

  get isStandaloneARF() {
    return !this.project;
  }

  async ngOnChanges() {
    await refresh();
  }

  async refresh(): Promise<void> {
    this.company = this.selectedQuote?.company || this.arf?.company;
    this.code = this.project?.code || this.arf?.code;
    this.module = this.project?.module || this.arf?.module;
    this.title = this.project?.title || this.arf?.title;
    this.fiscalYear = '' || this.arf?.fiscal_year;
    this.purchaseTypeId = this.selectedQuote?.arf_purchase_type_id || this.arf?.purchase_type_id;
    this.assetTagged = this.selectedQuote?.asset_tagged || this.arf?.asset_tag;
    this.products = this.selectedTenant?.project_products || this.arf?.products;
    this.total =
      (this.selectedQuote && this.productService.getSelectedQuotesTotal(this.selectedTenant, this.selectedQuote)) ||
      sumBy(this.arf?.products || [], 'total_price');
    if (!this.include) {
      this.reviewTask =
        this.selectedQuote?.arf_saved_approval_task || this.selectedQuote?.arf_approval_task || this.arf?.review_task;
      const accessoryData = this.reviewTask && JSON.parse(this.reviewTask.accessory_data);
      this.reviewChain = accessoryData?.reviewChain || this.reviewChain;
      this.include = this.getArfReviewers();
    }

    let arfReviewUsers: User[] = [];
    if (
      this.reviewChain?.length &&
      this.arf?.custody_chain_id &&
      (!this.usersToGet?.length ||
        this.usersToGet?.length !== this.reviewChain?.length ||
        !this.usersToGet.find((id) => this.reviewChain?.find((r) => r.id === id)))
    ) {
      this.usersToGet = [];
      for (const r of this.reviewChain) {
        this.usersToGet.push(r.id);
      }

      arfReviewUsers = await this.userService
        .getUserByIds(this.usersToGet, this.userService.userDisplayFields)
        .toPromise();
    }

    this.workspaceManager = this.project?.id
      ? {
          id: this.project?.workspace_manager_id,
          first_name: this.project?.workspace_manager_first_name,
          last_name: this.project?.workspace_manager_last_name,
        }
      : arfReviewUsers.find((user) => user.id === this.arf?.custody_chain?.wm_id);
    this.cto = this.project?.dfs || arfReviewUsers.find((user) => user.id === this.arf?.custody_chain?.cto_id);
    this.cfmo = this.project?.cfmo || arfReviewUsers.find((user) => user.id === this.arf?.custody_chain?.cfmo_id);

    this.ceo = this.authService.ceo;

    this.revision = this.selectedQuote?.arf_revision || this.arf?.revision;
  }
  floorDecimal(value, digits: number) {
    return floor(value, digits).toFixed(digits);
  }

  ceilDecimal(value, digits: number) {
    return ceil(value, digits).toFixed(digits);
  }

  public TaskAccessoryType = TaskAccessoryType;

  public ArfPurchaseTypeId = ArfPurchaseTypeId;

  public async createArfFile(
    quote: Quote = null,
    reviewChain: TaskAccessoryData['reviewChain'] = null,
    include: any = null,
    purchaseReason = null,
    tenant: ProjectTenant = null,
    arf: Arf = null,
    textToAppend?: string
  ) {
    this.include = include;
    this.selectedQuote = quote || this.selectedQuote;
    this.selectedTenant = tenant || this.selectedTenant;
    this.purchaseReason = purchaseReason || this.reviewTask?.description;
    this.arf = arf || this.arf;
    this.getReviewersWhoHaveSigned(reviewChain, true);
    await this.refresh();

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    pdf.defineFont({
      'Dawning of a New Day': '../../../assets/fonts/DawningofaNewDay-Regular.ttf',
    });
    const filesToAddToArf = [this.selectedQuote?.quote_file || this.arf?.quote_file];
    const files = [];

    // Generate Arf
    const transmittalGroup = await this.arfFile?.export();
    const transmittalBase64 = (await exportPDF(transmittalGroup)).replace('data:application/pdf;base64,', '');

    const transmittalByteCharacters = atob(transmittalBase64);
    const transmittalData = new Array(transmittalByteCharacters.length);

    for (let i = 0; i < transmittalByteCharacters.length; i++) {
      transmittalData[i] = transmittalByteCharacters.charCodeAt(i);
    }
    // Combine Files
    files.push({
      file: new Blob([new Uint8Array(transmittalData)]),
      name: `ARF_For_${this.company?.name}_v${this.revision}.pdf`,
    });

    for (const f of filesToAddToArf) {
      const fileToAdd = await this.fileService.downloadFile({ id: f.id }).toPromise();
      files.push({
        file: new Blob([new Uint8Array(fileToAdd.file.data)]),
        name: fileToAdd.name,
      });
    }

    let combinedFile;
    try {
      if (files.length > 1) {
        combinedFile = await this.fileService.combinePDFs(files).toPromise();
      }
    } catch (e) {
      const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
      errorSnack.onAction().subscribe(async () => {
        this.snackbar.dismiss();
      });
      return;
    }

    let fileName = `ARF-${this.company?.name}-for-${this.selectedTenant?.tenant_name || 'UHAT'}-${
      this.selectedQuote?.code ? this.selectedQuote?.code + '-' : ''
    }${this.code}-${this.module?.name}-v${this.revision}`;

    if (textToAppend) {
      fileName = `${fileName}-${textToAppend}`;
    }

    const blob = combinedFile
      ? new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' })
      : files[0].file;
    const file = new File([blob], `${fileName}.pdf`);

    return file || null;
  }

  isThirdDecimalNonZero(value) {
    return this.floorDecimal(value, 2) !== this.ceilDecimal(value, 2);
  }

  public async saveArfFile(reviewChain = null) {
    if (this.selectedQuote) {
      const updatedQuote = await this.productService
        .updateQuote(this.selectedQuote.id, { arf_revision: this.selectedQuote.arf_revision + 1 })
        .toPromise();
      this.selectedQuote.arf_revision = updatedQuote.arf_revision;
      this.revision = this.selectedQuote.arf_revision;
    } else {
      const updatedArf: Arf = await this.arfService
        .updateArf(this.arf.id, { revision: this.arf.revision + 1 })
        .toPromise();
      this.arf.revision = updatedArf.revision;
      this.revision = this.arf.revision;
    }

    const parentId = this.project?.id || this.arf?.id;
    const resourceType = this.project ? ResourceType.Project : ResourceType.AcquisitionRequestForm;

    const file = await this.createArfFile(null, reviewChain);

    const arfFile = await this.fileService.createFile(file, parentId, resourceType, file?.name).toPromise();
    return arfFile?.file_id || arfFile?.id ? [arfFile] : [];
  }

  public async exportArf(reviewChain = null): Promise<void> {
    if (reviewChain) {
      this.reviewChain = reviewChain;
      this.include = this.getArfReviewers();

      this.getReviewersWhoHaveSigned(reviewChain, false);
      await this.refresh();

      pdf.defineFont({
        'Dawning of a New Day': '../../../assets/fonts/DawningofaNewDay-Regular.ttf',
      });

      // refreshes the html component to assure all data is up-to-date.
      this.appRef.tick();

      await this.arfFile.saveAs(
        `ARF-${this.company?.name}-for-${this.selectedTenant?.tenant_name || 'UHAT'}-${this.code}-${
          this.module?.name
        }-v${this.revision}.pdf`
      );
    } else {
      this.snackbar.open('Download failed. Please try again.');
    }
  }

  public async export() {
    return await this.arfFile?.export();
  }

  getArfReviewers() {
    const totalToPass =
      this.total ||
      (this.selectedQuote && this.productService.getSelectedQuotesTotal(this.selectedTenant, this.selectedQuote)) ||
      sumBy(this.arf?.products || [], 'total_price');
    return this.displayReviewers.getArfReviewers(totalToPass, this.module || this.project?.module);
  }

  getReviewersWhoHaveSigned(reviewChain = null, updateDate) {
    if (!reviewChain) {
      this.getReviewChain();
    } else {
      this.reviewChain = reviewChain;
    }

    if (this.reviewChain?.length) {
      const currentUserId = this.authService.getLoggedInUser().id;
      let index = 0;
      for (const reviewer of this.reviewChain) {
        if (reviewer.status === TaskReviewStatus.Approved || (!index && currentUserId === reviewer.id)) {
          const reviewDate = !index && currentUserId === reviewer.id && updateDate ? moment().toDate() : reviewer.date;
          if (updateDate || reviewer.status === TaskReviewStatus.Approved) {
            this.datesSigned[reviewer.id] = reviewDate;
          }
        }

        index++;
      }
    }
  }

  getReviewChain() {
    const reviewTask =
      this.selectedQuote?.arf_saved_approval_task || this.selectedQuote?.arf_approval_task || this.arf.review_task;
    const accessoryData = reviewTask && JSON.parse(reviewTask.accessory_data);
    this.reviewChain = accessoryData?.reviewChain;
  }

  public formatSignatureDate(date) {
    return moment(date).format('MM/DD/YY h:mmA');
  }

  public get QuoteItemStatus() {
    return QuoteItemStatus;
  }

  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }
}
