import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { maxBy, minBy, sumBy } from 'lodash';
import * as moment from 'moment';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { InvoiceStatus, ResourceType, Workspace } from 'src/app/enums';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import {
  APIFilter,
  BidContract,
  InsuranceRequirements,
  Invoice,
  Meeting,
  Milestone,
  MilestoneTasks,
  Note,
  Phase,
  Project,
  ProjectFollower,
  ProjectPriority,
  ProjectStatus,
  ProjectSubstatusCategory,
  ServiceResponse,
  Task,
  User,
} from 'src/app/types';
import {
  Addendum,
  AsBuilt,
  Bid,
  BidPackage,
  BudgetData,
  BudgetItems,
  ChangeOrder,
  ProjectBudget,
  ProjectConstruction,
  ProjectDisplayData,
  ProposalRequest,
  Solicitation,
  Trade,
} from 'src/app/workspaces/construction/types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  @Output() capxIDEvent = new EventEmitter();
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    this.projectSelectedEvent.subscribe(async (project) => {
      if (project && project?.module?.id) {
        project.module_id = project.module.id;
      }
      if (project && !project.module_id) {
        const retrievedProject = await this.getProjectById(project.id, ['module_id']).toPromise();
        project.module_id = retrievedProject.module_id;
      }
      this.currentSelectedProject = project;
      this.currentSelectedProjectId = project ? project.id : null;
    });
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes('projects')) {
          this.projectSelectedEvent.emit();
        }
      }
    });
  }

  host: string = environment.serviceHost;
  projectUrl = `${this.host}/api/v1/projects`;
  projectBidDetailsUrl = `${this.host}/api/v1/project-bid-details`;
  phaseUrl = `${this.host}/api/v1/phases`;
  milestoneUrl = `${this.host}/api/v1/milestones`;
  taskUrl = `${this.host}/api/v1/tasks`;
  projectBudgetUrl = `${this.host}/api/v1/project-budgets`;
  projectStatusUrl = `${this.host}/api/v1/project-statuses`;
  taskStatusUrl = `${this.host}/api/v1/task-statuses`;
  notesUrl = `${this.host}/api/v1/notes`;
  tradesUrl = `${this.host}/api/v1/trades`;
  bidsUrl = `${this.host}/api/v1/bids`;
  bidContractsUrl = `${this.host}/api/v1/bid-contracts`;
  bidContractInsurancePoliciesUrl = `${this.host}/api/v1/bid-contract-insurance-policies`;
  bidPackagesUrl = `${this.host}/api/v1/bid-packages`;
  proposalRequestsUrl = `${this.host}/api/v1/proposal-requests`;
  proposalRequestReasonsUrl = `${this.host}/api/v1/proposal-request-reasons-options`;
  changeOrdersUrl = `${this.host}/api/v1/change-orders`;
  asBuiltsUrl = `${this.host}/api/v1/as-builts`;
  addendumsUrl = `${this.host}/api/v1/addendums`;
  invoicesUrl = `${this.host}/api/v1/invoices`;
  invoiceTimeframesUrl = `${this.host}/api/v1/invoice-timeframes`;
  insuranceRequirementsUrl = `${this.host}/api/v1/insurance-requirements`;
  solicitationsURL = `${this.host}/api/v1/solicitations`;
  solicitationTypesURL = `${this.host}/api/v1/solicitation-types`;
  phaseTypesURL = `${this.host}/api/v1/phase-types`;
  transmittalUrl = `${this.host}/api/v1/transmittal`;

  phaseTaskListURL = `${this.host}/api/v1/phase-task-list`;
  constructionProjectURL = `${this.host}/api/v1/project-construction`;
  projectPriorityURL = `${this.host}/api/v1/project-priorities`;
  projectSubstatusCategoriesURL = `${this.host}/api/v1/project-substatus-categories`;
  projectCAPXidsUrl = `${this.host}/api/v1/project-capx-ids`;
  projectCAPXBudgetUrl = `${this.host}/api/v1/project-capx-budget`;

  taskFields =
    'can_delete,project_manager_id,description,title,code,milestone_id,phase_id,project_id,module_id,status_id,status_name,sequence,milestone_sequence,assigned_user,assigned_user_id,assigned_user_first_name,assigned_user_login_enabled,assigned_user_last_name,assigned_user_email,assigned_user_type_id,due_date,note_count,file_count,conversation_count,file_approvals,followers,created_by_id,is_locked,accessory_data,review_turnaround,display_order_hash,milestone_display_order_hash,project_code,project_title,parent_id,parent_resource_type_id,task_reminder_sender,task_reminder_datetime,rank';
  arfTaskFields =
    'can_delete,description,title,code,module_id,status_id,status_name,assigned_user{is_login_enabled,user_type_id},assigned_user_id,due_date,note_count,file_count,conversation_count,file_approvals,followers,created_by_id,is_locked,accessory_data,review_turnaround,display_order_hash,parent_id,parent_resource_type_id';
  projectFields =
    'id,code,topic_group_id,topic_group,topic_category_id,topic_category,topic_id,topic{id,name,topic_type_id,topic_group_id,topic_category_id,topic_category{id,name,is_enabled,topic_group_id,topic_group{id,name}}},closed_datetime,module_id,module{name,needs_dfs_approval,workspace_type_id},status{id,name},title,building_id,building_code,building_name,building_circulation_factor,floor_id,floor_code,suite_id,department_id,budget_name,priority_id,priority_name,end_date,end_date_estimate,current_phase_id,current_phase_name,workspace_manager_id,workspace_manager_first_name,workspace_manager_last_name,workspace_manager_email,workspace_manager_office_phone,bsd,dfs,ico,one_call_director,architect_id,architect_first_name,architect_last_name,architect_email,architect_office_phone,account_coordinator_id,cfmo_id,cfmo_first_name,cfmo_last_name,project_manager_id,project_manager_first_name,project_manager_last_name,project_manager_email,project_manager_office_phone,square_footage,occupied_sqft,trades,uses_new_peb,peb_status,peb_revision,approval_task_id,tenant_approval_task_id,punchlist_pdf_id,punchlist_approval_task_id,punchlist_approval_task_status_id,saved_punchlist_approval_task_id,punchlist_revision,request_id,misc_files,punchlist_status_id,floor_plan_ids,engineer_ids,is_submittal_complete,on_schedule,floor_plans,sublease_contract_file_id,amortization_file_id,peb_files,exhibit_b_file_id,fyc_reason,fyc_approved_fiscal_year,is_architect_required,is_dfs_required,fyc_project_year,fyc_approved_budget,fyc_priority_id,fyc_impact,fyc_likely,construction_start_date,scope_of_work,project_schedule_file_id,sent_project_schedule_file_id,project_schedule_is_not_applicable,parent_bid_package{project_id,project_code},substatus_id,substatus,tags,request{request_method_id,request_method{id,name,is_enabled,is_default,icon}},followers,cost_codes,cost_code_description,capx_budget{description}';
  solicitationFields =
    'id,type_id,type_name,start_date,end_date,project_id,files,created_by_id,created_by_first_name,created_by_last_name,created_by_email,created_datetime,type_ids,types,comment,bid_package_id,tenant_id';

  public invoiceFields = [
    'bid_package_id',
    'status_id',
    'status_name',
    'title',
    'number',
    'received_date',
    'invoice_date',
    'invoice_end_date',
    'total',
    'retainage',
    'tax',
    'shipping',
    'approval_task_id',
    'approval_task_status_id',
    'approval_task{accessory_data}',
    'approval_task_accessory_data',
    'files',
    'created_datetime',
    'created_by_id',
    'review_user_id',
    'review_user_first_name',
    'review_user_last_name',
    'revision',
    'trade_name',
    'trade_allows_nonbid_invoices',
    'trade_is_consultant',
    'review_comment',
    'company_id',
    'company_name',
    'bid_contact_id',
    'bid_contact_first_name',
    'bid_contact_last_name',
    'bid_contact_email',
    'bid_contact_office_phone',
    'bid_contact_cell_phone',
    'change_order_id',
    'change_order_code',
    'change_order_local_index',
    'is_internally_funded',
    'is_contingency_funded',
    'is_gencon_funded',
    'tenant_id',
    'tenant_name',
    'timeframe_id',
    'timeframe_name',
    'trade_name',
    'is_retainage',
    'project_id',
    'processed_by{first_name,last_name,title}',
    'processed_by_id',
    'processed_datetime',
    'quote_id',
    'quote{arf_purchase_type_id,description}',
    'module_id',
    'parent_id',
    'parent_resource_type_id',
    'arf_invoice_amounts{amount,sub_cost_code_budget{code,label,cost_code{label,code},sub_cost_code{fiscal_year}}}',
  ];

  private tradeFields = ['id', 'name', 'allow_bids'];
  private projectCAPXBudgetFields = ['capx_id', 'description', 'budgets'];

  currentSelectedProject: ProjectConstruction;

  projectSelectedEvent = new EventEmitter<ProjectConstruction>();

  // TODO eventually implement this in the other locations where tasks are created
  taskCreatedEvent = new EventEmitter<Task>();

  // emits the taskId on delete event
  deleteTaskEvent = new EventEmitter<number>();

  currentSelectedProjectId: number;

  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  async selectProjectById(projectId: number, fields?: string[], getImageData?: boolean) {
    const project = await this.getProjectById(projectId, fields, getImageData).toPromise();
    if (project) {
      this.projectSelectedEvent.emit(project);
    }
    return project;
  }

  // Returns all projects with the given projectIds
  getProjectsByProjectIds(projectIds: number[]): Observable<ProjectConstruction[]> {
    return this.http.get(`${this.projectUrl}?fields=${this.projectFields}&filter=id=${projectIds.join('^')}`).pipe(
      map((result: ServiceResponse) => result.data.projects),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // Returns all projects with the given projectIds
  getProjectsByModuleId(moduleId: number, fields?: string[]): Observable<Project[]> {
    const fieldsString = fields ? fields.join(',') : this.projectFields;
    return this.http.get(`${this.projectUrl}?fields=${fieldsString}&filter=module_id=${moduleId}`).pipe(
      map((result: ServiceResponse) => result.data.projects),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjects(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    distinct?: boolean
  ): Observable<any[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);

    return this.http
      .get(
        `${this.projectUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}${
          distinct ? `&distinct=true` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projects: any[] = result.data.projects;
          return projects;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectsData(
    fields?: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    offset?: number
  ): Observable<ServiceResponse> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);

    return this.http
      .get(
        `${this.projectUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}${
          offset ? `&offset=${offset}` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return result;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectIdByRequestId(id?: number): Observable<ProjectDisplayData[]> {
    return this.http.get(`${this.projectUrl}?fields=id&filter=request_id=${id}`).pipe(
      map((result: ServiceResponse) => {
        const projects: ProjectDisplayData[] = result.data.projects;
        return projects;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  setOnSchedule(projectId: number, onSchedule: boolean): Observable<ProjectConstruction> {
    const body = { on_schedule: +onSchedule };
    return this.http.put(`${this.projectUrl}/${projectId}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProject(
    projectId: number,
    projectToUpdate: ProjectConstruction,
    fields?: string[]
  ): Observable<ProjectConstruction> {
    const url = fields
      ? `${this.projectUrl}/${projectId}?fields=${fields.join(',')}`
      : `${this.projectUrl}/${projectId}`;
    return this.http.put(url, projectToUpdate).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      }),
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectBubbleDrawings(projectId: number, fileIds: number[]): Observable<ProjectConstruction> {
    const body = { bubble_drawing_ids: `[${fileIds.join(',')}]` };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectMiscFiles(projectId: number, fileIds: number[]): Observable<ProjectConstruction> {
    const body = { misc_file_ids: fileIds.length > 0 ? `[${fileIds.join(',')}]` : `[]` };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectFloorPlans(projectId: number, fileIds: number[]): Observable<ProjectConstruction> {
    const body = { floor_plan_ids: fileIds.length > 0 ? `[${fileIds.join(',')}]` : `[]` };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the id of the exhibit b contract file (that was approved internally or is waiting to be improved)
  updateProjectExhibitBFile(projectId: number, fileId: number): Observable<ProjectConstruction> {
    const body = { exhibit_b_file_id: fileId };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the id of the sublease contract file (that was approved internally or is waiting to be improved)
  updateProjectSubleaseContractFile(projectId: number, fileId: number): Observable<ProjectConstruction> {
    const body = { sublease_contract_file_id: fileId };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the id of the amortization file (that was approved internally or is waiting to be improved)
  updateProjectAmortizationFile(projectId: number, fileId: number): Observable<ProjectConstruction> {
    const body = { amortization_file_id: fileId };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the list of peb files associated with this project (these relate to the finalized versions of the files that have been approved internally)
  updateProjectPebFiles(projectId: number, fileIds: number[]): Observable<ProjectConstruction> {
    const body = { peb_files: `[${fileIds.join(',')}]` };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // updates the task id associated with reviewing the files for approval (for internal review before going to the tenant)
  updateProjectApprovalTask(projectId: number, approvalTaskId: number): Observable<ProjectConstruction> {
    const body = { approval_task_id: approvalTaskId };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: ProjectConstruction = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjectById(id, fields?: string[], getImageData?: boolean): Observable<ProjectConstruction> {
    return this.http.get(`${this.projectUrl}/${id}?fields=${fields ? fields.join(',') : this.projectFields}`).pipe(
      map((result: ServiceResponse) => {
        const project: ProjectConstruction = result.data.project[0];
        // Get file image data base 64. Do this for bubble drawings, floor plans, and misc files
        // if (getImageData) {
        //   const floorPlans = project.floor_plans || [];
        //   const miscFiles = project.misc_files || [];
        //   for (const file of floorPlans.concat(miscFiles)) {
        //     this.fileService.fillFileWithBase64(file);
        //   }
        // }
        return project;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  // WARNING: This function is meant to not catch any errors and to instead allow the calling function to catch them
  getProjectByIdSuppressed(id, fields?: string[]): Observable<ProjectConstruction> {
    return this.http.get(`${this.projectUrl}/${id}?fields=${fields ? fields.join(',') : this.projectFields}`).pipe(
      map((result: ServiceResponse) => {
        const projects: ProjectConstruction[] = result.data.project;
        return projects[0];
      })
    );
  }

  getProjectsByStatus(status: string): Observable<ProjectConstruction[]> {
    return this.http
      .get(
        `${this.projectUrl}?filter=status=${status}&fields=id,code,title,status,building_name,priority_id,priority_name,workspace_manager_first_name,workspace_manager_last_name,architect_first_name,architect_last_name,current_phase_name`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projects: ProjectConstruction[] = result.data.projects;
          return projects;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  completeCurrentPhaseForProject(projectId: number) {
    // get all phases for the project
    this.getPhasesByProjectId(projectId).subscribe((phases) => {
      this.getProjectById(projectId, ['current_phase_id', 'current_phase_sequence']).subscribe((project) => {
        // let status = 'active';
        let statusId = 1;
        const maxPhase = maxBy(phases, 'sequence');
        let currentPhaseId = project.current_phase_id;
        const currentPhaseSequence = project.current_phase_sequence;
        // if not last phase, set current_phase_id to next
        if (maxPhase.id !== currentPhaseId) {
          const nextPhaseId = minBy(phases.filter((phase) => phase.sequence > currentPhaseSequence)).id;
          currentPhaseId = nextPhaseId;
          const body = { current_phase_id: nextPhaseId };
          this.http
            .put(`${this.projectUrl}/${projectId}?fields=current_phase_id`, body)
            .pipe(
              map((result: ServiceResponse) => {
                return result;
              }),
              catchError((e) => this.handleErrorService.handleError(e))
            )
            .subscribe();
        } else {
          // if last phase, set status to closed
          const body = { status_id: 3 };
          statusId = body.status_id;
          this.http
            .put(`${this.projectUrl}/${projectId}?fields=status`, body)
            .pipe(
              map((result: ServiceResponse) => {
                return result;
              }),
              catchError((e) => this.handleErrorService.handleError(e))
            )
            .subscribe();
        }
        this.currentSelectedProject.current_phase_id = currentPhaseId;
        this.currentSelectedProject.status_id = statusId;
        this.snackbar.open('Phase Completed');
      });
    });
  }

  setPhaseIdForProject(projectId: number, phaseId: number): Observable<any> {
    const body = { current_phase_id: phaseId };
    return this.http.put(`${this.projectUrl}/${projectId}?fields=${this.projectFields}`, body).pipe(
      map((result: ServiceResponse) => {
        return result.data.project;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjectBidDetailsByProjectId(projectId: number, fields?: string[]) {
    return this.http.get(`${this.projectBidDetailsUrl}?filter=project_id=${projectId}&fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const projectBidDetails =
          result.data.project_bid_details && result.data.project_bid_details[0]
            ? result.data.project_bid_details[0]
            : null;
        return projectBidDetails;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSolicitationTypes() {
    return this.http.get(`${this.solicitationTypesURL}`).pipe(
      map((result: ServiceResponse) => {
        const solicitationTypes = result.data.solicitation_types;
        return solicitationTypes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getSolicitationsByProjectId(projectId: number, fields?: Solicitation[]) {
    const queryFor = fields && fields.length > 0 ? fields.join(',') : this.solicitationFields;
    return this.http.get(`${this.solicitationsURL}?filter=project_id=${projectId}&fields=${queryFor}`).pipe(
      map((result: ServiceResponse) => {
        const solicitations: Solicitation[] = result.data.solicitations;
        // this is sorted by created datetime in order to be more coherent to the user
        solicitations.sort((a, b) => {
          return a.created_datetime < b.created_datetime ? -1 : 1;
        });
        solicitations.forEach((solicitation) => {
          if (!solicitation.files) {
            solicitation.files = [];
          }
        });
        return solicitations;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createSolicitation(solicitation: Solicitation) {
    const body = {
      start_date: solicitation.start_date ? moment(solicitation.start_date).format('YYYY-MM-DD') : null,
      end_date: solicitation.end_date ? moment(solicitation.end_date).format('YYYY-MM-DD') : null,
      bid_package_id: solicitation.bid_package_id,
      project_id: solicitation.project_id,
      tenant_id: solicitation.tenant_id,
      type_ids: solicitation.type_ids,
      comment: solicitation.comment,
    };
    return this.http.post(`${this.solicitationsURL}`, body).pipe(
      map((result: ServiceResponse) => {
        const createdSolicitation = result.data.solicitation;
        return createdSolicitation;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateSolicitation(solicitation: Solicitation) {
    const body = {
      start_date: solicitation.start_date ? moment(solicitation.start_date).format('YYYY-MM-DD') : null,
      end_date: solicitation.end_date ? moment(solicitation.end_date).format('YYYY-MM-DD') : null,
      type_ids: solicitation.type_ids,
      comment: solicitation.comment,
    };
    return this.http.put(`${this.solicitationsURL}/${solicitation.id}`, body).pipe(
      map((result: ServiceResponse) => {
        const solicitations = result.data.solicitations;
        return solicitations;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteSolicitation(solicitation: Solicitation) {
    return this.http.delete(`${this.solicitationsURL}/${solicitation.id}`).pipe(
      map((result: ServiceResponse) => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProjectBidDetails(projectBidDetailsToUpdate, fields?) {
    const fieldsString = fields.join(',');
    const body = projectBidDetailsToUpdate;
    const projectBidDetailsId = projectBidDetailsToUpdate.id;
    delete body.id;
    return this.http
      .put(
        `${this.projectBidDetailsUrl}/${projectBidDetailsId}?fields=${fieldsString || 'additional_requirements'}`,
        body
      )
      .pipe(
        map((result: ServiceResponse) => {
          const updatedProjectBidDetails = result.data['project bid details'];
          return updatedProjectBidDetails;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getPhasesByProjectId(projectId: number, fields = null): Observable<Phase[]> {
    fields = fields?.join(',') || 'id,code,name,type_id,sequence';
    return this.http.get(`${this.phaseUrl}?filter=project_id=${projectId}&fields=${fields}`).pipe(
      map((result: ServiceResponse) => {
        const phases: Phase[] = result.data.phases;
        return phases;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getPhaseTypes(fields: string[]) {
    return this.http.get(`${this.phaseTypesURL}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const phaseTypes = result.data.phase_types;
        return phaseTypes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProjectStatuses(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string,
    showAll: boolean = false
  ): Observable<ProjectStatus[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectStatusUrl}?limit=${limit || 1000}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const projectStatuses: ProjectStatus[] = result.data.project_statuses;
          return showAll ? projectStatuses.concat({ id: -1, name: 'View All' }) : projectStatuses;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectBudgets(): Observable<ProjectBudget[]> {
    return this.http.get(`${this.projectBudgetUrl}?fields=name,description`).pipe(
      map((result: ServiceResponse) => {
        const projectBudgets: ProjectBudget[] = result.data.project_budgets;
        return projectBudgets;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getMilestonesByPhaseId(phaseId: number): Observable<Milestone[]> {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    const closedMilestones = (preferences && preferences.closed_milestones) || false;

    return this.http.get(`${this.phaseTaskListURL}/byPhase/${phaseId}`).pipe(
      map((result: ServiceResponse) => {
        const milestones: Milestone[] = result.data.milestones.map((milestone) => {
          milestone.is_closed = closedMilestones && closedMilestones[milestone.id];
          return milestone;
        });

        return milestones;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getMilestonesByPhaseIdMin(phaseId: number): Observable<Milestone[]> {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    const closedMilestones = (preferences && preferences.closed_milestones) || false;

    return this.http.get(`${this.phaseTaskListURL}/byPhaseMin/${phaseId}`).pipe(
      map((result: ServiceResponse) => {
        const milestones: Milestone[] = result.data.milestones.map((milestone) => {
          milestone.is_closed = closedMilestones && closedMilestones[milestone.id];
          return milestone;
        });

        return milestones;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getMilestoneById(milestoneId: number): Observable<Milestone> {
    return this.http
      .get(
        `${this.milestoneUrl}?filter=id=${milestoneId}&fields=progress,name,sequence,start_date,end_date,phase_name,phase_id,project_id,project_code,project_title&limit=1`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const milestones: Milestone[] = result.data.milestones;
          return milestones[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public getMilestonesByPhaseNameAndProjectId(
    projectId: number,
    phaseName: string
  ): Observable<{ milestones: Milestone[]; phaseId: number }> {
    const bh = new ReplaySubject<{ milestones: Milestone[]; phaseId: number }>();
    this.getPhaseByPhaseNameAndProject(projectId, phaseName).subscribe((phase) => {
      if (phase) {
        this.getMilestonesByPhaseId(phase.id).subscribe((milestones: Milestone[]) => {
          bh.next({ milestones, phaseId: phase.id });
          bh.complete();
        });
      } else {
        bh.next(null);
        bh.complete();
      }
    });
    return bh.asObservable();
  }

  getMilestoneByNameAndPhaseName(projectId: number, name: string, phaseName: string): Observable<Milestone> {
    return this.http
      .get(
        `${this.milestoneUrl}?filter=project_id=${projectId},name=${name},phase_name=${phaseName}&fields=id,phase_id`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const milestones: Milestone[] = result.data.milestones;
          return milestones[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getPhaseByPhaseNameAndProject(projectId: number, phaseName: string): Observable<Phase> {
    return this.http
      .get(`${this.phaseUrl}?filter=name=${phaseName},project_id=${projectId}&fields=id,name,project_id,code`)
      .pipe(
        map((result: ServiceResponse) => {
          const phases: Phase[] = result.data.phases;
          return phases[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getMilestones(fields?: string[], filter?: APIFilter[], sort = '', order = '') {
    const filterString: string = this.apiFilterService.getFilterString(filter);
    const fieldString: string = fields ? `fields=${fields.join(',')}` : null;
    return this.http
      .get(
        `${this.milestoneUrl}?${fieldString ? fieldString : ''}&${
          filterString ? filterString : ''
        }&sort=${sort}&order=${order}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const milestones: Milestone[] = result.data.milestones;
          return milestones;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getPhaseLength(milestones: Milestone[]) {
    const firstMilestone = milestones
      .filter((m) => m.start_date)
      .map((m) => moment(m.start_date))
      .sort((a, b) => (a > b ? 1 : -1))[0];
    const lastMilestone = milestones
      .filter((m) => m.end_date)
      .map((m) => moment(m.end_date))
      .sort((a, b) => (a > b ? -1 : 1))[0];

    const phaseStart = firstMilestone ? firstMilestone.format('M/D') : 'Not Defined';
    const phaseEnd = lastMilestone ? lastMilestone.format('M/D') : 'Not Defined';
    if (phaseStart === 'Not Defined' && phaseEnd === 'Not Defined') {
      return 'Dates Not Defined';
    } else {
      return `${phaseStart} - ${phaseEnd}`;
    }
  }

  getPhaseDuration(phaseID: number) {
    return this.http.get(`${this.phaseTaskListURL}/duration/${phaseID}`).pipe(
      map((result: ServiceResponse) => result.data.duration),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createMilestone(milestone: Milestone): Observable<Milestone> {
    const body = milestone;
    return this.http.post(`${this.milestoneUrl}?fields=name,sequence,start_date,end_date,phase_name,name`, body).pipe(
      map((result: ServiceResponse) => {
        const createdMilestone: Milestone = result.data.milestone;
        return createdMilestone;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateMilestone(milestone: Milestone): Observable<Milestone> {
    const body = {
      name: milestone.name,
      start_date: milestone.start_date,
      end_date: milestone.end_date,
      progress: milestone.progress,
    };
    const milestoneId = milestone.id;
    return this.http
      .put(`${this.milestoneUrl}/${milestoneId}?fields=progress,name,sequence,start_date,end_date`, body)
      .pipe(
        map((result: ServiceResponse) => {
          const updatedMilestone: Milestone = result.data.milestone;
          console.log(updatedMilestone);
          return updatedMilestone;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateMilestoneRank(milestoneId: number, rank: string): Observable<Milestone> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.http.put(`${this.milestoneUrl}/${milestoneId}`, { rank }).pipe(
      map((result: ServiceResponse) => {
        const updatedMilestone: Milestone = result.data.milestone;
        return updatedMilestone;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deactivateMilestone(milestoneId: number): Observable<void> {
    return this.http.delete(`${this.milestoneUrl}/${milestoneId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTasksByPhaseId(phaseId: number): Observable<Task[]> {
    return this.http
      .get(
        `${this.taskUrl}?filter=phase_id=${phaseId}&fields=${this.taskFields}&sort=milestone_sequence,sequence&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const tasks: Task[] = result.data.tasks;
          return tasks;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTasksByMilestoneId(milestoneId: number): Observable<Task[]> {
    return this.http
      .get(`${this.taskUrl}?filter=milestone_id=${milestoneId}&fields=${this.taskFields}&sort=sequence&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          const tasks: Task[] = result.data.tasks;
          return tasks;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // also grabs relevant task information, such as note count, followers, and conversation count
  getTasksByMilestoneIds(milestoneIds: number[]): Observable<MilestoneTasks[]> {
    return this.http
      .get(
        `${this.taskUrl}?filter=milestone_id=${milestoneIds.join('^')}&fields=${
          this.taskFields
        }&sort=sequence&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const tasks: Task[] = result.data.tasks;

          const milestones: MilestoneTasks[] = [];
          tasks.forEach((task) => {
            const foundMilestone = milestones.find((milestone) => milestone.id === task.milestone_id);
            if (foundMilestone) {
              foundMilestone.tasks.push(task);
              foundMilestone.display_order_hash = task.milestone_display_order_hash;
            } else {
              milestones.push({ id: task.milestone_id, tasks: [task] });
            }
          });
          return milestones;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // get tasks by project
  getTasksByProject(projectId: number, fields?: string[]) {
    return this.http
      .get(
        `${this.taskUrl}?filter=project_id=${projectId}&fields=${
          fields ? fields.join(',') : this.taskFields
        }&sort=sequence&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const tasks: Task[] = result.data.tasks;
          return tasks;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTaskById(taskId: number, fields?: string[], allowedErrors?: number[]): Observable<Task> {
    return this.http
      .get(`${this.taskUrl}/${taskId}?fields=${fields ? fields.join(',') : this.taskFields}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.task[0];
        }),
        catchError((e) => this.handleErrorService.handleError(e, allowedErrors))
      );
  }

  // WARNING: This function is meant to not catch any errors and to instead allow the calling function to catch them
  getTaskByIdSuppressed(taskId: number, fields?: string[]): Observable<Task> {
    return this.http
      .get(`${this.taskUrl}/${taskId}?fields=${fields ? fields.join(',') : this.taskFields}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          return result.data.task[0];
        })
      );
  }

  public getMilestonesByProjectId(projectId: number): Observable<Milestone[]> {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    const closedMilestones = (preferences && preferences.closed_milestones) || false;

    return this.http
      .get(
        `${this.phaseTaskListURL}/byProject/${projectId}?fields=name,sequence,start_date,end_date,phase_name,display_order_hash&sort=end_date&order=asc`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const milestones: Milestone[] = result.data.milestones.map((milestone) => {
            milestone.is_closed = closedMilestones && closedMilestones[milestone.id];
            return milestone;
          });
          return milestones;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // get milestones by project
  getMilestonesByProject(projectId: number, fields?: string[], sortField?: string, sortOrder?: string) {
    return this.http
      .get(
        `${this.milestoneUrl}?filter=project_id=${projectId}&fields=${fields ? fields.join(',') : []}${
          sortField ? `&sort=${sortField}` : ''
        }${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const milestones: Milestone[] = result.data.milestones;
          return milestones;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // required fields for task are title and milestone_id
  // TODO: Move to Task Service
  createTask(task: Task): Observable<Task> {
    const body = task;
    return this.http
      .post(
        `${this.taskUrl}?fields=can_delete,description,title,milestone_id,status_id,status_name,sequence,assigned_user_id,assigned_user_first_name,assigned_user_login_enabled,assigned_user_last_name,assigned_user_email,due_date,assigned_user_type_id`,
        body
      )
      .pipe(
        map((result: ServiceResponse) => {
          const createdTask: Task = result.data.task;
          return createdTask;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateTask(task: Task, fields: string = null): Observable<Task> {
    const body = task;
    const taskId = task.id;
    delete body.id;
    fields = fields || this.taskFields;
    return this.http.put(`${this.taskUrl}/${taskId}?fields=${fields}`, body).pipe(
      map((result: ServiceResponse) => {
        const resultTask: Task = result.data.task;
        return resultTask;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deactivateTask(taskId: number): Observable<void> {
    this.deleteTaskEvent.emit(taskId);
    return this.http.delete(`${this.taskUrl}/${taskId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  loadTaskActivity(taskId: number) {
    return this.http.get(`${this.taskUrl}/taskData/${taskId}`).pipe(
      map((result: ServiceResponse) => {
        return result.data;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getNotesByTaskId(taskId: number, cursor?: string): Observable<{ cursor: string; notes: Note[] }> {
    return this.http
      .get(
        `${
          this.notesUrl
        }?filter=parent_type_id=7,parent_id=${taskId}&fields=created_by_id,created_by_first_name,created_by_last_name,created_datetime,message,files&limit=1000${
          cursor ? `&cursor=${cursor}` : ''
        }`
      )
      .pipe(
        map((result: ServiceResponse) => {
          return {
            notes: result.data.notes,
            cursor: result.next,
          };
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createNote(parentResourceType: ResourceType, parentId: number, message: string): Observable<Note> {
    const noteToCreate: Note = {
      parent_type_id: parentResourceType,
      parent_id: parentId,
      message,
    };
    const body = noteToCreate;
    return this.http
      .post(
        `${this.notesUrl}?fields=created_by_id,created_datetime,message,created_by_first_name,created_by_last_name`,
        body
      )
      .pipe(
        map((result: ServiceResponse) => {
          const noteToReturn = result.data.note;
          return noteToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getProjectSchedule(project) {
    return this.http.get(`${this.projectUrl}/${project.id}/schedule`).pipe(
      map((result: ServiceResponse) => {
        const schedule = result.data.project_schedule;
        return schedule;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTrades(apiFilters?: APIFilter[], fields: string[] = this.tradeFields): Observable<Trade[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.tradesUrl}?fields=${fields.join(',')}&${filterString}`).pipe(
      map((result: ServiceResponse) => {
        const trades = result.data.trades;
        return trades;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidPackages(apiFilters: APIFilter[], fields: string[]): Observable<BidPackage[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.bidPackagesUrl}?fields=${fields.join(',')}&${filterString}`).pipe(
      map((result: ServiceResponse) => {
        const bidPackages = result.data.bid_packages;
        return bidPackages;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidPackageById(bidPackageId: number, fields: string[]): Observable<BidPackage> {
    return this.http.get(`${this.bidPackagesUrl}/${bidPackageId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const bidPackages: BidPackage[] = result.data['bid package'];
        return bidPackages[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidPackagesForProjects(projects: number[], fields: string[]): Observable<BidPackage[]> {
    return this.http
      .get(`${this.bidPackagesUrl}?fields=${fields.join(',')}&filter=project_id=${projects.join('^')}`)
      .pipe(
        map((result: ServiceResponse) => {
          const bidPackages = result.data.bid_packages;
          return bidPackages;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getBidsForBidPackageIds(ids: number[], fields: string[]): Observable<Bid[]> {
    const filterString = `filter=bid_package_id=${ids.join('^')}`;
    return this.http.get(`${this.bidsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const bids: Bid[] = result.data.bids;
        return bids;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBids(apiFilters: APIFilter[], fields: string[]) {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.bidsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const bids = result.data.bids;
        return bids;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createBidPackage(bidPackage, fields?: string[]) {
    return this.http.post(`${this.bidPackagesUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, bidPackage).pipe(
      map((result: ServiceResponse) => {
        const bidPackageToReturn = result.data['bid package'];
        return bidPackageToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateBidPackage(bidPackageId, bidPackage, fields: string[]) {
    return this.http.put(`${this.bidPackagesUrl}/${bidPackageId}?fields=${fields.join(',')}`, bidPackage).pipe(
      map((result: ServiceResponse) => {
        const bidPackageToReturn = result.data['bid package'];
        return bidPackageToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteBidPackage(bidPackageId: number): Observable<void> {
    return this.http.delete(`${this.bidPackagesUrl}/${bidPackageId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  async updateBidPackageStatus(bidPackage: BidPackage): Promise<void> {
    const newStatus = bidPackage?.status_id === 2 ? 1 : 2;
    bidPackage.status_id = newStatus;

    const bidPackageData: BidPackage = {
      status_id: newStatus,
    };

    await this.updateBidPackage(bidPackage?.id, bidPackageData, ['status_id']).toPromise();
  }

  getBidById(bidId: number, fields: string[]): Observable<Bid> {
    return this.http.get(`${this.bidsUrl}/${bidId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const bids: Bid[] = result.data.bid;
        return bids[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createBid(bid, fields: string[]) {
    return this.http.post(`${this.bidsUrl}?fields=${fields.join(',')}`, bid).pipe(
      map((result: ServiceResponse) => {
        const bidToReturn = result.data.bid;
        return bidToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateBid(bidId, bid, fields: string[]) {
    return this.http.put(`${this.bidsUrl}/${bidId}?fields=${fields.join(',')}`, bid).pipe(
      map((result: ServiceResponse) => {
        const bidToReturn = result.data.bid;
        return bidToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteBid(bidId: number): Observable<void> {
    return this.http.delete(`${this.bidsUrl}/${bidId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidContractById(bidContractId: number, fields: string[]): Observable<BidContract> {
    return this.http.get(`${this.bidContractsUrl}/${bidContractId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const bidContracts: BidContract[] = result.data['bid contract'];
        return bidContracts[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidContracts(apiFilters: APIFilter[], fields: string[]): Observable<BidContract[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.bidContractsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const bidContracts: BidContract[] = result.data.bid_contracts;
        return bidContracts;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createBidContract(bidContract: BidContract, fields?: string[]): Observable<BidContract> {
    return this.http.post(`${this.bidContractsUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, bidContract).pipe(
      map((result: ServiceResponse) => {
        const bidContractToReturn: BidContract = result.data['bid contract'];
        return bidContractToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateBidContract(bidContractId: number, bidContract: BidContract, fields?: string[]) {
    return this.http
      .put(`${this.bidContractsUrl}/${bidContractId}?${fields ? `fields=${fields.join(',')}` : ''}`, bidContract)
      .pipe(
        map((result: ServiceResponse) => {
          const bidContractToReturn: BidContract = result.data['bid contract'];
          return bidContractToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteBidContract(bidContractId: number): Observable<void> {
    return this.http.delete(`${this.bidContractsUrl}/${bidContractId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getBidContractInsurancePolicies(apiFilters: APIFilter[], fields: string[]) {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(`${this.bidContractInsurancePoliciesUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          const bids = result.data.bid_contract_insurance_policies;
          return bids;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  signBidContractAsVendor(bidContractId: number, bidContract: { vendor_signature_text: string }, fields?: string[]) {
    return this.http
      .put(
        `${this.bidContractsUrl}/${bidContractId}/vendor-signature?${fields ? `fields=${fields.join(',')}` : ''}`,
        bidContract
      )
      .pipe(
        map((result: ServiceResponse) => {
          const bidContractToReturn: BidContract = result.data['bid contract'];
          return bidContractToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  signBidContractAsTrust(bidContractId: number, bidContract, fields?: string[]) {
    return this.http
      .put(
        `${this.bidContractsUrl}/${bidContractId}/trust-signature?${fields ? `fields=${fields.join(',')}` : ''}`,
        bidContract
      )
      .pipe(
        map((result: ServiceResponse) => {
          const bidContractToReturn: BidContract = result.data['bid contract'];
          return bidContractToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getTimelineTrades(bidId: number): Observable<BidPackage[]> {
    return this.http.get(`${this.bidsUrl}/${bidId}/timeline-trades`).pipe(
      map((result: ServiceResponse) => {
        const timelineTrades: BidPackage[] = result.data;
        return timelineTrades;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getTimelineMeetings(bidId: number): Observable<Meeting[]> {
    return this.http.get(`${this.bidsUrl}/${bidId}/timeline-meetings`).pipe(
      map((result: ServiceResponse) => {
        const bidMeetings: Meeting[] = result.data;
        return bidMeetings;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  signProjectScheduleAsVendor(bidId: number, bid: { timeline_vendor_signature_text: string }, fields?: string[]) {
    return this.http
      .put(`${this.bidsUrl}/${bidId}/timeline-vendor-signature?${fields ? `fields=${fields.join(',')}` : ''}`, bid)
      .pipe(
        map((result: ServiceResponse) => {
          const bidToReturn: Bid = result.data.bid;
          return bidToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateBidContractInsuranceFile(bidContractId: number, bidContract: BidContract, fields?: string[]) {
    return this.http
      .put(
        `${this.bidContractsUrl}/${bidContractId}/insurance-files?${fields ? `fields=${fields.join(',')}` : ''}`,
        bidContract
      )
      .pipe(
        map((result: ServiceResponse) => {
          const bidContractToReturn: BidContract = result.data['bid contract'];
          return bidContractToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  reviseBidContract(bidContractId: number, bidContract?: BidContract, fields?: string[]) {
    return this.http
      .put(
        `${this.bidContractsUrl}/${bidContractId}/request-revision?${fields ? `fields=${fields.join(',')}` : ''}`,
        bidContract
      )
      .pipe(
        map((result: ServiceResponse) => {
          const bidContractToReturn: BidContract = result.data['bid contract'];
          return bidContractToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  removeBidContractFromBid(bidContractId: number) {
    return this.http.delete(`${this.bidContractsUrl}/${bidContractId}/remove-from-bid`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getInsuranceRequirements(apiFilters: APIFilter[], fields: string[]): Observable<InsuranceRequirements[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(`${this.insuranceRequirementsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`)
      .pipe(
        map((result: ServiceResponse) => {
          const insuranceRequirements: InsuranceRequirements[] = result.data.insurance_requirements;
          return insuranceRequirements;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  updateTransmittalStatus(bidPackageId: number, transmittalData) {
    return this.http.put(`${this.bidPackagesUrl}/${bidPackageId}/transmittal`, transmittalData).pipe(
      map((result: ServiceResponse) => {
        const transmittalToReturn = result.data.transmittal;
        return transmittalToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  sendNotAwardedVendorEmail(bidPackageId: number) {
    return this.http.put(`${this.bidPackagesUrl}/${bidPackageId}/send-not-awarded-email`, null).pipe(
      map((result: ServiceResponse) => {
        const transmittalToReturn = result?.data?.transmittal;
        return transmittalToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getConstructionBudget(bidPackages: BidPackage[]) {
    const allowTradeBidPackages = [];
    const noTradeBidPackages = [];
    bidPackages.map((bidPackage) => {
      bidPackage.trade_allows_bids ? allowTradeBidPackages.push(bidPackage) : noTradeBidPackages.push(bidPackage);
    });

    return sumBy(allowTradeBidPackages, 'awarded_amount') + sumBy(noTradeBidPackages, 'budget_amount');
  }

  getChangeOrderTotal(changeOrders: ChangeOrder[]) {
    return sumBy(changeOrders, 'cost_change');
  }

  getPebTotal(projectTenants, pebItems) {
    let approvedBudget = 0;
    projectTenants.forEach((tenant) => {
      const selectedPEBItems = pebItems.filter((i) => i.peb_id === tenant.selected_peb_id);
      approvedBudget += sumBy(selectedPEBItems, 'subtotal');
    });
    return approvedBudget;
  }

  getProposalRequests(apiFilters: APIFilter[], fields: string[]): Observable<ProposalRequest[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.proposalRequestsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const proposalRequests: ProposalRequest[] = result.data.proposal_requests;
        return proposalRequests;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createProposalRequest(proposalRequest: ProposalRequest, fields: string[]) {
    return this.http.post(`${this.proposalRequestsUrl}?fields=${fields.join(',')}`, proposalRequest).pipe(
      map((result: ServiceResponse) => {
        const proposalRequestToReturn: ProposalRequest = result.data['proposal request'];
        return proposalRequestToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateProposalRequest(proposalRequestId: number, proposalRequest: ProposalRequest, fields: string[]) {
    return this.http
      .put(`${this.proposalRequestsUrl}/${proposalRequestId}?fields=${fields.join(',')}`, proposalRequest)
      .pipe(
        map((result: ServiceResponse) => {
          const proposalRequestToReturn: ProposalRequest = result.data['proposal request'];
          return proposalRequestToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteProposalRequest(proposalRequestId: number): Observable<void> {
    return this.http.delete(`${this.proposalRequestsUrl}/${proposalRequestId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getProposalRequestReasons(): Observable<any[]> {
    return this.http
      .get(
        `${this.proposalRequestReasonsUrl}?limit=10000&fields=name,proposal_request_reasons_id,proposal_request_reasons{name}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const proposalRequestReasons: any[] = result.data.proposal_request_reasons_options;
          return proposalRequestReasons;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getChangeOrders(apiFilters: APIFilter[], fields: string[]): Observable<ChangeOrder[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.changeOrdersUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const changeOrders: ChangeOrder[] = result.data.change_orders;
        return changeOrders;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getChangeOrdersForProjects(
    projectIds: number[],
    status_id: string | number,
    fields: string[]
  ): Observable<ChangeOrder[]> {
    return this.http
      .get(
        `${this.changeOrdersUrl}?fields=${fields.join(',')}&filter=id=${projectIds.join(
          '^'
        )},status_id=${status_id}&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const changeOrders: ChangeOrder[] = result.data.change_orders;
          return changeOrders;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  createChangeOrder(changeOrder: ChangeOrder, fields: string[]) {
    return this.http.post(`${this.changeOrdersUrl}?fields=${fields.join(',')}`, changeOrder).pipe(
      map((result: ServiceResponse) => {
        const changeOrderToReturn: ChangeOrder = result.data['change order'];
        return changeOrderToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateChangeOrder(changeOrderId: number, changeOrder: ChangeOrder, fields: string[]) {
    return this.http.put(`${this.changeOrdersUrl}/${changeOrderId}?fields=${fields.join(',')}`, changeOrder).pipe(
      map((result: ServiceResponse) => {
        const changeOrderToReturn: ChangeOrder = result.data['change order'];
        return changeOrderToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteChangeOrder(changeOrderId: number): Observable<void> {
    return this.http.delete(`${this.changeOrdersUrl}/${changeOrderId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getAsBuilts(apiFilters: APIFilter[], fields: string[]): Observable<AsBuilt[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.asBuiltsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const asBuilts: AsBuilt[] = result.data.as_builts;
        return asBuilts;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createAsBuilt(asBuilt: AsBuilt, fields: string[]) {
    return this.http.post(`${this.asBuiltsUrl}?fields=${fields.join(',')}`, asBuilt).pipe(
      map((result: ServiceResponse) => {
        const asBuiltToReturn: AsBuilt = result.data['as built'];
        return asBuiltToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateAsBuilt(asBuiltId: number, asBuilt: AsBuilt, fields: string[]) {
    return this.http.put(`${this.asBuiltsUrl}/${asBuiltId}?fields=${fields.join(',')}`, asBuilt).pipe(
      map((result: ServiceResponse) => {
        const asBuiltToReturn: AsBuilt = result.data['as built'];
        return asBuiltToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteAsBuilt(asBuiltId: number): Observable<void> {
    return this.http.delete(`${this.asBuiltsUrl}/${asBuiltId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getAddendums(apiFilters: APIFilter[], fields: string[]): Observable<Addendum[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.addendumsUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const addendums: Addendum[] = result.data.addendums;
        return addendums;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createAddendum(addendum: Addendum, fields: string[]) {
    return this.http.post(`${this.addendumsUrl}?fields=${fields.join(',')}`, addendum).pipe(
      map((result: ServiceResponse) => {
        const addendumToReturn: Addendum = result.data.addendum;
        return addendumToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateAddendum(addendumId: number, addendum: Addendum, fields: string[]) {
    return this.http.put(`${this.addendumsUrl}/${addendumId}?fields=${fields.join(',')}`, addendum).pipe(
      map((result: ServiceResponse) => {
        const addendumToReturn: Addendum = result.data.addendum;
        return addendumToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  deleteAddendum(addendumId: number): Observable<void> {
    return this.http.delete(`${this.addendumsUrl}/${addendumId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getInvoiceTimeframes(apiFilters: APIFilter[], fields: string[]): Observable<Invoice[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http.get(`${this.invoiceTimeframesUrl}?fields=${fields.join(',')}&${filterString}&limit=10000`).pipe(
      map((result: ServiceResponse) => {
        const invoiceTimeframes: Invoice[] = result.data.invoice_timeframes;
        return invoiceTimeframes;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getInvoices(apiFilters: APIFilter[], fields?: string[]): Observable<Invoice[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.invoicesUrl}?fields=${(fields && fields.join(',')) || this.invoiceFields}&${filterString}&limit=10000`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const invoices: Invoice[] = result.data.invoices;
          return invoices;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  getInvoiceById(invoiceId: number, fields: string[]): Observable<Invoice> {
    return this.http.get(`${this.invoicesUrl}/${invoiceId}?fields=${fields.join(',')}`).pipe(
      map((result: ServiceResponse) => {
        const invoices: Invoice[] = result.data.invoice;
        return invoices[0];
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  createInvoice(invoice: Invoice, fields?: string[]) {
    return this.http.post(`${this.invoicesUrl}?${fields ? `fields=${fields.join(',')}` : ''}`, invoice).pipe(
      map((result: ServiceResponse) => {
        const invoiceToReturn: Invoice = result.data.invoice;
        return invoiceToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateInvoice(invoiceId: number, invoice: Invoice, fields?: string[]) {
    return this.http
      .put(`${this.invoicesUrl}/${invoiceId}?${fields ? `fields=${fields.join(',')}` : ''}`, invoice)
      .pipe(
        map((result: ServiceResponse) => {
          const invoiceToReturn: Invoice = result.data.invoice;
          return invoiceToReturn;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // changes the status of the invoice and updates the revision count. Also triggers the reset task review function
  resetInvoice(invoiceId: number, message: string, fields, statusId: InvoiceStatus = null) {
    return this.http
      .put(`${this.invoicesUrl}/${invoiceId}/reset-review?${fields ? `fields=${fields.join(',')}` : ''}`, {
        message,
        status_id: statusId,
      })
      .pipe(
        map((result: ServiceResponse) => {
          return result.data;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  deleteInvoice(invoiceId: number): Observable<void> {
    return this.http.delete(`${this.invoicesUrl}/${invoiceId}`).pipe(
      map(() => null),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  /**
   * Retrieves a list of task ids from the PEB task fields on a project
   * @param projectId The projectId to get tasks for
   */
  public getProjectPebTaskIds(projectId: number): Observable<{
    tenantApprovalTaskId: number;
    tenantPebSigningTaskId: number;
    internalApprovalTaskId: number;
  }> {
    return this.http
      .get(`${this.projectUrl}/${projectId}?fields=approval_task_id,tenant_peb_signing_task_id,tenant_approval_task_id`)
      .pipe(
        map((result: ServiceResponse) => {
          const project: ProjectConstruction = result.data.project[0];
          return {
            tenantApprovalTaskId: project.tenant_approval_task_id,
            tenantPebSigningTaskId: project.tenant_peb_signing_task_id,
            internalApprovalTaskId: project.approval_task_id,
          };
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  // makes the api call to move the current node in between the before and after node
  public moveMilestoneNodeBefore(movedNode, newBeforeNode, newAfterNode) {
    if (movedNode) {
      movedNode = { id: movedNode.id, display_order_hash: movedNode.display_order_hash };
    }
    if (newBeforeNode) {
      newBeforeNode = {
        id: newBeforeNode.id,
        display_order_hash: newBeforeNode.display_order_hash,
      };
    }
    if (newAfterNode) {
      newAfterNode = { id: newAfterNode.id, display_order_hash: newAfterNode.display_order_hash };
    }
    return this.http
      .put(`${this.milestoneUrl}/moveNodeBefore`, {
        movedNode,
        newBeforeNode,
        newAfterNode,
      })
      .subscribe();
  }

  public getBudgetData(
    projectId: number,
    workspaceId: number,
    parentId?: number,
    changeOrderId?: number
  ): Observable<BudgetData> {
    return this.http
      .get(
        `${
          workspaceId === Workspace.Construction ? this.constructionProjectURL : this.projectUrl
        }/${projectId}/budget-data?${
          parentId ? `${workspaceId === Workspace.Construction ? 'bid_package_id' : 'quote_id'}=${parentId}` : ''
        }&change_order_id=${changeOrderId}`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const budgetData: BudgetData = result.data;
          return budgetData;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public getBudgetItems(projectId: number, workspaceId: number): Observable<BudgetItems> {
    return this.http
      .get(
        `${
          workspaceId === Workspace.Construction ? this.constructionProjectURL : this.projectUrl
        }/${projectId}/budget-items`
      )
      .pipe(
        map((result: ServiceResponse) => {
          const budgetItems: BudgetItems = result.data;
          return budgetItems;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public getProjectPriorities(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProjectPriority[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectPriorityURL}?limit=${limit || 10}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { project_priorities } }: ServiceResponse): ProjectPriority[] => {
          return project_priorities;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public reActivate(projectId: number, fields?: string[]) {
    return this.http
      .put(`${this.projectUrl}/${projectId}/reactivate?${fields ? `fields=${fields.join(',')}` : ''}`, null)
      .pipe(
        map(({ data: { project } }: ServiceResponse): Project => {
          return project;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public modifyProjectContacts(projectId: number, added_contact_ids: number[], removed_contact_ids: number[]) {
    return this.http
      .put(`${this.projectUrl}/${projectId}/contacts`, {
        added_contact_ids: JSON.stringify(added_contact_ids),
        removed_contact_ids: JSON.stringify(removed_contact_ids),
      })
      .pipe(
        map(() => {
          return null;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public async getPhaseInfo(projectId, milestoneName = 'Budget', phaseName: string = null) {
    const phases = await this.getPhasesByProjectId(projectId).toPromise();
    const phase = (phaseName && phases.find((p) => p.name === phaseName)) || phases[0];

    const milestones = (phase && (await this.getMilestonesByPhaseId(phase.id).toPromise())) || null;
    const milestone =
      milestones.find((m) => m.name === milestoneName) ||
      (phase &&
        (await this.createMilestone({
          name: milestoneName,
          phase_id: phase.id,
          sequence: 1,
        }).toPromise())) ||
      null;

    return {
      phaseName: phase?.name,
      milestoneName: milestone?.name,
      milestoneId: milestone?.id,
    };
  }

  public checkForUnprocessedInvoices(projectId: number) {
    return this.http.get(`${this.projectUrl}/${projectId}/has-unprocessed-invoices`).pipe(
      map(({ data: { hasUnprocessedInvoices } }: ServiceResponse): boolean => {
        return hasUnprocessedInvoices;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  changeProjectRequester(projectId: number, requesterId: number): Observable<Project> {
    const body = { requester_id: +requesterId };
    return this.http.put(`${this.projectUrl}/${projectId}/change-requester`, body).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: Project = result.data.project;
        return projectToReturn;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  sendProjectTimeline(projectId: number) {
    return this.http.post(`${this.constructionProjectURL}/${projectId}/send-project-timeline`, null).pipe(
      map((result: ServiceResponse) => {
        return result;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  public getProjectSubStatusCategories(
    fields: string[],
    apiFilters?: APIFilter[],
    limit?: number,
    sortField?: string,
    sortOrder?: string
  ): Observable<ProjectSubstatusCategory[]> {
    const filterString = this.apiFilterService.getFilterString(apiFilters);
    return this.http
      .get(
        `${this.projectSubstatusCategoriesURL}?limit=${limit || 10}&fields=${fields.join(',')}${
          !filterString || filterString === '' ? '' : `&${filterString}`
        }${sortField ? `&sort=${sortField}` : ''}${sortOrder ? `&order=${sortOrder}` : ''}`
      )
      .pipe(
        map(({ data: { project_substatus_categories } }: ServiceResponse): ProjectSubstatusCategory[] => {
          return project_substatus_categories;
        }),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  public updateFollowers(projectID: number, followers: ProjectFollower): Observable<User[]> {
    return this.http.put(`${this.projectUrl}/${projectID}/followers?fields=followers`, followers).pipe(
      map((result: ServiceResponse) => {
        const projectToReturn: Project = result.data.project;
        return projectToReturn.followers;
      }),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getCAPXids(): Observable<any[]> {
    return this.http.get(`${this.projectCAPXidsUrl}?fields=capx_id,description`).pipe(
      map((result: ServiceResponse) => result.data.projects_capx_ids),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  getCAPXBudgets(CAPXID: number): Observable<any[]> {
    return this.http.get(`${this.projectCAPXBudgetUrl}?filter=capx_id=${CAPXID}`).pipe(
      map((result: ServiceResponse) => result.data['projects_capx_budgets']),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }

  updateCAPXBudgets(budgets): Observable<any[]> {
    return this.http.put(this.projectCAPXBudgetUrl, budgets).pipe(
      map((result: ServiceResponse) => result.data),
      catchError((e) => this.handleErrorService.handleError(e))
    );
  }
}
