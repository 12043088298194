import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isString } from 'lodash';
import { Subscription } from 'rxjs';
import { ModalService, ProductService } from 'src/app/services';
import { ArfProduct, CostCode, Product, ProjectProduct, SubCostCode, SubCostCodeBudget } from 'src/app/types';
import { ArfAdminEditingStatus, Workspace } from '../../enums';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'app-arf-product',
  templateUrl: './arf-product.component.html',
  styleUrls: ['./arf-product.component.scss'],
})
export class ArfProductComponent implements OnInit, OnDestroy, OnChanges {
  @Input() costCodes: CostCode[] = [];
  @Input() fiscalYear: number | string;
  @Input() public products: Product[] = [];
  @Input() public product: ArfProduct;
  @Input() public canEdit: boolean;
  @Input() public adminEditingStatus: number;
  @Input() public moduleId: Workspace;
  @Output() public productUpdating = new EventEmitter<ArfProduct>();
  @Output() public productIsDoneUpdating = new EventEmitter();
  @Output() productIsUpdated = new EventEmitter<ArfProduct>();
  @Output() productIsDeleted = new EventEmitter<number>();
  @Output() updateProducts = new EventEmitter<ArfProduct>();

  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;

  public productFormGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    description: [''],
    quantity: [0, [Validators.required]],
    unit_price: [0],
    total_price: [0],
    product_id: [0],
    sub_cost_code_budget_id: [0, [Validators.required]],
  });

  get name(): AbstractControl {
    return this.productFormGroup.get('name');
  }
  get description(): AbstractControl {
    return this.productFormGroup.get('description');
  }
  get quantity(): AbstractControl {
    return this.productFormGroup.get('quantity');
  }
  get unit_price(): AbstractControl {
    return this.productFormGroup.get('unit_price');
  }
  get total_price(): AbstractControl {
    return this.productFormGroup.get('total_price');
  }

  get sub_cost_code_budget_id(): AbstractControl {
    return this.productFormGroup.get('sub_cost_code_budget_id');
  }

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private productService: ProductService,
    private snackbar: MatSnackBar
  ) {}

  dataToAdd: ArfProduct = {};
  filteredCostCodes: CostCode[];
  filteredProducts: Product[];
  updateProductSubscription: Subscription;
  totalChanged: boolean;
  selectedCostCode: CostCode;
  selectedSubCostCode: SubCostCode;
  updatedDescription: string;
  ArfAdminEditingStatus = ArfAdminEditingStatus;
  searchInputTerm: string;
  previousSubCostCode: SubCostCodeBudget;
  private originalProduct: ArfProduct;

  ngOnInit(): void {
    this.originalProduct = this.product;
    this.productFormGroup.addControl('description', this._editor_component?.content, { emitEvent: false });
    this.filteredCostCodes = this.costCodes;
    this.filteredProducts = this.products;
    this.updateForm(this.product);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatedDescription = null;
    this.filterProducts(this.product?.name?.replace(/\s+/g, ' ')?.trim() || null);

    if (this.canEdit) {
      this.productFormGroup.enable({ emitEvent: false });
    } else {
      this.productFormGroup.disable({ emitEvent: false });
    }

    let subCostCode: SubCostCode = this.getSubCostCodeById(this.product.sub_cost_code_budget_id);
    if (subCostCode) {
      this.setCostCodeId(subCostCode);
    }

    if (this.product?.module_id !== this.moduleId) {
      this.filterCostCodes(null, true);
      this.product.module_id = this.moduleId;
    }

    if (this.adminEditingStatus === ArfAdminEditingStatus.SavingAsAdmin) {
      // if saving as admin: save this.dataToAdd to db
      this.changeProductData([]);
    } else if (this.adminEditingStatus === ArfAdminEditingStatus.Cancelling) {
      // if canceling edit as admin: set product back to original product
      this.product = this.originalProduct;
      this.updateForm(this.product);
      if (this.dataToAdd.description && this.product.description !== this.dataToAdd.description) {
        this.updatedDescription = this.product.description;
      }

      this.dataToAdd = {};
    }
  }

  ngOnDestroy(): void {
    if (this.updateProductSubscription) {
      this.updateProductSubscription.unsubscribe();
    }
  }

  getSubCostCodeById(sub_cost_code_budget_id: number) {
    let subCostCode: SubCostCode = null;
    if (sub_cost_code_budget_id) {
      for (const costCode of this.costCodes) {
        subCostCode =
          costCode.sub_cost_codes.find((s) => s.sub_cost_code_budget?.id === sub_cost_code_budget_id) || subCostCode;
      }
    }
    return subCostCode;
  }

  get costCodeTitle(): string {
    return (
      (this.product?.sub_cost_code_budget?.code &&
        this.product?.sub_cost_code_budget?.label &&
        `${this.product?.sub_cost_code_budget?.code} - ${this.product?.sub_cost_code_budget?.label} - FY${this.selectedSubCostCode.fiscal_year}`) ||
      ''
    );
  }

  updateForm(product: ArfProduct): void {
    if (product) {
      for (const key in this.productFormGroup.value) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.productFormGroup.value.hasOwnProperty(key)) {
          if (this.productFormGroup.get(key).value !== product[key]) {
            let value = product[key];
            if (key === 'name' && value) {
              value = value?.replace(/\s+/g, ' ')?.trim();
            }
            if (['unit_price', 'total_price'].includes(key) && value) {
              value = (Number(value) || 0).toFixed(2);
            }

            if (value && key === 'sub_cost_code_budget_id') {
              this.selectedCostCode = this.costCodes.find((c) =>
                c.sub_cost_codes.find((s) => s.sub_cost_code_budget?.id === value)
              );

              if (this.product.sub_cost_code_budget?.id !== value) {
                for (const s of this.selectedCostCode.sub_cost_codes) {
                  const selectedBudget = s.sub_cost_code_budget;
                  if (selectedBudget) {
                    this.product.sub_cost_code_budget = selectedBudget;
                  }
                }
              }
            }

            this.productFormGroup.get(key).setValue(value, { emitEvent: false });
            // update product names
            if (key === 'name') {
              this.filterProducts(value);
            }
          }
        }
      }
    }
  }
  calculateValues(fields: string[], product?: ArfProduct): { total_price?: number; unit_price?: number } {
    const quantity = Number(this.quantity.value || product?.quantity);
    let unitPrice = Number(this.unit_price.value || product?.unit_price || 0);
    let totalPrice = Number(this.total_price.value || product?.total_price || 0);

    const updatedData: { total_price?: number; unit_price?: number } = {};
    if (fields.filter((f) => ['quantity', 'unit_price'].includes(f))?.length && !this.totalChanged) {
      totalPrice = quantity * unitPrice;
      updatedData.total_price = totalPrice;
      if (!unitPrice) {
        updatedData.unit_price = 0;
      }
      this.total_price.setValue(totalPrice.toFixed(2), { emitEvent: false });

      if (Number(this.unit_price.value) !== Number(this.total_price.value) / quantity) {
        const decimalCount = unitPrice?.toString().split('.')[1]?.length || 0;
        this.unit_price.setValue(unitPrice.toFixed(decimalCount <= 2 ? decimalCount : 2), { emitEvent: false });
      }
    } else if (fields.includes('total_price')) {
      unitPrice = this.total_price.value / this.quantity.value;
      updatedData.unit_price = Number(unitPrice);
      this.unit_price.setValue(unitPrice.toFixed(2), { emitEvent: false });
      updatedData.total_price = Number(totalPrice) || 0;
    }

    return updatedData;
  }

  changeProductData(value): void {
    this.totalChanged = false;
    for (const key in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(key)) {
        const newValueCheck = !['name', 'description'].includes(key) ? Number(value[key]) : value[key];
        const oldValueCheck = !['name', 'description'].includes(key) ? Number(this.product[key]) : this.product[key];
        if ((newValueCheck || oldValueCheck) && newValueCheck !== oldValueCheck) {
          if (key === 'total_price') {
            this.totalChanged = true;
          }

          this.dataToAdd[key] = value[key];
        }
      }
    }

    const changedFields: string[] = [];
    for (const key in this.product?.id ? this.dataToAdd : this.product) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.dataToAdd.hasOwnProperty(key)) {
        changedFields.push(key);
      }
    }

    this.dataToAdd = { ...this.dataToAdd, ...this.calculateValues(changedFields, this.dataToAdd) };

    if (changedFields.length > 0) {
      if (
        [ArfAdminEditingStatus.NoneAdminAction, ArfAdminEditingStatus.SavingAsAdmin].includes(this.adminEditingStatus)
      ) {
        this.product = { ...this.product, ...this.dataToAdd };
        this.productUpdating.emit(this.product);
        if (this.product?.id) {
          this.updateProduct(this.dataToAdd, changedFields);
        }
      } else if (this.adminEditingStatus === ArfAdminEditingStatus.EditingAsAdmin) {
        // if editing as admin: set product, but do not update db
        this.product = { ...this.product, ...this.dataToAdd };
      }
    }
  }

  filterProducts(product: string): void {
    if (product) {
      this.filteredProducts = this.products.filter((p) =>
        p.name?.trim()?.toLowerCase().includes(product?.replace(/\s+/g, ' ')?.trim()?.toLowerCase())
      );
    } else if (this.filteredProducts?.length !== this.products?.length) {
      this.filteredProducts = this.products;
    }
  }
  productValueMapper(product: ArfProduct): string | null {
    if (product?.id) {
      return product.name;
    } else if (isString(product)) {
      return product;
    } else {
      return null;
    }
  }

  async openNewProductDialog(event: Event): Promise<void> {
    event.stopPropagation();
    const newProduct: ArfProduct = await this.modalService
      .openProductDialog({
        name: this.name?.value?.replace(/\s+/g, ' ')?.trim(),
        workspaceId: this.moduleId,
      })
      .toPromise();

    // will have the fields which are able to be saved to the backend
    const newProductDetails = {
      id: newProduct.id,
      name: newProduct.name,
      unit_price: newProduct.unit_price,
      description: newProduct.description,
    };
    if (newProduct) {
      this.name.setValue(newProductDetails.name);
      this.updateSelectedProduct(newProductDetails);
      // update the list of products as well
      this.updateProducts.emit(newProductDetails);
    }
  }

  updateProduct(newProductData: ArfProduct, fields: string[]): void {
    if (this.updateProductSubscription) {
      this.updateProductSubscription.unsubscribe();
    }

    if (
      fields.length &&
      [ArfAdminEditingStatus.NoneAdminAction, ArfAdminEditingStatus.SavingAsAdmin].includes(this.adminEditingStatus)
    ) {
      this.updateProductSubscription = this.productService
        .updateArfProduct(this.product?.id, newProductData)
        .subscribe((res) => {
          this.product = { ...this.product, ...res };
          this.dataToAdd = {};
          this.productIsDoneUpdating.emit();
        });
    }
  }

  async deleteProduct(productId: number): Promise<void> {
    const deletedProduct = this.product;
    this.productIsDeleted.emit(productId);
    if (productId) {
      await this.productService.deleteArfProduct(productId).toPromise();
    }

    this.productIsDoneUpdating.emit();
    this.snackbar.open(`Product ${deletedProduct.name || 'No Name'} has been deleted.`);
  }

  get initialDescription() {
    return this.updatedDescription ?? this.product?.description ?? null;
  }

  updateSelectedProduct(product: ProjectProduct): void {
    if (product?.id) {
      this.filteredProducts = [product];
      product.unit_price = product.unit_price || 0;
      product.total_price = product.unit_price * this.product.quantity || 0;
      product.description = product.description || '';
      product.sub_cost_code_budget_id =
        this.sub_cost_code_budget_id?.value || this.product.sub_cost_code_budget?.id || null;

      let updatedProductInfo = { ...this.dataToAdd, ...product };
      const changedFields = ['description', 'name', 'quantity', 'unit_price', 'total_price'];

      delete updatedProductInfo.id;

      updatedProductInfo.product_id = product.id;
      updatedProductInfo.quantity = this.quantity.value;
      updatedProductInfo.sub_cost_code_budget_id = product.sub_cost_code_budget_id;
      let subCostCode = this.getSubCostCodeById(product.sub_cost_code_budget_id);
      if (subCostCode) {
        this.setCostCodeId(subCostCode);
        updatedProductInfo.sub_cost_code_budget_id = product.sub_cost_code_budget_id;
        this.product.sub_cost_code_budget_id = product.sub_cost_code_budget_id;
        this.product.sub_cost_code_budget = subCostCode;
      }

      this.productFormGroup.patchValue(updatedProductInfo);
      this.productFormGroup.updateValueAndValidity();

      if (
        [ArfAdminEditingStatus.NoneAdminAction, ArfAdminEditingStatus.SavingAsAdmin].includes(this.adminEditingStatus)
      ) {
        this.updatedDescription = updatedProductInfo.description;
        this.dataToAdd = updatedProductInfo;
        this.productUpdating.emit({ ...this.product, ...updatedProductInfo });
        if (this.product?.id) {
          this.updateProduct(updatedProductInfo, changedFields);
        }
      }
    }
  }

  filterCostCodes(value = null, manuallyUpdate = false): void {
    if (value?.trim()?.length) {
      this.searchInputTerm = value?.trim();
    } else {
      this.filteredCostCodes = this.costCodes;
      if (this.product?.sub_cost_code_budget_id) {
        this.product.sub_cost_code_budget = null;
        this.sub_cost_code_budget_id.setValue(null);
        if (manuallyUpdate) {
          this.changeProductData({ sub_cost_code_budget_id: null });
        }
      }
      // if the search term has a value, reset it
      if (this.searchInputTerm?.trim()) {
        this.searchInputTerm = '';
      }
    }
  }

  setCostCodeId(subCostCode: SubCostCode): void {
    const subCostCodeBudget = subCostCode.sub_cost_code_budget;
    if (subCostCodeBudget?.id !== this.sub_cost_code_budget_id.value) {
      this.selectedCostCode = subCostCode?.cost_code;
      this.selectedSubCostCode = subCostCode;
      this.previousSubCostCode = this.product.sub_cost_code_budget;
      this.product.sub_cost_code_budget = subCostCodeBudget;
      if (this.product?.sub_cost_code_budget) {
        this.product.sub_cost_code_budget.cost_code = this.selectedCostCode;
      }
      this.sub_cost_code_budget_id.setValue(subCostCodeBudget?.id || null);
      if (subCostCodeBudget?.id) {
        this.changeProductData({ sub_cost_code_budget_id: subCostCodeBudget.id });
      }
      if (!subCostCodeBudget?.id) {
        this.sub_cost_code_budget_id.setErrors({ doesNotExist: true });
      }
    }
  }
}
