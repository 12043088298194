import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ApplicationRef, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF, pdf } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { ceil, floor, isEmpty, remove, round, xor } from 'lodash';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import {
  CoverLetterDialogComponent,
  CustomerDialogComponent,
  FileRenameDialogComponent,
  ProjectProductComponent,
  QuoteDialogComponent,
  ViewTaskDialogComponent,
} from 'src/app/components';
import {
  CheckboxState,
  ProjectDetailsViews,
  QuoteItemStatus,
  QuoteItemStatus as QuoteItemStatusEnum,
  ResourceType,
  TaskReviewStatus,
  TaskStatus,
  Workspace,
  WorkspaceType,
} from 'src/app/enums';
import { BudgetApprovalProcess } from 'src/app/models';
import {
  AppRoutingService,
  ArfsService,
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  GeneralLedgerService,
  HandleErrorService,
  ModalService,
  ProductService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  PurchasingBudgetService,
  ReviewRevisionService,
  TaskActionsService,
  UserService,
} from 'src/app/services';
import {
  APIFilter,
  ConfirmationDialogInjectionData,
  CostCode,
  FiscalYear,
  Product,
  Project,
  ProjectProduct,
  ProjectTenant,
  Quote,
  QuoteItem,
  Task,
  User,
} from 'src/app/types';
import { convertDateToFiscalYear, getRankBetween } from 'src/app/utils';
import { TenantType } from 'src/app/workspaces/construction/enums';
import { ProjectTenantService } from 'src/app/workspaces/construction/services';
import { Solicitation } from '../../workspaces/construction/types';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit, OnDestroy {
  constructor(
    private arfService: ArfsService,
    private authService: AuthService,
    private appRef: ApplicationRef,
    private dialog: MatDialog,
    private displayReviewersService: DisplayReviewersService,
    private eventService: ProjectEventService,
    private fileService: FileService,
    private generalLedgerService: GeneralLedgerService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectTaskService: ProjectTaskService,
    private projectTenantService: ProjectTenantService,
    private projectService: ProjectService,
    private productService: ProductService,
    private taskService: ProjectTaskService,
    private snackbar: MatSnackBar,
    private reviewRevisionService: ReviewRevisionService,
    private routingService: AppRoutingService,
    private userService: UserService,
    private handleErrorService: HandleErrorService,
    private dateService: DateService,
    private taskActionsService: TaskActionsService,
    private budgetService: PurchasingBudgetService
  ) {}

  @ViewChild('pdf', { static: true }) pdf;
  @ViewChild('arf') arf;
  @ViewChild('coverLetter', { static: true }) coverLetter;
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  @ViewChildren('productComponent') productComponents: QueryList<ProjectProductComponent>;
  fiscalYearControl = new FormControl();
  fiscalYearOptions: FiscalYear[] = [];
  fyEdit: boolean = false;

  public selectedView;
  public sectionToggles = {
    coverLetter: false,
    budgetReview: false,
    tenantBudgetReview: false,
    bids: false,
    arfs: false,
    solicitations: false,
  };
  private workspaceId;
  public allProducts: Product[];
  public project: Project;
  public runProductFilter = 0;
  public projectTenants: ProjectTenant[];
  public tenantRep: User;
  public selectedTenant: ProjectTenant;
  public selectedTenantIndex = 0;
  public selectedQuotesIndex = 0;
  public selectedTotal: number;
  public include: any = {};
  public changeText = [];
  public canFinalize = false;
  public costCodes: CostCode[];
  public isAddingUHATTenant = false;
  public solicitations: Solicitation[];

  private refreshSubscription;
  private taskUpdatedRefresh;
  private purchaseFinalized;

  public canSubmitArfs = false;

  public productFields = ['name', 'description', 'unit_price'];

  public quoteFields: string[] = [
    'asset_tagged',
    'tag_asset_task_id',
    'arf_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data,description}',
    'arf_approval_task_id',
    'arf_saved_approval_task{status_id,assigned_user{id,first_name,last_name},accessory_data,description}',
    'arf_saved_approval_task_id',
    'arf_revision',
    'arf_purchase_type',
    'code',
    'description',
    'purchase_task_id',
    `company{id,name,verification_status}`,
    'contact_id',
    `subtotal`,
    `tax`,
    'files',
    'fiscal_year',
    'quote_file{id,name}',
    'solicitation_file{id,name}',
    `quote_items{id,project_product{id,is_taxable,selected_quote_item_id,tenant_id},total_price,is_awarded}`, // don't use quoteItemFields here or we'll get a loop
  ];

  public quoteItemFields: string[] = [
    'project_product_id',
    'status_id',
    'unit_price',
    'total_price',
    `quote{${this.quoteFields.join(',')}}`,
    'is_awarded',
  ];
  public projectProductFields: string[] = [
    `name`,
    `description`,
    `quantity`,
    `unit_price`,
    `total_price`,
    `fixed_properties`,
    `is_taxable`,
    `is_enabled`,
    'is_in_stock',
    'rank',
    `product{${this.productFields.join(',')}}`,
    // `files`,
    `quote_items{${this.quoteItemFields.join(',')}}`,
    `selected_quote_item_id`,
    `selected_quote_item{${this.quoteItemFields.join(',')}}`,
    'sub_cost_code_budget_id',
    'sub_cost_code_budget{label,sub_cost_code_id,code}',
  ];
  public tenantFields = [
    `project_products{${this.projectProductFields.join(',')}}`,
    `quotes{${this.quoteFields.join(',')}}`,
    `project_id`,
    `type_id`,
    `tenant_name`,
    `representative_id`,
    'budget_cover_letter_text',
    `budget_approval_task_id`,
    `budget_saved_approval_task_id`,
    `budget_revision`,
    `budget_tenant_approval_task_id`,
    `budget_tenant_saved_approval_task_id`,
    `budget_status_id`,
    'cover_letter_required',
    'budget_review_required',
    `shipping_budget`,
    `tax_budget`,
    `finalized_budget`,
    `fiscal_year`,
  ];

  // adds it to a project you are view tenants products
  /*
  public async addProductRank() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Adding ranks to products...');
    for (let tenantIndex = 0; tenantIndex < this.projectTenants.length; tenantIndex++) {
      const rank = getDefaultRanks(this.projectTenants[tenantIndex].project_products.length);
      // tenant id
      // const tenantId = this.projectTenants[tenantIndex].id;
      // console.log(tenantId, ' tenant');
      this.projectTenants[tenantIndex].project_products = this.projectTenants[tenantIndex].project_products.map(
        (product, index) => {
          product.rank = rank[index];

          const productId = product.id;

          (async function (comp) {
            // console.log(product);

            const updatedProjectProduct = await comp.productService
              .updateProjectProduct(productId, { rank: rank[index] })
              .toPromise();
            // console.log(updatedProjectProduct);
          })(this);

          return product;
        }
      );
    }

    this.progressIndicatorService.close();
  }
  */

  async ngOnInit(): Promise<void> {
    await this.refresh();
    this.fiscalYearOptions = await this.budgetService.getFiscalYears().toPromise();
    this.selectedView = this.selectedTenant?.id
      ? this._getFromPreferences('selected_view', this.selectedTenant?.id) || 'budget'
      : 'budget';

    this.refreshSubscription = this.projectService.refreshNeeded$.subscribe(async () => {
      if (this.project?.id) {
        await this.projectService.selectProjectById(this.project.id);
        this.project = this.projectService.currentSelectedProject;
      }
    });

    this.taskUpdatedRefresh = this.taskService.taskSelectedEvent.subscribe(async (data) => {
      if (data?.task?.accessory_data) {
        const task = data.task;
        for (const tenant of this.projectTenants) {
          for (const quote of tenant.quotes) {
            if (task.id === quote.task?.id) {
              const accessoryData = JSON.parse(task.accessory_data);
              if (accessoryData.reviewChain[0]?.status === TaskReviewStatus.Pending) {
                quote.arf_approval_task_id = null;
                quote.arf_saved_approval_task_id = task.id;
                quote.arf_revision = quote.arf_revision + 1;
              }

              quote.task = task;
            }
          }
        }
      }
    });

    this.purchaseFinalized = this.productService.finalizePurchaseEvent.subscribe((tenant) => {
      const selectedTenant = this.projectTenants.find((t) => t.id === tenant.id);
      selectedTenant.budget_status_id = 2;
      selectedTenant.budget_approval_process = new BudgetApprovalProcess(
        selectedTenant,
        this.projectTaskService,
        this.modalService,
        this.fileService,
        this.dateService,
        this.taskActionsService
      );

      selectedTenant.selected_quotes.forEach((quote) => {
        if (quote.arf_approval_task.status_id !== TaskStatus.Complete) {
          quote.arf_approval_task.status_id = TaskStatus.Complete;
          quote.task.status_id = TaskStatus.Complete;
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    if (this.taskUpdatedRefresh) {
      this.taskUpdatedRefresh.unsubscribe();
    }
    if (this.purchaseFinalized) {
      this.purchaseFinalized.unsubscribe();
    }
  }

  floorDecimal(value, digits: number) {
    return floor(value, digits).toFixed(digits);
  }

  ceilDecimal(value, digits: number) {
    return ceil(value, digits).toFixed(digits);
  }

  isThirdDecimalNonZero(value) {
    return this.floorDecimal(value, 2) !== this.ceilDecimal(value, 2);
  }

  public async refresh(selectedTenantId = null) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading Data..');
    this.project = this.projectService.currentSelectedProject;
    this.workspaceId = this.project.module_id;

    await this.getProducts();

    // cache tenant API response into local projectTenants variable so expansion won't be jarring on the front end
    const projectTenants: any[] = await this.projectTenantService
      .getTenantsForProject(this.project.id, this.tenantFields)
      .toPromise();
    for (const tenant of projectTenants) {
      tenant.budget_approval_process = new BudgetApprovalProcess(
        tenant,
        this.projectTaskService,
        this.modalService,
        this.fileService,
        this.dateService,
        this.taskActionsService
      );
      tenant.shipping_budget = this.roundDecimal(tenant.shipping_budget, 2);
      tenant.tax_budget = this.roundDecimal(tenant.tax_budget, 3);
      this.getBudgetSubtotal(tenant);

      for (const pp of tenant?.project_products || []) {
        this.updateBudgetQuoteItem(pp);
      }
      this.getBidSectionSubtotal(tenant);
      await this.getCoverLetter(tenant);

      if (tenant.cover_letter_required) {
        this.sectionToggles.coverLetter = true;
      }

      tenant.selected_quotes = this.productService.returnSelectedQuotes(tenant);
    }

    // setting the public variable after expansion logic keeps it from being jarring on the front end
    this.projectTenants = projectTenants;
    if (!this.selectedTenant && this.projectTenants[0]) {
      this.selectTenant(null, 0);
    } else if (selectedTenantId) {
      this.selectTenant(selectedTenantId);
    } else if (this.selectedTenant?.id) {
      this.selectTenant(this.selectedTenant?.id);
    }

    this.costCodes = (await this.generalLedgerService.getCostCodesByModuleId(this.project.module_id)) || [];

    this.solicitations = await this.projectService.getSolicitationsByProjectId(this.project?.id).toPromise();

    this.setCanFinalize();

    this.sectionToggles.bids = !this.selectedTenant?.budget_approval_process?.isFinalized;
    this.sectionToggles.arfs =
      !this.selectedTenant?.budget_approval_process?.noARFReviewTasksStarted ||
      !this.selectedTenant?.budget_approval_process?.isFinalized;

    let fy = this.selectedTenant?.fiscal_year || convertDateToFiscalYear();
    this.fiscalYearControl.setValue(fy);
    this.progressIndicatorService.close();
  }

  get TaskStatus() {
    return TaskStatus;
  }

  get QuoteItemStatus() {
    return QuoteItemStatus;
  }

  get TenantType() {
    return TenantType;
  }

  get isAdmin(): boolean {
    return !!this.authService.isProjectAdmin(this.project?.id, this.project?.module_id);
  }

  async getProducts() {
    const productFilters: APIFilter[] = [
      { type: 'field', field: 'module_id', value: this.workspaceId.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'enabled', value: '1' },
    ];

    this.allProducts = await this.productService
      .getProducts(this.productFields, productFilters, undefined, 'name')
      .toPromise();
  }

  public get selectedSolicitations(): Solicitation[] {
    return this.solicitations?.filter((s) => s.tenant_id === this.selectedTenant?.id);
  }

  private async getCoverLetter(tenant) {
    if (!tenant.budget_cover_letter_text) {
      const coverLetterText = `Enclosed is the Estimated Budget for project ${this.project.code} - ${this.project.title}. Before any work can begin, please review, approve and sign the attached Estimated Budget. Once these items have been signed and approved, please upload them to the 1CALL System. We will review the documents to ensure all information required has been received and then coordinate with you regarding our next steps.`;
      await this.saveCoverLetterText(tenant.id, coverLetterText);
      tenant.budget_cover_letter_text = coverLetterText;
    }
  }

  private async saveCoverLetterText(id: number, text: string) {
    await this.projectTenantService.updateProjectTenant(id, { budget_cover_letter_text: text }).toPromise();
  }

  async openCoverLetter(tenant) {
    const dialogRef = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Budget Review',
        tenant: {
          ...this.currentTenantInfo,
          tenant_id: tenant.id,
          type_id: tenant.type_id,
          cover_letter_text: tenant.budget_cover_letter_text,
        },
        pmInfo: this.pmInfo,
        wmInfo: this.wmInfo,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res?.id && res?.cover_letter_text) {
        this.snackbar.open('Your changes have been saved.');
        tenant.budget_cover_letter_text = res.cover_letter_text;

        await this.saveCoverLetterText(res.id, res.cover_letter_text);
      }
    });
  }

  public async toggleCoverLetter(tenant: ProjectTenant) {
    const newStatus = tenant.cover_letter_required ? 0 : 1;
    tenant.cover_letter_required = newStatus;

    await this.projectTenantService
      .updateProjectTenant(tenant.id, {
        cover_letter_required: newStatus,
      })
      .toPromise();
  }

  public toggleSection(section: string, value?: boolean) {
    this.sectionToggles[section] = value || value === false ? value : !this.sectionToggles[section];
  }

  private roundDecimal(value, digits: number) {
    return round(value, digits).toFixed(digits);
  }

  private updateBudgetQuoteItem(projectProduct: ProjectProduct) {
    const foundBidEstimate = (projectProduct?.quote_items || []).find((qi: any) => qi.is_budget);
    if (foundBidEstimate) {
      foundBidEstimate.unit_price = projectProduct.unit_price;
      foundBidEstimate.total_price = projectProduct.total_price;
    } else {
      const estimate = {
        is_budget: true,
        status_id: QuoteItemStatus.BidReceived,
        total_price: projectProduct.total_price,
        unit_price: projectProduct.unit_price,
        is_awarded: projectProduct.selected_quote_item_id ? 0 : 1,
        quote: { id: projectProduct.selected_quote_item?.quote?.id },
        label: 'Budget Estimate',
      };
      if (projectProduct.quote_items) {
        projectProduct.quote_items.unshift(estimate);
      } else {
        projectProduct.quote_items = [estimate];
      }
      if (!projectProduct?.selected_quote_item_id) {
        projectProduct.selected_quote_item = estimate;
      }
    }
  }

  public shippingBlur(tenant: ProjectTenant) {
    tenant.shipping_budget = this.roundDecimal(tenant.shipping_budget, 2);
    this.updateProjectTenant(tenant);
  }

  public taxBlur(tenant: ProjectTenant) {
    tenant.tax_budget = this.roundDecimal(tenant.tax_budget, 3);
    this.updateProjectTenant(tenant);
  }

  private getTaxBudgetTotal(tenant) {
    tenant.tax_budget_total = this.roundDecimal((+tenant.tax_budget / 100) * +tenant.budget_taxable_subtotal, 2);
  }

  private async updateProjectTenant(tenant) {
    await this.projectTenantService
      .updateProjectTenant(tenant.id, {
        shipping_budget: +tenant.shipping_budget,
        tax_budget: +tenant.tax_budget,
      })
      .toPromise();

    this.getBudgetSubtotal(tenant);
    this.getBidSectionSubtotal(tenant);
  }

  public getQuoteFiles(quote: Quote) {
    let allFiles = [];
    if (quote.files) {
      allFiles = allFiles.concat(quote.files);
    }
    if (quote.quote_file) {
      allFiles.push(quote.quote_file);
    }
    if (quote.solicitation_file) {
      allFiles.push(quote.solicitation_file);
    }
    return allFiles;
  }

  public downloadFile(file) {
    this.fileService
      .downloadFile(file)
      .pipe(
        map((result) => result),
        catchError((e) => {
          file.isDownloading = false;
          this.handleErrorService.handleError(e);
          return e;
        })
      )
      .subscribe((downloadedFileResult) => {
        saveAs(new Blob([new Uint8Array(downloadedFileResult.file.data)]), downloadedFileResult.name);
      });
  }

  public async previewFile(file) {
    await this.fileService.previewFile(file);
  }

  public async editFile(file) {
    const tags = await this.fileService.getTags([this.project.module_id]).toPromise();
    const fileWithTags = await this.fileService.getTagsForFile(file.id).toPromise();
    file.tags = fileWithTags.tags;
    const dialogRef = this.dialog.open(FileRenameDialogComponent, {
      width: '480px',
      data: {
        file,
        allTags: tags,
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
      if (event) {
        // if the name isn't the same, or the tag array ids have switched, then update the file
        if (
          file.name !== event.name ||
          !isEmpty(
            xor(
              file.tags?.map((t) => +t.id),
              event.tags?.map((t) => +t.id)
            )
          )
        ) {
          const tag_ids = `[${event.tags.map((t) => +t.id).join(',')}]`;
          const updateFile = {
            name: event.name,
            tag_ids,
          };
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Saving Changes..');
          this.fileService.updateFileFields(file.file_id || file.id, updateFile).subscribe((ret) => this.refresh());
        }
      }
    });
  }

  public clearApprovalField(tenant) {
    this.projectTenantService.updateProjectTenant(tenant.id, { budget_approval_task_id: null }).subscribe();
  }

  getTenantName(tenant: ProjectTenant) {
    return tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name;
  }

  get isWorkspaceStaff() {
    return this.authService?.isUserWorkspaceStaff(this.project?.module_id);
  }

  // Combines arf tasks so only one field needs to be used
  private addQuoteTaskField() {
    // Adds the review task to quotes
    if (this.projectTenants) {
      if (this.selectedTenant?.quotes) {
        for (const quote of this.selectedTenant.quotes) {
          if (quote.arf_saved_approval_task_id || quote.arf_approval_task_id) {
            quote.task = quote.arf_approval_task || quote.arf_saved_approval_task;
          }
        }
        this.productService.disableProductEvent.emit(this.selectedTenant.id);
      }
    }
  }

  public async openNewCustomerDialog() {
    const dialogRef = this.dialog.open(CustomerDialogComponent, {
      width: '440px',
      data: {
        projectId: this.project.id,
      },
    });

    dialogRef.afterClosed().subscribe((createdCustomer) => {
      if (createdCustomer) {
        this.refresh(createdCustomer.id);
      }
    });
  }

  public async changeTenantContact(tenant: ProjectTenant): Promise<void> {
    const data = {
      title: 'Switch Supplier',
      preSelectedUsers: [{ ...this.tenantRep, ...{ is_selected: true } }],
      checkIfSelected: true,
      maxSelected: 1,
      hasProject: true,
      createUser: { title: 'Guest', guestUser: false },
    };

    await this.modalService
      .openUserSelectModal(data)
      .toPromise()
      .then(async ({ selectedUsers }) => {
        if (selectedUsers?.length) {
          this.tenantRep = selectedUsers[0];
          const tenantData = {
            representative_id: this.tenantRep?.id,
          };
          await this.projectTenantService.updateProjectTenant(tenant.id, tenantData).toPromise();
          tenant.representative_id = this.tenantRep.id;

          const tenantTaskId = tenant?.budget_tenant_approval_task_id || tenant?.budget_tenant_saved_approval_task_id;
          if (tenantTaskId) {
            const taskData = {
              id: tenantTaskId,
              assigned_user_id: this.tenantRep?.id,
            };

            await this.projectService.updateTask(taskData).toPromise();
          }
        }
      });
  }

  public async openProjectDetailsDialog() {
    await this.modalService.openProjectDetailsDialog(this.project.id, ProjectDetailsViews.Roles).toPromise();
  }

  async addUHATManaged(): Promise<void> {
    if (!this.isAddingUHATTenant) {
      this.isAddingUHATTenant = true;
      await this.projectTenantService.linkTenantToProject(this.project.id, null, null, 3).toPromise();
      this.snackbar.open(`UHAT Managed Budget added!`);
      await this.refresh();
      this.isAddingUHATTenant = false;
    }
  }

  public addProjectProduct(tenant: any) {
    if (this.cantMakeChanges) {
      this.snackbar.open(
        tenant.budget_approval_process.isFinalized
          ? 'Budget is finalized'
          : !this.cantMakeChanges
          ? 'Tenant review completed'
          : 'ARF review in progress'
      );
      return;
    }
    const projectProduct = { rank: getRankBetween(tenant.project_products[tenant.project_products.length - 1]?.rank) }; // add rank

    this.updateBudgetQuoteItem(projectProduct);
    tenant.project_products.push(projectProduct);
  }

  private updateBidEstimate(projectProduct: ProjectProduct, tenant) {
    const foundProjectProduct = (tenant.project_products || []).find((pp) => pp.id === projectProduct.id);
    this.updateBudgetQuoteItem(foundProjectProduct);
  }

  public async projectProductUpdated(updatedProjectProduct: ProjectProduct, existingProjectProduct, tenant) {
    if (!existingProjectProduct?.id && tenant?.quotes?.length) {
      const quoteFilters: APIFilter[] = [
        {
          type: 'field',
          field: 'project_product_id',
          value: updatedProjectProduct?.id.toString(),
        },
      ];
      const quoteItems = await this.productService.getQuoteItems(this.quoteItemFields, quoteFilters).toPromise();
      updatedProjectProduct.quote_items = [...updatedProjectProduct.quote_items, ...quoteItems];
    }

    const quote = this.checkArfDifferences(updatedProjectProduct, existingProjectProduct);
    Object.assign(existingProjectProduct, updatedProjectProduct);

    this.updateBidEstimate(updatedProjectProduct, tenant);
    this.getBudgetSubtotal(tenant);
    this.getBidSectionSubtotal(tenant);
    this.updateSelectedTenant(tenant);

    if (updatedProjectProduct.quantity !== existingProjectProduct.quantity && quote) {
      const selectedQuoteItemIndex = quote.quote_items.findIndex(
        (item) => item.id === updatedProjectProduct.selected_quote_item_id
      );
      if (selectedQuoteItemIndex !== -1) {
        quote[selectedQuoteItemIndex] = updatedProjectProduct.selected_quote_item;
      }
    }

    await this.reviseArfs([quote]);
  }

  // Checks to see if an arf is connected to the updated project and then sees if that arf needs to be revised
  private checkArfDifferences(updatedProjectProduct, existingProjectProduct) {
    const fieldsToCheck = ['is_in_stock', 'quantity'];
    let quote = null;

    fieldsToCheck.forEach((field) => {
      if (updatedProjectProduct[field] !== existingProjectProduct[field] && !quote) {
        quote = updatedProjectProduct?.selected_quote_item?.quote || existingProjectProduct?.selected_quote_item?.quote;
      }
    });

    return quote;
  }

  public async projectProductDeleted(
    projectProduct: ProjectProduct,
    tenant: ProjectTenant,
    projectProductIndex?: number
  ) {
    await this.refresh();
    if (projectProduct.id) {
      remove(tenant.project_products || [], (p) => p.id === projectProduct.id);
    } else if (projectProductIndex || projectProductIndex === 0) {
      if (
        tenant?.project_products?.length >= +projectProductIndex + 1 &&
        tenant?.project_products[projectProductIndex] &&
        !tenant?.project_products[projectProductIndex].id
      ) {
        tenant.project_products.splice(projectProductIndex, 1);
      }
    }

    if (projectProduct?.selected_quote_item) {
      await this.reviseArfs([projectProduct?.selected_quote_item?.quote]);
    }

    this.updateSelectedTenant(tenant);

    this.snackbar.open('Product deleted!');
  }

  public async toggleProjectProduct(isCurrentlyEnabled, projectProduct: ProjectProduct, tenant) {
    if (this.cantMakeChanges) {
      return;
    }
    const isEnabled = !isCurrentlyEnabled;
    const updatedProjectProduct = await this.productService
      .updateProjectProduct(projectProduct.id, { is_enabled: isEnabled ? 1 : 0 })
      .toPromise();
    projectProduct.is_enabled = updatedProjectProduct.is_enabled;

    this.getBudgetSubtotal(tenant);
    this.getBidSectionSubtotal(tenant);

    this.updateSelectedTenant(tenant);
  }

  public async deleteProjectProduct(tenant: ProjectTenant, projectProduct: ProjectProduct) {
    // TODO: check to see if deletion is allowed
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Product',
        descriptionText: 'Are you sure you want to remove this product?',
        confirmationButtonText: 'Remove',
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          await this.productService.deleteProjectProduct(projectProduct.id).toPromise();

          tenant.project_products = tenant.project_products.filter((p) => p.id !== projectProduct.id);

          this.getBudgetSubtotal(tenant);
          this.getBidSectionSubtotal(tenant);
          this.updateSelectedTenant(tenant);
        }
      });
  }

  async tenantTabChange(event) {
    const tenantIndex: number = event.index;
    if (tenantIndex >= 0) {
      await this.selectTenant(null, tenantIndex);
    }

    // Makes sure the product component updates when needed
    this.productService.disableProductEvent.emit(this.selectedTenant.id);
  }

  public async selectTenant(tenantId: number, tenantIndex?: number) {
    let tenantToSelect: ProjectTenant;
    if (tenantIndex >= 0) {
      tenantToSelect = this.projectTenants[tenantIndex];
    } else if (tenantId) {
      const foundTenant = (this.projectTenants || []).find((t) => t.id === tenantId);
      if (foundTenant) {
        tenantToSelect = foundTenant;
      }
    }

    if (tenantToSelect) {
      this.selectedTenant = tenantToSelect;
      this.getBidSectionSubtotal(this.selectedTenant);
    }

    if (this.selectedTenant) {
      const repId =
        this.selectedTenant.representative_id || this.project?.workspace_manager_id || this.project?.project_manager_id;
      this.tenantRep = await this.userService.getUserById(repId).toPromise();
    }

    this.selectedTenantIndex = this.projectTenants.findIndex((tenant) => tenant.id === this.selectedTenant.id);
    // Takes any arf task and assigns it to the task field instead of seperate fields
    this.addQuoteTaskField();
    this.setCanFinalize();
  }

  public updateSelectedTenant(tenant) {
    this.selectedTenant = tenant;
    this.selectedTenant.selected_quotes = this.productService.returnSelectedQuotes(tenant);
    this.setCanFinalize();
    this.runProductFilter++;
  }

  public async selectQuoteItem({ quoteItem, projectProduct, tenant }) {
    let makeAchangeSelected = false;
    if (
      (!tenant.budget_approval_process?.tenantTaskIsComplete ||
        !tenant.budget_approval_process.noARFReviewTasksStarted) &&
      this.cantMakeChanges
    ) {
      return;
    } else if (this.cantMakeChanges) {
      if (projectProduct.selected_quote_item?.total_price < quoteItem.total_price) {
        const res = await this.modalService
          .openConfirmationDialog({
            titleBarText: 'Total Exceeded',
            headerText: 'Clicked item total exceeds current item total',
            descriptionText:
              'Please adjust the total of the item you would like to select to be below the total of the currently selected item. Or press make a change to restart the budget review process.',
            confirmationButtonText: 'Make a Change',
          })
          .toPromise();

        if (res) {
          await this.updateProductEditingStatus(tenant);
          makeAchangeSelected = true;
        } else {
          return;
        }
      }
    }

    if (
      projectProduct.is_enabled &&
      !projectProduct.is_in_stock &&
      quoteItem.status_id === QuoteItemStatusEnum.BidReceived
    ) {
      const oldQuoteItem = projectProduct.selected_quote_item;

      // allow deselection
      const selectedQuoteItemId =
        projectProduct.selected_quote_item_id === quoteItem.id || quoteItem.is_budget ? null : quoteItem.id;

      projectProduct.selected_quote_item_id = selectedQuoteItemId;
      projectProduct.selected_quote_item = (projectProduct.quote_items || []).find((qi: any) =>
        quoteItem.is_budget ? qi.is_budget : +qi.id === selectedQuoteItemId
      );

      const productIndex = tenant.project_products?.findIndex((product) => product.id === projectProduct.id);
      if (productIndex >= 0) {
        tenant.project_products[productIndex] = projectProduct;
      }
      // Resets Current Total
      this.getBidSectionSubtotal(tenant);
      this.productService
        .updateProjectProduct(projectProduct.id, { selected_quote_item_id: selectedQuoteItemId }, [
          `selected_quote_item{${this.quoteItemFields.join(',')}}`,
        ])
        .subscribe();

      await this.reviseArfs([quoteItem?.quote, oldQuoteItem?.quote]);
    }

    tenant.selected_quotes = this.productService.returnSelectedQuotes(tenant);
    this.runProductFilter++;

    if (makeAchangeSelected) {
      await this.refresh();
    }

    this.setCanSubmitArfs();
  }

  async reviseArfs(quotes: Quote[]) {
    for (const quote of quotes) {
      if (quote?.arf_approval_task_id) {
        await this.reviewRevisionService.InternalARFRevision(
          quote,
          false,
          'Arf has been changed',
          quote.arf_approval_task_id
        );
        quote.arf_saved_approval_task_id = quote.arf_approval_task_id;
        quote.arf_approval_task_id = null;
      }
    }
  }

  public async openQuoteDialog(tenant: ProjectTenant, quote?: Quote) {
    const dialogRef = this.dialog.open(QuoteDialogComponent, {
      width: '740px',
      autoFocus: false,
      data: {
        tenantId: tenant.id,
        quoteId: quote?.id,
        workspace_type_id: this.project.module.workspace_type_id,
      },
    });

    dialogRef.afterClosed().subscribe(async (returnedQuote) => {
      if (returnedQuote) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        let needToReviseArf = false;
        if (quote?.arf_approval_task_id) {
          for (const quoteItem of quote.quote_items) {
            if (quoteItem.project_product?.selected_quote_item_id === quoteItem.id) {
              const updatedQuoteItem = returnedQuote.quote_items.find((qi) => qi.id === quoteItem.id);
              if (updatedQuoteItem?.total_price !== quoteItem?.total_price) {
                needToReviseArf = true;
              }
            }
          }
        }
        if (needToReviseArf) {
          await this.reviseArfs([{ ...quote, ...returnedQuote }]);
        }

        await this.refresh();
      }
    });
  }

  public viewQuote(quote: Quote) {
    this.modalService.openViewQuoteDialog(quote).subscribe((res) => {});
  }

  public openProductListDialog() {
    this.modalService.openProductDialog({ showList: true }).subscribe((product) => {
      if (product) {
        void this.getProducts();
      }
    });
  }

  public async submitARFApproval(tenant, quote) {
    this.selectedQuotesIndex = tenant?.selected_quotes?.findIndex((q) => q.id === quote.id);
    this.modalService
      .openArfPurchaseTypeModal(quote.company?.name)
      .toPromise()
      .then(async (res: any) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Getting Arf Info...');
          quote.arf_purchase_type_id = res.arfPurchaseType;
          quote.asset_tagged = res.assetTagged ? 1 : 0;
          const fiscalYear = res.fiscalYear;

          if (Number(fiscalYear) !== Number(quote.fiscal_year)) {
            quote.fiscal_year = res.fiscalYear;
            for (let product of this.selectedTenant.project_products) {
              if (product.sub_cost_code_budget_id) {
                const fiscalYearFilters: APIFilter[] = [
                  { type: 'field', field: 'sub_cost_code_id', value: product.sub_cost_code_budget.sub_cost_code_id },
                ];
                const newSubCostCodeBudget = await this.generalLedgerService
                  .getSubCostCodeBudgets(fiscalYearFilters)
                  .toPromise();
                if (newSubCostCodeBudget) {
                  const updatedProduct = this.productService
                    .updateProjectProduct(product.id, { sub_cost_code_budget_id: newSubCostCodeBudget[0].id })
                    .toPromise();
                  product = { ...product, ...updatedProduct };
                }
              }
            }
          }

          const phaseInfo = await this.projectService.getPhaseInfo(this.project.id);

          this.progressIndicatorService.updateStatus('Creating Arf Review File...');
          if (tenant?.quotes) {
            this.getReviewers(tenant, quote);

            const reviewers = this.displayReviewersService.getInternalReviewers(this.include);
            const reviewIds = reviewers.reviewIds;
            const internalFiles = await this.getArfFiles(quote, reviewIds, res.purchaseInformation);

            this.progressIndicatorService.updateStatus('Creating Arf Task...');
            const newTaskId = await tenant.budget_approval_process.beginStaffReview({
              arfName: quote.company.name + ' for ' + (tenant.tenant_name || 'UHAT') + ' (' + this.project.code + ')',
              taskDescription: res.purchaseInformation,
              files: [internalFiles],
              reviewIds,
              reviewCreator: this.authService.currentUser?.id,
              areFilesLinked: false,
              phaseName: phaseInfo.phaseName,
              milestoneName: phaseInfo.milestoneName,
              parentId: quote.id,
            });

            if (newTaskId) {
              this.progressIndicatorService.updateStatus('Updating Arf Info...');
              const quoteData: Quote = {
                arf_approval_task_id: newTaskId,
                arf_purchase_type_id: quote.arf_purchase_type_id,
                asset_tagged: quote.asset_tagged,
                fiscal_year: quote.fiscalYear,
              };

              await this.productService.updateQuote(quote.id, quoteData).toPromise();

              quote.arf_approval_task_id = newTaskId;
              quote.arf_approval_task = { id: newTaskId, status_id: TaskStatus.Open };

              await this.updateTasksLockedStatus(tenant.budget_tenant_approval_task_id);

              await this.refresh();
            }

            if (this.authService.currentUser.id === reviewIds[0].id) {
              await this.eventService
                .createTaskApprovalEvent(newTaskId, TaskReviewStatus.Approved, 'Approved upon creation')
                .toPromise();
            }

            this.progressIndicatorService.close();
          }
        }
      });
  }

  // Approval Process
  public async submitApproval(tenant: ProjectTenant, isInternal = true) {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Start Review',
        headerText: 'Start Review',
        descriptionText:
          'Starting the review process will automatically create a review task and lock the Purchase page from editing.',
        confirmationButtonText: 'Start',
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Generating files for task creation...');
      const budgetFiles = await this.getReviewFiles(tenant);
      let newTaskId;
      const phaseInfo = await this.projectService.getPhaseInfo(this.project.id);

      if (isInternal) {
        const include: any = {
          pm: true,
          wm: true,
          ocd: this.project.module.workspace_type_id === WorkspaceType.OneCall,
        };

        if (this.project.is_dfs_required) {
          include.dfs = true;
        }

        const reviewers = this.displayReviewersService.getInternalReviewers(include);
        const reviewIds = reviewers.reviewIds;

        this.progressIndicatorService.updateStatus('Creating task...');
        newTaskId = await tenant.budget_approval_process.beginStaffReview({
          budgetName:
            (this.project.title ? this.project.title : 'Project ' + this.project.code) +
            ' (' +
            (tenant.tenant_name || 'UHAT') +
            ')',
          files: [budgetFiles],
          reviewIds,
          areFilesLinked: false,
          phaseName: phaseInfo.phaseName,
          milestoneName: phaseInfo.milestoneName,
          parentId: tenant.id,
        });

        if (newTaskId) {
          await this.projectTenantService
            .updateProjectTenant(tenant.id, { budget_approval_task_id: newTaskId })
            .toPromise();
        }

        this.progressIndicatorService.close();
      } else {
        const reviewIds = [{ id: tenant.representative_id, status: TaskReviewStatus.Pending }];
        const assigned_user = {
          id: this.tenantRep.id,
          firstName: this.tenantRep.first_name,
          lastName: this.tenantRep.last_name,
          title: this.tenantRep.title,
          reviewer: true,
        };

        this.progressIndicatorService.updateStatus('Creating task...');
        newTaskId = await tenant.budget_approval_process.beginTenantReview({
          budgetName:
            (this.project.title ? this.project.title : 'Project ' + this.project.code) +
            ' (' +
            (tenant.tenant_name || 'UHAT') +
            ')',
          files: [budgetFiles],
          reviewIds,
          areFilesLinked: false,
          assigned_user,
          phaseName: phaseInfo.phaseName,
          milestoneName: phaseInfo.milestoneName,
          parentId: tenant.id,
        });

        if (newTaskId) {
          await this.projectTenantService
            .updateProjectTenant(tenant.id, { budget_tenant_approval_task_id: newTaskId })
            .toPromise();
        }
      }
      if (newTaskId) {
        this.snackbar.open('Approval Process Started and Task Created');
        await this.refresh();
      }
    }
  }

  // Resets or Creates a revision for a tenants approval process
  public async resetReview(tenant, reset: boolean, isInternal = true, arf = false) {
    const revisionType = isInternal ? (arf ? 'arf' : 'staff review') : 'tenant review';
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset Approval Process',
        headerText: 'Make a Revision',
        descriptionText: `Are you sure you want to make a revision to this ${revisionType}? A revision will require the review process to be reset. `,
        confirmationButtonText: 'Make Revision',
        userInput: {
          required: true,
          placeholder: 'Reason for Revision',
        },
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Resetting Approval');
          const updateReviewers = [];

          if (isInternal) {
            if (reset || arf) {
              for (const quote of tenant.selected_quotes) {
                const taskId = await this.resetArf(tenant, quote, reset, res);

                if (reset) {
                  updateReviewers.push(taskId);
                }
              }

              await this.updateTasksLockedStatus(tenant.budget_tenant_approval_task_id, false);
            }

            if (tenant.budget_approval_task_id && !arf) {
              await this.reviewRevisionService.budgetInternalRevision(tenant, reset, res);
              if (reset) {
                updateReviewers.push(tenant.budget_approval_task_id);
              }
            }
          }
          if (
            (tenant.budget_tenant_approval_task_id && !isInternal) ||
            (tenant.budget_tenant_approval_task_id && reset)
          ) {
            await this.reviewRevisionService.budgetTenantRevision(tenant, reset, res);
          }

          // If The review process is being reset this goes through each review task and updates the reviewers if needed.
          for (const taskId of updateReviewers) {
            const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId);

            if (!updatedAccessoryData.isTheSame) {
              const taskData = {
                id: taskId,
                accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
                assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
                status_id: TaskStatus.Open,
              };
              await this.projectService.updateTask(taskData).toPromise();
            }
          }

          this.progressIndicatorService.updateStatus('Refreshing Tenant Info');
          await this.refresh();
          this.progressIndicatorService.close();
          this.snackbar.open('The review process has been reset');
        }
      });
  }

  public async makeProductsEditable(tenant) {
    const isFinalized = tenant.budget_approval_process?.isFinalized;

    const descriptionText = isFinalized
      ? `If you need to make a change, this will unfinalize the purchase and allow the product list and supplier bids to be edited.`
      : `If you need to make a change, this will allow the product list and supplier bids to be edited. Once you've made the necessary changes, you will need to resubmit for review.`;
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Purchasing Change',
        headerText: 'Make a Change',
        confirmationButtonText: 'Make a Change',
        descriptionText,
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          await this.updateProductEditingStatus(tenant, isFinalized);
          await this.refresh();
        }
      });
  }

  private async updateProductEditingStatus(tenant, isFinalized = false) {
    await this.budgetUnapproval(tenant);
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Unlocking Products for editing...');

    if (tenant.type_id !== TenantType.Internal && tenant.budget_tenant_approval_task_id) {
      await this.reviewRevisionService.budgetTenantRevision(tenant, false, '');
    }

    if (tenant.budget_approval_task_id) {
      await this.reviewRevisionService.budgetInternalRevision(tenant, false, '');
    }

    for (const quote of tenant.selected_quotes) {
      await this.updateTasksLockedStatus(quote.arf_approval_task_id, true, isFinalized);
    }

    this.progressIndicatorService.close();
    this.snackbar.open('All products can now be edited');
  }

  public async submitForReview(tenant: ProjectTenant) {
    const confirmationData: ConfirmationDialogInjectionData = {
      titleBarText: 'Purchasing Change',
      headerText: 'Submit Change for Review',
      descriptionText: `In the space below, please describe the change and the reason for it. This message will be added to the task and made visible to staff and tenants. Please be as detailed as possible. <br/>`,
      confirmationButtonText: 'Submit Change',
      userInput: {
        required: true,
        placeholder: 'Reason For Change',
      },
    };

    if (tenant.type_id !== TenantType.Internal) {
      confirmationData.userInput.checkboxText = 'Skip Budget Review';
      confirmationData.descriptionText =
        confirmationData.descriptionText +
        `<br/>You can also mark the checkbox below if this change doesn't affect the tenant and should go straight to ARF review. `;
    }

    await this.modalService
      .openConfirmationDialog(confirmationData)
      .toPromise()
      .then(async (res) => {
        const checkRes = res?.checkRes;
        res = res?.res || res;
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Unlocking tasks...');
          if (tenant.type_id !== TenantType.Internal) {
            if (tenant.budget_tenant_saved_approval_task_id) {
              await this.submitRevision(tenant, false, false, res);
            }
            if (tenant.budget_saved_approval_task_id) {
              await this.submitRevision(tenant, true, false, res);
            }

            if (checkRes === CheckboxState.Checked) {
              const taskIds = [tenant.budget_saved_approval_task_id, tenant.budget_tenant_saved_approval_task_id];
              const quotes = tenant.selected_quotes || [];

              for (const id of taskIds) {
                const taskData = {
                  id,
                  status_id: TaskStatus.Complete,
                  is_locked: 1,
                };

                if (id) {
                  await this.projectService.updateTask(taskData).toPromise();
                }
              }
              for (const quote of quotes) {
                const taskData = {
                  id: quote.arf_approval_task_id,
                  is_locked: 0,
                };

                if (taskData.id) {
                  await this.projectService.updateTask(taskData).toPromise();
                }
              }
            }
          } else if (tenant.type_id === TenantType.Internal) {
            this.progressIndicatorService.updateStatus('Updating Arfs...');
            for (const quote of tenant.selected_quotes) {
              if (quote.arf_approval_task_id) {
                await this.unlockArfTask(quote);
              }
            }

            this.progressIndicatorService.close();
            this.snackbar.open('Review process restarted');
          }

          await this.refresh();
        }
      });
  }

  public async deleteStaffReview(tenant) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Staff Review',
        headerText: 'Remove Staff Review',
        descriptionText:
          'Are you sure you want to remove this staff review? All data associated with this task will be lost',
        confirmationButtonText: 'Remove',
      })
      .subscribe(async (res) => {
        if (res) {
          const taskId = tenant.budget_saved_approval_task_id;
          if (taskId) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Staff Review...');
            await this.projectService.deactivateTask(taskId).toPromise();
            await this.projectTenantService
              .updateProjectTenant(tenant.id, { budget_saved_approval_task_id: null })
              .toPromise();
            tenant.budget_saved_approval_task_id = null;
            this.progressIndicatorService.close();
          }
        }
      });
  }

  public async resetArfReview(tenant, quote) {
    return await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Reset ARF',
        headerText: 'Reset ARF',
        descriptionText: `Are you sure you want to reset this ARF? This will restart the ARF review process. `,
        confirmationButtonText: 'Reset',
        userInput: {
          required: true,
          placeholder: 'Reason for Reset',
        },
      })
      .toPromise()
      .then(async (res) => {
        if (res) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Revising Arf...');
          await this.resetArf(tenant, quote, true, res);

          this.progressIndicatorService.updateStatus('Refreshing Arf Info');
          await this.refresh();
          this.progressIndicatorService.close();
          this.snackbar.open('The arf review process has been reset');
          return true;
        } else return false;
      });
  }

  private async resetArf(tenant, quote, reset, res) {
    if (quote.arf_approval_task_id) {
      await this.reviewRevisionService.InternalARFRevision(quote, reset, res);

      const taskId = quote.arf_approval_task_id;

      this.getReviewers(tenant, quote);

      const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId, this.include);

      // This is added so that users can start using the digital signature on already existing arfs. It can be removed in the future.
      if (!updatedAccessoryData.accessoryData.parentId) {
        updatedAccessoryData.accessoryData.parentId = quote.id;
        updatedAccessoryData.isTheSame = false;
      }

      if (!updatedAccessoryData.isTheSame) {
        const taskData = {
          id: taskId,
          accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
          assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
          status_id: TaskStatus.Open,
        };

        await this.projectService.updateTask(taskData).toPromise();
      }

      return taskId;
    }
  }

  // Submits a revision to restart the approval process
  public async submitRevision(data: any, isInternal: boolean, arf = false, message = null) {
    let arfPurchaseType = null;
    let assetTagged = false;
    let fiscalYear;

    if (arf) {
      const res: any = await this.modalService
        .openArfPurchaseTypeModal(
          data.company?.name,
          data.arf_purchase_type_id,
          data.asset_tagged,
          data.task?.description,
          data.fiscal_year
        )
        .toPromise();

      if (!res) {
        return;
      }

      arfPurchaseType = res.arfPurchaseType;
      assetTagged = res.assetTagged;
      fiscalYear = res.fiscalYear;
      if (data.arf_saved_approval_task) {
        data.arf_saved_approval_task.description = res.purchaseInformation;
      } else {
        data.arf_approval_task.description = res.purchaseInformation;
      }
      data.task.description = res.purchaseInformation;
    }

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating files for task creation...');
    this.snackbar.open('Your Review is being restarted');

    if (arf && fiscalYear && Number(fiscalYear) !== Number(data.fiscal_year)) {
      for (let product of this.selectedTenant.project_products) {
        if (product.sub_cost_code_budget_id) {
          const fiscalYearFilters: APIFilter[] = [
            { type: 'field', field: 'sub_cost_code_id', value: product.sub_cost_code_budget.sub_cost_code_id },
          ];
          const newSubCostCodeBudget = await this.generalLedgerService
            .getSubCostCodeBudgets(fiscalYearFilters)
            .toPromise();
          if (newSubCostCodeBudget) {
            const updatedProduct = this.productService
              .updateProjectProduct(product.id, { sub_cost_code_budget_id: newSubCostCodeBudget[0].id })
              .toPromise();
            product = { ...product, ...updatedProduct };
          }
        }
      }
    }

    const budgetFile = !arf && [await this.getReviewFiles(data)];
    if (isInternal) {
      if (arf) {
        this.selectedQuotesIndex = (this.selectedTenant?.selected_quotes || []).findIndex(
          (quote) => quote.id === data.id
        );

        if (data.arf_purchase_type_id !== arfPurchaseType || data.asset_tagged !== assetTagged) {
          data.arf_purchase_type_id = arfPurchaseType;
          data.asset_tagged = assetTagged ? 1 : 0;
          data.fiscal_year = fiscalYear;

          await this.productService
            .updateQuote(data.id, {
              arf_purchase_type_id: data.arf_purchase_type_id,
              asset_tagged: data.asset_tagged,
              fiscal_year: data.fiscal_year,
            })
            .toPromise();
        }

        const arfFile = [await this.getArfFiles(data, null, data.task?.description)];
        await this.reviewRevisionService.internalARFSubmitRevision(data, arfFile, 55, this.include, message);
      } else {
        await this.reviewRevisionService.internalBudgetSubmitRevision(data, budgetFile, 55, message);
      }
    } else {
      await this.reviewRevisionService.tenantBudgetSubmitRevision(data, budgetFile, 55, message);
    }

    if (arf) {
      await this.refresh();
    }
  }

  // Re-upload ARF PDF (after cost code change by admin)
  async reUploadArfPdf(quote: Quote): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();

    this.progressIndicatorService.updateStatus('Generating ARF PDF');

    // append epoch time to prevent file name conflicts
    const arfFile: File = await this.getArfFiles(quote, null, quote.task?.description, Date.now().toString());

    this.progressIndicatorService.updateStatus('Creating task note');
    const taskId = quote.arf_approval_task_id;
    const newNote = await this.projectService
      .createNote(ResourceType.Task, taskId, 'ARF PDF re-uploaded by Admin')
      .toPromise();

    this.progressIndicatorService.updateStatus('Attaching PDF to task note');
    await this.fileService.addFilesToNote(newNote, taskId, [arfFile], []);

    this.progressIndicatorService.close();
  }

  editArf(tenant: ProjectTenant, quote: Quote) {
    this.modalService
      .openArfPurchaseTypeModal(
        quote.company?.name,
        quote.arf_purchase_type_id,
        !!quote.asset_tagged,
        quote.task?.description,
        quote.fiscal_year
      )
      .toPromise()
      .then(async (res: any) => {
        if (res) {
          const fiscalYearHasChanged = Number(res.fiscalYear) !== Number(quote.fiscal_year);
          if (
            quote.arf_purchase_type_id !== res.arfPurchaseType ||
            fiscalYearHasChanged ||
            Boolean(quote.asset_tagged) !== res.assetTagged ||
            quote.task.description !== res.purchaseInformation
          ) {
            const resetted = await this.resetArfReview(tenant, quote);
            if (!resetted) {
              return;
            }
          }
          quote.arf_purchase_type_id = res.arfPurchaseType;
          quote.asset_tagged = res.assetTagged ? 1 : 0;
          quote.fiscal_year = res.fiscalYear;

          const quoteData: Quote = {
            arf_purchase_type_id: quote.arf_purchase_type_id,
            asset_tagged: quote.asset_tagged,
            fiscal_year: quote.fiscal_year,
          };

          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Updating Arf...');
          if (fiscalYearHasChanged) {
            for (let product of this.selectedTenant.project_products) {
              if (product.sub_cost_code_budget_id) {
                const fiscalYearFilters: APIFilter[] = [
                  { type: 'field', field: 'sub_cost_code_id', value: product.sub_cost_code_budget.sub_cost_code_id },
                ];
                const newSubCostCodeBudget = await this.generalLedgerService
                  .getSubCostCodeBudgets(fiscalYearFilters)
                  .toPromise();
                if (newSubCostCodeBudget) {
                  const updatedProduct = this.productService
                    .updateProjectProduct(product.id, { sub_cost_code_budget_id: newSubCostCodeBudget[0].id })
                    .toPromise();
                  product = { ...product, ...updatedProduct };
                }
              }
            }
          }

          await this.productService.updateQuote(quote.id, quoteData).toPromise();

          if (res.purchaseInformation !== quote.task.description) {
            const taskData: Task = {
              id: quote.arf_approval_task_id,
              description: res.purchaseInformation,
            };

            quote.task.description = res.purchaseInformation;
            quote.arf_approval_task.description = res.purchaseInformation;
            await this.projectService.updateTask(taskData).toPromise();
          }

          this.progressIndicatorService.close();
        }
      });
  }

  get noArfsAreCreated() {
    return !this.selectedTenant?.selected_quotes?.find(
      (quote) => quote.arf_approval_task_id || quote.arf_saved_approval_task_id
    );
  }

  get allArfsAreCreated() {
    return !this.selectedTenant?.selected_quotes?.find(
      (quote) => !quote.arf_approval_task_id && !quote.arf_saved_approval_task_id
    );
  }

  get noArfsInReview() {
    return !this.selectedTenant?.selected_quotes?.find((quote) => quote.arf_approval_task_id);
  }

  get allArfsAreCompleted() {
    return (
      !!this.selectedTenant?.selected_quotes?.length &&
      !this.selectedTenant?.selected_quotes?.find((quote) => quote.arf_approval_task?.status_id !== TaskStatus.Complete)
    );
  }

  get allProductsAreInStock() {
    return this.selectedTenant.budget_approval_process.allProductsAreInStock;
  }

  setCanFinalize() {
    let canFinalize;
    if (this.selectedTenant?.selected_quotes?.length) {
      canFinalize = true;

      this.selectedTenant?.selected_quotes?.forEach((quote) => {
        const accessoryData =
          quote.arf_approval_task?.accessory_data && JSON.parse(quote.arf_approval_task.accessory_data);
        if (accessoryData) {
          canFinalize =
            canFinalize &&
            !accessoryData?.reviewChain?.find(
              (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
            );
        } else {
          canFinalize = false;
        }
      });
    } else {
      canFinalize = false;
    }

    this.setCanSubmitArfs();
    this.canFinalize = canFinalize;
  }

  setCanSubmitArfs(): void {
    this.canSubmitArfs =
      this.getGrandTotal(this.selectedTenant) < 25000 ||
      !!this.solicitations?.filter((s) => s.tenant_id === this.selectedTenant?.id)?.length;
  }

  public async unlockArfTask(quote: Quote) {
    quote.arf_approval_task.is_locked = 0;
    quote.task.is_locked = 0;
    await this.updateTasksLockedStatus(quote.arf_approval_task_id, false);
  }

  private async updateTasksLockedStatus(id: number, lockTask = true, unCompleteTask = false) {
    const is_locked = lockTask ? 1 : 0;
    if (id) {
      const taskData: Task = {
        id,
        is_locked,
      };

      if (unCompleteTask) {
        taskData.status_id = TaskStatus.Open;
      }

      await this.projectService.updateTask(taskData).toPromise();
    }
  }

  // Approve budget
  public async budgetApproval(tenant: ProjectTenant): Promise<void> {
    const res = await this.arfService.finalizePurchasePage(tenant, this.project);

    if (res) {
      await this.refresh();
    }
  }

  public async budgetUnapproval(tenant) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Unfinalizing Budget...');
    for (const quote of tenant.selected_quotes || []) {
      for (const qi of quote.quote_items) {
        if (qi.is_awarded) {
          await this.productService.updateQuoteItem(qi.id, { is_awarded: 0 }).toPromise();
        }
      }
      const actionTaskIds = [quote.purchase_task_id, quote.tag_asset_task_id];

      for (const taskId of actionTaskIds) {
        if (taskId) {
          const taskData = {
            id: taskId,
            is_locked: 1,
            status_id: TaskStatus.Open,
          };

          await this.projectService.updateTask(taskData).toPromise();
        }
      }
    }

    if (tenant.budget_status_id === 2) {
      await this.projectTenantService.updateProjectTenant(tenant.id, { budget_status_id: 1 }).toPromise();
    }
  }

  arfStatusText(quote: Quote) {
    let statusText = 'Awaiting Creation';

    if (quote.arf_saved_approval_task_id) {
      statusText = 'Awaiting Changes';
    } else if (quote.arf_approval_task_id && quote?.task?.status_id !== TaskStatus.Complete) {
      statusText = 'In Review';
    } else if (quote.arf_approval_task_id && quote?.task?.status_id === TaskStatus.Complete) {
      statusText = 'Review Complete';
    }

    return statusText;
  }

  public async downloadArf() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Downloading ARFs');
    if (this.selectedTenant?.quotes) {
      let index = 0;
      for (const quote of this.selectedTenant?.selected_quotes) {
        this.selectedQuotesIndex = index;
        index++;
        this.getReviewers(this.selectedTenant, quote);

        this.arf.saveAs(`ARF__${quote?.company?.name}_v${quote?.arf_revision}.pdf`);
      }
    }
    this.progressIndicatorService.close();
  }

  public async openSolicitationDialog(solicitation = null): Promise<boolean> {
    this.selectedTenant.purchase_total = this.getGrandTotal(this.selectedTenant);

    const res = await this.modalService
      .openSolicitationDialog({
        project: this.project,
        solicitation,
        tenant: this.selectedTenant,
      })
      .toPromise();

    if (res) {
      const newSolicitation = this.solicitations.findIndex((sol) => sol.id === res.id);
      if (newSolicitation === -1) {
        this.solicitations.push(res);
      } else {
        this.solicitations.splice(newSolicitation, 1, res);
      }

      this.canSubmitArfs = true;
    }

    return !!res;
  }

  removeSolicitation(solicitation) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: `Remove Solicitation`,
        confirmationButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        descriptionText: 'You are about to remove this solicitation. Are you sure you want to continue?',
      })
      .subscribe(async (confirmation) => {
        if (confirmation) {
          this.solicitations.splice(this.solicitations.indexOf(solicitation), 1);
          await this.projectService.deleteSolicitation(solicitation).toPromise();
        }
      });
  }

  public async downloadBudget() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Downloading Budget');
    this.pdf.saveAs(`Project_${this.project.code}_Budget_v${this.selectedTenant.budget_revision}.pdf`);
    this.progressIndicatorService.close();
  }

  // Gets the kendo pdf stored in the products page
  private async getArfFiles(quote = null, reviewChain = null, purchaseReason = null, textToAppend?: string) {
    this.selectedQuotesIndex = this.selectedTenant.selected_quotes.indexOf(quote);
    this.getReviewers(this.selectedTenant, quote);

    return await this.arf.createArfFile(quote, reviewChain, this.include, purchaseReason, null, null, textToAppend);
  }

  // Downloads the arf as a pdf - Currently not being used but should be kept until its certain adding the file to the task at the end of the review is no causing issues.
  public exportArfFile(quote) {
    const accessoryData = quote.task?.accessory_data && JSON.parse(quote.task.accessory_data);
    this.arf.exportArf(accessoryData?.reviewChain);
  }

  // Sets this.includes with the correct roles according to the cost of the quote bid and workspace type
  private getReviewers(tenant, quote) {
    this.selectedTotal = this.productService.getSelectedQuotesTotal(tenant, quote);

    this.include = this.displayReviewersService.getArfReviewers(this.selectedTotal, this.project?.module);
  }

  private async getReviewFiles(tenant: ProjectTenant) {
    if (tenant.cover_letter_required) {
      const files = [];

      // refreshes the html component to assure all data is up-to-date.
      this.appRef.tick();

      // Create Cover Letter PDF
      const coverLetterGroup = await this.coverLetter.export();
      const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');
      const coverLetterByteCharacters = atob(coverLetterBase64);
      const coverLetterData = new Array(coverLetterByteCharacters.length);
      for (let i = 0; i < coverLetterByteCharacters.length; i++) {
        coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
      }
      let coverLetterTitle = 'Cover_Letter_' + (tenant.type_id === 3 ? 'UHAT' : tenant.tenant_name);
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      coverLetterTitle = coverLetterTitle.replace(/ /g, '_');
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(coverLetterData)]),
        name: `${coverLetterTitle}_v${tenant.budget_revision}.pdf`,
      });

      // Create Budget PDF
      const budgetGroup = await this.pdf.export();
      const budgetBase64 = (await exportPDF(budgetGroup)).replace('data:application/pdf;base64,', '');
      const budgetByteCharacters = atob(budgetBase64);
      const budgetData = new Array(budgetByteCharacters.length);
      for (let i = 0; i < budgetByteCharacters.length; i++) {
        budgetData[i] = budgetByteCharacters.charCodeAt(i);
      }
      let budgetTitle = `Project_${this.project.code}_Budget`;
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      budgetTitle = budgetTitle.replace(/ /g, '_');
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(budgetData)]),
        name: `${budgetTitle}_v${tenant.budget_revision}.pdf`,
      });

      const combinedFile = await this.fileService.combinePDFs(files).toPromise();

      const blob = new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' });
      const file = new File([blob], `Project_${this.project.code}_Budget_v${tenant.budget_revision}.pdf`);

      return file;
    } else {
      return await this.pdf.export().then(async (groupData) => {
        let createdPDF: File;
        // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
        await new Promise<File>((resolve) => {
          pdf.toBlob(groupData, (res) => {
            createdPDF = new File([res], `Project_${this.project.code}_Budget_v${tenant.budget_revision || 0}.pdf`);
            resolve(createdPDF);
          });
        });
        return createdPDF || null;
      });
    }
  }

  // Opens up the selected task using the view task dialog
  public async viewTask(taskId: number) {
    if (taskId) {
      const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
        data: {
          taskId,
        },
        autoFocus: false,
      });
      dialogRef.componentInstance.reviewChanged.subscribe(async (data) => {
        await this.refresh();
      });
    }
  }

  public getBudgetStatusText(tenant) {
    return tenant.budget_approval_process.statusText;
  }

  public getBidSectionSubtotal(tenant: ProjectTenant | any) {
    let total = 0;
    for (const pp of tenant?.project_products || []) {
      if (pp.is_enabled) {
        total += pp.selected_quote_item?.total_price;
      }
    }

    tenant.quote_subtotal = total;
    this.getTaxBidTotal(tenant);
  }

  private getTaxBidTotal(tenant: ProjectTenant | any) {
    let taxTotal = 0;
    for (const pp of tenant?.project_products || []) {
      if (pp.is_enabled && pp.is_taxable) {
        taxTotal += this.productService.getQuoteItemTax(pp.selected_quote_item, tenant, pp);
      }
    }
    tenant.tax_bid_total = this.roundDecimal(taxTotal, 2);
  }

  private getBudgetSubtotal(tenant) {
    let subtotal = 0;
    let taxableSubtotal = 0;
    tenant?.project_products.forEach((pp) => {
      if (pp.is_enabled) {
        subtotal += pp?.total_price || 0;
        if (pp.is_taxable) {
          taxableSubtotal += pp?.total_price || 0;
        }
      }
    });

    tenant.budget_subtotal = subtotal;
    tenant.budget_taxable_subtotal = taxableSubtotal;
    this.getTaxBudgetTotal(tenant);
  }

  getGrandTotal(tenant) {
    return (+tenant?.quote_subtotal || 0) + (+tenant?.tax_bid_total || 0);
  }

  public productInfo(product: ProjectProduct, field) {
    return (product.selected_quote_item && product.selected_quote_item[field]) || product[field] || 0;
  }

  async deleteQuote(tenant: ProjectTenant, quote: Quote) {
    await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Bid',
        headerText: 'Delete Bid',
        descriptionText: 'Are you sure you want to delete this bid? This action cannot be undone.',
        confirmationButtonText: 'Delete Bid',
      })
      .toPromise()
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          await this.productService.deleteQuote(quote.id).toPromise();
          this.snackbar.open(`Bid removed!`);
          this.refresh();
        }
      });
  }

  get currentTenantInfo() {
    if (this.selectedTenant?.representative_id) {
      return {
        first_name: this.tenantRep?.first_name,
        name: `${this.tenantRep?.first_name} ${this.tenantRep?.last_name}`,
        title: this.tenantRep?.title,
        department: this.selectedTenant.tenant_name,
      };
    } else if (this.projectTenants && this.selectedTenant?.type_id === 3) {
      const first = this.project.cfmo_first_name ? this.project.cfmo_first_name : 'Not Given';
      const last = this.project.cfmo_last_name ? this.project.cfmo_last_name : '';
      return {
        first_name: first,
        name: `${first} ${last}`,
        title: 'Chief Facilities Management Officer',
        department: 'University Hospitals Authority & Trust',
      };
    } else {
      return { first_name: '', name: '', title: '', department: '' };
    }
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.project.project_manager_first_name ? this.project.project_manager_first_name : 'Not Given';
    const last = this.project.project_manager_last_name ? this.project.project_manager_last_name : '';
    const email = this.project.project_manager_email ? this.project.project_manager_email : '';
    const phone = this.project.project_manager_office_phone ? this.project.project_manager_office_phone : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }

  get wmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.project.workspace_manager_first_name ? this.project.workspace_manager_first_name : 'Not Given';
    const last = this.project.workspace_manager_last_name ? this.project.workspace_manager_last_name : '';
    const email = this.project.workspace_manager_email ? this.project.workspace_manager_email : '';
    const phone = this.project.workspace_manager_office_phone ? this.project.workspace_manager_office_phone : '';
    return {
      name: `${first} ${last}`,
      title: `${this.projectService.currentSelectedProject?.module?.name || 'Workspace'} Manager`,
      email,
      phone,
    };
  }

  get needsToAddUser() {
    return (
      !this.project.workspace_manager_id ||
      !this.project.cfmo_id ||
      !this.project.account_coordinator_id ||
      (this.project.module_id !== Workspace.Construction && !this.project.ico_id) ||
      !this.project.bsd_id ||
      (this.authService.needsDFSManager(this.workspaceId) && this.project.is_dfs_required && !this.project.dfs_id)
    );
  }

  get cantMakeChanges() {
    return (
      this.selectedTenant?.budget_approval_process?.isFinalized ||
      this.selectedTenant?.budget_tenant_approval_task_id ||
      this.selectedTenant?.budget_approval_task_id ||
      (this.selectedTenant?.type_id === TenantType.Internal &&
        this.selectedTenant?.selected_quotes?.find((quote) => quote.arf_approval_task_id && !quote.task?.is_locked))
    );
  }

  get reviewCanStart(): boolean {
    return (
      !this.productComponents?.find(
        (p) =>
          p.tenant?.id === this.selectedTenant?.id &&
          !p.projectProductFormGroup.disabled &&
          !p.projectProductFormGroup.valid
      ) && !this.needsToAddUser
    );
  }

  public usersToBeAdded() {
    let usersToAdd = '';

    if (!this.project.workspace_manager_id) {
      usersToAdd = `${this.projectService.currentSelectedProject?.module?.name || 'Workspace'} Manager`;
    }
    if (this.authService.needsDFSManager(this.workspaceId) && this.project.dfs_id && !this.project.dfs_id) {
      usersToAdd += `${usersToAdd ? ', ' : ' '}CTO`;
    }
    if (!this.project.ico_id) {
      usersToAdd += `${usersToAdd ? ', ' : ' '}ICO`;
    }
    if (!this.project.account_coordinator_id) {
      usersToAdd += `${usersToAdd ? ', ' : ' '}Account Coordinator`;
    }
    if (!this.project.bsd_id) {
      usersToAdd += `${usersToAdd ? ', ' : ' '}BSD`;
    }
    if (!this.project.cfmo_id) {
      usersToAdd += `${usersToAdd ? ', ' : ' '}CFMO`;
    }

    return `Please define the following Project roles before continuing: ${usersToAdd}`;
  }

  get reviewProcessMessage() {
    let message: string;
    if (this.needsToAddUser) {
      message = this.usersToBeAdded();
    }

    return message;
  }

  private _getFromPreferences(key: string, tenantId?: number) {
    const preferenceString = localStorage.getItem('preferences');
    const preferences = preferenceString ? JSON.parse(preferenceString) || {} : {};
    const productPreferences = preferences.products || {};
    if (tenantId) {
      const tenantPreferences = productPreferences[tenantId] || {};
      return tenantPreferences[key];
    } else {
      return productPreferences[key];
    }
  }

  public inProgress() {
    this.modalService.openConfirmationDialog({
      titleBarText: `Under Construction`,
      headerText: `We're working here`,
      descriptionText:
        'Project Products is still being worked on. Feel free to click around, but you may experience some bugs.  We hope to have this feature ready to go in a few days. \n \nThank you for your patience.',
      hideConfirmationButton: true,
      cancelButtonText: 'Dismiss',
    });
  }

  public numberWithCommas(x: number): string {
    if (!x) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  public getFormattedDate(date: Date): string {
    return moment(date).format('D MMMM YYYY');
  }

  public async drop(event: CdkDragDrop<ProjectProduct[]>, tenantId: number) {
    // get the tenant location
    const tenantIndex = this.projectTenants?.findIndex(
      (projectTenant: ProjectTenant) => +projectTenant.id === +tenantId
    );

    // update the product positions
    moveItemInArray(this.projectTenants[tenantIndex].project_products, event.previousIndex, event.currentIndex);

    //  get the ranks of the rpoducts
    const beforeProductRank = this.projectTenants[tenantIndex].project_products[event.currentIndex - 1]?.rank;
    const afterProductRank = this.projectTenants[tenantIndex].project_products[event.currentIndex + 1]?.rank;

    const newRank = getRankBetween(beforeProductRank, afterProductRank);
    const draggedProduct = this.projectTenants[tenantIndex].project_products[event.currentIndex];
    // Only update if the rank changes
    if (draggedProduct.rank !== newRank) {
      // update the current state
      this.projectTenants[tenantIndex].project_products[event.currentIndex].rank = newRank;
      // update the database
      await this.productService.updateProjectProduct(draggedProduct.id, { rank: newRank }).toPromise();
      await this.reviseArfs(draggedProduct?.quote_items?.map((quote_item: QuoteItem) => quote_item?.quote));
    }
  }

  public async openCostCodeHelpDialog(): Promise<void> {
    await this.modalService.openCostCodeHelpDialog({ moduleId: this.project.module_id || null }).toPromise();
  }

  async saveFY() {
    if (!this.isAppAdminOrFinanceManager) return;
    const tenant = await this.projectTenantService
      .updateProjectTenant(this.selectedTenant.id, { fiscal_year: this.fiscalYearControl.value })
      .toPromise();
    this.selectedTenant.fiscal_year = tenant.fiscal_year;
    this.fyEdit = false;
    this.refresh();
  }

  cancelFyEdit() {
    this.fiscalYearControl.setValue(this.selectedTenant.fiscal_year || convertDateToFiscalYear());
    this.fyEdit = false;
  }

  get isAppAdminOrFinanceManager(): boolean {
    return this.authService.isAppAdmin || this.authService.isFinanceManager;
  }
}
