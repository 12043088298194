<div id="dispatch-activities" data-test-id="dispatch-activities" class="dispatch-activities">
  <div class="d-flex">
    <h6 class="m-0 dkblue font-weight-semibold">Activity</h6>
    <h6 class="m-0 red font-weight-semibold ml-auto">
      Transfers: <strong>{{ transferActivity?.length }}</strong>
    </h6>
  </div>
  <div class="d-flex flex-column">
    <!-- ng for activity item below -->
    <div id="activity-item" class="d-flex row mt-4" *ngFor="let activity of reassignmentActivity">
      <div class="col-auto">
        <app-profile-thumbnail
          [noBorder]="true"
          [height]="28"
          [width]="28"
          [userId]="activity?.created_by_id"
        ></app-profile-thumbnail>
      </div>
      <div class="col pl-0 d-flex flex-column border-bottom border-gray">
        <div class="d-flex">
          <p class="m-0 dkblue font-weight-semibold">
            <span class="mr-2"> {{ activity?.created_by?.first_name }} {{ activity?.created_by?.last_name }} </span>
          </p>
        </div>
        <div class="d-flex mb-3">
          <p class="m-0 gray">
            {{ activity?.created_datetime | date: 'MMM d yyyy • h:mm aa' }}
          </p>
        </div>
        <div class="d-flex mb-3">
          <p class="m-0 dkblue font-weight-normal mr-auto" *ngIf="activity?.previous_workspace">
            <strong class="bg-red white br-5 pl-2 pr-2 mr-3">T</strong>
            <span class="dkblue font-weight-semibold">{{ activity?.previous_workspace?.name || 'None' }}</span>
            <i class="material-icons dkblue mr-2 ml-2">arrow_forward</i>
            <span class="dkblue font-weight-semibold">{{ activity?.current_workspace?.name || 'None' }}</span>
          </p>
          <p class="bg-gray white font-weight-semibold br-4 pl-2 pr-2 mr-3" *ngIf="!activity?.previous_workspace">
            <span class="">{{ activity?.current_workspace?.name?.toUpperCase() }}</span>
          </p>
        </div>
        <div class="d-flex mb-4" *ngIf="activity?.comment">
          <p class="m-0 dkgray font-weight-normal">
            {{ activity?.comment }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
