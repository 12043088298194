<div
  id="work-order"
  data-test-id="work-order"
  #mainScreen
  class="work-order app-container p-0 m-0"
  [ngClass]="{ 'no-scroll': openDialog }"
>
  <app-work-order-header
    [isWorkOrderAdmin]="workspaceAdmin"
    [isWorkspaceStaff]="isWorkspaceStaff"
    [workOrder]="workOrder"
    (updateWorkOrderfollowers)="updateWorkOrderfollowers($event)"
    (freezeScroll)="freezeScroll($event)"
    (changeWorkOrderAssignment)="changeWorkOrderAssignment()"
    (closeWorkOrder)="closeWorkOrder()"
    (openEditWorkOrder)="editWorkOrder()"
    (followWorkOrder)="followWorkOrder()"
    (unfollowWorkOrder)="unfollowWorkOrder()"
    (openWorkOrder)="openWorkOrder()"
    (reactivateWorkOrder)="reactivateWorkOrder()"
    (putOnHoldWorkOrder)="putOnHoldWorkOrder()"
    (setWorkOrderToPlanned)="setToPlanned()"
    (readyForPickupWorkOrder)="readyForPickupWorkOrder()"
    (newWorkOrderUpdate)="getAllUpdates()"
    (refresh)="headerRefresh()"
    (updateWorkOrderDueDate)="updateWorkOrderDueDate($event)"
    (updateWorkOrderPriority)="updateWorkOrderPriority($event)"
  ></app-work-order-header>
  <div id="work-order-content" [hidden]="!workOrder" class="pl-4 pr-4 pb-4">
    <div class="row d-flex" [ngClass]="{ 'pt-5': workOrder?.status_id === 3 }">
      <div class="col-lg-8">
        <section id="details" class="d-flex flex-column">
          <div class="d-flex align-items-center mb-3">
            <span (click)="toggleDetailsView()" class="d-flex pointer align-items-center">
              <mat-icon class="dkblue mr-2"> {{ showDetailsView ? 'expand_more' : 'chevron_right' }}</mat-icon>
              <h5 class="m-0 dkblue pointer">Work Order Details</h5>
            </span>
            <button
              *ngIf="showDetailsView && isStaff"
              [disabled]="workOrder?.status_id === 3"
              mat-button
              class="lh-28 pl-3 pr-3 ml-auto bg-shade-ltblue"
              (click)="editWorkOrder()"
            >
              Edit Work Order
            </button>
          </div>
          <div class="m-portlet">
            <div [hidden]="!showDetailsView" class="m-portlet__body p-0">
              <div class="row m-0">
                <div class="col item p-4">
                  <h4
                    (click)="editTitle()"
                    class="gray m-0 mb-2 font-weight-semibold pointer d-inline-block align-items-center"
                    *ngIf="!workOrderTitle && !isEditingTitle"
                  >
                    Add Short Description
                    <mat-icon *ngIf="isStaff" class="gray ml-2" style="font-size: 16px; height: 16px">edit</mat-icon>
                  </h4>
                  <h4
                    (click)="editTitle()"
                    class="dkblue m-0 mb-2 font-weight-semibold pointer d-inline-block align-items-center"
                    *ngIf="workOrderTitle && !isEditingTitle"
                  >
                    {{ workOrderTitle }}
                    <mat-icon *ngIf="isStaff" class="gray ml-2" style="font-size: 16px; height: 16px">edit</mat-icon>
                  </h4>
                  <div id="titleInput" class="full-width mb-1" *ngIf="isEditingTitle">
                    <mat-form-field class="full-width" appearance="outline">
                      <input
                        placeholder="Short Description"
                        autoFocus
                        id="work-order-title-input"
                        class="font-weight-semibold"
                        data-test-id="work-order-title-input"
                        matInput
                        required="true"
                        type="text"
                        [formControl]="title"
                      />
                      <mat-error *ngIf="title?.errors?.required">Short Description is required</mat-error>
                    </mat-form-field>
                    <div class="d-flex flex-row-reverse">
                      <button
                        mat-flat-button
                        (click)="updateTitle()"
                        color="accent"
                        type="button"
                        class="px-2 lh-24 ml-2"
                        [disabled]="!title?.value"
                      >
                        Save Changes
                      </button>
                      <button mat-button type="button" class="gray px-2 lh-24" (click)="cancelEditingTitle()">
                        Cancel
                      </button>
                    </div>
                  </div>
                  <h5 *ngIf="topicCategory && topic" class="dkgray m-0 mb-4 font-weight-normal">
                    {{ topicGroup?.name }} > {{ topicCategory?.name }} > {{ topic?.name }}
                  </h5>
                  <h6
                    (click)="editWorkOrder()"
                    *ngIf="!topic?.id && isStaff"
                    class="d-flex align-items-center red pointer m-0 mb-4 font-weight-semibold"
                  >
                    <i class="fas fa-exclamation-circle mr-2"></i><span class="hover-u">Click here to add topic</span>
                  </h6>
                  <h6 class="m-0 mt-4 pt-1 dkblue font-weight-semibold mb-2 d-flex align-items-center">
                    <span class="mr-3"> Created </span>
                    <mat-icon class="method-icon">
                      {{ requestMethod?.icon }}
                    </mat-icon>
                  </h6>
                  <p class="mb-1 font-weight-normal dkblue lh-18">
                    <span> {{ formattedOpenDate }} • {{ formattedOpenTime }} </span>
                  </p>
                  <div *ngIf="createdByName" class="d-flex align-items-center mb-1">
                    <app-profile-thumbnail
                      [noBorder]="true"
                      class="mr-2"
                      [height]="18"
                      [width]="18"
                      [userId]="workOrder?.created_by?.id"
                    ></app-profile-thumbnail>
                    <p class="m-0 dkblue font-weight-normal lh-18">
                      <span>{{ createdByName }}</span>
                    </p>
                  </div>

                  <div class="row mt-4">
                    <div class="col">
                      <span class="d-flex align-items-center mb-2">
                        <h6
                          (click)="editSummary()"
                          class="m-0 dkblue pointer font-weight-semibold mb-0 mr-2 d-inline-block"
                        >
                          Summary
                        </h6>
                        <mat-icon
                          *ngIf="isStaff"
                          class="gray pointer d-inline-block"
                          (click)="editSummary()"
                          style="font-size: 16px; height: 16px"
                          >edit</mat-icon
                        >
                      </span>
                      <p
                        [hidden]="isEditingSummary"
                        class="m-0 dkblue font-weight-normal"
                        [innerHTML]="workOrderSummary"
                        appTextCollapseExpand
                        maxHeight="190"
                        (heightExceededEvent)="showCollapseDetailViewButton = $event"
                        [showMore]="!collapseDetailView"
                      ></p>
                      <p
                        id="collapse-detail-view-button"
                        class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2"
                        *ngIf="showCollapseDetailViewButton === true && !isEditingSummary"
                        (click)="collapseDetailView = !collapseDetailView"
                      >
                        <i class="fas {{ collapseDetailView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
                        {{ collapseDetailView === true ? 'Show More' : 'Show Less' }}
                      </p>
                      <span [hidden]="!isEditingSummary">
                        <app-editor
                          #summaryEditor
                          placeholder="Summary"
                          class="full-width"
                          required_content="Work Order Summary is Required"
                        ></app-editor>
                        <div class="d-flex align-items-center flex-row-reverse position-absolute save-buttons">
                          <button
                            mat-flat-button
                            [disabled]="!summaryEditor?.content?.value"
                            (click)="updateSummary()"
                            color="accent"
                            type="button"
                            class="px-2 lh-24 ml-2"
                          >
                            Save Changes
                          </button>
                          <button mat-button type="button" class="gray px-2 lh-24" (click)="cancelEditingSummary()">
                            Cancel
                          </button>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="formTemplate" class="m-portlet__body details-highlight pointer p-0">
              <div class="row m-0">
                <div class="col pl-4 pr-4 item">
                  <div class="row" *ngFor="let s of formTemplate.sections; index as sectionIndex">
                    <div
                      class="col-3"
                      [ngClass]="{
                        'p-4': elementIndex < 4,
                        'pl-4 pr-4 pb-4 pt-2': elementIndex >= 4
                      }"
                      *ngFor="let e of s.elements; index as elementIndex"
                    >
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">
                        {{ e.label }}
                      </h6>
                      <h5 class="m-0 dkblue font-weight-normal">
                        {{ e.value?.name || e.value?.label || e.value }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="workOrder?.form_submission?.form_template_id === 10"
              class="m-portlet__body details-highlight pointer p-0"
            >
              <div class="row m-0">
                <div
                  class="col-3"
                  [ngClass]="{
                    'p-4': elementIndex < 4,
                    'pl-4 pr-4 pb-4 pt-2': elementIndex >= 4
                  }"
                  *ngFor="let e of this.workOrder?.form_submission?.elements; index as elementIndex"
                >
                  <h6 class="m-0 dkblue font-weight-semibold mb-2">
                    {{ e.label }}
                  </h6>
                  <h5 class="m-0 dkblue font-weight-normal">
                    {{ e.value }}
                  </h5>
                </div>
              </div>
            </div>
            <div *ngIf="showDetailsView" class="m-portlet__body p-0">
              <div class="row m-0">
                <div class="col p-4 item">
                  <div class="row">
                    <div class="col-2">
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">Building</h6>
                      <h5 *ngIf="buildingCode || floorCode" class="m-0 font-weight-normal dkblue">
                        {{ buildingCode }}-{{ floorCode }}
                      </h5>
                    </div>
                    <div class="col-2">
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">Suite</h6>
                      <h5 *ngIf="suiteName" class="m-0 font-weight-normal dkblue">
                        {{ suiteName }}
                      </h5>
                    </div>
                    <div class="col-4">
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">Department</h6>
                      <h5 *ngIf="departmentName" class="m-0 font-weight-normal dkblue">
                        {{ departmentName }}
                      </h5>
                    </div>
                    <div class="col-4">
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">Room # / Landmark</h6>
                      <h5 class="m-0 font-weight-normal dkblue">
                        {{ landmark }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showDetailsView" class="m-portlet__body p-0">
              <div class="row m-0">
                <div class="col-6 p-4 item item-last border-right border-gray d-flex flex-column">
                  <div class="d-flex align-items-top mb-1">
                    <h6 class="m-0 dkblue font-weight-semibold mr-auto">Requested By</h6>
                    <button
                      *ngIf="requester?.id && isStaff"
                      mat-stroked-button
                      [disabled]="workOrder?.status_id === 3"
                      (click)="changeWorkOrderRequester()"
                      color="accent"
                      class="ml-3 lh-28 pl-3 pr-3 bg-white"
                    >
                      Change
                    </button>
                  </div>
                  <span class="d-flex align-items-top mt-1">
                    <button
                      *ngIf="!requester?.id && isStaff"
                      [disabled]="workOrder?.status_id === 3"
                      mat-flat-button
                      (click)="changeWorkOrderRequester()"
                      color="accent"
                      class="mt-3"
                    >
                      Add Requestor
                    </button>
                    <app-profile-info [user]="requester" [additionalUserFields]="['title', 'department_name']">
                    </app-profile-info>
                  </span>
                </div>
                <div class="col-6 p-4 item item-last d-flex flex-column">
                  <div class="d-flex align-items-top mb-1">
                    <h6 class="m-0 dkblue font-weight-semibold mr-auto">Assigned To</h6>
                    <button
                      *ngIf="assignedToUser?.id && isStaff"
                      mat-stroked-button
                      [disabled]="workOrder?.status_id === 3"
                      (click)="changeWorkOrderAssignment()"
                      color="accent"
                      class="ml-3 lh-28 pl-3 pr-3 bg-white"
                    >
                      {{ assignedToUser?.id ? 'Change' : 'Assign' }}
                    </button>
                  </div>
                  <span class="d-flex align-items-top mt-1">
                    <button
                      *ngIf="!assignedToUser?.id && isStaff"
                      [disabled]="workOrder?.status_id === 3"
                      mat-flat-button
                      (click)="changeWorkOrderAssignment()"
                      color="warn"
                      class="mt-3"
                    >
                      Assign Work Order
                    </button>
                    <app-profile-info [user]="assignedToUser" [additionalUserFields]="['title', 'department_name']">
                    </app-profile-info>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="updates" class="d-flex flex-column">
          <div class="d-flex align-items-center mb-3">
            <span (click)="toggleUpdatesView()" class="d-flex align-items-center pointer">
              <mat-icon class="dkblue mr-2"> {{ showUpdatesView ? 'expand_more' : 'chevron_right' }}</mat-icon>
              <h5 class="m-0 dkblue pointer">
                Updates
                <span class="ml-2 gray font-weight-semibold">
                  {{ workOrderUpdates?.length }}
                </span>
              </h5>
            </span>
            <button
              *ngIf="showUpdatesView"
              (click)="addWorkOrderUpdate()"
              mat-flat-button
              color="success"
              type="button"
              class="ml-3 lh-28 pl-3 pr-3 ml-auto"
              [disabled]="workOrder?.status_id === 3 && !canReactivate"
            >
              + Update
            </button>
          </div>
          <div *ngIf="workOrderUpdates?.length === 0" class="m-portlet bg-transparent">
            <div *ngIf="showUpdatesView" class="m-portlet__body p-4 text-center">
              <p class="m-0 gray font-weight-normal">
                Click <span class="font-weight-semibold">+ Update</span> to add the first update
              </p>
            </div>
          </div>
          <div
            *ngFor="let workOrderUpdate of workOrderUpdates"
            [hidden]="!showUpdatesView"
            class="project-update m-portlet mb-3"
          >
            <div class="m-portlet__body pt-3 pr-4 pl-4 pb-4">
              <div class="row align-items-top">
                <div class="col d-flex flex-column pl-3">
                  <div class="d-flex align-items-center">
                    <app-profile-info
                      class="mr-auto"
                      [user]="{
                        id: workOrderUpdate.created_by?.id,
                        first_name: workOrderUpdate.created_by?.first_name,
                        last_name: workOrderUpdate.created_by?.last_name
                      }"
                      [date]="workOrderUpdate.created_datetime"
                    ></app-profile-info>
                    <p
                      class="mb-0 mr-3 font-italic"
                      [ngClass]="{
                        green: workOrderUpdate?.notify_followers === 1,
                        gray: workOrderUpdate?.notify_followers === 0
                      }"
                    >
                      <small>
                        <i *ngIf="workOrderUpdate?.notify_followers === 1" class="fa fa-check mr-1"></i>
                        {{ workOrderUpdate?.notify_followers === 1 ? 'Notifications Sent' : 'Notifications Not Sent' }}
                      </small>
                    </p>
                    <h6
                      *ngIf="
                        (isStaff && workOrderUpdate?.work_order_health_type?.id) || workOrderUpdate?.is_original_request
                      "
                      class="mb-0 px-2 br-4 mr-2 bg-green white"
                      [ngClass]="{
                        'bg-ltblue': workOrderUpdate?.is_original_request,
                        'bg-green': workOrderUpdate?.work_order_health_type?.id === 1,
                        'bg-orange': workOrderUpdate?.work_order_health_type?.id === 2,
                        'bg-red': workOrderUpdate?.work_order_health_type?.id === 3
                      }"
                      style="font-size: 11px; line-height: 20px"
                    >
                      {{
                        workOrderUpdate?.is_original_request
                          ? 'Original Request'
                          : workOrderUpdate?.work_order_health_type?.name
                      }}
                    </h6>
                    <h6
                      *ngIf="
                        isStaff && workOrderUpdate?.access?.indexOf(2) > -1 && workOrderUpdate?.access?.indexOf(3) > -1
                      "
                      class="mb-0 px-2 br-4 mr-2 white bg-gray lh-20"
                    >
                      Everyone
                    </h6>
                    <p
                      *ngIf="
                        isStaff &&
                        workOrderUpdate?.access?.indexOf(2) === -1 &&
                        workOrderUpdate?.access?.indexOf(3) === -1
                      "
                      class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-orange lh-20"
                    >
                      UHAT/1Call Only
                    </p>
                    <button
                      [matMenuTriggerFor]="update_menu"
                      mat-icon-button
                      color="accent"
                      type="button"
                      [disabled]="!workOrderUpdate?.id || workOrderUpdate?.created_by_id !== currentUser?.id"
                    >
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #update_menu="matMenu" xPosition="before">
                      <button
                        mat-menu-item
                        *ngIf="workOrderUpdate?.id && workOrderUpdate?.created_by_id === currentUser?.id"
                        (click)="editWorkOrderUpdate(workOrderUpdate)"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit Update</span>
                      </button>
                      <button
                        mat-menu-item
                        *ngIf="workOrderUpdate?.id && workOrderUpdate?.created_by_id === currentUser?.id && isStaff"
                        (click)="deleteWorkOrderUpdate(workOrderUpdate?.id)"
                      >
                        <mat-icon>delete</mat-icon>
                        <span>Delete Update</span>
                      </button>
                    </mat-menu>
                  </div>
                  <div class="d-flex update-content flex-column mt-3">
                    <div
                      class="ml-5 dkblue"
                      [innerHTML]="workOrderUpdate?.message"
                      appTextCollapseExpand
                      maxHeight="100"
                      (heightExceededEvent)="workOrderUpdate.showCollapseMessageViewButton = $event"
                      [showMore]="!workOrderUpdate.collapseMessageView"
                    ></div>
                    <p
                      id="collapse-detail-view-button"
                      class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2 ml-5"
                      *ngIf="workOrderUpdate.showCollapseMessageViewButton === true"
                      (click)="workOrderUpdate.collapseMessageView = !workOrderUpdate.collapseMessageView"
                    >
                      <i
                        class="fas {{
                          workOrderUpdate.collapseMessageView === true ? 'fa-angle-down' : 'fa-angle-up'
                        }}  mr-1"
                      ></i>
                      {{ workOrderUpdate.collapseMessageView === true ? 'Show More' : 'Show Less' }}
                    </p>
                    <app-file-chip-list
                      [maxHeight]="80"
                      class="ml-5 pt-3"
                      *ngIf="workOrderUpdate?.files?.length"
                      [files]="workOrderUpdate.files"
                      [diameter]="40"
                    ></app-file-chip-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="col-lg-4">
        <section id="checklist" *ngIf="authService.isStaffOnAnyModule">
          <app-work-order-checklist
            #workorderChecklist
            [woId]="workOrderId"
            [woStatus]="workOrder?.status_id"
            [canReactivate]="canReactivate"
            (checklistUpdated)="checklistUpdated()"
          ></app-work-order-checklist>
        </section>
        <section id="files">
          <div class="d-flex align-items-center mb-2">
            <span (click)="toggleFilesView()" class="mr-auto d-flex align-items-center pointer">
              <mat-icon class="dkblue mr-2"> {{ showFileView ? 'expand_more' : 'chevron_right' }}</mat-icon>
              <h5 class="m-0 dkblue pointer">
                Files
                <span class="ml-2 gray font-weight-semibold">
                  {{ files.length }}
                </span>
              </h5>
            </span>
            <div
              *ngIf="showFileView && files.length / filePagination.pageSize > 1"
              class="d-flex align-items-center ml-3"
            >
              <button
                mat-icon-button
                color="primary"
                type="button"
                class="ml-auto"
                (click)="paginateFile(-1)"
                [disabled]="filePagination.pageIndex == 0"
              >
                <mat-icon>chevron_left</mat-icon>
              </button>
              <h6 class="mx-2 mb-0 gray font-weight-semibold">
                {{ filePagination.currentPages }}
              </h6>
              <button
                mat-icon-button
                color="primary"
                type="button"
                (click)="paginateFile(1)"
                [disabled]="filePagination.pageIndex >= filePagination.maxPage - 1"
              >
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
            <mat-spinner *ngIf="isDownloadingAllFiles" diameter="21" color="accent" class=""> </mat-spinner>
            <button
              *ngIf="showFileView && files?.length > 1 && !isDownloadingAllFiles"
              mat-button
              color="accent"
              class="ml-3 lh-28 px-3"
              [disabled]="isDownloadingAllFiles"
              (click)="downloadAllFiles()"
            >
              Download All
            </button>
            <button
              *ngIf="showFileView"
              mat-flat-button
              color="success"
              class="ml-3 lh-28 pl-3 pr-3"
              [disabled]="workOrder?.status_id === 3"
              (click)="addWorkOrderFile()"
            >
              + File
            </button>
          </div>
          <div *ngIf="files.length === 0 || !showFileView" class="m-portlet bg-transparent">
            <div *ngIf="showFileView" class="m-portlet__body p-4 text-center">
              <p class="m-0 gray font-weight-normal">
                Click <span class="font-weight-semibold">+ Files</span> to Upload a File
              </p>
            </div>
          </div>
          <div *ngIf="files.length > 0 && showFileView" class="m-portlet p-0">
            <div
              *ngFor="let file of files | paginator: filePagination.pageIndex:filePagination.pageSize; let last = last"
              class="m-portlet__body p-3 item file full-width d-flex align-items-center"
              [ngClass]="{ 'item-last': last }"
            >
              <app-file-thumbnail class="mr-3" width="36" height="36" [file]="file"></app-file-thumbnail>
              <div class="d-flex flex-column text-truncate mr-auto">
                <p
                  (click)="previewFile(file)"
                  class="dkblue hover-u m-0 lh-18 font-weight-semibold pointer text-truncate"
                >
                  {{ file.name }}
                </p>
                <h6 *ngIf="file?.created_datetime" class="gray m-0 lh-18 font-weight-normal">
                  {{ file.created_datetime | date: 'EEE, MMM d yyyy • h:mm aa' }}
                </h6>
              </div>
              <div class="d-flex align-items-center ml-auto">
                <mat-icon
                  matRipple
                  color="accent"
                  (click)="previewFile(file)"
                  class="ml-2"
                  matTooltip="Preview"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  >find_in_page</mat-icon
                >
                <mat-icon
                  matRipple
                  color="accent"
                  (click)="downloadFile(file)"
                  class="ml-2"
                  matTooltip="Download"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  >get_app</mat-icon
                >
                <mat-icon
                  matRipple
                  color="accent"
                  [matMenuTriggerFor]="file_menu"
                  matTooltip="More"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                  class="ml-2"
                  >more_vert</mat-icon
                >
                <mat-menu #file_menu="matMenu">
                  <button mat-menu-item (click)="editFile(file)" [disabled]="isProjectFile(file)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="deleteFile(file)"
                    [disabled]="!!workOrderUpdateFileIds.includes(file.file_id)"
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </section>
        <section *ngIf="isStaff" id="timeLog">
          <div class="d-flex align-items-center mb-3">
            <span (click)="toggleTimeLogView()" class="d-flex align-items-center pointer">
              <mat-icon class="dkblue mr-2"> {{ showTimeLogView ? 'expand_more' : 'chevron_right' }}</mat-icon>
              <h5 class="m-0 dkblue pointer">
                Time Log
                <span class="ml-2 gray font-weight-semibold">
                  {{ timeLogEntries.length }}
                </span>
              </h5>
            </span>
            <span class="ml-auto dkblue font-weight-semibold">
              Total Logged: {{ timeLogTotal.hoursWorked }}h {{ timeLogTotal.minutesWorked }}m
            </span>
            <button
              *ngIf="showTimeLogView"
              mat-flat-button
              color="success"
              class="lh-28 pl-3 pr-3 ml-3"
              (click)="createTimeLogEntry()"
            >
              + Entry
            </button>
          </div>
          <div *ngIf="timeLogEntries.length === 0" class="m-portlet bg-transparent">
            <div *ngIf="showTimeLogView" class="m-portlet__body p-4 text-center">
              <p class="m-0 gray font-weight-normal">
                Click <span class="font-weight-semibold">+ Time Entry</span> to Add a Time Entry
              </p>
            </div>
          </div>
          <div *ngIf="timeLogEntries.length > 0" class="m-portlet p-0">
            <!-- TIME ENTRIES -->
            <div
              [hidden]="!showTimeLogView"
              class="m-portlet__body pl-3 pr-2 pt-2 pb-2 item"
              *ngFor="let entry of timeLogEntries; let last = last"
              [ngClass]="{ 'item-last': last }"
            >
              <div class="d-flex align-items-center">
                <app-profile-thumbnail
                  [noBorder]="true"
                  class="d-inline-block align-middle mr-3"
                  [height]="30"
                  [width]="30"
                  [userId]="entry.worker.id"
                ></app-profile-thumbnail>
                <div class="d-flex flex-column">
                  <h6 class="mb-0 dkblue font-weight-semibold">
                    {{ entry.worker.first_name }} {{ entry.worker.last_name }}
                  </h6>
                  <h6 class="m-0 font-weight-normal dkgray text-truncate">
                    {{ entry.activity.name }}
                  </h6>
                </div>
                <div class="ml-auto d-flex align-items-center">
                  <span class="d-flex flex-column align-items-end">
                    <p class="m-0 dkblue font-weight-semibold">{{ entry.hours_worked }}h {{ entry.minutes_worked }}m</p>
                    <!-- bg-ltblue for UHAT, bg-red for 1CALL, bg-dkred for OUMI -->
                    <h6
                      class="m-0 company-badge white font-weight-semibold br-4"
                      [ngClass]="{
                        'bg-red': entry.company?.id === 1,
                        'bg-ltblue': entry.company?.id === 2,
                        'bg-dkred': entry.company?.id === 3 || 5,
                        'bg-dkgray': entry.company?.id === 4
                      }"
                    >
                      {{ entry.company?.name }}
                    </h6>
                  </span>
                  <button mat-icon-button [matMenuTriggerFor]="entry_menu" color="accent" class="ml-1">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #entry_menu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="editTimeLogEntry(entry)">
                      <mat-icon>edit</mat-icon>
                      <span> Edit Time Entry </span>
                    </button>
                    <button mat-menu-item (click)="deleteTimeLogEntry(entry.id)">
                      <mat-icon>delete</mat-icon>
                      <span> Delete Time Entry </span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section *ngIf="isStaff" id="activity">
          <div class="d-flex align-items-center mb-2">
            <mat-icon (click)="toggleActivityView()" class="dkblue mr-2">
              {{ showActivityView ? 'expand_more' : 'chevron_right' }}</mat-icon
            >
            <h5 (click)="toggleActivityView()" class="m-0 dkblue pointer mr-auto">
              Recent Activity
              <span class="ml-2 gray font-weight-semibold">
                {{ activityEntries.length }}
              </span>
            </h5>
            <div
              class="d-flex align-items-center"
              *ngIf="showActivityView && activityEntries.length / activityPagination.pageSize > 1"
            >
              <button
                mat-icon-button
                color="primary"
                type="button"
                class="ml-auto"
                (click)="paginateActivity(-1)"
                [disabled]="activityPagination.pageIndex == 0"
              >
                <mat-icon>chevron_left</mat-icon>
              </button>
              <h6 class="mx-2 mb-0 gray font-weight-semibold">
                {{ activityPagination.currentPages }}
              </h6>
              <button
                mat-icon-button
                color="primary"
                type="button"
                (click)="paginateActivity(1)"
                [disabled]="activityPagination.pageIndex >= activityPagination.maxPage - 1"
              >
                <mat-icon>chevron_right</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="activityEntries.length > 0" class="m-portlet p-0">
            <!-- ACTIVITY ENTRIES -->
            <div
              [hidden]="!showActivityView"
              class="m-portlet__body p-3 item"
              *ngFor="
                let activity of activityEntries | paginator: activityPagination.pageIndex:activityPagination.pageSize;
                let last = last
              "
              [ngClass]="{ 'item-last': last }"
            >
              <div>
                <div class="d-flex align-items-start">
                  <app-profile-thumbnail
                    *ngIf="activity.impersonator_id"
                    [noBorder]="false"
                    [height]="34"
                    style="margin-right: -15px"
                    [width]="34"
                    [userId]="activity.impersonator_id"
                  ></app-profile-thumbnail>
                  <app-profile-thumbnail
                    [noBorder]="false"
                    class="mr-3"
                    [height]="34"
                    [width]="34"
                    [userId]="activity.user_id"
                  ></app-profile-thumbnail>
                  <div>
                    <h6 class="m-0 primary lh-18 text-wrap">
                      <span *ngIf="activity.impersonator_id">
                        {{ activity.impersonator?.first_name }} {{ activity.impersonator?.last_name }}
                      </span>
                      <span *ngIf="activity.impersonator_id" class="dkgray font-weight-normal"> as </span>
                      <span class="mr-2"> {{ activity.user.first_name }} {{ activity.user.last_name }} </span>
                      <small class="dkgray font-weight-normal">
                        {{ activity.created_datetime | date: 'MMM d yyyy • h:mm aa' }}
                      </small>
                    </h6>
                    <h6 class="mb-0 activity-changes" *ngFor="let act of activity.data">
                      <span class="lh-18 d-flex align-items-center flex-wrap">
                        <span *ngIf="act.type === 'text'" class="dkgray font-weight-normal mr-3" [innerHtml]="act.text">
                        </span>
                        <span *ngIf="act.type === 'change'" class="dkgray font-weight-normal mr-3 text-nowrap">
                          Updated the {{ act.field }}:
                        </span>
                        <span class="d-flex align-items-center" *ngIf="act.type === 'change'">
                          <span class="primary font-weight-semibold mr-2 mb-0" [innerHtml]="act.from ?? 'None'"></span>
                          <i class="material-icons gray mr-2">east</i>
                          <span class="primary font-weight-semibold mr-2 mb-0" [innerHtml]="act.to ?? 'None'"></span>
                        </span>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
