import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { pdf } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { find } from 'lodash';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import {
  AgendaItemDialogComponent,
  ConcludeMeetingDialogComponent,
  ConfirmationChoiceDialogComponent,
  EditorComponent,
  MeetingDialogComponent,
  MeetingExportDialogComponent,
  UserSelectModalComponent,
} from 'src/app/components';
import { MeetingAttendeeType, ResourceType, UserType } from 'src/app/enums';
import { HtmlEncodeDecodePipe } from 'src/app/pipes';
import {
  AuthService,
  FileService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectOverviewService,
  ProjectService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import {
  AgendaItem,
  Meeting,
  MeetingAttendee,
  Note,
  ProjectUpdate,
  UhatFileReference,
  User,
  WorkOrderUpdate,
} from 'src/app/types';
import { RFIService } from 'src/app/workspaces/construction/services';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChildren(EditorComponent)
  private _agenda_item_note_editors_components!: QueryList<EditorComponent>;
  constructor(
    private appRef: ApplicationRef,
    private meetingService: MeetingService,
    private userService: UserService,
    private fileService: FileService,
    public authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    public router: Router,
    private progressIndicator: ProgressIndicatorService,
    private rfiService: RFIService,
    private projectService: ProjectService,
    private _projectOverviewService: ProjectOverviewService,
    private modalService: ModalService,
    private workOrderService: WorkOrderService
  ) {}

  private refreshSubscription;
  private _htmlEncodeDecodePipe = new HtmlEncodeDecodePipe();

  public attachmentIds = [];
  public noteToEdit: Note = null;
  shownMeeting: Meeting;
  agendaItems: AgendaItem[] = [];
  agendaItemsToExport;
  attendees: MeetingAttendee[] = [];
  attendeesCount: number;
  printPreview; // TODO: link this variable
  reportDateTime = Date.now();
  searching: boolean;
  meetingId: number;
  currentUser: User;
  showNotification = true;
  showOverdueNotification = true;
  pendingRequestCount: number;
  meetingLength: number;
  agendaLength: number;
  agendaMeetingDiff = { diff: 0, over: false };
  loaders = {
    loadingMeeting: false,
  };
  downloading = false;
  details_expanded = true;
  @ViewChild('pdf', { static: true }) pdf;
  showAddMyselfButton = false;
  public agendaItemFileTracker: { [key: string]: Set<string> } = {};

  get isConcluded() {
    return this.shownMeeting && this.shownMeeting.is_concluded;
  }

  get isCreatorOrFacilitator() {
    let validIds = [];
    if (this.attendees && this.attendees.length > 0) {
      validIds = validIds.concat(
        this.attendees.filter((attendee) => attendee.attendee_type_id === 1).map((attendee) => attendee.user_id)
      );
    }
    if (this.shownMeeting && this.shownMeeting.created_by_id && !validIds.includes(this.shownMeeting.created_by_id)) {
      validIds.push(this.shownMeeting.created_by_id);
    }

    return this.currentUser ? validIds.includes(this.currentUser.id) : false;
  }

  get meetingDueDate() {
    return moment(this.shownMeeting.end_datetime).add(5, 'days').format('MMMM D yyyy • h:mm a');
  }

  get isFiveMinutesBeforeUntilEnd() {
    // show 5 minutes before until end of meeting
    return moment(this.shownMeeting.start_datetime).diff(moment()) < 300000 && !this.meetingIsPastEndTime;
  }

  get meetingIsPastEndTime() {
    return moment(this.shownMeeting.end_datetime).diff(moment()) < 0;
  }

  get resourceType() {
    return ResourceType;
  }

  async ngOnInit() {
    this.currentUser = this.authService.getLoggedInUser();
    this.route.params.subscribe((params: Params) => {
      this.meetingId = params.id;
      this.refresh();
    });
    // now also watches for refreshNeeded (to fix issue where routing to same link from notifications doesn't refresh page/data)
    this.refreshSubscription = this.meetingService.refreshNeeded$.subscribe(async () => {
      await this.refresh();
    });
  }

  ngAfterViewInit() {
    this._agenda_item_note_editors_components.changes.subscribe((editors) => {
      editors.forEach((editor) => {
        editor?.content?.valueChanges?.subscribe((val) => {
          const fountAgendaItem = this.agendaItems.find((agendaItem: AgendaItem) => agendaItem.id === editor.id?.id);
          if (fountAgendaItem) {
            fountAgendaItem.draftNote = val;
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  async refresh() {
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus('Refreshing..');
    this.loaders.loadingMeeting = true;
    this.noteToEdit = null;
    const meeting = await this.meetingService
      .getMeetingById(this.meetingId, true, (error) => {
        if (error.status === 404) {
          this.router.navigate(['/meetings']);
          return true;
        } else {
          return false;
        }
      })
      .toPromise();

    const meetingData = await this.meetingService.getInitDataMeetingById(this.meetingId).toPromise();

    // get the agenda items files and add them to the the tracker list
    meetingData?.agendaItems?.forEach((agendaItem: AgendaItem) => {
      if (agendaItem?.id && !this.agendaItemFileTracker[agendaItem.id]) {
        // extract the agenda item file names
        const fileNames = agendaItem.files?.map((file: UhatFileReference) => file.name);
        // add them to the tracker
        this.agendaItemFileTracker[agendaItem.id] = fileNames?.length
          ? new Set<string>(fileNames)
          : new Set<string>([]);
      }
    });

    if (meeting && meeting.type_id === 3) {
      this.pendingRequestCount = meetingData.pendingRequests ? meetingData.pendingRequests.length : 0;
    }

    const attendees = meetingData.attendees;

    const agendaItems = meetingData.agendaItems;

    // Gets all total times in minutes. As well as the difference between the meeting time and the total time of all of the agendas.
    this.agendaItemsTotalTime(agendaItems);
    this.findMeetingLength(meeting);
    this.findAgendaMeetingDiff();

    for (const i of agendaItems) {
      const foundAgendaItem = (this.agendaItems || []).find((ai) => +ai.id === +i.id);
      i.draftNote = null;
      i.is_expanded = foundAgendaItem ? !!foundAgendaItem.is_expanded : true;
      delete i.files;
      this.loadAgendaData(i);
      i.newNoteFiles = [];
      i.newNote = false;
    }

    this.shownMeeting = meeting;
    this.attendees = attendees;
    this.attendeesCount = this.attendees.length;
    this.agendaItems = agendaItems;
    this.showAddMyselfButton = !attendees.find((a) => a.user_id === this.authService.currentUser.id);
    this.loaders.loadingMeeting = false;
    this.progressIndicator.close();
  }

  public canDeleteAgendaItem(agendaItem: AgendaItem): boolean {
    return (
      this.authService.isAppAdmin ||
      this.authService.isCFMO ||
      this.authService.isCFO ||
      this.currentUser.id === agendaItem.created_by_id ||
      this.currentUser.id === this.shownMeeting.created_by_id ||
      this.isCreatorOrFacilitator
    );
  }

  openEditMeetingDialog() {
    const dialogRef = this.dialog.open(MeetingDialogComponent, {
      width: '600px',
      data: this.shownMeeting,
    });
    dialogRef.afterClosed().subscribe((updatedMeeting) => {
      // TODO bookmark: this meeting gets deleted when the recurring meeting is updated
      // maybe only refresh if the current meeting id = the new meeting id
      // otherwise, navigate to the new meeting?
      // OR... don't delete each recurring meeting unless the recurrence changes
      // if the recurrence IS edited, then it should probably take you back to the meeting list
      this.refresh();
    });
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.agendaItems, event.previousIndex, event.currentIndex);

    const prev = event.currentIndex - 1 >= 0 ? this.agendaItems[event.currentIndex - 1] : null;
    const after = event.currentIndex + 1 < this.agendaItems.length ? this.agendaItems[event.currentIndex + 1] : null;
    this.updateHash(this.agendaItems[event.currentIndex], prev, after);
    this.meetingService.moveNodeBefore(this.agendaItems[event.currentIndex], prev, after);
  }

  // locally set the new hash to correct value
  // if back end returns an error (because outdated data), we will refresh
  private updateHash(moved, prev, after) {
    const min = prev && prev.display_order_hash ? prev.display_order_hash : 0;
    const max = after && after.display_order_hash ? after.display_order_hash : 99999999;
    moved.display_order_hash = Math.round((min + max) / 2);
  }

  openAddAgendaItemDialog() {
    const dialogRef = this.dialog.open(AgendaItemDialogComponent, {
      width: '480px',
      data: {
        meeting_id: this.shownMeeting.id,
        meeting_title: this.shownMeeting.title,
        meeting_code: this.shownMeeting.code,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addAgendaItem(result);
      }
    });
  }

  openEditAgendaItemDialog(agendaItem: AgendaItem) {
    const dialogRef = this.dialog.open(AgendaItemDialogComponent, {
      width: '480px',
      data: {
        id: agendaItem.id,
        description: agendaItem.description,
        duration: agendaItem.duration,
        meeting_id: this.shownMeeting.id,
        meeting_title: this.shownMeeting.title,
        meeting_code: this.shownMeeting.code,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.id) {
        const agendaItemToUpdate = {
          description: result.description,
          duration: result.duration,
        };
        this.progressIndicator.openAwaitIndicatorModal();
        this.progressIndicator.updateStatus('Updating Agenda Item..');
        this.meetingService.updateAgendaItem(result.id, agendaItemToUpdate).subscribe((updatedAgendaItem) => {
          this.snackbar.open('Agenda item updated!');
          this.refresh();
        });
      }
    });
  }

  addAgendaItem(agendaItem) {
    const agendaItemToAdd = {
      meeting_id: this.meetingId,
      description: agendaItem.description,
      duration: agendaItem.duration,
    };
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus('Adding Agenda Item..');
    this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
      this.snackbar.open('Agenda item added!');
      this.refresh();
    });
  }

  deactivateAgendaItem(agendaItem) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Agenda Item',
        headerText: 'Remove Agenda Item',
        descriptionText:
          'Are you sure you want to remove this agenda item? Any notes on this agenda item will also be deleted.',
        confirmationButtonText: 'Remove',
      })
      .subscribe(async (isConfirmed) => {
        if (isConfirmed) {
          this.progressIndicator.openAwaitIndicatorModal();
          this.progressIndicator.updateStatus('Removing Agenda Item..');
          await this.meetingService.deactivateAgendaItem(agendaItem.id).toPromise();
          await this.refresh();
          this.snackbar.open(`Meeting Agenda '${agendaItem.description}' has been removed`);
        }
      });
  }

  private findMeetingLength(meeting: Meeting) {
    const startTime = moment(meeting.start_datetime);
    const endTime = moment(meeting.end_datetime);
    const diff = startTime.diff(endTime, 'minutes');

    this.meetingLength = Math.abs(diff);
  }

  private agendaItemsTotalTime(agendaItems: AgendaItem[]) {
    let total = 0;
    agendaItems.forEach((agendaItem) => {
      total += agendaItem.duration;
    });
    this.agendaLength = total;
  }

  private findAgendaMeetingDiff() {
    const diff = this.meetingLength - this.agendaLength;
    this.agendaMeetingDiff.diff = Math.abs(diff);
    this.agendaMeetingDiff.over = diff < 0 ? false : true;
  }

  async addAttendees(attendeesToAdd) {
    const toAdd = [];
    const alreadyAdded = [];
    // determine if we have already added the attendee or not
    for (const attendee of attendeesToAdd) {
      if (find(this.attendees, (a) => a.user_id === attendee.id)) {
        alreadyAdded.push(attendee);
      } else {
        toAdd.push(attendee);
      }
    }

    if (toAdd.length === 0 && alreadyAdded.length > 0) {
      // if no users to add
      this.snackbar.open(`User${alreadyAdded.length > 1 ? 's are' : ' is'} already attending this meeting`);
    } else {
      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Adding Attendees..');
      // otherwise add each attendee
      for (const attendee of toAdd) {
        const user = {
          meeting_id: this.meetingId,
          user_id: attendee.id,
          attendee_type_id: 3,
        };
        await this.meetingService.addAttendee(user).toPromise();
      }
      this.snackbar.open(
        `${toAdd.length} attendee${toAdd.length > 1 ? 's' : ''} added!${
          alreadyAdded.length > 0
            ? ` ${alreadyAdded.length} attendee${alreadyAdded.length > 1 ? 's' : ''} previously added!`
            : ''
        }`
      );
      this.refresh();
    }
  }

  async updateAttendeeRole(attendee, roleId) {
    if (attendee.id && roleId) {
      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Updating Attendee..');
      await this.meetingService.updateAttendee(attendee.id, { attendee_type_id: roleId }).toPromise();
      this.snackbar.open('Attendee updated!');
      this.refresh();
    }
  }

  async removeAttendee(attendeeToRemove) {
    if (attendeeToRemove.id) {
      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Removing Attendee..');
      await this.meetingService.removeAttendee(attendeeToRemove.id).toPromise();
      this.snackbar.open('Attendee removed!');
      this.refresh();
    }
  }

  hideNotification() {
    this.showNotification = false;
    this.snackbar.open(`Pending requests dismissed until next time`);
  }

  hideOverdueNotification() {
    this.showOverdueNotification = false;
    this.snackbar.open(`Due date notification dismissed until next time`);
  }

  copyShareLink() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.origin}${window.location.pathname}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getProfileThumbnailUrl(userId: number) {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  async gotoAgendaParentLink(agendaItem) {
    if (!this.authService.isVendorOnAnyProject || this.authService.isStaffOnAnyModule) {
      let link;
      if (agendaItem.parent_type_id === ResourceType.Task) {
        link = [`/tasks/${agendaItem.parent_id}`];
      } else if (agendaItem.parent_type_id === ResourceType.RFI) {
        const projects = await this.rfiService
          .getRFIs(
            ['project_id', 'id'],
            [
              {
                type: 'field',
                field: 'id',
                value: agendaItem.parent_id,
                match: 'exact',
              },
            ]
          )
          .toPromise();
        link = [`projects/${projects[0].project_id}/rfi`];
      } else if (agendaItem.parent_type_id === ResourceType.Project) {
        link = [`projects/${agendaItem.parent_id}/tasks`];
      } else if (agendaItem.parent_type_id === ResourceType.Milestone) {
        const milestone = await this.projectService.getMilestoneById(agendaItem.parent_id).toPromise();
        link = [`projects/${milestone.project_id}/phase/${milestone.phase_id}`];
      } else if (agendaItem.parent_type_id === ResourceType.WorkOrder) {
        link = [`work-orders/${agendaItem.parent_id}`];
      }
      if (link) {
        window.open(link, '_blank');
        // this.router.navigate(link);
      }
    } else {
      this.snackbar.open('Not authorized to view the linked item.');
    }
  }

  private async _downloadZip() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Exporting Notes and Files',
        descriptionText: `The pdf notes will be downloaded first and then the meeting files will be zipped up and then downloaded afterwards. You can go ahead and continue working, but do not refresh the browser or you will cancel the zip action. You will be notified when the zip files are ready!`,
        hideConfirmationButton: true,
        cancelButtonText: 'Continue',
      })
      .subscribe(async () => {
        this.meetingService.isZippingNotes = true;
        // download notes and then attachments in a zip
        await this.pdf.saveAs(`MT-${this.shownMeeting.id}_${this.shownMeeting.title}.pdf`);
        const zip = new JSZip();
        const folderName = `MT-${this.shownMeeting.id}_${this.shownMeeting.title}.zip`;
        this.snackbar.open('Zipping your files after pdf download. Will notify you when done...!');
        for (const id of this.attachmentIds) {
          const file = await this.fileService.downloadFile({ id }).toPromise();
          zip.file(file.name, file.file.data);
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, folderName);
        });
        this.meetingService.isZippingNotes = false;
        this.snackbar.open('You zip file is done downloading...!');
      });
  }
  openExportMeetingDialog() {
    // we need to decode notes
    const data = this.agendaItems?.map((agendaItem: AgendaItem) => ({
      ...agendaItem,
      notes: agendaItem.notes?.map((note: Note) => ({
        ...note,
        message: this._htmlEncodeDecodePipe.transform(note.message ?? ''),
      })),
    }));

    this.dialog
      .open(MeetingExportDialogComponent, {
        width: '500px',
        data,
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          this.downloading = true;
          this.progressIndicator.openAwaitIndicatorModal();
          this.progressIndicator.updateStatus('Downloading...');
          this.agendaItemsToExport = result;
          this.attachmentIds = result
            .filter((agendaItem: AgendaItem) => agendaItem.selectedAttachments)
            ?.reduce((accummelatedFiles, agendaItem: AgendaItem) => {
              const files = agendaItem.notes
                .filter((note: Note) => note?.files?.length)
                ?.map((note: Note) => note.files);
              return accummelatedFiles.concat(...files);
            }, [])
            .map((attachment) => +attachment.id);

          if (!this.attachmentIds.length) {
            // only download notes
            await this.pdf.saveAs(`MT-${this.shownMeeting.id}_${this.shownMeeting.title}.pdf`);
          } else {
            if (typeof Worker !== 'undefined') {
              const worker = new Worker(new URL('src/app/workers/zip.worker', import.meta.url));
              worker.onmessage = async () => {
                await this._downloadZip();
              };
              worker.postMessage('done');
            } else {
              // Web workers are not supported in this environment.
              // You should add a fallback so that your program still executes correctly.
              this.snackbar.open(
                `Web workers are not supported in this web browser. You should update the browser or contact customer service to get a zip of your file notes`
              );
            }
          }
          this.downloading = false;
          this.progressIndicator.close();
        }
      });
  }

  async concludeMeeting() {
    this.agendaItemsToExport = this.agendaItems;

    // refreshes the html component to assure all data is up-to-date.
    this.appRef.tick();

    await this.pdf.export().then(async (groupData) => {
      let file: File;
      // Contain the toBlob function inside of a promise so we can wait on its completion and push the resulting file into our array.
      await new Promise<File>((resolve) => {
        pdf.toBlob(groupData, (data) => {
          file = new File([data], `MT-${this.shownMeeting.id}_${this.shownMeeting.title}.pdf`);
          resolve(file);
        });
      });

      const downloadableFiles = [];
      // this.agendaItemsToExport.forEach((item) => {
      //   if (item.draftNoteFiles) {
      //     item.draftNoteFiles.forEach((f) => {
      //       downloadableFiles.push(f);
      //     });
      //   }
      // });

      // open the dialog for concluding the meeting
      const ref = this.dialog.open(ConcludeMeetingDialogComponent, {
        width: '500px',
        data: {
          attendees: this.attendees,
          file,
          downloadableFiles,
        },
      });
      ref.afterClosed().subscribe(async (r: { subject: string; message: string; attendees: []; file }) => {
        if (r) {
          // on dialog confirm, we need to set the meeting to concluded and create the event to send the emails
          // conclude this meeting
          this.progressIndicator.openAwaitIndicatorModal();
          this.progressIndicator.updateStatus('Concluding meeting..');
          this.meetingService.concludeMeeting(this.shownMeeting.id).subscribe((data) => {
            this.refresh();
          });

          // TODO send the email
          console.log(`sending email to attendees`, r);
        }
      });
    });
  }

  openBulkSelectModal() {
    this.dialog
      .open(UserSelectModalComponent, {
        disableClose: true,
        data: {
          title: 'Update Attendees',
          createUser: { title: 'Guest', guestUser: true },
          includeGuestUsers: true,
          preSelectedUsers: this.attendees?.map((attendee: MeetingAttendee) => {
            return {
              id: attendee.user_id,
              last_name: attendee.last_name,
              first_name: attendee.first_name,
              canNotDeselect:
                !this.isConcluded &&
                (+attendee.attendee_type_id === +MeetingAttendeeType.Moderator ||
                  +attendee.attendee_type_id === +MeetingAttendeeType.Organizer ||
                  (+attendee?.user_id !== +this.currentUser?.id && !this.isCreatorOrFacilitator)),
            }; // user select modal expects the id as a user
          }),
        },
      })
      .afterClosed()
      .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
        if (deSelectedUsers?.length) {
          // since this handles one user at a time, we use a for loop
          // we also have to geeting the id for the meeting using the user details
          for (const deSelectedUser of deSelectedUsers) {
            // grab the attendee details
            const deselectedAttendee: MeetingAttendee = this.attendees?.find(
              (attendee: MeetingAttendee) => attendee.user_id === deSelectedUser.id
            );
            // remove attendee
            await this.removeAttendee(deselectedAttendee);
          }
        }

        if (selectedUsers?.length) {
          this.addAttendees(selectedUsers);
        }
      });
  }

  // formats the relevant data for the agenda item as a summary
  // for example, task returns project data -> task data
  async loadAgendaData(agendaItem) {
    if (!agendaItem.loading && !agendaItem.desc) {
      agendaItem.loading = true;
      if (agendaItem.parent_type_id === ResourceType.Task) {
        // this attempts to get the project information. On error, gives generic information due to invalid access
        await this.projectService
          .getTaskByIdSuppressed(agendaItem.parent_id, ['title', 'code', 'project_title', 'project_code'])
          .toPromise()
          .then((task) => {
            agendaItem.desc = `PRJ ${task.project_code}: ${task.project_title}`;
            agendaItem.smallDesc = `${task.code}: ${task.title}`;
            agendaItem.loading = false;
          })
          .catch((e) => {
            // agendaItem.desc = `Agenda Item for a Task`;
            agendaItem.smallDesc = `Task`;
            agendaItem.loading = false;
          });
      } else if (agendaItem.parent_type_id === ResourceType.RFI) {
        // this attempts to get the project information. On error, gives generic information due to invalid access
        await this.rfiService
          .getRFIsSuppressed(
            ['project_title', 'project_code', 'code', 'question'],
            [
              {
                type: 'field',
                field: 'id',
                value: agendaItem.parent_id,
                match: 'exact',
              },
            ]
          )
          .toPromise()
          .then((rfi) => {
            const selectedRFI = rfi[0];
            agendaItem.desc = `PRJ ${selectedRFI.project_code}: ${selectedRFI.project_title}`;
            agendaItem.smallDesc = `RFI ${selectedRFI.code}: ${selectedRFI.question}`;
            agendaItem.loading = false;
          })
          .catch((e) => {
            console.log('Invalid access to view the rfi information.');
            // agendaItem.desc = `RFI Agenda Item`;
            agendaItem.smallDesc = `RFI`;
            agendaItem.loading = false;
          });
      } else if (agendaItem.parent_type_id === ResourceType.Project) {
        // this attempts to get the project information. On error, gives generic information due to invalid access
        await this.projectService
          .getProjectByIdSuppressed(agendaItem.parent_id, ['code', 'title'])
          .toPromise()
          .then((project) => {
            agendaItem.desc = project.title ? `PRJ ${project.code}: ${project.title}` : `PRJ ${project.code}`;
            // agendaItem.smallDesc = `PRJ ${project.code} - ${project.title}`;
            agendaItem.loading = false;
          })
          .catch((e) => {
            console.log('Invalid access to view the project information.');
            agendaItem.desc = `Project Agenda Item`;
            agendaItem.smallDesc = `Project`;
            agendaItem.loading = false;
          });
      } else if (agendaItem.parent_type_id === ResourceType.Milestone) {
        await this.projectService
          .getMilestoneById(agendaItem.parent_id)
          .toPromise()
          .then((milestone) => {
            agendaItem.desc = `PRJ ${milestone.project_code}: ${milestone.project_title}`;
            agendaItem.smallDesc = `Milestone ${milestone.name}`;
            agendaItem.loading = false;
          })
          .catch((e) => {
            console.log('Invalid access to view the milestone information.');
            agendaItem.desc = `Milestone Agenda Item`;
            agendaItem.smallDesc = `Milestone`;
            agendaItem.loading = false;
          });
      } else if (agendaItem.parent_type_id === ResourceType.WorkOrder) {
        await this.workOrderService
          .getWorkOrderByIdSuppressed(agendaItem.parent_id, ['code', 'title'])
          .toPromise()
          .then((workOrder) => {
            agendaItem.desc = workOrder.title ? `${workOrder.code}: ${workOrder.title}` : `${workOrder.code}`;
            // agendaItem.smallDesc = `WO ${workOrder.code} - ${workOrder.title}`;
            agendaItem.loading = false;
          })
          .catch((e) => {
            console.log('Invalid access to view the work order information.');
            agendaItem.desc = `Work Order Agenda Item`;
            agendaItem.smallDesc = `Work Order`;
            agendaItem.loading = false;
          });
      }
    }
  }

  public openAssignUserDialog(agendaItem: AgendaItem) {
    if (this.authService.isStaffOnAnyModule) {
      this.dialog
        .open(UserSelectModalComponent, {
          disableClose: true,
          data: {
            title: 'Assign Agenda Item to User',
            includeGuestUsers: true,
            maxSelected: 1,
            preSelectedUsers: [
              {
                first_name: agendaItem?.assigned_user_first_name,
                id: agendaItem?.assigned_user_id,
                last_name: agendaItem?.assigned_user_last_name,
              },
            ],
          },
        })
        .afterClosed()
        .subscribe(async ({ selectedUsers, deSelectedUsers }) => {
          // because, its single selection item,
          // if there is a selection, the db updates
          // otherwise, check for a deselection
          if (selectedUsers?.length) {
            await this.meetingService
              .updateAgendaItem(agendaItem.id, {
                assigned_user_id: selectedUsers[0].id,
              })
              .toPromise();
            agendaItem.assigned_user_id = selectedUsers[0].id;
            agendaItem.assigned_user_first_name = selectedUsers[0].first_name;
            agendaItem.assigned_user_last_name = selectedUsers[0].last_name;
          } else if (deSelectedUsers?.length) {
            await this.meetingService
              .updateAgendaItem(agendaItem.id, {
                assigned_user_id: null,
              })
              .toPromise();
            agendaItem.assigned_user_id = undefined;
            agendaItem.assigned_user_first_name = undefined;
            agendaItem.assigned_user_last_name = undefined;
          }
        });
    }
  }

  private _accessHelper(option) {
    return option
      ? JSON.stringify([UserType.Staff, UserType.Tenant, UserType.Vendor])
      : JSON.stringify([UserType.Staff]);
  }

  private async _addNoteToProjectUpdate(noteId: number, projectUpdate: ProjectUpdate, noteFiles) {
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus(`Adding Agenda Item Note To Project...`);
    const result: ProjectUpdate = await this._projectOverviewService
      .createProjectUpdate(projectUpdate)
      .pipe(
        map((res) => res),
        catchError((e) => {
          this.progressIndicator.close();
          return e;
        })
      )
      .toPromise();
    this.progressIndicator.close();
    if (result) {
      for (const file of noteFiles) {
        this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.ProjectUpdate).subscribe();
      }
      // update note
      this._updateAgendaItemNoteTypeAndId(noteId, result.id, ResourceType.ProjectUpdate);
      this.snackbar.open(`Agenda Item Note Added to Project Updates!`);
    }
  }

  private async _addNoteToWorkOrderUpdate(noteId: number, workOrderUpdate: WorkOrderUpdate, noteFiles) {
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus(`Adding Agenda Item Note To Work Order...`);
    const result: WorkOrderUpdate = await this.workOrderService
      .createWorkOrderUpdate(workOrderUpdate)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((e) => {
          this.progressIndicator.close();
          return e;
        })
      )
      .toPromise();
    this.progressIndicator.close();
    if (result) {
      for (const file of noteFiles) {
        this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.WorkOrderUpdate).subscribe();
      }
      // update note
      this._updateAgendaItemNoteTypeAndId(noteId, result.id, ResourceType.WorkOrderUpdate);
      this.snackbar.open(`Agenda Item Note Added to Work Order Updates!`);
    }
  }

  private async _addNoteToTask(noteId: number, parentId: number, message: string, noteFiles) {
    this.progressIndicator.openAwaitIndicatorModal();
    this.progressIndicator.updateStatus(`Adding Agenda Item Note To Task...`);
    const result = await this.meetingService.createParentNote(ResourceType.Task, parentId, message).toPromise();
    this.progressIndicator.close();
    if (result) {
      for (const file of noteFiles) {
        this.fileService.linkFile(file.file_id || file.id, result.id, ResourceType.Note).subscribe();
        this.fileService.linkFile(file.file_id || file.id, parentId, ResourceType.Task).subscribe();
      }
      // update note
      this._updateAgendaItemNoteTypeAndId(noteId, result.id, ResourceType.Task);
      this.snackbar.open(`Agenda Item Note Added to Task Notes!`);
    }
  }

  private async _updateAgendaItemNoteTypeAndId(noteId: number, itemId: number, type: ResourceType) {
    if (noteId && itemId && type) {
      this.progressIndicator.openAwaitIndicatorModal();
      this.progressIndicator.updateStatus('Update note...');
      const result = await this.meetingService
        .updateAgendaNote(noteId, {
          created_item_id_from_agenda: itemId,
          created_item_type_id_from_agenda: type,
        })
        .toPromise();
      this.progressIndicator.close();
      if (result?.id) {
        this.refresh();
        this.snackbar.open(`Note updated!`);
      }
    }
  }

  public async addNoteToParent({ parent_id, parent_type_id }: AgendaItem, note: Note) {
    const noteId = note.id;
    const message = note.message;
    if (note && !note.files) {
      note.files = [];
    }
    if (parent_id && parent_type_id && message) {
      // check if it project or WO
      if (parent_type_id === ResourceType.Project || parent_type_id === ResourceType.WorkOrder) {
        const dialogRef = this.dialog
          .open(ConfirmationChoiceDialogComponent, {
            width: '400px',
            data: {
              title: 'Visibility',
              heading: 'Add Meeting Note to Item',
              subHeading: 'Who can see this update?',
              option1: { text: 'Staff Only', value: null },
              option2: { text: 'EveryOne', value: 1 },
            },
          })
          .afterClosed()
          .subscribe((result) => {
            // Make sure that the user did not cancel
            if (result && parent_type_id === ResourceType.Project) {
              const projectUpdate: ProjectUpdate = {
                access: this._accessHelper(result?.option),
                message,
                project_id: parent_id,
                notify_followers: 1,
              };
              this._addNoteToProjectUpdate(noteId, projectUpdate, note.files);
            } else if (result && parent_type_id === ResourceType.WorkOrder) {
              const workOrderUpdate: WorkOrderUpdate = {
                access: this._accessHelper(result?.option),
                message,
                work_order_id: parent_id,
                notify_followers: 1,
              };
              this._addNoteToWorkOrderUpdate(noteId, workOrderUpdate, note.files);
            }
          });
      } else if (parent_type_id === ResourceType.Task) {
        this._addNoteToTask(noteId, parent_id, message, note.files);
      }
    }
  }

  public deactivateAgendaNote(noteId: number) {
    if (noteId) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'Delete Note',
          headerText: 'Delete Note',
          descriptionText: `Are you sure you want to delete this note? This can't be undone.`,
          confirmationButtonText: 'Delete Note',
        })
        .subscribe(async (isConfirmed) => {
          if (isConfirmed) {
            this.progressIndicator.openAwaitIndicatorModal();
            this.progressIndicator.updateStatus('Deleting Note...');
            await this.meetingService.deactivateAgendaItemNote(noteId).toPromise();
            await this.refresh();
            this.snackbar.open(`Meeting note deleted`);
          }
        });
    }
  }

  public editAgendaNote(agendaItemNote: Note) {
    this.noteToEdit = agendaItemNote;
  }

  public viewParent(parentId: number, resourceTypeId: ResourceType) {
    switch (resourceTypeId) {
      case ResourceType.ProjectUpdate:
        this.router.navigate([`/projects/${parentId || ''}`]);
        break;
      case ResourceType.Task:
        this.router.navigate([`/tasks/${parentId || ''}`]);
        break;
      case ResourceType.WorkOrderUpdate:
        this.router.navigate([`/work-orders/${parentId || ''}`]);
        break;
      default:
        return;
    }
  }

  expandAllAgendaItems(): void {
    this.agendaItems.forEach((item) => {
      item.is_expanded = true;
    });
  }

  collapseAllAgendaItems(): void {
    this.agendaItems.forEach((item) => {
      item.is_expanded = false;
    });
  }

  handleAddMyself(): void {
    void this.addAttendees([this.authService.currentUser]);
  }
}
