<app-base-dialog [title]="'Project Details'" (closeDialog)="close()"></app-base-dialog>
<div id="project-details-dialog" data-test-id="project-details-dialog" class="project-details-dialog project-modal p-0">
  <section id="project-details">
    <div class="d-flex align-items-top m-0">
      <div id="dialog-nav">
        <button
          (click)="setView(ProjectDetailsViews.Details)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.Details }"
        >
          Details
        </button>
        <button
          (click)="setView(ProjectDetailsViews.Location)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.Location }"
        >
          Location
        </button>
        <button
          (click)="setView(ProjectDetailsViews.Status)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.Status }"
        >
          Status
        </button>
        <!-- IF CONSTRUCTION (EVENTUALLY) -->
        <button
          (click)="setView(ProjectDetailsViews.Trades)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.Trades }"
          *ngIf="isConstruction"
        >
          Trades
        </button>
        <button
          (click)="setView(ProjectDetailsViews.Roles)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.Roles }"
        >
          Roles
        </button>
        <!-- <button
          *ngIf="projectKeyControls?.length && authService?.isStaff"
          (click)="setView('key_controls')"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.KeyControls }"
        >
          Key Controls
        </button> -->
        <button
          (click)="setView(ProjectDetailsViews.CostCodes)"
          mat-button
          color="primary"
          class="text-left full-width mb-2 opacity5"
          [ngClass]="{ 'opacity10 bg-shade-ltblue': currentView === ProjectDetailsViews.CostCodes }"
          *ngIf="isConstruction"
        >
          Cost Codes
        </button>
        <hr />
        <span>
          <!--the disabled element is not a valid attribute of a tag. throws error in console testing-->
          <a
            mat-button
            [routerLink]="['/requests/' + project?.request_id]"
            color="accent"
            class="text-left full-width"
            target="_blank"
            style="text-decoration: none; padding: 0 0.75rem"
            [disabled]="!project?.request_id"
            [matTooltip]="'Project Request does not exist'"
            [matTooltipShowDelay]="300"
            matTooltipPosition="above"
            [matTooltipDisabled]="project?.request_id"
          >
            View Request
          </a>
          <!-- <button
            mat-button
            (click)="exportProjectDetails()"
            color="accent"
            class="text-left full-width mb-2"
          >
            Export as PDF
          </button> -->
        </span>
        <hr />
        <span *ngIf="isWorkspaceStaff && isEditing">
          <button (click)="resetIsEditing(shownProject)" mat-button color="accent" class="full-width mb-2 gray lh-34">
            Discard Changes
          </button>
          <button
            mat-flat-button
            color="success"
            class="full-width mb-2 lh-34"
            (click)="saveProjectDetails()"
            [disabled]="!isEnabled"
          >
            <span *ngIf="!loading">Save Changes</span><span *ngIf="loading"><i class="la la-spinner la-spin"></i></span>
          </button>
        </span>
        <!--The toggle is automatic, so, just needs to get out of editing mode-->
        <!-- <span
          *ngIf="
            isEditing &&
            currentView === ProjectDetailsViews.KeyControls &&
            projectKeyControls?.length &&
            authService?.isStaff
          "
          ><button
            mat-flat-button
            color="success"
            class="full-width mb-2"
            style="line-height: 34px"
          >
            <span *ngIf="!loading">Done</span
            ><span *ngIf="loading"><i class="la la-spinner la-spin"></i></span></button
        ></span> -->
      </div>
      <div cdkScrollable mat-dialog-content id="dialog-form" class="flex-grow-1">
        <form [formGroup]="projectDetailsForm">
          <!-- DETAILS SECTION -->
          <section [hidden]="currentView !== ProjectDetailsViews.Details" class="dialog-form">
            <!-- <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">
                  Workspace
                </h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    formControlName="moduleId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option [value]="w.id" *ngFor="let w of workspaces">{{
                      w.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Short Description*</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="title"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Enter Project Title"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Request Method*</h6>
                <app-request-method [showTitle]="false" #requestMethod></app-request-method>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col textarea">
                <h6 class="font-weight-semibold dkblue mb-2">Summary*</h6>
                <app-editor #descriptionEditor placeholder="Enter Project Description" [required]="true"></app-editor>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <app-project-topic #projectTopic [projectId]="data.projectId"></app-project-topic>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col textarea">
                <h6 class="font-weight-semibold dkblue mb-3">Priority</h6>
                <mat-radio-group formControlName="priorityLevelId" (ngModelChange)="unsavedChangesExist = true">
                  <mat-radio-button [value]="1">Low</mat-radio-button>
                  <mat-radio-button [value]="2">Medium</mat-radio-button>
                  <mat-radio-button [value]="3">High</mat-radio-button>
                  <mat-radio-button [value]="4">Critical</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <!-- TODO: PEB | Adam - Design PEB Switch -->
            <!-- <div *ngIf="shownProject?.module?.id === workspace.Construction" class="d-flex flex-column mb-4">
              <h6 class="primary mb-2">Financial Components</h6>
              <div class="m-portlet mb-0">
                <div class="m-portlet__body p-3 d-flex align-items-center">
                  <div class="mr-auto d-flex flex-column text-truncate">
                    <p class="font-weight-semibold dkblue mb-0 lh-18">Use New PEB</p>
                    <p class="font-weight-normal dkgray mb-0 lh-18">Includes new PEB UX</p>
                  </div>
                  <mat-slide-toggle
                    color="accent"
                    labelPosition="before"
                    matNativeControl
                    formControlName="usesNewPEB"
                    [checked]="!!usesNewPEB.value"
                    (change)="unsavedChangesExist = true"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
            </div> -->
            <!-- 5 YR CAP FIELDS -->
            <div class="mb-4" [hidden]="!projectHasCAPX">
              <h5>Board Approved Budget*</h5>
              <p style="font-weight: 500">
                If your project was board approved for the start of the Fiscal Year (July 1) use its designated CAPX ID.
                If your project was approved mid-year, please select “None” in the CAPX ID Section.
              </p>
              <mat-slide-toggle class="mb-4" color="accent" [formControl]="isParentProject">
                <span class="font-weight-semibold ltblue"
                  >This Project Has a Parent Project<mat-icon
                    class="tool-tip"
                    matTooltip="Toggle this on if your project's budget is already a part of a bigger project"
                    >info</mat-icon
                  ></span
                >
              </mat-slide-toggle>
              <div *ngIf="isParentProject.value === true" class="mb-2">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">
                  Parent Project<mat-icon class="tool-tip" matTooltip="">info</mat-icon>
                </h6>
                <app-resource-search [control]="parent_id" [resourceType]="ResourceType.Project"></app-resource-search>
              </div>
              <h6 class="m-0 dkblue font-weight-semibold mb-2">
                CAPX ID<mat-icon class="tool-tip" matTooltip="CAPX ID given at start of Fiscal Year">info</mat-icon>
              </h6>
              <app-auto-complete
                [control]="capx_id"
                [items]="CAPXids"
                [searchFields]="['capx_id', 'description']"
                title="Search CAPX IDs"
                threshold="0.1"
                [displayFn]="capxDisplayFn"
              ></app-auto-complete>
              <div *ngIf="capx_id.value && capx_id.value?.id">
                <div class="capx-budget-row">
                  <div>
                    <h6 class="font-weight-semibold dkblue mb-2">Fiscal Year</h6>
                  </div>
                  <div>
                    <h6>Budget</h6>
                  </div>
                </div>
                <div *ngFor="let budget of budgetForm?.controls" class="row capx-budget-row capx-budget-background">
                  <div>
                    <h4 class="font-weight-semibold ltblue mb-2">{{ budget.controls?.fiscal_year.value }}</h4>
                  </div>
                  <div>
                    <span *ngIf="editingBudget === false">
                      {{ budget.controls?.budget?.value | currency }}
                    </span>
                    <mat-form-field
                      *ngIf="editingBudget === true"
                      appearance="outline"
                      class="full-width"
                      style="text-align: right"
                    >
                      <input matInput type="number" min="0" [formControl]="budget.controls.budget" />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row capx-budget-row">
                  <div>
                    <button
                      mat-flat-button
                      class="ltblue"
                      (click)="editingBudget = true"
                      *ngIf="
                        !editingBudget &&
                        (authService.isFinanceManager ||
                          authService.isCFMO ||
                          authService.isAppAdmin ||
                          authService.isCFO)
                      "
                    >
                      Edit Budget
                    </button>
                    <button mat-flat-button class="bg-ltblue white" (click)="saveBudget()" *ngIf="editingBudget">
                      Save Budget</button
                    ><button *ngIf="editingBudget" class="gray" mat-flat-button (click)="cancelBudgetSave()">
                      Cancel
                    </button>
                  </div>
                  <div>SUBTOTAL: {{ subTotal() | currency }}</div>
                </div>
              </div>
            </div>
            <h6 class="font-weight-semibold dkblue mb-3">Tags</h6>
            <div class="row mb-4">
              <mat-checkbox
                *ngFor="let tag of selectedTags?.controls || []; let i = index"
                class="col-6 py-2"
                [formControl]="tag"
                (ngModelChange)="changeTagData($event, tags[i]?.id)"
              >
                <span class="font-weight-semibold dkblue mt-2">
                  {{ tags[i]?.name }}
                  {{ tags[i]?.abbreviation !== tags[i]?.name ? '(' + tags[i]?.abbreviation + ')' : '' }}</span
                >
                <small *ngIf="isConstruction && tags[i]?.id === 3" class="dkgray">&nbsp;Set at Conversion</small>
              </mat-checkbox>
            </div>
          </section>
          <!-- LOCATION SECTION -->
          <section *ngIf="currentView === ProjectDetailsViews.Location" class="dialog-form">
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Building</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="buildingId"
                    (selectionChange)="getFloors(buildingId.value)"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let b of buildings" [value]="b.id">{{ b.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Floor</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="floorId"
                    (selectionChange)="getSuites(floorId.value)"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let f of floors" [value]="f.id">{{ f.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Suite</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="suiteId"
                    (selectionChange)="getDepartments(suiteId.value)"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let s of suites" [value]="s.id">{{ s.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Department</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="departmentId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option [value]="null">-</mat-option>
                    <mat-option *ngFor="let d of departments" [value]="d.id">{{ d.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Total Construction SF</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    data-test-id="project-detail-square-footage-input"
                    matInput
                    type="number"
                    min="0"
                    formControlName="squareFootage"
                    (ngModelChange)="unsavedChangesExist = true"
                  />
                  <mat-error
                    data-test-id="project-detail-square-footage-required-error"
                    *ngIf="squareFootage?.errors?.required"
                    >Total Construction SF is required</mat-error
                  >
                  <mat-error data-test-id="project-detail-square-footage-min-error" *ngIf="squareFootage?.errors?.min"
                    >Minimum value is 0</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Occupied Square Feet</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    data-test-id="project-detail-occupied-square-feet-input"
                    matInput
                    type="number"
                    min="0"
                    formControlName="occupiedSqft"
                    (ngModelChange)="unsavedChangesExist = true"
                  />
                  <mat-error
                    data-test-id="project-detail-occupied-square-feet-min-error"
                    *ngIf="occupiedSqft?.errors?.pattern"
                  >
                    Occupied Square Feet has to be a number
                  </mat-error>
                  <mat-error
                    data-test-id="project-detail-occupied-square-feet-min-error"
                    *ngIf="occupiedSqft?.errors?.min"
                  >
                    Minimum value is 0
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- IF CONSTRUCTION PROJECT -->
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-3">Request Type</h6>
                <mat-radio-group formControlName="requestType" (ngModelChange)="unsavedChangesExist = true">
                  <mat-radio-button [value]="1">New Space</mat-radio-button>
                  <mat-radio-button [value]="2">Renovation</mat-radio-button>
                  <mat-radio-button [value]="3">Shell Space</mat-radio-button>
                  <mat-radio-button [value]="4">Aesthetics</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <!-- IF NON-CONSTRUCTION? -->
            <div class="row mb-4" *ngIf="!isConstruction">
              <div class="col-6">
                <h6 class="font-weight-semibold dkblue mb-2">Room # / Landmark</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="landmark"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Enter a Room# / Landmark"
                  />
                </mat-form-field>
              </div>
            </div>
          </section>
          <!-- STATUS SECTION -->
          <section *ngIf="currentView === ProjectDetailsViews.Status" class="dialog-form">
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Status</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select formControlName="status" (ngModelChange)="unsavedChangesExist = true">
                    <mat-option *ngFor="let status of projectStatuses" [value]="status.value"
                      >{{ status.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Substatus</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    formControlName="substatus"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Not Set"
                  >
                    <div *ngFor="let category of projectSubstatusCategories">
                      <h6 class="gray ml-3 pt-2">
                        {{ category.name }}
                      </h6>
                      <mat-option
                        *ngFor="let substatus of category.substatuses"
                        [value]="substatus.id"
                        class="pl-4"
                        style="height: 40px"
                        [hidden]="!substatus.is_enabled"
                      >
                        {{ substatus.name }}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Schedule</h6>
                <mat-radio-group formControlName="onSchedule" (ngModelChange)="unsavedChangesExist = true">
                  <mat-radio-button [value]="1">On Schedule</mat-radio-button>
                  <mat-radio-button [value]="0">Behind Schedule</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Estimated Construction Start Date</h6>
                <!-- datepicker -->
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="When is the estimated start date?"
                    formControlName="estimatedStartDate"
                    (ngModelChange)="unsavedChangesExist = true"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker [calendarHeaderComponent]="customDateHeader"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Estimated Completion Date</h6>
                <!-- datepicker -->
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="When is the estimated completion date?"
                    formControlName="estimatedCompletionDate"
                    (ngModelChange)="unsavedChangesExist = true"
                    matTooltip="The Estimated Completion Date can be updated by leaving a Project Update."
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker [calendarHeaderComponent]="customDateHeaderNoClear"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Design Timeline</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="design_timeline"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Design Timeline"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Construction Timeline</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="construction_timeline"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Construction Timeline"
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Total Timeline</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="total_timeline"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Total Timeline"
                  />
                </mat-form-field>
              </div>
            </div>
          </section>
          <!-- TRADES SECTION IF CONSTRUCTION -->
          <section *ngIf="currentView === ProjectDetailsViews.Trades" class="dialog-form">
            <div class="row">
              <div class="col-12 mb-4">
                <p>* Selected trades will be added by default to the PEB. Please add all that may apply.</p>
              </div>
            </div>
            <div class="row">
              <div *ngFor="let t of trades" ngClass="col-6 mb-4">
                <mat-checkbox
                  data-test-id="project-detail-trades-checkbox"
                  [checked]="t.is_selected"
                  (change)="toggleTradeSelection(t, $event.checked)"
                  [disabled]="projectDetailsForm.disabled"
                >
                  <div class="d-flex flex-wrap">
                    <span class="mr-2 lh-16 primary">
                      {{ t.name }}
                    </span>
                    <small class="lh-16 px-1 br-4 bg-orange white" *ngIf="t && !t.allow_bids">Bids N/A</small>
                  </div>
                </mat-checkbox>
              </div>
            </div>
          </section>
          <!-- roles section -->
          <section *ngIf="currentView === ProjectDetailsViews.Roles">
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Project Manager</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="projectManagerId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allProjectManagers" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <h6 class="m-0 dkblue font-weight-semibold">Architect</h6>
                  </div>
                  <div class="col text-right">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      matNativeControl
                      formControlName="architectRequired"
                      [checked]="!!architectRequired.value"
                      (change)="changeArchitectData()"
                    >
                      <div class="dkgray">{{ architectRequired.value ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="architectId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allArchitects" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">
                  {{ this.project?.module?.name || 'Workspace' }} Manager
                </h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="workspaceManagerId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allWorkspaceManagers" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">
                  {{ this.project?.module?.name || 'Workspace' }} Account Coordinator
                </h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="accountCoordinatorId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allAccountCoordinators" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="needsDFSManager">
              <div class="col">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <h6 class="font-weight-semibold dkblue mb-0">Chief Technology Officer</h6>
                  </div>
                  <div class="col text-right" *ngIf="shownProject?.module?.show_dfs_toggle">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      matNativeControl
                      formControlName="dfsRequired"
                      [checked]="!!dfsRequired.value"
                      (change)="changeDFSData()"
                    >
                      <div class="dkgray">{{ dfsRequired.value ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="dfsManagerId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allDFSManagers" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isOneCallWorkspace">
              <div class="col">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <h6 class="font-weight-semibold dkblue mb-0">1CALL Director</h6>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="oneCallDirectorId"
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option *ngFor="let a of allOneCallDirectors" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Inventory Control Officer</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select matNativeControl formControlName="icoId" (ngModelChange)="unsavedChangesExist = true">
                    <mat-option *ngFor="let a of allICOs" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Business Systems Director</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select matNativeControl formControlName="bsdId" (ngModelChange)="unsavedChangesExist = true">
                    <mat-option *ngFor="let bsd of allBSDs" [value]="bsd.id"
                      >{{ bsd.first_name }} {{ bsd.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">CFMO</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select matNativeControl formControlName="cfmoId" (ngModelChange)="unsavedChangesExist = true">
                    <mat-option *ngFor="let a of allCFMOs" [value]="a.id"
                      >{{ a.first_name }} {{ a.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Engineers</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-select
                    matNativeControl
                    formControlName="engineerIds"
                    multiple
                    (ngModelChange)="unsavedChangesExist = true"
                  >
                    <mat-option
                      data-test-id="project-detail-engineer-role-option"
                      *ngFor="let e of allEngineers"
                      [value]="e.id"
                      >{{ e.first_name }} {{ e.last_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </section>

          <!-- key controls -->
          <!-- <section
            *ngIf="
              currentView === ProjectDetailsViews.KeyControls && projectKeyControls?.length && authService?.isStaff
            "
            class="d-flex flex-column"
          >
            <div class="d-flex mb-2">
              <h5 class="m-0 dkblue font-weight-bold mr-auto">Key Controls</h5>
            </div>
            <div class="m-portlet">
              <div
                *ngFor="let projectKeyControl of projectKeyControls; let i = index"
                class="m-portlet__body item d-flex align-items-center p-3"
              >
                <div class="d-flex flex-column mr-auto">
                  <p class="m-0 font-weight-semibold dkblue hover-u pointer lh-16">
                    {{ projectKeyControl?.key_control_template?.key_control_name }}
                  </p>
                </div>
                <mat-slide-toggle
                  [checked]="!projectKeyControl?.is_disabled"
                  [disabled]="!isEditing"
                  (input)="toggleKeyControl(projectKeyControl.id)"
                ></mat-slide-toggle>
              </div>
            </div>
          </section> -->
          <section [hidden]="currentView !== ProjectDetailsViews.CostCodes" class="dialog-form">
            <div class="row mb-4">
              <div class="col">
                <h6 class="font-weight-semibold dkblue mb-2">Cost Code(s)</h6>
                <mat-form-field appearance="outline" class="full-width">
                  <input
                    matInput
                    formControlName="costCodes"
                    (ngModelChange)="unsavedChangesExist = true"
                    placeholder="Enter Cost Codes"
                    [ngClass]="{
                      'text-danger': !isValidCostCodesLength
                    }"
                  />
                  <mat-hint
                    align="end"
                    [ngClass]="{
                      red: !isValidCostCodesLength,
                      gray: isValidCostCodesLength
                    }"
                    >{{ costCodes?.value?.length || 0 }} / {{ costCodesLength }}</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col textarea">
                <h6 class="font-weight-semibold dkblue mb-2">Cost Code Description</h6>
                <app-editor
                  #costCodeDescriptionEditor
                  [has_max_length]="true"
                  [max_length]="150"
                  placeholder="Enter Cost Code Description"
                ></app-editor>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  </section>
</div>
