<div [style.visibility]="loading ? 'hidden' : 'visible'" #mainScreen class="container profile app-container p-4">
  <div class="row align-items-center mb-4">
    <div class="col d-flex align-items-top justify-content-between">
      <div class="d-flex flex-column">
        <h3 id="title" class="dkblue font-weight-semibold">{{ department?.name }}</h3>
        <div class="d-flex align-items-center">
          <h6 class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-dkgray">Department</h6>
          <h6
            *ngIf="department?.is_enabled == 1 && !editingDetails"
            class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-green"
          >
            ACTIVE
          </h6>
          <h6
            *ngIf="department?.is_enabled == 0 && !editingDetails"
            class="text-uppercase py-0 px-2 br-4 mr-3 mb-0 white lh-20 bg-gray"
          >
            INACTIVE
          </h6>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button *ngIf="!editingDetails && canEdit" (click)="toggleDetails()" mat-button type="button" color="accent">
          Edit Department
        </button>
        <button *ngIf="editingDetails" (click)="cancel()" class="gray" mat-button color="accent" type="button">
          Cancel
        </button>
        <button
          *ngIf="editingDetails"
          [disabled]="suiteOccupancies.invalid"
          mat-flat-button
          color="success"
          type="button"
          (click)="updateProfile()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div>
        <div class="m-portlet mt-2 mb-0">
          <div class="m-portlet__body p-0">
            <div class="d-flex flex-column">
              <div
                [formGroup]="suiteOccupancy"
                *ngFor="let suiteOccupancy of suiteOccupancies.controls; let index = index; let last = last"
                class="d-flex flex-column p-4 item"
                [ngClass]="{ 'item-last': last }"
              >
                <div *ngIf="suiteOccupancies.length > 0" class="row mb-0">
                  <div class="col-11">
                    <div class="row">
                      <div class="col">
                        <h6 class="primary mb-2">Building</h6>
                      </div>
                      <div class="col">
                        <h6 class="primary mb-2">Floor</h6>
                      </div>
                      <div class="col">
                        <h6 class="primary mb-2">Suite</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-11">
                    <div class="row">
                      <div class="col pr-0">
                        <h5 *ngIf="!editingDetails" class="primary font-weight-normal mb-0">
                          {{ suiteOccupancy.get('building_name')?.value }}
                        </h5>
                        <mat-form-field *ngIf="editingDetails" class="full-width" appearance="outline">
                          <mat-select
                            placeholder="Select Building"
                            formControlName="building_id"
                            (selectionChange)="refreshFloors(suiteOccupancy)"
                          >
                            <mat-option></mat-option>
                            <mat-option *ngFor="let building of buildings" [value]="building.id">
                              {{ building.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="ml-2" *ngIf="suiteOccupancy.get('building_id')?.errors?.required"
                          >required</mat-error
                        >
                      </div>
                      <div class="col pr-0">
                        <h5 *ngIf="!editingDetails" class="primary font-weight-normal mb-0">
                          {{ suiteOccupancy.get('floor_name')?.value }}
                        </h5>
                        <mat-form-field *ngIf="editingDetails" class="full-width" appearance="outline">
                          <mat-select
                            placeholder="Floor"
                            formControlName="floor_id"
                            (selectionChange)="refreshSuites(suiteOccupancy)"
                          >
                            <mat-option *ngFor="let floor of suiteOccupancy.floors" [value]="floor.id">
                              {{ floor.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="ml-2" *ngIf="suiteOccupancy.get('floor_id')?.errors?.required"
                          >required</mat-error
                        >
                      </div>
                      <div class="col pr-0">
                        <h5 *ngIf="!editingDetails" class="primary font-weight-normal mb-0">
                          {{ suiteOccupancy.get('suite_name')?.value }}
                        </h5>
                        <mat-form-field *ngIf="editingDetails" class="full-width" appearance="outline">
                          <mat-select placeholder="Suite" formControlName="suite_id">
                            <mat-option *ngFor="let suite of suiteOccupancy.filteredSuites" [value]="suite.id">
                              {{ suite.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-error class="ml-2" *ngIf="suiteOccupancy.get('suite_id')?.errors?.suiteExists"
                          >Suite already assigned</mat-error
                        >
                        <mat-error class="ml-2" *ngIf="suiteOccupancy.get('suite_id')?.errors?.required"
                          >required</mat-error
                        >
                      </div>
                    </div>
                  </div>
                  <div *ngIf="editingDetails" class="col-1 pl-1 d-flex align-items-center">
                    <mat-icon class="py-1" id="remove-mat-icon" (click)="removeSuiteOccupancy(index)">delete </mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="editingDetails" class="mb-2">
          <button mat-button (click)="addSuite()" color="accent">+ Location</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <mat-tab-group>
        <mat-tab label="Directory">
          <div class="row mt-4">
            <div class="col-sm-12">
              <h4 class="dkblue font-weight-semibold mb-3">Authorized Requesters</h4>
              <div class="m-portlet" [ngClass]="{ 'bg-transparent': ARs.length === 0 }">
                <div *ngIf="ARs.length === 0" class="dkgray p-4">No Authorized Requesters</div>
                <div
                  class="m-portlet__body table-header bg-shade-ltblue border-bottom border-gray p-0 pl-3 pr-3"
                  *ngIf="ARs.length > 0"
                >
                  <div class="row align-items-center">
                    <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Person</h6>
                    </div>
                    <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Email</h6>
                    </div>
                    <div class="col pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">1CC Account</h6>
                    </div>
                    <div class="col-1 pt-1 pb-1 pr-3 pl-3 d-flex align-items-center header-item"></div>
                  </div>
                </div>
                <div
                  class="mat-ripple m-portlet__body item pointer p-3"
                  *ngFor="let ar of ARs | orderByField: 'first_name':'asc'"
                >
                  <div class="row align-items-center">
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <app-profile-info
                        [user]="ar"
                        [avatarSize]="38"
                        [additionalUserFields]="['title']"
                      ></app-profile-info>
                    </div>
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ ar.email }}</p>
                    </div>
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ ar.is_enabled ? 'Active' : 'Inactive' }}</p>
                    </div>
                    <div class="col-1 py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <button
                        mat-button
                        type="button"
                        color="accent"
                        class="lh-30 px-2"
                        [routerLink]="['/profile/' + ar.id]"
                      >
                        Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12">
              <h4 class="primary mb-3">Non-ARs</h4>
              <div class="m-portlet">
                <div *ngIf="users.length === 0" class="data-item p-5">no users</div>
                <div
                  class="m-portlet__body table-header bg-shade-ltblue border-bottom border-gray p-0 pl-3 pr-3"
                  *ngIf="users.length > 0"
                >
                  <div class="row align-items-center">
                    <div class="col py-2 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Person</h6>
                    </div>
                    <div class="col py-2 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">Email</h6>
                    </div>
                    <div class="col py-2 pr-3 pl-3 d-flex align-items-center header-item">
                      <h6 class="m-0 text-truncate header-title mr-auto">1CC Account</h6>
                    </div>
                    <div class="col-1 py-2 pr-3 pl-3 d-flex align-items-center header-item"></div>
                  </div>
                </div>
                <div
                  class="mat-ripple m-portlet__body item pointer p-3"
                  *ngFor="let user of users | orderByField: 'first_name':'asc'"
                >
                  <div class="row align-items-center">
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <app-profile-info
                        [user]="user"
                        [avatarSize]="38"
                        [additionalUserFields]="['title']"
                      ></app-profile-info>
                    </div>
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ user.email }}</p>
                    </div>
                    <div class="col py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <p class="m-0 mr-auto dkblue pointer">{{ user.is_enabled ? 'Active' : 'Inactive' }}</p>
                    </div>
                    <div class="col-auto py-0 pr-3 pl-3 d-flex align-items-center data-item">
                      <button
                        mat-button
                        type="button"
                        color="accent"
                        class="lh-30 px-2"
                        [routerLink]="['/profile/' + user.id]"
                      >
                        Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Documents">
          <div class="d-flex justify-content-between align-items-center mb-3 mt-4">
            <h4 class="primary mb-0">Documents</h4>
            <button mat-flat-button color="success" class="ml-3 lh-28 px-3 ml-auto" (click)="addFile()">+ File</button>
          </div>

          <div class="p-4 dkgray" *ngIf="department?.files?.length == 0">No Documents Yet</div>
          <app-files-list [files]="department?.files"></app-files-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
