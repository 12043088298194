/**
 * Sidenav Configuration. Accessable without an instance.
 */
export abstract class SidenavLinksConfig {
  public static home = {
    routerLink: '/',
    label: 'Home',
    matIcon: 'home',
  };

  public static dispatchDashboard = {
    routerLink: '/dispatch',
    label: 'Dashboard',
    matIcon: 'dashboard',
  };

  public static workspaceDashboard = {
    routerLink: '/dashboard',
    label: 'Dashboard',
    matIcon: 'dashboard',
  };

  public static projectRequests = {
    routerLink: '/requests',
    label: 'Requests',
    matIcon: 'assignment_late',
  };

  public static tenantProjectRequests = {
    routerLink: '/requests',
    label: 'Project Requests',
    matIcon: 'assignment_late',
  };

  public static projectsView = {
    routerLink: '/projects',
    label: 'Projects',
    matIcon: 'assignment_turned_in',
  };

  public static workOrdersView = {
    routerLink: '/work-orders',
    label: 'Work Orders',
    matIcon: 'description',
  };

  public static purchasingView = {
    routerLink: '/purchasing',
    label: 'Purchasing',
    matIcon: 'request_quote',
  };

  public static tasksView = {
    routerLink: '/task-list',
    label: 'Tasks',
    matIcon: 'check_circle',
  };
  // private static messageInbox = { routerLink: '/inbox',  label: 'Messages',  matIcon: 'mail' };

  public static meetings = { routerLink: '/meetings', label: 'Meetings', matIcon: 'event_note' };

  public static directory = { routerLink: '/user-directory', label: 'Directory', matIcon: 'group' };

  public static releaseNotes = { routerLink: '/release-notes', label: 'Release Notes', matIcon: 'new_releases' };

  public static reminders = {
    routerLink: '/reminders',
    label: 'Reminders',
    matIcon: 'notification_important',
  };

  private static testVendor = {
    routerLink: 'tenant/projects/2',
    label: 'Vendor',
    matIcon: 'group',
  };

  // private static search = { routerLink: '/search', label: 'Search', matIcon: 'search'};

  public static workspaceLinks = [
    SidenavLinksConfig.tasksView,
    SidenavLinksConfig.workOrdersView,
    SidenavLinksConfig.projectRequests,
    SidenavLinksConfig.projectsView,
    SidenavLinksConfig.purchasingView,
  ];

  public static staffLinks = [SidenavLinksConfig.directory, SidenavLinksConfig.meetings, SidenavLinksConfig.reminders];

  public static dispatchLinks = [SidenavLinksConfig.dispatchDashboard];

  public static tenantLinks = [
    SidenavLinksConfig.tenantProjectRequests,
    SidenavLinksConfig.projectsView,
    SidenavLinksConfig.workOrdersView,
  ];

  public static vendorLinks = [SidenavLinksConfig.projectsView, SidenavLinksConfig.workOrdersView];

  public static myLinks = [
    SidenavLinksConfig.projectRequests,
    SidenavLinksConfig.projectsView,
    SidenavLinksConfig.meetings,
    SidenavLinksConfig.reminders,
  ];
}
