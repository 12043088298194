import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy } from 'lodash';
import { FileAttachmentDialogComponent } from 'src/app/components';
import { FOUND_PAGE, ResourceType } from 'src/app/enums';
import { AuthService, FileService, LocationService, ProgressIndicatorService } from 'src/app/services';
import { User } from 'src/app/types';

@Component({
  selector: 'app-department-profile',
  templateUrl: './department-profile.component.html',
  styleUrls: ['./department-profile.component.scss'],
})
export class DepartmentProfileComponent implements OnInit {
  loading = true;
  currentUser: User;
  id: number;
  department;
  editingDetails = false;
  detailsFormGroup: FormGroup = this.fb.group({});
  fields = [
    'id',
    'name',
    'department_affiliations{user{title,is_enabled},is_ar}',
    'files',
    'suite_occupancies{suite{floor},department,building}',
    'is_enabled',
  ];
  users = [];
  ARs = [];
  buildings = [];
  suites = [];
  suiteOccupancies: FormArray = new FormArray([]);
  canEdit = false;

  constructor(
    public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private locationService: LocationService,
    private _fileService: FileService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private fb: FormBuilder,
    private progressIndicatorService: ProgressIndicatorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading...');
    this.canEdit =
      this.authService.isAppAdmin ||
      this.authService.isBuildingsDepartmentsSuitesManager ||
      this.authService.isDirectoryManager;
    this.buildings = await this.locationService.getBuildings(['id', 'name', 'floors']).toPromise();
    this.suites = await this.locationService.getSuites(['id', 'name', 'floor_id']).toPromise();
    this.currentUser = this.authService.getLoggedInUser();
    this.id = +this.activeRoute.snapshot.paramMap.get('id');
    const department = (await this.locationService.getDepartmentByID(this.id, this.fields).toPromise()) as any;
    if (department) {
      department.department_affiliations.forEach((da) => {
        if (da.is_ar === 1) {
          this.ARs.push(da.user);
        } else {
          this.users.push(da.user);
        }
      });
      this.mapDepartment(department);
    } else {
      this._router.navigate(['/404'], {
        queryParams: {
          status: FOUND_PAGE.NOT_FOUND,
        },
      });
    }
    this.progressIndicatorService.close();
    this.loading = false;
  }

  async mapDepartment(department) {
    department.suite_occupancies = orderBy([...department.suite_occupancies], (so) => so.id, ['asc']);
    this.suiteOccupancies.clear();
    department.suite_occupancies.forEach((so) => {
      const formGroup = new FormGroup({
        id: new FormControl(so.id),
        building_id: new FormControl(so.building_id, [Validators.required]),
        building_name: new FormControl(so.building?.name),
        floor_id: new FormControl(so.floor_id, [Validators.required]),
        floor_name: new FormControl(so.suite?.floor?.name),
        suite_id: new FormControl(so.suite_id, [Validators.required]),
        suite_name: new FormControl(so.suite?.name),
      });
      this.getFloors(formGroup);
      this.suiteOccupancies.push(formGroup);
    });
    this.department = department;
    this.detailsFormGroup = this.fb.group({
      name: [this.department.name, [Validators.required]],
      is_enabled: [this.department.is_enabled === 1 ? true : false],
    });
  }

  public addFile() {
    this._dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Department,
          parentResourceId: this.department.id,
          workOrderFile: true,
          allowComment: false,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (Array.isArray(result) && result[0].id) {
          this.department.files = [...result, ...(this.department.files || [])];
          this._snackBar.open('File has been added!');
        }
      });
  }

  toggleDetails() {
    this.editingDetails = true;
  }

  async updateProfile() {
    if (!this.detailsFormGroup.valid) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving...');
    const departmentToUpdate = this.detailsFormGroup.value;
    departmentToUpdate.is_enabled = departmentToUpdate.is_enabled === true ? 1 : 0;

    for (let so of this.suiteOccupancies.controls) {
      if (!so.get('suite_id')?.value || !this.department.id) {
        return;
      }
      const suiteOccupancyToAddOrUpdate = { department_id: this.department.id, suite_id: so.get('suite_id').value };

      if (!so.get('id')?.value) {
        await this.locationService.addSuiteOccupancy(suiteOccupancyToAddOrUpdate).toPromise();
      } else if (so.get('suite_id').value) {
        const currentSuiteOccupancy = this.department.suite_occupancies.find((cso) => cso.id === so.get('id').value);
        if (currentSuiteOccupancy && currentSuiteOccupancy.suite_id !== so.get('suite_id').value) {
          await this.locationService.updateSuiteOccupancy(so.get('id').value, suiteOccupancyToAddOrUpdate).toPromise();
        }
      }
    }

    this.department.suite_occupancies.forEach(async (cso) => {
      if (!this.suiteOccupancies.controls.find((so) => so.get('id')?.value === cso.id)) {
        await this.locationService.deleteSuiteOccupancy(cso.id).toPromise();
      }
    });

    const updatedDepartment = await this.locationService
      .updateDepartment(this.department.id, departmentToUpdate, this.fields)
      .toPromise();
    this.mapDepartment(updatedDepartment);

    this.editingDetails = false;
    this.progressIndicatorService.close();
  }

  cancel() {
    this.detailsFormGroup.patchValue({
      name: this.department.name,
      address: this.department.address,
      phone: this.department.phone,
      website: this.department.website,
      is_enabled: this.department.is_enabled === 1 ? true : false,
    });
    this.mapDepartment(this.department);
    this.editingDetails = false;
  }

  addSuite() {
    this.suiteOccupancies.push(
      new FormGroup({
        building_id: new FormControl(null, [Validators.required]),
        floor_id: new FormControl(null, [Validators.required]),
        suite_id: new FormControl(null, [Validators.required, this.suiteExists()]),
      })
    );
    this.suiteOccupancies.markAllAsTouched();
  }

  refreshFloors(suiteOccupancy) {
    this.getFloors(suiteOccupancy);
    suiteOccupancy.get('suite_id').setValue(null);
    suiteOccupancy.get('floor_id').setValue(null);
    this.getSuites(suiteOccupancy);
  }

  getFloors(suiteOccupancy) {
    const building = this.buildings.find((b) => b.id === suiteOccupancy.get('building_id').value);
    suiteOccupancy.floors = building?.floors || [];
    this.getSuites(suiteOccupancy);
  }

  getSuites(suiteOccupancy) {
    suiteOccupancy.filteredSuites = this.suites?.filter((s) => s.floor_id === suiteOccupancy.get('floor_id').value);
  }

  refreshSuites(suiteOccupancy) {
    suiteOccupancy.get('suite_id').setValue(null);
    this.getSuites(suiteOccupancy);
  }

  removeSuiteOccupancy(index) {
    this.suiteOccupancies.controls.splice(index, 1);
    this.suiteOccupancies.updateValueAndValidity();
  }

  private suiteExists(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const exists = this.suiteOccupancies.controls.find(
        (ctrl) => ctrl.get('id')?.value && ctrl.get('suite_id').value == control.value
      );
      return exists ? { suiteExists: { value: true } } : null;
    };
  }
}
