import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ResourceType } from 'src/app/enums';
import { ApiFilterService, HandleErrorService } from 'src/app/services';
import { APIFilter, ServiceResponse } from 'src/app/types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resource-search',
  templateUrl: './resource-search.component.html',
  styleUrls: ['./resource-search.component.scss'],
})
export class ResourceSearchComponent implements OnInit {
  host: string = environment.serviceHost;
  appUrl = `${this.host}/api/v1`;
  @Input() control = new FormControl();
  @Input() result: any;
  @Input() resourceType: ResourceType;
  @Input() displayFn: Function;
  @Input() fields: string[];
  @Input() filters: APIFilter[];
  selectedResource: any;
  resourceTypes = {
    3: {
      label: 'Project',
      placeholder: 'Search Projects',
      url: 'projects',
      fields: 'id,code,title,module_name',
      displayFn: (p) => {
        return p ? `${p.code}${p.title ? ` | ${p.title}` : ''} | ${p.module_name}` : '';
      },
      getApiFilters: (value) => [
        { type: 'field', field: 'id', value: `${value}` },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'code', value: `${value}`, match: 'any' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'title', value: `${value}`, match: 'any' },
      ],
    },
    34: {
      label: 'Work Order',
      placeholder: 'Search Work Orders',
      url: 'work-orders',
      fields: 'id,code,title',
      displayFn: (wo) => {
        return wo ? `${wo.code} | ${wo.title}` : '';
      },
      getApiFilters: (value) => [
        { type: 'field', field: 'id', value: `${value}` },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'code', value: `${value}`, match: 'any' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'title', value: `${value}`, match: 'any' },
      ],
    },
    2: {
      label: 'Work Order',
      placeholder: 'Search Work Orders',
      url: 'modules',
      fields: 'id,name',
      displayFn: (wo) => {
        return wo ? wo.name : '';
      },
      getApiFilters: (value) => [{ type: 'field', field: 'name', value: `${value}`, match: 'any' }],
    },
    110: {
      label: 'Users',
      placeholder: 'Search Users',
      url: 'users',
      fields: 'id,email,first_name,last_name',
      displayFn: (u) => {
        return u ? `${u.first_name} ${u.last_name}` : '';
      },
      getApiFilters: (value) => [
        { type: 'field', field: 'email', value: `${value}`, match: 'any' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'first_name', value: `${value}`, match: 'any' },
        { type: 'operator', value: 'OR' },
        { type: 'field', field: 'last_name', value: `${value}`, match: 'any' },
      ],
    },
  };
  searching = false;
  options: any[] = [];
  sub;
  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private apiFilterService: ApiFilterService
  ) {}

  ngOnInit(): void {
    this.selectedResource = this.resourceTypes[this.resourceType];
    this.control.addValidators(this.validResource());
    this.sub = this.control.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this._filter(value);
    });
  }

  private fetch(searchTerm) {
    searchTerm = encodeURIComponent(searchTerm.trim());
    let apiFilters = this.selectedResource.getApiFilters(searchTerm);
    if (this.filters) {
      apiFilters.unshift({ type: 'operator', value: '(' });
      apiFilters = apiFilters.concat([
        { type: 'operator', value: ')' },
        { type: 'operator', value: 'AND' },
      ]);
      apiFilters = apiFilters.concat(this.filters);
    }
    const filterString = this.apiFilterService.getFilterString(apiFilters);

    return this.http
      .get(
        `${this.appUrl}/${this.selectedResource.url}?fields=${
          this.fields || this.selectedResource.fields
        }&${filterString}&limit=50`
      )
      .pipe(
        map((result: ServiceResponse) => result.data[Object.keys(result.data)[0]]),
        catchError((e) => this.handleErrorService.handleError(e))
      );
  }

  private async _filter(value: any) {
    this.options = [];
    if (!value || typeof value === 'object' || !value.trim()) return;
    this.searching = true;
    this.options = await this.fetch(value).toPromise();
    this.searching = false;
  }

  validResource(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = control.value && typeof control.value !== 'object';
      return invalid ? { 'not found': { value: control.value } } : null;
    };
  }

  errorMessage() {
    return this.control.errors ? Object.keys(this.control.errors).join(', ') : null;
  }

  clear() {
    this.control.setValue(null);
    this.control.markAsDirty();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
