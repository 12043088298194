import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { AuthService, ExportService, ModalService, ModuleService } from 'src/app/services';
import { Preferences, SelectOption } from 'src/app/types';
import { convertDateToFiscalYear, getUniqueArray, PreferenceStorage } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { InvoiceStatus } from '../../enums';

export interface PurchasingInvoice {
  id: number;
  invoice_number: null | string;
  title: string;
  payment_amount: number | null;
  amount_due: number | null;
  total: number;
  created_datetime: null | string;
  fiscal_year: null | string;
  invoice_status_id: number;
  invoice_status_name: string;
  project_id: number;
  project_code: string;
  project_title: null | string;
  module_id: number;
  module_name: string;
  module_icon: string;
  company_id: number;
  company_name: string;
  query_join_type: string;
}

interface PurchasingInvoicesFilterOptions {
  modules: SelectOption[];
  fiscalYears: SelectOption[];
}

interface PurchasingInvoicesDynamicFilterOptions {
  statuses: SelectOption[];
  companies: SelectOption[];
  projects: SelectOption[];
}

export interface PurchasingInvoicesFilterValues {
  modules: string[];
  fiscalYears: string[];
  status: string;
  company: string;
  project: string;
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  types: string[];
  search: string;
}

interface PurchasingInvoicesPreferences extends Preferences {
  moduleIds: string[];
  fiscalYears: string[];
  sort: Sort;
  status: string;
}

const filterPredicate = (value: PurchasingInvoice, filters: PurchasingInvoicesFilterValues) => {
  const includesModule = filters.modules.length === 0 || filters.modules.includes(value.module_id.toString());
  const includesFiscalYear =
    filters.fiscalYears?.length === 0 || filters.fiscalYears?.includes(value.fiscal_year || '( fiscal year not set )');
  const includesStatus = !filters.status || filters.status === value.invoice_status_id.toString();
  const includesCompany =
    !filters.company || (value.company_name || 'no_company').toLowerCase().includes(filters.company.toLowerCase());
  const includesProject =
    !filters.project ||
    ((value.project_id && `${value.project_code} ${value.project_title}`) || 'no_company')
      .toLowerCase()
      .includes(filters.project.toLowerCase());
  const greaterThanStartDate =
    !filters.dateRange.startDate ||
    (value.created_datetime && value.created_datetime >= filters.dateRange.startDate.toJSON());
  const lessThanEndDate =
    !filters.dateRange.endDate ||
    (value.created_datetime && value.created_datetime <= filters.dateRange.endDate.toJSON());
  const includesType = filters.types.length === 0 || filters.types.includes(value.query_join_type.toString());
  const includesSearch =
    !filters.search ||
    `${value.company_name} ${value.project_code} ${value.project_title} ${value.invoice_number}`
      .toLowerCase()
      .includes(filters.search.toLowerCase());

  return (
    includesModule &&
    includesFiscalYear &&
    includesStatus &&
    includesCompany &&
    includesProject &&
    greaterThanStartDate &&
    lessThanEndDate &&
    includesType &&
    includesSearch
  );
};

@Component({
  selector: 'app-purchasing-invoice-list',
  templateUrl: './purchasing-invoice-list.component.html',
  styleUrls: ['./purchasing-invoice-list.component.scss'],
})
export class PurchasingInvoiceListComponent implements OnInit, OnDestroy, AfterViewInit {
  host: string = environment.serviceHost;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  dataSource: MatTableDataSource<PurchasingInvoice> = new MatTableDataSource([]);
  selection: SelectionModel<PurchasingInvoice> = new SelectionModel(true, []);
  displayedColumns: string[] = [
    'select',
    'fiscal_year',
    'invoice',
    'created_datetime',
    'module_name',
    'linked_item',
    'total',
    'invoice_status_name',
    'query_join_type',
  ];
  statusOptions: SelectOption[] = [
    {
      name: 'New',
      value: '1',
    },

    {
      name: 'Received',
      value: '2',
    },
    {
      name: 'Approved',
      value: '3',
    },
    {
      name: 'Rejected',
      value: '5',
    },
    {
      name: 'Ready for Payment',
      value: '4',
    },
  ];
  filterOptions: PurchasingInvoicesFilterOptions = {
    modules: [],
    fiscalYears: [],
  };
  dynamicFilterOptions: PurchasingInvoicesDynamicFilterOptions = {
    projects: [],
    companies: [],
    statuses: [],
  };
  filteredCompanyOptions$: Observable<SelectOption[]> = of([]);
  filteredProjectOptions$: Observable<SelectOption[]> = of([]);
  filters = new FormGroup({
    modules: new FormControl([]),
    fiscalYears: new FormControl([]),
    status: new FormControl(''),
    company: new FormControl(''),
    project: new FormControl(''),
    dateRange: new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    }),
    types: new FormControl([]),
    search: new FormControl(''),
  });
  selectedStatus = '';
  searchString = '';
  showFilters = false;
  isAdmin = false;
  isDataAnalyst = false;
  protected readonly InvoiceStatus = InvoiceStatus;
  private subscriptions = new Subscription();
  private preferences = new PreferenceStorage<PurchasingInvoicesPreferences>('preferences_purchasing_invoices_list', {
    moduleIds: [],
    fiscalYears: [],
    sort: { active: 'created_datetime', direction: 'desc' },
    status: '',
    version: 3,
  });

  constructor(
    private exportService: ExportService,
    private httpClient: HttpClient,
    private modalService: ModalService,
    public authService: AuthService,
    public moduleService: ModuleService,
    private snackBar: MatSnackBar
  ) {
    this.dataSource.filterPredicate = filterPredicate as unknown as (
      data: PurchasingInvoice,
      filter: string
    ) => boolean;

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'invoice':
          return data.company_name.trim().toLowerCase();
        default:
          return data[sortHeaderId] as string | number;
      }
    };
  }

  get currentFiscalYear(): string {
    return convertDateToFiscalYear().toString();
  }

  get filtersAreActive(): boolean {
    const filterValues = this.filters.value as PurchasingInvoicesFilterValues;
    return (
      !!filterValues.fiscalYears.length ||
      !!filterValues.modules.length ||
      !!filterValues.types.length ||
      !!filterValues.company ||
      !!filterValues.project ||
      !!filterValues.search ||
      !!filterValues.status ||
      !!filterValues.dateRange.startDate ||
      !!filterValues.dateRange.endDate
    );
  }

  get isExporting(): boolean {
    return !!this.exportService?.isExporting;
  }

  get filtersAppliedCount(): number {
    let filtersAppliedCount = 0;
    filtersAppliedCount += this.filters?.get('company')?.value?.length ? 1 : 0;
    filtersAppliedCount += this.filters?.get('search')?.value?.length ? 1 : 0;
    filtersAppliedCount += this.filters?.get('project')?.value?.length ? 1 : 0;
    filtersAppliedCount += this.filters?.get('types')?.value?.length || 0;
    filtersAppliedCount +=
      this.filters.get('dateRange')?.value?.startDate || this.filters.get('dateRange')?.value?.endDate ? 1 : 0;
    return filtersAppliedCount;
  }

  get selectedFilteredRows(): PurchasingInvoice[] {
    return this.dataSource.filteredData.filter((f) => !!this.selection.selected.find((s) => s.id === f.id));
  }

  get isAllFilteredRowsSelected(): boolean {
    return this.selectedFilteredRows.length === this.dataSource.filteredData.length;
  }

  ngOnInit(): void {
    void this.refresh();
    this.isAdmin =
      this.authService.isAppAdmin ||
      this.authService.isCFMO ||
      this.authService.isFinanceManager ||
      this.authService.isCFO;
    this.isDataAnalyst = this.authService.isDataAnalyst;

    this.subscriptions.add(
      this.filters.valueChanges.subscribe((filters: PurchasingInvoicesFilterValues) => {
        this.preferences.setPartialValue({
          moduleIds: filters.modules,
          fiscalYears: filters.fiscalYears,
          status: filters.status,
        });
        this.dataSource.filter = filters as unknown as string;
        this.selectedStatus = filters.status;
        this.searchString = filters.search;
        this.dynamicFilterOptions = this.getDynamicFilterOptions(this.dataSource.filteredData);
        this.filteredCompanyOptions$ = this.getFilteredSearchOptions('company', 'companies');
        this.filteredProjectOptions$ = this.getFilteredSearchOptions('project', 'projects');
      })
    );

    this.moduleService.executeAfterServiceInitialization(() => {
      let moduleIds;
      if (this.isAdmin || this.isDataAnalyst) {
        moduleIds = this.preferences.currentValue.moduleIds;
        if (this.preferences.currentValue.moduleIds.length === 0) {
          moduleIds = [this.moduleService.workspace_id.toString()];
        }
      } else {
        moduleIds = [this.moduleService.workspace_id.toString()];
      }

      this.filters.patchValue({
        modules: moduleIds,
        fiscalYears: this.preferences.currentValue.fiscalYears,
        status: this.preferences.currentValue.status,
      });
    });

    this.filteredCompanyOptions$ = this.getFilteredSearchOptions('company', 'companies');
    this.filteredProjectOptions$ = this.getFilteredSearchOptions('project', 'projects');
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

    this.dataSource.sort = this.matSort;
    this.dataSource.sort.disableClear = true;

    this.subscriptions.add(
      this.dataSource.sort.sortChange.subscribe((sort) => this.preferences.setPartialValue({ sort }))
    );

    setTimeout(() => {
      this.matSort.sort({
        id: this.preferences.currentValue.sort.active,
        start: this.preferences.currentValue.sort.direction || 'asc',
        disableClear: true,
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleResetFilters(): void {
    const defaultFilterValues: PurchasingInvoicesFilterValues = {
      search: '',
      fiscalYears: [this.currentFiscalYear],
      modules: [this.moduleService.workspace_id.toString()],
      status: '',
      company: '',
      project: '',
      dateRange: { startDate: null, endDate: null },
      types: [],
    };

    this.filters.setValue(defaultFilterValues);
  }

  handleToggleAllFilteredRowSelections(): void {
    if (this.isAllFilteredRowsSelected) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.filteredData);
    }
  }

  handleInvoiceNumberClick(invoiceId: number, moduleId: number, queryJoinType: string): void {
    this.modalService.openViewInvoiceModal(invoiceId, moduleId, undefined, undefined, queryJoinType !== 'arf');
  }

  handleSelectAll(formControlName: 'modules' | 'fiscalYears'): void {
    const formControl = this.filters.get(formControlName);

    switch (formControlName) {
      case 'modules':
        if (formControl.value.length === this.filterOptions.modules.length) {
          formControl.setValue([]);
        } else {
          formControl.setValue(this.filterOptions.modules.map((m) => m.value));
        }
        break;
      case 'fiscalYears':
        if (formControl.value.length === this.filterOptions.fiscalYears.length) {
          formControl.setValue([]);
        } else {
          formControl.setValue(this.filterOptions.fiscalYears.map((y) => y.value));
        }
        break;
    }
  }

  private async refresh(): Promise<void> {
    const invoices = await this.httpClient
      .get<PurchasingInvoice[]>(`${this.host}/api/v1/invoices/combined-list`)
      .toPromise();

    this.dataSource.data = invoices;
    this.filterOptions = this.getFilterOptions(invoices);
    this.dynamicFilterOptions = this.getDynamicFilterOptions(this.dataSource.filteredData);
  }

  private getFilterOptions(data: PurchasingInvoice[]): PurchasingInvoicesFilterOptions {
    return {
      modules: getUniqueArray(
        data.map((i) => {
          return {
            name: i.module_name,
            value: i.module_id.toString(),
          };
        })
      ).sort((a, b) => (a.name > b.name ? 1 : -1)),
      fiscalYears: [
        ...getUniqueArray(
          data.filter((i) => !!i.fiscal_year).map((i) => ({ name: i.fiscal_year, value: i.fiscal_year }))
        ).sort((a, b) => (a.name > b.name ? -1 : 1)),
        { name: '( fiscal year not set )', value: '( fiscal year not set )' },
      ],
    };
  }

  private getDynamicFilterOptions(invoices: PurchasingInvoice[]): PurchasingInvoicesDynamicFilterOptions {
    function sortByProperty(property: string): (a, b) => number {
      return (a, b) => (a[property] > b[property] ? 1 : -1);
    }

    const companies: SelectOption[] = getUniqueArray(
      invoices.map((i) => {
        return {
          name: i.company_name || 'no_company',
          value: i.company_id?.toString() || 'no_company',
        };
      })
    ).sort(sortByProperty('name'));

    const projects: SelectOption[] = getUniqueArray(
      invoices.map((i) => {
        return {
          name: (i.project_id && `${i.project_code} ${i.project_title}`) || 'no_project',
          value: i.project_id?.toString() || 'no_project',
        };
      })
    ).sort(sortByProperty('name'));

    const statuses: SelectOption[] = getUniqueArray(
      invoices.map((i) => {
        return {
          name: i.invoice_status_name,
          value: i.invoice_status_id.toString(),
        };
      })
    )
      .sort(sortByProperty('id'))
      .reverse();

    return {
      companies,
      projects,
      statuses,
    };
  }

  private getFilteredSearchOptions(filterProperty: string, filterOptionsProperty: string): Observable<SelectOption[]> {
    return this.filters.controls[filterProperty].valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      map((value: string) => {
        return this.dynamicFilterOptions[filterOptionsProperty].filter((option: SelectOption) =>
          option.name.toLowerCase().includes(value.toLowerCase())
        ) as SelectOption[];
      })
    );
  }

  public exportInvoices(): void {
    try {
      const dataToReturn: string[] = [
        'Fiscal Year, Invoice, Created Datetime, Workspace, Project/ARF, Payment, Status, Type',
      ];

      for (const entry of this.dataSource.filteredData) {
        const createdDatetime = entry.created_datetime
          ? moment(entry.created_datetime).format('MM/DD/YYYY HH:mm:ss')
          : null;
        const typeChoices = {
          arf: 'ARF',
          bid: 'T61',
          quote: 'T74',
        };
        const type =
          entry?.query_join_type?.toLocaleLowerCase() && typeChoices[entry.query_join_type.toLowerCase()]
            ? typeChoices[entry.query_join_type.toLowerCase()]
            : '-';

        // push the data to return data
        dataToReturn.push(
          this.exportService.sanitizeItems([
            entry.fiscal_year || '-',
            entry.title || '-',
            createdDatetime || '-',
            entry.module_name || '-',
            `${entry?.project_code || ''}  ${entry?.project_title || ''}`?.trim() || '-',
            (parseInt(`${entry.payment_amount * 100}`) / 100).toFixed(2) || '-',
            entry.invoice_status_name || '-',
            type || '-',
          ])
        );
      }

      this.exportService.exportDataWithConfirmation(
        dataToReturn,
        `invoice_list.csv`,
        `Confirm Invoices' Export`,
        `Invoice export will use the currently selected filter settings. Are you sure you wish to continue?`
      );
    } catch (err) {
      this.snackBar.open(`Something went wrong while downloading Invoices`, `Close`);
    }
  }
}
