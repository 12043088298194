import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { cloneDeep, round, sumBy } from 'lodash';
import * as moment from 'moment';
import { CoverLetterDialogComponent, FileAttachmentDialogComponent, ViewTaskDialogComponent } from 'src/app/components';
import { FileAction, ResourceType, ReviewType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import { USDollarPipe } from 'src/app/pipes';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectEventService,
  ProjectService,
  ProjectTaskService,
  ReviewRevisionService,
  UserService,
} from 'src/app/services';
import { APIFilter, Preferences, UhatFileReference } from 'src/app/types';
import { PreferenceStorage } from 'src/app/utils';
import {
  CBItemType,
  PEBFundingSourceTypeEnum,
  ProjectTenantPEBStatus,
  TenantType,
} from 'src/app/workspaces/construction/enums';
import { CbApprovalProcess } from 'src/app/workspaces/construction/models';
import { ConstructionBudgetService, PEBService, ProjectTenantService } from 'src/app/workspaces/construction/services';
import {
  CBFundingSource,
  CBSection,
  ConstructionBudget,
  ConstructionBudgetItem,
  PEB,
  PEBFundingSource,
  PEBItemNew,
  PEBLine,
  PEBOption,
  PEBTenantLine,
  ProjectConstruction,
  ProjectTenantConstruction,
} from 'src/app/workspaces/construction/types';
import { TaskActionsService } from '../../../../services';
import { CbSectionDetailsDrawerComponent } from '../../components';

interface ConstructionBudgetDisplay extends ConstructionBudget {
  occupied_sqft?: any;
  tenant_allowance_per_sqft?: any;
  rental_rate?: any;
  construction_sqft?: any;
  total_timeline?: any;
  construction_timeline?: any;
  design_timeline?: any;
  circulation_sqft?: number;
  rental_sqft_w_circ?: number;
  tenant_allowance?: number;
  total_rent_per_year?: number;
}

interface ConstructionBudgetDisplayItem extends ConstructionBudgetItem {
  type_id?: number;
  name?: string;
  budget?: number;
  is_modified?: boolean;
  percent_cost?: number;
}

interface CBpreferences extends Preferences {
  percentOrDollar: 'percent' | 'dollar';
}

@Component({
  selector: 'app-construction-budget',
  templateUrl: './construction-budget.component.html',
  styleUrls: ['./construction-budget.component.scss'],
})
export class ConstructionBudgetComponent implements OnInit {
  constructor(
    private appRef: ApplicationRef,
    public dateService: DateService,
    public projectService: ProjectService,
    public projectTaskService: ProjectTaskService,
    public authService: AuthService,
    public projectTenantService: ProjectTenantService,
    public progressIndicatorService: ProgressIndicatorService,
    public pebService: PEBService,
    public userService: UserService,
    public constructionBudgetService: ConstructionBudgetService,
    private displayReviewersService: DisplayReviewersService,
    public reviewRevisionService: ReviewRevisionService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public modalService: ModalService,
    private fileService: FileService,
    private eventService: ProjectEventService,
    private taskActionsService: TaskActionsService
  ) {}

  project: ProjectConstruction;
  @ViewChild('drawer') sectionDrawer: MatDrawer;
  @ViewChild('sectionDetailsDrawerComponent') sectionDetailsComponent: CbSectionDetailsDrawerComponent;

  coverLetterSection = {
    first_name: null,
    name: null,
    title: null,
    department: null,
    cb_cover_letter_text: null,
  };

  exportShowAllSections = true;
  exportSections: CBSection[];
  selectedSection: CBSection;
  selectedSectionIndex = 0;
  exportSectionsLength: number;

  currentTime;
  currentDate;
  projectFields: string[] = [
    'code',
    'title',
    'building_code',
    'floor_code',
    'project_manager_first_name',
    'project_manager_last_name',
    'square_footage',
    'end_date',
    'architect_first_name',
    'architect_last_name',
    'building_circulation_factor',
    'uses_new_peb',
  ];
  projectTenantFields: string[] = [
    'tenant_name',
    'type_id',
    'selected_peb_id',
    'peb_status',
    'cb_is_finalized',
    'peb_approval_task_id',
    'tenant_approval_task_id',
    'cb_approval_task_id',
    'saved_cb_approval_task_id',
    'cb_tenant_approval_task_id',
    'saved_cb_tenant_approval_task_id',
    'representative_id',
    'cb_revision',
    'cb_cover_letter_text',
    'peb_cover_letter_text',
    'cb_management_fee_percentage',
    'peb_management_fee_percentage',
    'peb_management_fee_subtotal',
    'project_uses_new_peb',
  ];
  pebFields: string[] = [
    'design_timeline',
    'construction_timeline',
    'total_timeline',
    'construction_sqft',
    'occupied_sqft',
    'rental_rate',
    'tenant_allowance_per_sqft',
    'tenant_id',
    'tenant_type_id',
  ];
  pebItemFields: string[] = [
    'owner_type_id',
    'subtotal',
    'name',
    'sqft',
    'cost_per_sqft',
    'type_id',
    'peb_tenant_id',
    'trade_id',
    'trade_name',
  ];
  bidPackageFields: string[] = [
    'trade_id',
    'trade_name',
    'budget_amount',
    'awarded_amount',
    'child_request{id}',
    'child_project{id,budget_data}',
  ];
  constructionBudgetFields: string[] = [
    'tenant_id',
    'design_timeline',
    'construction_timeline',
    'total_timeline',
    'construction_sqft',
    'occupied_sqft',
    'rental_rate',
    'tenant_allowance_per_sqft',
  ];
  constructionBudgetItemFields: string[] = [
    'project_id',
    'owner_type_id',
    'tenant_id',
    'bid_package_id',
    'peb_fee_item_id',
    'subtotal',
  ];
  projectTenants: ProjectTenantConstruction[];
  peb;
  pebIsFinalized;
  unsavedChangesExist = false;
  hideDetails = false;
  allBidsAllocated = false;
  USDollarPipe = new USDollarPipe();
  tenantTotalBudget: number;
  tenantTotalCost: number;
  trustTotalBudget: number;
  trustTotalCost: number;
  tenantItems: ConstructionBudgetDisplayItem[];
  trustItems: ConstructionBudgetDisplayItem[];
  tenantAllowanceItem: ConstructionBudgetDisplayItem = {
    type_id: CBItemType.TenantAllowance,
    name: 'Tenant Allowance',
    owner_type_id: 1,
  };
  trustAllowanceItem: ConstructionBudgetDisplayItem = {
    type_id: CBItemType.TenantAllowance,
    name: 'Tenant Allowance',
    owner_type_id: 2,
  };
  managementFeeItem: ConstructionBudgetDisplayItem = {
    type_id: CBItemType.ManagementFee,
    name: 'Management Fee',
    owner_type_id: 1,
  };
  currentManagementFeePercentage;
  isEditing = false;
  selectedTenant: ProjectTenantConstruction;
  private tenantRep;
  isDownLoading: boolean;
  private reviewers = {};

  public selectedPEB: PEB;
  exportData = [];
  selectedTenantId: number;
  selectedCB: ConstructionBudgetDisplay;
  private allCoverLetterDesc = {};
  PEBData: PEBOption;
  cbData;
  feeTypeIds = [2, 3, 5, 6];
  isDoneLoading: boolean;

  public bidPackages = [];
  public foundCBs = [];
  public existingItems = [];
  public bidPackageTotals = { awarded: 0, allocated: 0 };
  reviewTaskUpdated: any;

  cbSections;
  changeTenantTabUIOnly = false;
  percentOrDollar: 'percent' | 'dollar' = 'percent';

  @ViewChild('pdf', { static: true }) public pdf: PDFExportComponent;
  @ViewChild('cbpdf', { static: true }) public cbpdf: PDFExportComponent;
  @ViewChild('coverLetter', { static: true }) public coverLetter: PDFExportComponent;
  @ViewChild('summary', { static: true }) public summary: PDFExportComponent;
  @ViewChild('tenantTabGroup', { static: false }) tenantTabGroup: MatTabGroup;

  osfWCirc: number;
  savingCB = false;

  private delay(ms: number): Promise<{}> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private preferences = new PreferenceStorage<CBpreferences>('preferences_cb', {
    percentOrDollar: 'percent',
    version: 1,
  });

  ngOnInit() {
    this.percentOrDollar = this.preferences.currentValue.percentOrDollar;
    this.currentDate = moment().format('LL');
    this.currentTime = moment().format('LTS');
    setTimeout(() => {
      this.refresh();
    });

    this.reviewTaskUpdated = this.projectTaskService.milestoneTaskEvent.subscribe(async (data) => {
      if (data && this.cbData) {
        let allTasksComplete = true;
        let updatedTask;
        const internalTaskId = this.cbData.sections[0]?.cb_approval_task_id;
        let internalTaskHasBeenCompleted;
        let tenantTasksAreLocked = true;

        for (const section of this.cbData?.sections || []) {
          const tasks = [
            section.cb_approval_process?._staffReview?.getTask,
            section.cb_approval_process?._tenantReview?.getTask,
          ];
          updatedTask = tasks.find((t) => t?.id === data.id) || updatedTask;
          allTasksComplete =
            allTasksComplete &&
            !!tasks.find(
              (t) =>
                !t ||
                (t?.status_id === TaskStatus.Complete && t.status_id !== data.id) ||
                (t?.id === data.id && data.status_id === TaskStatus.Complete)
            );
          tenantTasksAreLocked = tenantTasksAreLocked
            ? (!section.needs_tenant_approval && !this.noTenantReviews) ||
              !!section.cb_approval_process?._tenantReview?.getTask?.is_locked
            : false;
          internalTaskHasBeenCompleted =
            internalTaskHasBeenCompleted ||
            (internalTaskId === updatedTask?.id &&
              updatedTask.status_id !== TaskStatus.Complete &&
              data.status_id === TaskStatus.Complete);
        }

        if (
          (allTasksComplete && updatedTask && updatedTask.status_id !== data.status_id) ||
          (internalTaskHasBeenCompleted && tenantTasksAreLocked)
        ) {
          updatedTask.status_id = data.status_id;

          if (internalTaskHasBeenCompleted && tenantTasksAreLocked) {
            const tenantSections = this.cbData.sections.filter((s) => s.needs_tenant_approval);
            if (tenantSections?.length) {
              await this.submitForTenantReview(tenantSections);
            }
          } else {
            await this.refresh();
          }
        }
      } else if (
        data &&
        !this.project.uses_new_peb &&
        data.status_id === TaskStatus.Complete &&
        data.id === this.selectedTenant.cb_approval_process?._staffReview?.getTask?.id &&
        data.status_id !== this.selectedTenant.cb_approval_process?._staffReview?.getTask?.status_id &&
        this.selectedTenant.cb_approval_process?._staffReview?.getTask?.status_id &&
        this.selectedTenant.cb_approval_process?._tenantReview?.getTask?.is_locked
      ) {
        this.selectedTenant.cb_approval_process._staffReview.getTask.status_id = data.status_id;
        await this.submitForTenantReview([this.selectedTenant]);
      }
    });
  }

  /**
   * Get the current time in the format 3:55:30 PM
   */
  public getCurrentTime(): string {
    return moment(moment.now()).format('LTS');
  }

  /**
   * Get the current date in the format August 15, 2019
   */
  public getCurrentDate(): string {
    return moment(moment.now()).format('LL');
  }

  private checkBidAllocation() {
    this.allBidsAllocated = !(this.bidPackages ?? []).find((bp) =>
      bp.child_request?.id || bp.child_project?.id
        ? bp.child_project?.budget_data?.awardedBidTotal || 0
        : bp.awarded_amount - bp.used_amount !== 0
    );
  }

  // Only allows certain staff users to edit the review process
  get userIsReviewAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject?.module_id
    );
  }

  // This is only used for the new construction format
  get reviewsHaveStarted() {
    return this.cbData?.sections[0]?.cb_approval_task_id || this.cbData?.sections[0]?.saved_cb_approval_task_id;
  }

  get dataIsAddedBySectionStatus() {
    const sectionsStatus = [];
    for (const section of this.cbData?.sections) {
      sectionsStatus.push(!section.cb_approval_process?.hasFiles || !section.cb_approval_process?.hasCoverLetter);
    }
    return sectionsStatus;
  }

  get requiredDataIsAdded(): boolean {
    return !this.dataIsAddedBySectionStatus.find((s) => s);
  }

  get internalReviewCanStart(): boolean {
    return (
      this.cbData?.sections[0]?.cb_approval_process?.staffCanStart &&
      this.requiredDataIsAdded &&
      !this.isEditing &&
      this.isAllocated
    );
  }

  get internalReviewCanRestart() {
    return this.requiredDataIsAdded && !this.isEditing && this.isAllocated;
  }

  get tenantReviewCanStart(): boolean {
    return (
      (this.project?.uses_new_peb &&
        this.cbData?.sections[0]?.cb_approval_process?._staffReview?._approvalTask?.status_id === TaskStatus.Complete &&
        !!this.cbData?.sections?.find(
          (s) => s.needs_tenant_approval && !s.cb_tenant_approval_task_id && !s.saved_cb_tenant_approval_task_id
        )) ||
      (!this.project?.uses_new_peb && this.selectedTenant.cb_approval_process?.tenantCanStart)
    );
  }

  get tenantReviewCanRestart(): boolean {
    return (
      this.project?.uses_new_peb &&
      this.cbData?.sections[0]?.cb_approval_process?._staffReview?._approvalTask?.status_id === TaskStatus.Complete &&
      !!this.cbData?.sections?.find((s) => s.needs_tenant_approval && s.saved_cb_tenant_approval_task_id)
    );
  }
  get tenantReviewHasStarted(): boolean {
    return !!this.cbData?.sections?.find((s) => s.cb_tenant_approval_task_id || s.saved_cb_tenant_approval_task_id);
  }

  get tenantTaskIds(): number[] {
    return this.cbData?.sections
      ?.filter((s) => s.cb_tenant_approval_task_id || s.saved_cb_tenant_approval_task_id)
      ?.map((s) => s.cb_tenant_approval_task_id || s.saved_cb_tenant_approval_task_id);
  }

  get CBApprovalTaskId(): number {
    return this.cbData?.sections[0]?.cb_approval_task_id;
  }

  get savedCBApprovalTaskId(): number {
    return this.cbData.sections[0]?.saved_cb_approval_task_id;
  }

  get canEdit(): boolean {
    return !this.reviewsHaveStarted || !!this.savedCBApprovalTaskId || this.isEditing;
  }

  get isAllocated(): boolean {
    return !this.cbData?.lines?.find((line) => line.trade_id && Number(line.awarded) !== Number(line.total));
  }

  get canFinalize(): boolean {
    return this.cbData?.sections?.length && !this.cbData?.sections?.find((s) => !s.cb_approval_process?.canFinalizeNew);
  }
  get isFinalized(): boolean {
    return (
      (!!this.cbData?.sections?.length && !!this.cbData.sections[0].cb_approval_process?.isFinalized) ||
      (!this.project?.uses_new_peb && !!this.selectedTenant?.cb_is_finalized)
    );
  }

  async refresh() {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading Budget...');
    this.project = await this.projectService
      .getProjectById(this.projectService.currentSelectedProjectId, this.projectFields)
      .toPromise();
    this.osfWCirc = (this.project.occupied_sqft ?? 0) * (1 + 0.01 * (this.project.building_circulation_factor ?? 0));
    this.projectTenants = await this.projectTenantService
      .getTenantsForProject(this.project.id, this.projectTenantFields)
      .toPromise();
    if (this.project.uses_new_peb) {
      const selectedPEBId = this.projectTenants?.[0]?.selected_peb_id;
      if (selectedPEBId) {
        this.cbData = await this.constructionBudgetService.getCBData(this.project.id).toPromise();
        this.refreshCB();
        this.calculateOptionTotals(this.cbData);
      }
    }
    this.projectTenants.forEach((tenant) => {
      tenant.cb_approval_process = new CbApprovalProcess(
        tenant,
        this.projectTaskService,
        this.modalService,
        this.fileService,
        this.dateService,
        this.taskActionsService
      );

      if (!this.project.uses_new_peb) {
        this.getAllCoverLetterDesc(tenant);
      }
    });
    if (this.selectedTenantId) {
      await this.selectTenant(this.selectedTenantId);
    } else if (this.projectTenants.length > 0) {
      await this.selectTenant(this.projectTenants[0].id);
    }
    this.reviewers = await this.displayReviewersService.displayReviewers(this.projectTenants, ReviewType.CB);

    this.projectTenants.forEach((tenant) => {
      tenant.assigned_user = tenant.cb_approval_process.tenantReviewAssignedUser;
    });
    this.checkBidAllocation();
    this.progressIndicatorService.close();
    this.isDoneLoading = true;
  }

  private async getAllCoverLetterDesc(tenant) {
    let coverLetterDesc = tenant.cb_cover_letter_text;

    if (!coverLetterDesc) {
      coverLetterDesc = `Enclosed is the Construction Budget for project ${this.projectService.currentSelectedProject.code} - ${this.projectService.currentSelectedProject.title}. Before any work can begin, please review, approve, and sign the attached Construction Budget. When the Construction Budget is approved, please upload the signed copy to the 1CALL System.  We will review it to ensure all information required has been received and then coordinate with you regarding our next steps.`;
      if (!this.project?.uses_new_peb) {
        await this.saveCoverLetterDesc(tenant.id, coverLetterDesc);
      }
    }
    this.allCoverLetterDesc[tenant.id] = coverLetterDesc;
  }

  public async breakForUnsavedChanges(): Promise<boolean> {
    if (this.unsavedChangesExist) {
      let breakout = false;
      await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Unsaved Changes',
          descriptionText: 'You have unsaved changes that will be discarded if you continue. Do you wish to continue?',
          confirmationButtonText: 'Continue',
        })
        .toPromise()
        .then(async (isConfirmed) => {
          if (!isConfirmed) {
            breakout = true;
          } else {
            this.unsavedChangesExist = false;
            this.isEditing = false;
          }
        });
      return breakout;
    } else {
      return false;
    }
  }

  async selectTenant(tenantId?: number, selectEvent?) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Loading tenant information...');
    if (await this.breakForUnsavedChanges()) {
      this.changeTenantTabUIOnly = true;
      this.tenantTabGroup.selectedIndex = this.projectTenants.map((t) => t.id).indexOf(this.selectedTenantId);
      return;
    }
    if (tenantId) {
      this.selectedTenantId = tenantId;
      this.selectedTenant = this.projectTenants.find((tenant) => tenant.id === tenantId);
    } else if (selectEvent) {
      this.selectedTenantId = this.projectTenants[selectEvent.index].id;
      this.selectedTenant = this.projectTenants[selectEvent.index];
    } else {
      console.error(`Invalid tenant selection: null`);
    }
    this.cbSections = [{ name: 'UHAT Investment', owner_type_id: 2 }];
    const foundTenant = this.projectTenants.find((t) => t.id === this.selectedTenantId);
    if (foundTenant && foundTenant.type_id !== 3) {
      this.cbSections.unshift({ name: foundTenant.tenant_name, owner_type_id: 1 });
    }
    const projectTenant = this.projectTenants.find((t) => t.id === this.selectedTenantId);
    this.currentManagementFeePercentage = projectTenant?.cb_management_fee_percentage;
    this.managementFeeItem.percent_cost = this.currentManagementFeePercentage;
    this.selectedTenant.peb_management_fee_subtotal = projectTenant?.peb_management_fee_subtotal;
    this.pebIsFinalized =
      projectTenant.peb_status === ProjectTenantPEBStatus.Finalized && !!projectTenant.selected_peb_id;
    if (this.pebIsFinalized) {
      if (!this.project.uses_new_peb) {
        this.peb = await this.pebService.getPEBById(projectTenant.selected_peb_id, this.pebFields).toPromise();
        this.peb.circulation_sqft = 0.01 * +this.project.building_circulation_factor * +this.peb.occupied_sqft;
        this.peb.rental_sqft_w_circ = +this.peb.occupied_sqft + +this.peb.circulation_sqft;
        this.peb.tenant_allowance = +this.peb.rental_sqft_w_circ * +this.peb.tenant_allowance_per_sqft; // rental sf w/ circ * tenant allowance
        this.peb.total_rent_per_year = +this.peb.rental_sqft_w_circ * +this.peb.rental_rate; // rental sf w/ circ * rental rate
      } else {
        const foundSection = this.cbData.sections?.find((s) => s.tenant_id === tenantId);
        if (foundSection) {
          this.peb = {
            construction_sqft: foundSection.construction_sqft,
            occupied_sqft: foundSection.occupied_sqft,
            tenant_allowance_per_sqft: 0,
            rental_rate: foundSection.rental_rate ?? 0,
            design_timeline: '',
            construction_timeline: '',
            total_timeline: '',
          };
          this.peb.circulation_sqft = 0.01 * +this.project.building_circulation_factor * +this.peb.occupied_sqft;
          this.peb.rental_sqft_w_circ = +this.peb.occupied_sqft + +this.peb.circulation_sqft;
          this.peb.tenant_allowance = +this.peb.rental_sqft_w_circ * +this.peb.tenant_allowance_per_sqft;
          this.peb.total_rent_per_year = +this.peb.rental_sqft_w_circ * +this.peb.rental_rate;
        }
      }

      this.foundCBs = await this.constructionBudgetService
        .getConstructionBudgets(this.constructionBudgetFields)
        .toPromise();
      let foundCB;
      if (this.foundCBs) {
        foundCB = this.foundCBs.find((cb) => +cb.tenant_id === +this.selectedTenantId);
      }
      if (foundCB) {
        this.selectedCB = foundCB;
      } else {
        this.selectedCB = {
          design_timeline: this.peb.design_timeline,
          construction_timeline: this.peb.construction_timeline,
          total_timeline: this.peb.total_timeline,
          construction_sqft: this.peb.construction_sqft,
          occupied_sqft: this.peb.occupied_sqft,
          rental_rate: this.peb.rental_rate,
          tenant_allowance_per_sqft: this.peb.tenant_allowance_per_sqft,
        };
      }
      this.calculateHeaders();

      let pebItems: {
        id: number;
        owner_type_id: number;
        subtotal: number;
        name: string;
        sqft: number;
        cost_per_sqft: number;
        type_id: number;
        peb_tenant_id: number;
        trade_id: number;
        trade_name: string;
      }[];
      if (!this.project.uses_new_peb) {
        const retrievedPEBItems: any[] = await this.pebService
          .getPEBItems(this.pebItemFields, [
            { type: 'field', field: 'peb_id', value: projectTenant.selected_peb_id.toString() },
          ])
          .toPromise();
        pebItems = retrievedPEBItems;
      } else {
        pebItems = [];
        const selectedTenant = this.PEBData?.sections?.find((s) => s.tenant_id === projectTenant.id);
        if (selectedTenant) {
          let itemId = 0;
          for (const l of selectedTenant.lines) {
            const ownerTypes = [];
            for (const fs of selectedTenant.funding_sources) {
              if (fs.type_id === PEBFundingSourceTypeEnum.UHAT) {
                const foundOwnerType = ownerTypes.find((ot) => ot.id === 2);
                const linesToAdd = fs.items.filter((i) => i.line_id === l.id);
                if (!foundOwnerType) {
                  ownerTypes.push({ id: 2, items: linesToAdd });
                } else {
                  foundOwnerType.items = [...foundOwnerType.items, ...linesToAdd];
                }
              } else {
                const foundOwnerType = ownerTypes.find((ot) => ot.id === 1);
                const linesToAdd = fs.items.filter((i) => i.line_id === l.id);
                if (!foundOwnerType) {
                  ownerTypes.push({ id: 1, items: linesToAdd });
                } else {
                  foundOwnerType.items = [...foundOwnerType.items, ...linesToAdd];
                }
              }
            }
            for (const t of ownerTypes) {
              const itemSubtotal = sumBy(t.items, (i: any) => +i.value);
              itemId--;
              const newItem = {
                id: itemId,
                owner_type_id: t.id,
                subtotal: itemSubtotal,
                name: l.name,
                sqft: l.sqft,
                cost_per_sqft: l.sqft ? (itemSubtotal ?? 0) / l.sqft : 0,
                type_id: l.type_id,
                peb_tenant_id: projectTenant.id,
                trade_id: l.trade_id,
                trade_name: l.name,
              };
              pebItems.push(newItem);
            }
          }
        }
      }

      const cbItemFilters: APIFilter[] = [
        {
          type: 'field',
          field: 'project_id',
          value: this.projectService.currentSelectedProjectId.toString(),
        },
      ];
      this.existingItems = await this.constructionBudgetService
        .getConstructionBudgetItems(this.constructionBudgetItemFields, cbItemFilters)
        .toPromise();

      const bidPackageFilters: APIFilter[] = [
        {
          type: 'field',
          field: 'project_id',
          value: this.projectService.currentSelectedProjectId.toString(),
        },
      ];
      const bidPackages = await this.projectService
        .getBidPackages(bidPackageFilters, this.bidPackageFields)
        .toPromise();
      this.bidPackages = bidPackages;
      let tenantItems = [];
      let trustItems = [];
      const noBidPackagePEBItems = pebItems.filter(
        (i) => i.type_id === 1 && !bidPackages.find((bp) => bp.trade_id === i.trade_id)
      );
      for (const i of noBidPackagePEBItems) {
        const cbItem: ConstructionBudgetDisplayItem = {
          type_id: 1,
          name: i.trade_name,
          budget: i.subtotal,
          subtotal: this.formatDollar(0),
          owner_type_id: i.owner_type_id,
        };
        if (cbItem.owner_type_id === 1) {
          tenantItems.push(cbItem);
        } else if (cbItem.owner_type_id === 2) {
          trustItems.push(cbItem);
        }
      }
      for (const p of bidPackages) {
        const tenantItem: ConstructionBudgetDisplayItem = {
          type_id: 1,
          name: p.trade_name,
          bid_package_id: p.id,
        };
        const trustItem = cloneDeep(tenantItem);
        const tradePEBItems = pebItems.filter(
          (i) => i.type_id === 1 && i.trade_id === p.trade_id && i.peb_tenant_id === this.selectedTenantId
        );
        tenantItem.budget = sumBy(
          tradePEBItems.filter((i) => i.owner_type_id === 1),
          'subtotal'
        ); // sum all peb items for that trade
        tenantItem.owner_type_id = 1;
        tenantItem.tenant_id = this.selectedTenantId;
        trustItem.budget = sumBy(
          tradePEBItems.filter((i) => i.owner_type_id === 2),
          'subtotal'
        ); // sum all peb items for that trade
        trustItem.tenant_id = this.selectedTenantId;
        trustItem.owner_type_id = 2;
        const foundTenantItem = this.existingItems
          .filter((item) => +item.tenant_id === +this.selectedTenantId)
          .find((i) => i.bid_package_id === p.id && i.owner_type_id === 1);
        if (foundTenantItem) {
          tenantItem.id = foundTenantItem.id;
        }
        tenantItem.subtotal = this.formatDollar(foundTenantItem ? +foundTenantItem.subtotal : 0);
        const foundTrustItem = this.existingItems
          .filter((item) => +item.tenant_id === +this.selectedTenantId)
          .find((i) => i.bid_package_id === p.id && i.owner_type_id === 2);
        if (foundTrustItem) {
          trustItem.id = foundTrustItem.id;
        }
        trustItem.subtotal = this.formatDollar(foundTrustItem ? +foundTrustItem.subtotal : 0);
        tenantItems.push(tenantItem);
        trustItems.push(trustItem);
      }
      const pebFees = pebItems.filter((i) => [2, 3, 5].indexOf(i.type_id) > -1);
      const feeItems: ConstructionBudgetDisplayItem[] = pebFees.map((f) => ({
        type_id: 2,
        name: f.name,
        budget: f.subtotal,
        owner_type_id: f.owner_type_id,
        tenant_id: this.selectedTenantId,
        peb_fee_item_id: f.id,
      }));
      for (const f of feeItems) {
        const foundFeeItem = this.existingItems
          .filter((item) => +item.tenant_id === +this.selectedTenantId)
          .find((i) => +i.peb_fee_item_id === +f.peb_fee_item_id);
        if (foundFeeItem) {
          f.id = foundFeeItem.id;
        }
        f.subtotal = this.formatDollar(foundFeeItem ? +foundFeeItem.subtotal : 0);
      }
      tenantItems = tenantItems.concat(feeItems.filter((i) => i.owner_type_id === 1));
      trustItems = trustItems.concat(feeItems.filter((i) => i.owner_type_id === 2));
      if (foundTenant.type_id !== 3) {
        tenantItems.push(this.tenantAllowanceItem);
        trustItems.push(this.trustAllowanceItem);
        tenantItems.push(this.managementFeeItem);
      }
      this.tenantItems = tenantItems;
      this.trustItems = trustItems;
      this.unsavedChangesExist = false;
      this.calculateSubtotals();
    }
    if (this.selectedTenant && this.selectedTenant.representative_id) {
      this.tenantRep = await this.userService.getUserById(this.selectedTenant.representative_id).toPromise();
    }

    this.calculateUsedTotals();
    this.progressIndicatorService.close();
  }

  private getBaseline(bidPackageId) {
    const otherTenantCBIds = this.foundCBs
      .filter((cb) => +cb.tenant_id !== +this.selectedTenantId)
      .map((cb) => +cb.tenant_id);
    const otherTenantLineItems = this.existingItems.filter((item) => otherTenantCBIds.includes(+item.tenant_id));
    const lineItemsForPackage = otherTenantLineItems.filter((item) => +item.bid_package_id === +bidPackageId);
    let total = 0;
    lineItemsForPackage.forEach((item) => {
      total = round(total + parseFloat(String(item.subtotal)), 2);
    });
    return total;
  }

  // returns the used amount in the total cost column for a trade_id
  private calculateUsedTotals() {
    this.bidPackageTotals = { awarded: 0, allocated: 0 };
    const trade_names = [];
    this.bidPackages.forEach((p) => {
      p.used_amount = this.getBaseline(p.id);
      this.bidPackageTotals.awarded +=
        p.child_request?.id || p.child_project?.id
          ? p.child_project?.budget_data?.awardedBidTotal || 0
          : p.awarded_amount;
      trade_names.push(p.trade_name);
    });
    if (this.trustItems) {
      this.trustItems.forEach((i) => {
        if (trade_names.includes(i.name)) {
          // need to wrap in string because console log is showing it's a string, but the code thinks it's a number
          const foundIndex = this.bidPackages.findIndex((p) => p.trade_name === i.name);
          this.bidPackages[foundIndex].used_amount = round(
            this.bidPackages[foundIndex].used_amount + parseFloat(String(i.subtotal)),
            2
          );
        }
      });
    }
    if (this.tenantItems) {
      this.tenantItems.forEach((i) => {
        if (trade_names.includes(i.name)) {
          // need to wrap in string because console log is showing it's a string, but the code thinks it's a number
          const foundIndex = this.bidPackages.findIndex((p) => p.trade_name === i.name);
          this.bidPackages[foundIndex].used_amount = round(
            this.bidPackages[foundIndex].used_amount + parseFloat(String(i.subtotal)),
            2
          );
        }
      });
    }
    this.bidPackages.forEach((bidPackage) => {
      this.bidPackageTotals.allocated += bidPackage.used_amount;
    });
  }

  modifyField(budgetItem?: ConstructionBudgetDisplayItem, category?: string) {
    if (category === 'subtotal') {
      this.calculateUsedTotals();
    }
    this.unsavedChangesExist = true;
    this.calculateHeaders();
    if (budgetItem) {
      budgetItem.is_modified = true;
      this.calculateSubtotals();
    }
    this.checkBidAllocation();
  }

  calculateHeaders() {
    if (this.selectedCB) {
      this.selectedCB.circulation_sqft =
        0.01 * +this.project.building_circulation_factor * +this.selectedCB.occupied_sqft;
      this.selectedCB.rental_sqft_w_circ = +this.selectedCB.occupied_sqft + +this.selectedCB.circulation_sqft;
      this.selectedCB.tenant_allowance =
        +this.selectedCB.rental_sqft_w_circ * +this.selectedCB.tenant_allowance_per_sqft;
      this.selectedCB.total_rent_per_year = +this.selectedCB.rental_sqft_w_circ * +this.selectedCB.rental_rate;
      this.tenantAllowanceItem.budget = -1 * +this.peb.rental_sqft_w_circ * +this.peb.tenant_allowance_per_sqft;
      this.tenantAllowanceItem.subtotal = -1 * this.selectedCB.tenant_allowance;
      this.trustAllowanceItem.budget = +this.peb.rental_sqft_w_circ * +this.peb.tenant_allowance_per_sqft;
      this.trustAllowanceItem.subtotal = this.selectedCB.tenant_allowance;
    }
  }

  calculateSubtotals() {
    this.tenantTotalBudget =
      sumBy(this.tenantItems, (i) => +i.budget || 0) + +this.selectedTenant.peb_management_fee_subtotal;
    this.trustTotalBudget = sumBy(this.trustItems, (i) => +i.budget || 0);
    const tenantCostItems = sumBy(
      this.tenantItems.filter((i) => i.type_id !== 6),
      (i) => +i.subtotal || 0
    );
    this.managementFeeItem.subtotal = (this.managementFeeItem.percent_cost / 100) * tenantCostItems;
    this.tenantTotalCost = tenantCostItems + this.managementFeeItem.subtotal;
    this.trustTotalCost = sumBy(this.trustItems, (i) => +i.subtotal || 0);
  }

  public blurUSDollarInput(oldValue, item?, propertyName?) {
    const newValue = this.formatDollar(oldValue, item, propertyName);
    // only format input if property was changed during formatting
    if (oldValue !== newValue) {
      this.modifyField(item);
    }
  }

  public formatDollar(oldValue, object?, propertyName?) {
    if (oldValue || oldValue === 0) {
      const newValue = this.USDollarPipe.transform(oldValue, 2, false, false);
      if (object && propertyName) {
        object[propertyName] = newValue;
      }
      return newValue;
    } else {
      return null;
    }
  }

  public getPebStatusText(tenant: ProjectTenantConstruction) {
    return tenant.cb_approval_process?.statusText;
  }

  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // --------------------------------------------Reviews--------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------
  /**
   * Open the dialog that is responsible for starting the Approvals Process. Creating the initial tasks, activities, and assigning followers.
   * @param tenant The tenant to start the process for
   * @param isInternalApproval Whether the approval is for internal or tenant approval
   */
  public async submitApproval(tenant: ProjectTenantConstruction, isInternalApproval: boolean) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }

    let refresh = true;

    tenant = tenant || this.cbData.sections[0];
    const internalTenant = this.project.uses_new_peb ? this.cbData.sections[0] : tenant;
    const tenants = this.project.uses_new_peb ? this.cbData.sections.filter((s) => s.needs_tenant_approval) : [tenant];

    if (
      (isInternalApproval && internalTenant.saved_cb_approval_task_id) ||
      (!isInternalApproval && tenant.saved_cb_tenant_approval_task_id)
    ) {
      await this.restartReviewProcess(tenant, isInternalApproval, false);

      await this.refresh();
      return;
    }

    if (!this.project.uses_new_peb) {
      const pebFilters: APIFilter[] = [{ type: 'field', field: 'tenant_id', value: tenant.id.toString() }];
      const tenantPEBs = await this.pebService.getPEBs(this.pebFields, pebFilters).toPromise();
      if (tenantPEBs?.[0]?.id) {
        tenant.old_type_id = tenantPEBs[0].tenant_type_id;
      } else {
        this.snackbar.open('No PEBs exist for this tenant!');
        return;
      }
    }

    if (isInternalApproval) {
      refresh = await this.startInternalReview(internalTenant);
    } else {
      await this.submitForTenantReview(tenants);
    }

    if (refresh) {
      this.snackbar.open('Approval Process Started and Task Created');
      await this.refresh();
    }
  }

  // Starts an internal Review
  private async startInternalReview(tenant: any) {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Start Review',
        headerText: 'Start Internal Review',
        descriptionText:
          'Starting the internal review process will automatically create a review task and lock the CB page from editing.',
        confirmationButtonText: 'Start',
      })
      .toPromise();

    if (res) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Generating files for task creation...');
      const cbFiles = [await this.combineFiles(this.project?.uses_new_peb ? this.cbData.sections : [tenant])];
      const linkedFiles = [];
      if (this.project.uses_new_peb) {
        for (const s of this.cbData.sections) {
          for (const fileId of s.construction_documents_ids) {
            linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
          }
        }
      }
      const reviewers = this.displayReviewersService.getInternalReviewers();
      const reviewIds = reviewers.reviewIds;
      const selectedReviewers = reviewers.selectedReviewers;
      const tenantTypeId = tenant.old_type_id || tenant.type_id;
      this.progressIndicatorService.updateStatus('Creating review task...');

      const newTaskId = await tenant.cb_approval_process.beginStaffReview({
        cbName:
          (this.project.title ? this.project.title : 'Project ' + this.project.code) +
          ' (' +
          (tenantTypeId === TenantType.Internal ? 'UHAT' : tenant.tenant_name) +
          ')',
        files: cbFiles,
        linkedFiles,
        reviewIds,
        isTypeLocked: true,
        isReviewersLocked: true,
        areFilesLinked: false,
        selectedReviewers,
      });

      // If the user creating the review task is the same as the first reviewers this creates a task activity on the new task
      if (this.authService.getLoggedInUser().id === selectedReviewers[0].id) {
        await this.eventService
          .createTaskApprovalEvent(newTaskId, TaskReviewStatus.Approved, 'Approved upon creation')
          .toPromise();
      }

      tenant.cb_approval_task_id = newTaskId;
      await this.projectTenantService
        .updateProjectTenant(tenant.id || tenant.tenant_id, { cb_approval_task_id: newTaskId })
        .toPromise();
    }

    return res;
  }

  public async submitForTenantReview(sections: CBSection[] | ProjectTenantConstruction[], refreshData = true) {
    let updateTenantReview = true;
    if (this.project?.uses_new_peb && this.tenantReviewCanStart) {
      updateTenantReview = await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Start Tenant Review',
          headerText: 'Start Tenant Review',
          descriptionText: `${sections?.length} tenant review task${
            this.cbData?.sections?.length > 1 ? 's' : ''
          } will be created. Please verify that the internal review task has been correctly completed and that all data is correct.`,
          confirmationButtonText: 'Start',
        })
        .toPromise();
    }

    if (updateTenantReview) {
      let index = 1;
      for (const section of sections) {
        if (section.saved_cb_tenant_approval_task_id) {
          await this.restartReviewProcess(section, false, false, index, sections?.length);
        } else if (this.tenantReviewCanStart) {
          await this.startTenantReview(section, index, sections.length);
        }
        index++;
      }

      if (refreshData) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus('Refreshing Reviews...');
        await this.refresh();
        this.progressIndicatorService.close();
      }
    }
  }

  // Starts a tenant review
  private async startTenantReview(tenant: any, index = 0, totalCount = 0) {
    const countProgressMessage = (index && `[${index}/${totalCount}] `) || '';
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Generating files for task...`);
    const cbFiles = [await this.combineFiles([tenant], false)];
    const reviewIds = [{ id: tenant.representative_id, status: TaskReviewStatus.Pending }];
    const tenantTypeId = tenant.old_type_id || tenant.type_id;
    const user = await this.userService
      .getUserById(tenant.representative_id, ['id', 'first_name', 'last_name', 'title', 'user_type_id'])
      .toPromise();
    const assigned_user = {
      id: tenant.representative_id,
      firstName: user.first_name,
      lastName: user.last_name,
      title: user.title,
      user_type_id: user.user_type_id,
      reviewer: true,
    };

    this.progressIndicatorService.updateStatus(`${countProgressMessage}Creating Task...`);
    const newTaskId = await tenant.cb_approval_process.beginTenantReview({
      cbName:
        (this.project.title ? this.project.title : 'Project ' + this.project.code) +
        ' (' +
        (tenantTypeId === TenantType.Internal ? 'UHAT' : tenant.tenant_name) +
        ')',
      files: cbFiles,
      reviewIds,
      areFilesLinked: false,
      assigned_user,
    });

    tenant.cb_tenant_approval_task_id = newTaskId;
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Task Created...`);
    await this.projectTenantService
      .updateProjectTenant(this.project.uses_new_peb ? tenant.tenant_id : tenant?.id, {
        cb_tenant_approval_task_id: newTaskId,
      })
      .toPromise();

    this.progressIndicatorService.close();
  }

  // stops the review process and adds to revision if not reset
  public async resetReview(tenant = null, reset?: boolean) {
    if (await this.breakForUnsavedChanges()) {
      return false;
    }

    let resetReview = true;
    if (this.isFinalized) {
      resetReview = await this.unFinalizeCB();
    }

    if (resetReview) {
      tenant = tenant || this.cbData.sections[0];
      const internalTenant = this.project.uses_new_peb ? this.cbData.sections[0] : tenant;
      const tenants = this.project.uses_new_peb ? this.cbData.sections : [tenant];
      await this.modalService
        .openConfirmationDialog({
          titleBarText: 'Reset Approval Process',
          descriptionText:
            'You are about to reset the approval process, are you sure? This will invalidate any existing approvals and require a new approval.',
          confirmationButtonText: 'Submit',
          userInput: {
            required: true,
            placeholder: 'Reason for Reset',
          },
        })
        .toPromise()
        .then(async (res) => {
          if (res) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Resetting Approval');

            if (internalTenant.cb_approval_task_id) {
              await this.reviewRevisionService.cbInternalRevision(internalTenant, reset, res);
            }
            if (internalTenant.cb_tenant_approval_task_id || internalTenant.saved_cb_approval_task_id) {
              for (const t of tenants) {
                await this.reviewRevisionService.cbTenantRevision(t, reset, res);
              }
            }

            if (reset) {
              const taskId = internalTenant.cb_approval_task_id;
              const updatedAccessoryData = await this.displayReviewersService.getCurrentReviewers(taskId);

              if (!updatedAccessoryData.isTheSame) {
                const taskData = {
                  id: taskId,
                  accessory_data: JSON.stringify(updatedAccessoryData.accessoryData),
                  assigned_user_id: updatedAccessoryData.accessoryData.reviewChain[0].id,
                  status_id: TaskStatus.Open,
                };
                await this.projectService.updateTask(taskData).toPromise();
              }
            }

            this.progressIndicatorService.updateStatus('Refreshing Tenant Info');
            // await this.refresh();
            this.progressIndicatorService.close();
            this.snackbar.open('The review process has been reset');
            return true;
          } else {
            resetReview = false;
          }
        });
    }

    return resetReview;
  }

  // restarts a review process that has been stopped. Resets all the reviewers to pending and recreates the attached file
  private async restartReviewProcess(tenant, isInternal, refreshData = true, index = 0, totalCount = 0) {
    if (await this.breakForUnsavedChanges()) {
      return;
    }
    const countProgressMessage = (index && `[${index}/${totalCount}] `) || '';

    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus(`${countProgressMessage}Generating files for task...`);
    this.snackbar.open('Your Review is being restarted');
    const combinedFile = [
      await this.combineFiles(isInternal && this.project?.uses_new_peb ? this.cbData.sections : [tenant], isInternal),
    ];
    if (isInternal) {
      const linkedFiles = [];
      if (this.project.uses_new_peb) {
        for (const s of this.cbData.sections) {
          for (const fileId of s.construction_documents_ids) {
            linkedFiles.push(await this.fileService.getIdAndName(fileId).toPromise());
          }
        }
      }

      await this.reviewRevisionService.internalCbSubmitRevision(tenant, combinedFile, 49, linkedFiles);
    } else {
      await this.reviewRevisionService.tenantCbSubmitRevision(tenant, combinedFile, 49);
    }

    if (refreshData) {
      await this.refresh();
    }
  }

  public finalizeCB(tenant) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize CB',
        headerText: 'Finalize CB',
        confirmationButtonText: 'Finalize CB',
        descriptionText:
          'Warning: Finalizing is permanent. Please verify that the selected CB is 100% correct, as no more changes can be made after finalizing. Are you sure you want to finalize the CB?',
      })
      .subscribe(async (closeEvent) => {
        if (closeEvent) {
          await this.projectTenantService.updateProjectTenant(tenant.id, { cb_is_finalized: +true }).toPromise();
          const tasksToLock = [tenant.cb_approval_task_id, tenant.cb_tenant_approval_task_id];
          for (const taskId of tasksToLock) {
            if (taskId) {
              await this.lockTask(taskId);
            }
          }
          this.refresh();
        }
      });
  }

  public finalizeCBNew() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Finalize CB',
        headerText: 'Finalize CB',
        confirmationButtonText: 'Finalize CB',
        descriptionText:
          'Warning: Please verify that the selected CB is 100% correct. Are you sure you want to finalize the CB?',
      })
      .subscribe(async (closeEvent) => {
        if (closeEvent) {
          this.progressIndicatorService.openAwaitIndicatorModal();
          this.progressIndicatorService.updateStatus('Finalizing CB...');
          for (const tenant of this.cbData.sections) {
            await this.projectTenantService
              .updateProjectTenant(tenant.tenant_id, { cb_is_finalized: +true })
              .toPromise();
            const tasksToLock = [tenant.cb_approval_task_id, tenant.cb_tenant_approval_task_id];
            for (const taskId of tasksToLock) {
              if (taskId) {
                await this.lockTask(taskId);
              }
            }
          }
          await this.refresh();
        }
      });
  }

  async unFinalizeCB() {
    const closeEvent = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Un-finalize CB',
        headerText: 'Un-finalize CB',
        confirmationButtonText: 'Un-Finalize CB',
        descriptionText:
          'Warning: Please verify that you would like to un-finalize this CB, this will reset all reviews.',
      })
      .toPromise();

    if (closeEvent) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Un-finalizing CB...');
      const tenantsToUpdate = this.project.uses_new_peb ? this.cbData.sections : [this.selectedTenant];
      for (const s of tenantsToUpdate) {
        await this.projectTenantService
          .updateProjectTenant(s.tenant_id || s.id, {
            cb_is_finalized: null,
          })
          .toPromise();
        s.cb_is_finalized = null;
      }

      this.progressIndicatorService.close();
    }

    return closeEvent;
  }

  public async lockTask(taskId) {
    const task = {
      id: taskId,
      is_locked: 1,
    };

    await this.projectService.updateTask(task).toPromise();
  }

  public async internalReviewAction(taskId: number) {
    if (taskId) {
      this.viewTask(taskId);
    } else {
      await this.submitApproval(null, true);
    }
  }

  public async tenantReviewAction() {
    if (!this.sectionsThatNeedTenantReviews?.length) {
      await this.viewTenantTasks();
    } else if (this.tenantReviewCanStart) {
      await this.submitForTenantReview(this.sectionsThatNeedTenantReviews);
    }
  }

  async refreshCB() {
    const firstSection = this.cbData.sections[0];
    const reviewers = await this.displayReviewersService.displayReviewers([firstSection], ReviewType.CB);

    this.cbData.sections.map((section) => {
      if (firstSection.cb_approval_task_id && !section.cb_approval_task_id) {
        section.cb_approval_task_id = firstSection?.cb_approval_task_id;
      }

      section.reviewers = reviewers[firstSection.tenant_id];

      section.isExpanded = true;
      this.calculateSectionSubtotals(section);
      section.cb_approval_process = new CbApprovalProcess(
        section,
        this.projectTaskService,
        this.modalService,
        this.fileService,
        this.dateService,
        this.taskActionsService
      );

      for (const l of section.lines) {
        l.funding_sources = [];
        for (const fs of section.funding_sources) {
          const foundItem = fs.items.find(
            (i) => (i.trade_id && i.trade_id === l.trade_id) || (i.line_type_id !== 1 && i.line_type_id === l.type_id)
          );
          if (foundItem) {
            l.funding_sources.push({
              item_value: foundItem.value ?? 0,
              id: foundItem.id,
              trade_id: foundItem.trade_id,
            });
          }
        }
      }
    });
    this.calculateOptionTotals(this.cbData);
    this.formatOptionValues(this.cbData);
  }

  private formatOptionValues(option: PEBOption) {
    for (const s of option.sections) {
      s.rental_rate = this.formatDollar(s.rental_rate, true);
      for (const fs of s.funding_sources) {
        for (const i of fs.items) {
          i.value = this.formatDollar(i.value);
        }
      }
    }
  }

  async CBchanged() {
    let current = await this.constructionBudgetService.getCBData(this.project.id).toPromise();
    current = this.pebService.mapBugetForComparison(current);
    const selectedCB = this.pebService.mapBugetForComparison(this.cbData);
    return JSON.stringify(selectedCB) !== JSON.stringify(current);
  }

  async saveCBChanges() {
    this.savingCB = true;
    if ((await this.CBchanged()) !== true) {
      this.isEditing = false;
      this.savingCB = false;
      return;
    }
    if ((this.CBApprovalTaskId && (await this.resetReview(this.selectedTenant)) === true) || !this.CBApprovalTaskId) {
      const cbData = cloneDeep(this.cbData);
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Saving Budget...');
      for (const section of cbData.sections) {
        delete section.cb_approval_process;
      }

      await this.constructionBudgetService
        .updateCB(this.projectService.currentSelectedProjectId, cbData)
        .toPromise()
        .then(async (res) => {
          this.cbData = res;
          await this.refreshCB();
          this.isEditing = false;
        })
        .finally(() => {
          this.progressIndicatorService.close();
          this.savingCB = false;
        });
    }
  }

  async discardCBChanges() {
    this.cbData = await this.constructionBudgetService.getCBData(this.project.id).toPromise();
    this.refreshCB();
    this.isEditing = false;
    this.unsavedChangesExist = false;
  }

  async saveChanges() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Saving Budget...');
    const cbToSend: ConstructionBudget = {
      design_timeline: this.selectedCB.design_timeline,
      construction_timeline: this.selectedCB.construction_timeline,
      total_timeline: this.selectedCB.total_timeline,
      construction_sqft: this.selectedCB.construction_sqft,
      occupied_sqft: this.selectedCB.occupied_sqft,
      rental_rate: this.selectedCB.rental_rate,
      tenant_allowance_per_sqft: this.selectedCB.tenant_allowance_per_sqft,
    };
    const foundCBs = await this.constructionBudgetService
      .getConstructionBudgets(this.constructionBudgetFields, [
        { type: 'field', field: 'tenant_id', value: this.selectedTenantId.toString() },
      ])
      .toPromise();
    const foundCB = foundCBs && foundCBs[0];
    if (foundCB && foundCB.id) {
      await this.constructionBudgetService
        .updateConstructionBudget(foundCB.id, cbToSend, this.constructionBudgetFields)
        .toPromise();
    } else {
      cbToSend.tenant_id = this.selectedTenantId;
      await this.constructionBudgetService
        .createConstructionBudget(cbToSend, this.constructionBudgetFields)
        .toPromise();
    }
    for (const item of this.tenantItems.concat(this.trustItems)) {
      if (item.is_modified && item.type_id !== 4) {
        const itemToSend: ConstructionBudgetItem = {
          subtotal: +item.subtotal,
        };
        if (item.id) {
          await this.constructionBudgetService
            .updateConstructionBudgetItem(item.id, itemToSend, this.constructionBudgetItemFields)
            .toPromise();
        } else {
          itemToSend.project_id = +this.projectService.currentSelectedProjectId;
          itemToSend.owner_type_id = +item.owner_type_id;
          itemToSend.tenant_id = +item.tenant_id;
          itemToSend.bid_package_id = +item.bid_package_id;
          itemToSend.peb_fee_item_id = +item.peb_fee_item_id;
          await this.constructionBudgetService
            .createConstructionBudgetItem(itemToSend, this.constructionBudgetItemFields)
            .toPromise();
        }
      }
    }
    if (
      (this.managementFeeItem.percent_cost || this.managementFeeItem.percent_cost === 0) &&
      this.currentManagementFeePercentage !== this.managementFeeItem.percent_cost
    ) {
      await this.projectTenantService
        .updateProjectTenant(this.selectedTenantId, {
          cb_management_fee_percentage: this.managementFeeItem.percent_cost,
        })
        .toPromise();
      this.currentManagementFeePercentage = this.managementFeeItem.percent_cost;
    }
    this.isEditing = false;
    this.unsavedChangesExist = false;
    this.snackbar.open('Construction Budget saved!');
    this.refresh();
  }

  openCoverLetter(): void {
    // use the peb cover letter as default text, replacing peb with cons
    const defaultCoverLetterText = this.selectedTenant?.peb_cover_letter_text?.replace(
      /preliminary estimated/gi,
      'Construction'
    );

    const dialogRef = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Construction Budget',
        tenant: {
          ...this.currentTenantInfo,
          tenant_id: this.selectedTenant.id,
          type_id: this.selectedTenant.type_id,
          cover_letter_text: this.selectedTenant?.cb_cover_letter_text || defaultCoverLetterText,
        },
        pmInfo: this.pmInfo,
        architectInfo: this.architectInfo,
      },
    });

    dialogRef.afterClosed().subscribe(async (tenant) => {
      if (tenant) {
        if (tenant.id) {
          this.saveCoverLetter(tenant);
          this.refresh();
        }
      }
    });
  }

  public async saveCoverLetter(tenant) {
    if (this.CBApprovalTaskId && (await this.resetReview()) === false) {
      return;
    }
    this.unsavedChangesExist = false;
    this.isEditing = false;
    await this.saveCoverLetterDesc(tenant.id, tenant.cover_letter_text);
    this.snackbar.open('Your changes have been saved.');
    await this.refresh();

    if (this.cbData) {
      const section = this.cbData.sections?.find((s) => s.tenant_id === tenant.id);
      this.sectionDetailsComponent.load(section);
    }
  }

  private async saveCoverLetterDesc(id: number, text: string) {
    await this.projectTenantService.updateProjectTenant(id, { cb_cover_letter_text: text }).toPromise();
  }

  public async combineFiles(tenants: ProjectTenantConstruction[], isInternal = true, fileAction = FileAction.Return) {
    this.selectedSectionIndex = 0;
    const files = [];
    const cbRevision = this.cbData?.sections[0]?.cb_revision || this.selectedTenant.cb_revision;
    this.exportSectionsLength = tenants?.length;

    // add peb numbers to the export
    const selectedPEBId = this.projectTenants?.[0]?.selected_peb_id;
    if (selectedPEBId && this.project.uses_new_peb === 1) {
      const PEBdata = await this.pebService.getPEBData(selectedPEBId).toPromise();
      this.pebService.calculateOptionTotals(PEBdata);
      PEBdata.sections.forEach((s) => {
        s.lines.forEach((l) => {
          l.peb_funding_sources = [];
          s.funding_sources.forEach((fs) => {
            const items = fs.items.find((i) => i.line_id === l.id);
            l.peb_funding_sources.push(items);
          });
        });
      });
      (tenants as any).forEach((t) => {
        const tenantPEB = PEBdata.sections.find((s) => s.tenant_id === t.tenant_id);
        this.cbData.peb_total = PEBdata.total;
        t.lines.forEach((tl) => {
          const PEBline = tenantPEB.lines.find((pl) =>
            tl.trade_id ? tl.trade_id === pl.trade_id : tl.type_id === pl.type_id
          );
          if (PEBline) {
            tl.peb_sqft = PEBline.sqft;
            tl.peb_subtotal = PEBline.subtotal;
            tl.funding_sources.map((fs, i) => {
              fs.peb_value = PEBline.peb_funding_sources[i].value;
            });
          }
        });
        t.funding_sources.map((fs, i) => {
          fs.peb_subtotal = tenantPEB.funding_sources[i].subtotal;
        });
        t.peb_subtotal = tenantPEB.subtotal;
        t.lines.forEach((l) => {
          const pebLine = PEBdata.lines.find((pl) =>
            l.trade_id ? l.trade_id === pl.trade_id : l.type_id === pl.type_id
          );
          l.peb_total = pebLine?.total;
        });
      });
    }

    for (const tenant of tenants) {
      this.selectedSectionIndex += 1;
      this.selectedSection = tenant;

      this.appRef.tick();
      let tenantTypeId;
      if (!this.project.uses_new_peb) {
        const pebFilters: APIFilter[] = [{ type: 'field', field: 'tenant_id', value: tenant.id.toString() }];
        const tenantPEBs = await this.pebService.getPEBs(this.pebFields, pebFilters).toPromise();
        if (tenantPEBs?.[0]?.id) {
          tenantTypeId = tenantPEBs[0].tenant_type_id;
        } else {
          this.snackbar.open('No PEBs exist for this tenant!');
          return;
        }
      } else {
        tenantTypeId = tenant.type_id;
      }

      // Generate Cover Letter Export
      const coverLetterGroup = await this.coverLetter.export();
      const coverLetterBase64 = (await exportPDF(coverLetterGroup)).replace('data:application/pdf;base64,', '');
      const coverLetterByteCharacters = atob(coverLetterBase64);
      const coverLetterData = new Array(coverLetterByteCharacters.length);
      for (let i = 0; i < coverLetterByteCharacters.length; i++) {
        coverLetterData[i] = coverLetterByteCharacters.charCodeAt(i);
      }
      let coverLetterTitle = 'Cover_Letter_' + (tenantTypeId === 3 ? 'UHAT' : tenant.tenant_name);
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      coverLetterTitle = coverLetterTitle.replace(/ /g, '_');

      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(coverLetterData)]),
        name: `${coverLetterTitle}_v${tenant.cb_revision}.pdf`,
      });

      // Generate Construction Budget Export
      const cbGroup = this.project.uses_new_peb ? await this.cbpdf.export() : await this.pdf.export();
      const cbBase64 = (await exportPDF(cbGroup)).replace('data:application/pdf;base64,', '');
      const cbByteCharacters = atob(cbBase64);
      const cbData = new Array(cbByteCharacters.length);
      for (let i = 0; i < cbByteCharacters.length; i++) {
        cbData[i] = cbByteCharacters.charCodeAt(i);
      }
      let title = 'Construction_Budget';
      title = title.replace(/ /g, '_');

      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(cbData)]),
        name: `${title}_v${tenant.cb_revision || '0'}_PRJ${this.project.code}.pdf`,
      });
    }

    if (isInternal && this.project.uses_new_peb) {
      await this.delay(100);
      const summaryGroup = await this.summary.export();
      const summaryBase64 = (await exportPDF(summaryGroup)).replace('data:application/pdf;base64,', '');
      const summaryByteCharacters = atob(summaryBase64);
      const summaryData = new Array(summaryByteCharacters.length);
      for (let i = 0; i < summaryByteCharacters.length; i++) {
        summaryData[i] = summaryByteCharacters.charCodeAt(i);
      }
      let summaryTitle = 'Summary_' + (tenants[0]?.type_id === 3 ? 'UHAT' : tenants[0]?.tenant_name);
      // replaced with this regex to replace ALL occurances, rather than just the first
      // '/ /g' means every space, not just the first
      summaryTitle = summaryTitle.replace(/ /g, '_');
      // Combine Files
      files.push({
        file: new Blob([new Uint8Array(summaryData)]),
        name: `${summaryTitle}_v${tenants[0]?.cb_revision}.pdf`,
      });
    }

    let combinedFile;
    try {
      combinedFile = await this.fileService.combinePDFs(files).toPromise();
    } catch (e) {
      const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
      errorSnack.onAction().subscribe(async () => {
        this.snackbar.dismiss();
      });
      return;
    }

    const cbTitle = `CB_v${cbRevision}_PRJ${this.project.code}.pdf`;
    if (fileAction === FileAction.Return) {
      const blob = new Blob([new Uint8Array(combinedFile.data)], { type: 'application/pdf' });
      return new File([blob], cbTitle);
    } else {
      saveAs(new Blob([new Uint8Array(combinedFile.data)]), cbTitle);
    }
  }

  public async getPdfPackage(tenant) {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Generating pdf package...');

    const file = await this.combineFiles([tenant]);
    this.progressIndicatorService.updateStatus('Downloading File...');
    await saveAs(file);
    this.progressIndicatorService.close();
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.project_manager_first_name
      ? this.projectService.currentSelectedProject.project_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.project_manager_last_name
      ? this.projectService.currentSelectedProject.project_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.project_manager_email
      ? this.projectService.currentSelectedProject.project_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.project_manager_office_phone
      ? this.projectService.currentSelectedProject.project_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }
  get wmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.workspace_manager_first_name
      ? this.projectService.currentSelectedProject.workspace_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.workspace_manager_last_name
      ? this.projectService.currentSelectedProject.workspace_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.workspace_manager_email
      ? this.projectService.currentSelectedProject.workspace_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.workspace_manager_office_phone
      ? this.projectService.currentSelectedProject.workspace_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Construction Manager',
      email,
      phone,
    };
  }
  get architectInfo(): { name: string; title: string; email: string; phone: string } {
    if (!this.projectService.currentSelectedProject.architect_first_name) {
      return null;
    }
    const first = this.projectService.currentSelectedProject.architect_first_name
      ? this.projectService.currentSelectedProject.architect_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.architect_last_name
      ? this.projectService.currentSelectedProject.architect_last_name
      : '';
    const email = this.projectService.currentSelectedProject.architect_email
      ? this.projectService.currentSelectedProject.architect_email
      : '';
    const phone = this.projectService.currentSelectedProject.architect_office_phone
      ? this.projectService.currentSelectedProject.architect_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Architect',
      email,
      phone,
    };
  }

  get noTenantReviews(): boolean {
    return !this.cbData?.sections?.find((s) => s.needs_tenant_approval);
  }

  get sectionsThatNeedTenantReviews(): CBSection[] {
    const sectionsWithoutTasks: CBSection[] = [];
    for (const section of this.cbData?.sections) {
      if (
        !section.saved_cb_tenant_approval_task_id &&
        !section.cb_tenant_approval_task_id &&
        section.needs_tenant_approval
      ) {
        sectionsWithoutTasks.push(section);
      }
    }

    return sectionsWithoutTasks;
  }

  get currentReviewers() {
    return this.selectedSection?.reviewers ||
      (this.reviewers && this.selectedTenant && this.selectedTenant.id && this.reviewers[this.selectedTenant.id])
      ? this.reviewers[this.selectedTenant.id]
      : [];
  }

  get coverLetterDesc(): string {
    if (this.selectedSection) {
      return this.selectedSection.cb_cover_letter_text;
    } else if (this.selectedTenant) {
      return this.allCoverLetterDesc[this.selectedTenant.id];
    }
    return 'Loading...';
  }

  // Cover letter functions to get the current data
  get currentTenantInfo(): { first_name: string; name: string; title: string; department: string } {
    const tenant = this.project?.uses_new_peb ? this.selectedSection : this.selectedTenant;
    if (tenant && this.tenantRep) {
      return {
        first_name: this.selectedSection?.representative_first_name || this.tenantRep.first_name,
        name: this.project?.uses_new_peb
          ? `${this.selectedSection?.representative_first_name} ${this.selectedSection?.representative_last_name}`
          : `${this.tenantRep.first_name} ${this.tenantRep.last_name}`,
        title: this.selectedSection?.representative_title || this.tenantRep.title,
        department: tenant.tenant_name,
      };
    } else if (
      (this.projectTenants &&
        this.selectedTenantId &&
        this.projectTenants.find((t) => t.id === this.selectedTenantId).type_id === TenantType.Internal) ||
      !this.selectedSection?.needs_tenant_approval
    ) {
      return {
        first_name: this.projectService.currentSelectedProject.cfmo_first_name,
        name: `${this.projectService.currentSelectedProject.cfmo_first_name} ${this.projectService.currentSelectedProject.cfmo_last_name}`,
        title: 'Chief Facilities Management Officer',
        department: 'University Hospitals Authority & Trust',
      };
    } else {
      return { first_name: '', name: '', title: '', department: '' };
    }
  }

  discardChanges() {
    this.isEditing = false;
    this.unsavedChangesExist = false;
    this.refresh();
  }

  async export() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Exporting...');

    const tenantData = this.project.uses_new_peb ? this.cbData.sections : [this.selectedTenant];
    await this.combineFiles(tenantData, true, FileAction.Download);

    this.progressIndicatorService.close();
  }

  exportCoverLetter() {
    this.isDownLoading = true;
    this.coverLetter.saveAs(`Construction_Budget_Cover_Letter.pdf`);
    this.isDownLoading = false;
  }

  public getCurrentProject(): ProjectConstruction {
    return this.projectService.currentSelectedProject;
  }

  public numberWithCommas(x: number): string {
    if (!x) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public async viewTenantTasks() {
    const tenantTasks = [];
    let taskId;
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Getting Task Data...');
    for (const section of this.cbData?.sections) {
      taskId = section.cb_tenant_approval_task_id || section.saved_cb_tenant_approval_task_id;
      if (taskId) {
        tenantTasks.push(
          section.cb_approval_process?._tenantReview?.getTask ||
            (await this.projectService.getTaskById(taskId).toPromise())
        );
      }
    }

    this.progressIndicatorService.close();

    if (tenantTasks?.length > 1) {
      const data = {
        tasks: tenantTasks,
        dontFilterTasks: true,
      };
      await this.modalService.openTaskOverviewDialog(data).toPromise();
    } else {
      await this.viewTask(tenantTasks[0]?.id);
    }
  }

  public viewTask(taskId: number) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.reviewChanged.subscribe((data) => {
      this.refresh();
    });
  }

  private calculateSectionSubtotals(section: CBSection) {
    this.calculateLineSubtotals(section);
    this.calculateFundingSourceSubtotals(section);
    section.subtotal = round(
      sumBy(section.lines, (li: PEBLine) => +li.subtotal),
      4
    );
  }

  private calculateLineSubtotals(section: CBSection) {
    for (const l of section.lines) {
      let lineSubtotal = 0;
      for (const fs of section.funding_sources) {
        const foundItem = fs.items.find((fsi) =>
          l.trade_id ? l.trade_id === fsi.trade_id : l.type_id === fsi.line_type_id
        );
        lineSubtotal = round(lineSubtotal + (foundItem ? +foundItem?.value : 0), 4);
      }
      l.subtotal = lineSubtotal;
    }
  }

  private calculateFundingSourceSubtotals(section: CBSection) {
    for (const fs of section.funding_sources) {
      fs.subtotal = round(
        sumBy(fs.items, (i: PEBItemNew) => +i.value),
        4
      );
    }
  }

  private calculateOptionTotals(option: PEBOption) {
    for (const s of option.sections) {
      this.calculateSectionSubtotals(s);
    }
    for (const l of option.lines) {
      let lineTotal = 0;
      for (const s of option.sections) {
        const foundLine = s.lines.find((sl) => (l.trade_id ? l.trade_id === sl.trade_id : l.type_id === sl.type_id));
        lineTotal = round(lineTotal + (foundLine ? +foundLine.subtotal : 0), 4);
      }
      l.total = lineTotal;
    }
    option.total = this.formatDollar(
      round(
        sumBy(option.sections, (s: any) => +s.subtotal),
        4
      ),
      true
    );

    for (const s of option.sections) {
      s.subtotal = this.formatDollar(s.subtotal, true);
      for (const l of s.lines) {
        l.subtotal = this.formatDollar(l.subtotal, true);
      }
      for (const fs of s.funding_sources) {
        fs.subtotal = this.formatDollar(fs.subtotal, true);
      }
    }
    for (const l of option.lines) {
      l.total = this.formatDollar(l.total, true);
    }
  }

  private updatePercentageLineItems(option) {
    for (const s of option?.sections ?? []) {
      for (const fs of s.funding_sources) {
        const tradeTotal = this.getFundingSourceTradeTotal(fs);
        const nonManagementFeeTypes = this.feeTypeIds.filter((ftid) => ftid !== 6);
        const nonManagementFeeItems = fs.items.filter((fsi) => nonManagementFeeTypes.indexOf(fsi.line_type_id) > -1);
        let tradeAndFeeTotal = tradeTotal;
        for (const i of nonManagementFeeItems) {
          if (this.percentOrDollar === 'percent') {
            i.value = this.formatDollar(round((i.percentage / 100) * tradeTotal, 4));
          } else {
            i.percentage = round((i.value / tradeTotal) * 100, 4);
          }
          tradeAndFeeTotal += +i.value;
          i.is_modified = true;
        }
        const managementFeeItems = fs.items.filter((fsi) => fsi.line_type_id === 6);
        for (const i of managementFeeItems) {
          i.value = this.formatDollar(round((i.percentage / 100) * tradeAndFeeTotal, 4));
          i.is_modified = true;
        }
      }
    }
  }

  private getFundingSourceTradeTotal(fundingSource: PEBFundingSource) {
    const tradeItems = fundingSource.items.filter((i) => i.line_type_id === 1);
    const tradeTotal = sumBy(tradeItems, (i) => +i.value);
    return tradeTotal;
  }

  async getCBitems() {
    const cbItemFilters: APIFilter[] = [
      {
        type: 'field',
        field: 'project_id',
        value: this.projectService.currentSelectedProjectId.toString(),
      },
    ];

    const CBitems = await this.constructionBudgetService
      .getConstructionBudgetItems(this.constructionBudgetItemFields, cbItemFilters)
      .toPromise();

    return CBitems;
  }

  blurDollarItem(object: any, property: string) {
    if (object[property]) {
      object[property] = this.formatDollar(object[property]);
    }
  }

  public cbItemChanged(item: PEBItemNew, fundingSource?: CBFundingSource, lines = null) {
    item.is_modified = true;
    let value;
    if (this.feeTypeIds.indexOf(item.line_type_id) > -1) {
      const tradeTotal = this.getFundingSourceTradeTotal(fundingSource);
      if (this.percentOrDollar === 'percent') {
        value = round((item.percentage / 100) * tradeTotal, 4);
        item.value = value;
        item.percentage = item.percentage || null;
      } else {
        item.percentage = round((Number(item.value) / tradeTotal) * 100, 4);
      }
    } else {
      value = item?.value;
    }
    item.value = item.value || null;

    if (String(value).match(new RegExp(/^\d*\.?\d{0,4}$/))) {
      this.calculateOptionTotals(this.cbData);
      this.updatePercentageLineItems(this.cbData);
    }

    for (const line of lines) {
      const currentItem = line.funding_sources?.find((fs) => fs.id === item.id);
      if (currentItem?.id) {
        currentItem.item_value = item.value;
      }
    }
  }

  public tenantLineChanged(line: PEBTenantLine) {
    if ('sqft' in line) {
      line.sqft = +line.sqft;
    }
    line.is_modified = true;
  }

  public async openSectionDrawer(section: CBSection) {
    await this.updateSectionData(section);
    await this.sectionDrawer.open();
    await this.sectionDetailsComponent.load(section);
  }

  public async updateSectionData(section: CBSection) {
    this.coverLetterSection = {
      first_name: section.representative_first_name,
      name: `${section.representative_first_name} ${section.representative_last_name}`,
      title: section.representative_title ?? '',
      department: section.tenant_name,
      cb_cover_letter_text: section.cb_cover_letter_text,
    };
    this.exportShowAllSections = false;
    this.exportSections = this.cbData.sections.filter((s) => s.tenant_id === section.tenant_id);
  }

  // Helper function for attaching supporting documents
  private _attachFileHelper(preSelectedTags?: { id: number }[]) {
    let data;
    if (preSelectedTags) {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
        preSelectedTags,
      };
    } else {
      data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.projectService.currentSelectedProjectId,
        maxFiles: 1,
        allowComment: false,
        verifyFileExtension: true,
      };
    }
    return this.dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed();
  }

  async uploadConstructionDocument(section) {
    this._attachFileHelper([{ id: 99 }]).subscribe(async (filesToAttach: UhatFileReference[]) => {
      if (filesToAttach) {
        if (this.CBApprovalTaskId && (await this.resetReview()) === false) {
          return;
        }
        const file = filesToAttach[0];
        section.construction_documents_ids.push(file.file_id);
        await this.projectTenantService
          .updateProjectTenant(section.tenant_id, {
            construction_documents_ids: `[${section.construction_documents_ids.join(',')}]`,
          })
          .toPromise();
        await this.fileService.addTags(file.file_id, [99]).toPromise();
        section.cb_approval_process.loadData(
          section,
          this.modalService,
          this.fileService,
          this.dateService,
          this.taskActionsService
        );
        await this.refresh();
        this.snackbar.open('Document Has Been Attached');
      }
    });
  }

  async removeConstructionDocument(event) {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Construction Document',
        headerText: 'Remove Construction Document',
        confirmationButtonText: 'Remove',
        descriptionText:
          'Are you sure you want to detach the construction document? It will still be available in Project Files.',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          if (this.CBApprovalTaskId && (await this.resetReview()) === false) {
            return;
          }
          // remove the Construction Document tag from this file (id = 99)
          await this.fileService.removeTags(event.fileId, [99]).toPromise();
          event.section.construction_documents_ids.splice(
            event.section.construction_documents_ids.indexOf(event.fileId),
            1
          );
          await this.projectTenantService
            .updateProjectTenant(event.section.tenant_id, {
              construction_documents_ids: `[${event.section.construction_documents_ids.join(',')}]`,
            })
            .toPromise();
          // this.updateApprovalProcesses.emit();
          await this.refresh();
          this.snackbar.open('Construction Document Has Been Removed');
          // this.section.approval_process
          //   .loadData(this.section, this.modalService, this.fileService, this.dateService, this.taskActionsService)
          //   .then();
        }
      });
  }

  changePercentOrDollar(item) {
    if ([3, 5].indexOf(item.line_type_id) < 0) {
      return;
    }
    this.percentOrDollar === 'dollar' ? (this.percentOrDollar = 'percent') : (this.percentOrDollar = 'dollar');
    this.preferences.setPartialValue({ percentOrDollar: this.percentOrDollar });
  }

  parseNumber(n) {
    return parseFloat(n?.replace(/,/g, ''));
  }
}
