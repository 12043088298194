<app-base-dialog [title]="'Change Order #' + changeOrder.local_index" (closeDialog)="cancel()"></app-base-dialog>
<div
  id="view-change-order-dialog"
  data-test-id="view-change-order-dialog"
  class="view-change-order-dialog project-modal view-co p-0"
>
  <div class="row">
    <div class="col">
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Short Description</h6>
          <h5 class="dkblue m-0 mb-1" [innerHTML]="changeOrder.short_description"></h5>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Summary</h6>
          <p class="dkblue m-0 mb-1" [innerHTML]="changeOrder.summary"></p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Reason</h6>
          <p class="dkblue m-0 mb-1">
            {{ changeOrder?.proposal_request?.reason_name }}
            {{
              changeOrder?.proposal_request?.reason_option
                ? ' | ' + changeOrder?.proposal_request?.reason_option?.name
                : ''
            }}
          </p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Supplier</h6>
          <h5 class="dkblue font-weight-normal m-0">
            {{ changeOrder.company_name }}
          </h5>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Trade</h6>
          <h5 class="dkblue font-weight-normal m-0">
            {{ changeOrder.trade_name }}
          </h5>
        </div>
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Date Initiated</h6>
          <h5 *ngIf="changeOrder.status_id === 2" class="dkblue font-weight-normal m-0">
            {{ changeOrder.approval_datetime | date: 'MMM dd yyyy' }}
          </h5>
          <p
            *ngIf="changeOrder.status_id === 1"
            class="bg-orange px-2 lh-22 white font-weight-semibold br-5 m-0 d-inline-block"
          >
            Proposed
          </p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Proposal Request</h6>
          <h5 class="dkblue m-0 font-weight-normal">PR #{{ changeOrder.proposal_request_local_index }}</h5>
        </div>
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Funding Source</h6>
          <h5 class="dkblue m-0 font-weight-normal">
            {{ changeOrder.funding_source_fee_name || changeOrder.funding_source_name
            }}{{
              changeOrder.funding_source_fee_funding_source_id
                ? ' (' + changeOrder.funding_source_fee_funding_source_abbreviation + ')'
                : ''
            }}
          </h5>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Timeline Change</h6>
          <h5 class="dkblue font-weight-normal m-0">
            + {{ changeOrder.time_change_amount }} {{ changeOrder.time_change_unit }}(s)
          </h5>
        </div>
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Additional Cost</h6>
          <h5 class="m-0 dkblue font-weight-normal">
            {{ changeOrder.cost_change | USDollar }}
          </h5>
        </div>
      </div>
      <div *ngIf="changeOrder.files" class="row mb-4">
        <div class="col text-left">
          <h6 class="dkblue font-weight-semibold">Files</h6>
          <mat-chip-list *ngIf="!fileLoading">
            <app-file-chip *ngFor="let file of changeOrder.files" [file]="file"></app-file-chip>
          </mat-chip-list>
          <mat-spinner
            *ngIf="fileLoading"
            class="d-inline-block align-middle m-0 ml-auto"
            color="accent"
            [diameter]="20"
          ></mat-spinner>
        </div>
      </div>
    </div>
    <div [hidden]="!isReviewItem" class="col">
      <div class="row mb-4">
        <div class="col">
          <h6 class="m-0 font-weight-semibold dkblue mb-2">Review Status</h6>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select>
              <mat-option value="Approve" (click)="updateStatus(TaskReviewStatus.Approved)">
                Approve Change Order
              </mat-option>
              <mat-option value="Reject" (click)="updateStatus(TaskReviewStatus.Rejected)">
                Reject Change Order
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col textarea">
          <h6 class="m-0 font-weight-semibold dkblue mb-2">
            Review Comments {{ approvalStatus === TaskReviewStatus.Rejected ? '*' : '' }}
          </h6>
          <app-editor #editor placeholder="Leave a comment"></app-editor>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="m-0 dkblue">If necessary, please attach any files to support your review.</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-left">
          <div *ngIf="attachedFiles" class="row">
            <div class="col text-left">
              <mat-chip-list class="d-inline-block align-middle px-1">
                <button
                  mat-stroked-button
                  color="accent"
                  type="button"
                  class="mt-2 mb-2 mr-2"
                  (click)="openFileAttachmentDialog()"
                >
                  <mat-icon>attach_file</mat-icon>
                  <span> Attach Files </span>
                </button>
                <app-file-chip *ngFor="let file of attachedFiles" [file]="file"></app-file-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!isReviewItem">
    <div class="col">
      <button mat-button (click)="cancel()" color="accent" class="full-width gray" type="button">Dismiss</button>
    </div>
  </div>
  <div *ngIf="isReviewItem" class="row mb-2">
    <div class="col">
      <button mat-button (click)="cancel()" class="full-width gray" type="button">Cancel</button>
    </div>
    <div class="col">
      <button
        mat-flat-button
        [disabled]="!isValid()"
        (click)="submitApproval()"
        color="accent"
        class="full-width"
        type="button"
      >
        Submit Review
      </button>
    </div>
  </div>
</div>
