import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav/drawer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { orderBy, uniqBy } from 'lodash';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WorkOrderDialogComponent } from 'src/app/components';
import {
  COMPARE,
  MyWorkOrderFilters,
  Order,
  ORDER_FIELD,
  ResourceType,
  WorkOrderStatus,
  Workspace,
} from 'src/app/enums';
import { WorkOrderListFilter } from 'src/app/models';
import {
  AuthService,
  ExportService,
  ModuleService,
  ProgressIndicatorService,
  ResourceTagsService,
  SidenavLinksService,
  WorkOrderService,
} from 'src/app/services';
import {
  APIFilter,
  Floor,
  ResourceTag,
  Topic,
  User,
  WorkOrder,
  WorkOrderListPreferences,
  WorkOrderPriority,
  WorkOrderStatus as WorkOrderStatusType,
  WorkOrderUpdate,
} from 'src/app/types';
import { noTagsTag, PreferenceStorage } from 'src/app/utils';
import { OrderByFieldPipe } from '../../pipes';

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.scss'],
})
export class WorkOrderListComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;

  private _cachedPages = 0;
  private _cursor = '';
  private currentSubscription;
  private _isSearching = false;
  private _SEARCH_CACHE = 5;
  private _PAGE_CACHE = 5;
  private _searchSubscription;
  private _workOrderSearchFields = [
    'assigned_user_first_name',
    'assigned_user_last_name',
    'building_code',
    'building_name',
    'floor_name',
    'floor_code',
    ORDER_FIELD.CODE,
    'priority_name',
    'requester_first_name',
    'requester_last_name',
    'title',
    'topic_category_name',
    'topic_group_name',
    'topic_name',
  ];

  public allWorkOrders: WorkOrder[] = [];
  public allWorkOrdersForFilters: WorkOrder[] = [];
  public allTags: ResourceTag[] = [];
  public allBuildings = [];
  public allFloors = [];
  public allTopics: Topic[] = [];
  public allAssignees: User[] = [];
  public allRequesters: User[] = [];
  public divWidth: number;
  public showFilters = false;
  public endIndex: number;
  public fieldToSortBy: ORDER_FIELD;
  public isFiltering = false;
  public apiWorkOrderFilter: WorkOrderListFilter = new WorkOrderListFilter();
  public pageSizeOptions: number[] = [5, 10, 20, 50, 100, 200];
  public showDesktop: boolean;
  public showIpad: boolean;
  public updateCount: number;
  public workOrderUpdates: WorkOrderUpdate[] = [];
  public sortDirection: Order = Order.DESC;
  public cachedSortDirection = this.sortDirection; // Coupled with Sort direction
  public startIndex = 0;
  public isLoading = false;
  public workOrderFields = [
    'id',
    'code',
    'closed_datetime',
    'module_id',
    'module{id,name}',
    'title',
    'status{id,name}',
    'building{id,name,code}',
    'building_code',
    'building_name',
    'floor{id,name,code}',
    'suite{id,name}',
    'department{id,name}',
    'due_date',
    'priority{id,name,abbreviation}',
    'summary',
    'assigned_user{id,first_name,last_name,email}',
    'assigned_user_first_name',
    'assigned_user_last_name',
    'created_by{id,first_name,last_name}',
    'requester{id,first_name,last_name,user_type_name}',
    'requester_first_name',
    'requester_last_name',
    'created_datetime',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
    'topic_category{id,name,topic_group_id}',
    'topic_group{id,name}',
    'topic_id',
    'updates{work_order,code,created_by_id,created_by{id,first_name,last_name},created_datetime,id,message,work_order_id,work_order_health_type{id,name}}',
    'tags',
    'request_method_id',
    'request_method{id,name,is_enabled,is_default,icon}',
    'latest_update_created_datetime',
    'cms_work_order_id',
  ];
  public workOrderFilterFields = [
    'id',
    'building{id,name,code}',
    'floor{id,name,code}',
    'assigned_user{id,first_name,last_name,email}',
    'requester{id,first_name,last_name,user_type_name}',
    'topic{id,name,workspace{id,name},topic_category{id,name,topic_group_id},topic_group{id,name}}',
  ];

  private getDataSubscription: any;
  public workOrderPriorities: WorkOrderPriority[];
  public workOrderStatuses: WorkOrderStatusType[];
  public workOrderStatusCounts = {};
  public workOrderTotalCount = 0;
  public unassignedWorkOrderCount = 0;
  public overdue = false;
  public search = new FormControl('');
  public dateRange = this.fb.group({
    start: new FormControl(),
    end: new FormControl(),
  });

  public selectedTopics = this.fb.group({
    topics: new FormControl(),
  });

  public selectedBuildings = this.fb.group({
    buildings: new FormControl(),
  });

  public selectedRequesters = this.fb.group({
    requesters: new FormControl(),
  });

  public selectedFloors = this.fb.group({
    floors: new FormControl({ value: '', disabled: true }),
  });

  public selectedTags = this.fb.group({
    tags: new FormControl(),
  });

  public selectedAssignees = this.fb.group({
    assignees: new FormControl(),
  });

  public dateRangeEmpty = true;
  public minCreatedDate: Date | string = '2017-01-02';
  public maxCreatedDate: Date | string = moment().add(1, 'day').format('YYYY-MM-DD'); // now, add 1 to fix the offset

  public isTypingSearchTerm = false;
  public openDialog = false;

  private preferences = new PreferenceStorage<WorkOrderListPreferences>('preferences_work_order_list', {
    fieldToSortBy: ORDER_FIELD.CODE,
    notAssigned: false,
    sortDirection: Order.ASC,
    statusId: WorkOrderStatus.ACTIVE,
    version: 1,
  });

  public get isDispatch() {
    return this.moduleService.workspace_id === Workspace.Dispatch;
  }

  public get MyWorkOrderFilters() {
    return MyWorkOrderFilters;
  }

  get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.moduleService.workspace_id);
  }
  public get filtersApplied(): number {
    let count = 0;
    count += this.selectedTopics?.value?.topics?.length || 0;
    count += this.selectedAssignees?.value?.assignees?.length || 0;
    count += this.selectedRequesters?.value?.requesters?.length || 0;
    count += this.selectedBuildings?.value?.buildings?.length || 0;
    count += this.selectedFloors?.value?.floors?.length || 0;
    count += this.selectedTags?.value?.tags?.length || 0;
    count += this.dateRange?.value?.start || this.dateRange?.value?.end ? 1 : 0;
    return count;
  }

  public get ORDER_BY_FIELD() {
    return ORDER_FIELD;
  }

  // Since code is used by the backend, but, we want to use the numeric sorting,
  // We will use a custom variable to sort, if its code
  public get customizedFieldToSortBy(): string {
    if (this.fieldToSortBy === ORDER_FIELD.CODE) {
      return ORDER_FIELD.SORT_CODE;
    }
    return this.fieldToSortBy;
  }

  public get firstSelectedTag(): ResourceTag {
    return this.allTags.find((t) => Number(t.id) === this.selectedTags.value.tags?.[0]);
  }

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private progressIndicatorService: ProgressIndicatorService,
    private router: Router,
    private sidenavLinksService: SidenavLinksService,
    private snackbar: MatSnackBar,
    private workOrderService: WorkOrderService,
    private moduleService: ModuleService,
    private sortPipe: OrderByFieldPipe,
    private exportService: ExportService,
    private fb: FormBuilder,
    private resourceTagsService: ResourceTagsService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    const mainWorkOrderListWrapper = document.querySelector('#main-work-order-list-wrapper');
    const ps = new PerfectScrollbar(mainWorkOrderListWrapper);

    setTimeout(async () => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.workOrders);
    });

    // warning: we used to have a settimeout here. Angular 10 seems to have fixed the ngChangeBeforeChecked errors, but if they appear on work orders page, add settimeout back in
    await this._getWorkOrderStatus();
    this.getDivWidth();

    const tagFilter: APIFilter[] = [
      { type: 'field', field: `resource_type_ids`, value: ResourceType.WorkOrder, match: 'exact' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: 1 },
    ];
    this.allTags = [noTagsTag, ...(await this.resourceTagsService.getResourceTags(tagFilter).toPromise())];

    this._setPreferences();

    // keep track of subscription, so we can destroy it when the component is destroyed
    this.currentSubscription = this.moduleService?.selectWorkspaceEvent?.subscribe(() => {
      this._goToFirstPageAndRefresh(true);
    });
    await this._goToFirstPageAndRefresh(true);
    this._subscribeToSearch();
  }

  private _setAssigneeList() {
    this.allAssignees = orderBy(
      uniqBy(
        this.allWorkOrdersForFilters
          .filter((workOrder: WorkOrder) => !!workOrder.assigned_user)
          .map((wo) => wo.assigned_user),
        'id'
      ),
      ['first_name', 'last_name']
    );
  }

  private _setRequesterList() {
    this.allRequesters = orderBy(
      uniqBy(
        this.allWorkOrdersForFilters.filter((workOrder: WorkOrder) => !!workOrder.requester).map((wo) => wo.requester),
        'id'
      ),
      ['first_name', 'last_name']
    );
  }

  private _setBuildingList() {
    this.allBuildings = orderBy(
      uniqBy(
        this.allWorkOrdersForFilters.filter((workOrder: WorkOrder) => !!workOrder.building).map((wo) => wo.building),
        'id'
      ),
      ['name']
    );
  }

  private _setTopicList() {
    this.allTopics = orderBy(
      uniqBy(
        this.allWorkOrdersForFilters.filter((workOrder: WorkOrder) => !!workOrder.topic).map((wo) => wo.topic),
        'id'
      ),
      ['topic_group.name', 'topic_category.name', 'name']
    );
  }

  // if the filter is not on, create a new list
  // only changing the assignee, topic, building list for now due to practical uses
  private _setFilteredLists() {
    this._resetIndex();
    if (!this.apiWorkOrderFilter.topics?.length) {
      this._setTopicList();
    }

    if (!this.apiWorkOrderFilter?.assignees.length) {
      this._setAssigneeList();
    }

    if (!this.apiWorkOrderFilter?.requesters.length) {
      this._setRequesterList();
    }

    if (!this.apiWorkOrderFilter.buildings?.length) {
      this._setBuildingList();
    }
  }

  public async buildingChanged() {
    const selectedBuildings: { buildings: number[] } = this.selectedBuildings?.value;
    if (selectedBuildings?.buildings?.length === 1) {
      // enable floors
      this.selectedFloors.enable();
      this.allFloors = orderBy(
        uniqBy(
          this.allWorkOrders.filter((workOrder: WorkOrder) => !!workOrder.floor).map((wo) => wo.floor),
          'id'
        ),
        ['name']
      ).filter((floor: Floor) => selectedBuildings?.buildings?.includes(floor.building_id));
    } else if (this.selectedFloors.enabled) {
      // reset floors and disable
      this.selectedFloors.reset();
      this.selectedFloors.disable();
      this.floorChanged();
    }

    this._changeApiFilters(selectedBuildings);
    await this._getWorkOrderData(true);
    this._setFilteredLists();
  }

  public async floorChanged() {
    const selectedFloors: { floors: number[] } = this.selectedFloors?.value;
    this._changeApiFilters(selectedFloors);
    await this._getWorkOrderData(true);
    this._setFilteredLists();
  }

  public async tagsChanged() {
    const selectedTags: { tags: number[] } = this.selectedTags?.value;
    this._changeApiFilters(selectedTags);
    await this._getWorkOrderData(true);
    this._setFilteredLists();
  }

  public async assigneeChanged() {
    const selectedAssignees: { assignees: number[] } = this.selectedAssignees?.value;
    this._changeApiFilters(selectedAssignees);
    await this._getWorkOrderData(true);
    this._setFilteredLists();
  }

  public async requesterChanged() {
    const selectedRequesters: { requesters: number[] } = this.selectedRequesters?.value;
    this._changeApiFilters(selectedRequesters);
    await this._getWorkOrderData(true);
    this._setFilteredLists();
  }

  public async dateChanged() {
    const startDate = this.dateRange.value.start;
    const endDate = this.dateRange.value.end;
    if (!startDate && !endDate) {
      this.dateRangeEmpty = true;
    } else {
      this.dateRangeEmpty = false;
    }

    this._setFilteredLists();
    await this._getWorkOrderData(true);
  }

  public async topicChanged() {
    const selectedTopics: { topics: number[] } = this.selectedTopics?.value;

    this._changeApiFilters(selectedTopics);
    await this._getWorkOrderData(true);

    this._setFilteredLists();
  }

  ngOnDestroy(): void {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }

    if (this._searchSubscription) {
      this._searchSubscription.unsubscribe();
    }

    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }

    this.isTypingSearchTerm = false;
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  get assignedWorkOrderFilter(): APIFilter[] {
    const whereClause = [
      ...this.baseWorkOrderFilter,
      ...this.statusFieldFilter,
      ...this.topicWorkOrderFilter,
      ...this.buildingWorkOrderFilter,
      ...this.floorWorkOrderFilter,
      ...this.assigneeWorkOrderFilter,
      ...this.requesterWorkOrderFilter,
    ];

    return this.cleanFilterOption([
      ...this.getWhereCondition(whereClause),
      ...this.userWorkerOrderFilter,
      ...this.tagsWorkOrderFilter,
    ]);
  }

  get baseWorkOrderFilter(): APIFilter[] {
    // if module_id == 2, check if you have a sub selection
    if (this.workspace === null || this.isDispatch) {
      const workspaceIds = this.apiWorkOrderFilter.workspaceIds.join('^');
      return workspaceIds ? [{ type: 'field', field: 'module_id', value: workspaceIds }] : [];
    }
    return [{ type: 'field', field: 'module_id', value: (this.workspace?.id || '').toString() }];
  }

  get apiFilter() {
    return this.apiWorkOrderFilter?.searchTerm
      ? this._getWorkOrderSearchFilter(this.apiWorkOrderFilter.searchTerm)
      : this.apiWorkOrderFilter?.notAssigned
      ? this.cleanFilterOption(this.unAssignedWorkOrderFilter)
      : this.cleanFilterOption(this.assignedWorkOrderFilter);
  }

  get _totalCachedWorkOrders(): number {
    return this.pageSize * this._PAGE_CACHE;
  }

  get pageSize(): number {
    return this.pageSizeOptions[3];
  }

  get statusFieldFilter(): APIFilter[] {
    const customFilter: APIFilter[] =
      this.apiWorkOrderFilter.statusId && this.apiWorkOrderFilter.statusId != -1
        ? [
            {
              type: 'field',
              field: 'status_id',
              value: `${this.apiWorkOrderFilter.statusId}`,
            },
          ]
        : [];

    // closed for date range
    if (this.apiWorkOrderFilter.statusId === WorkOrderStatus.CLOSED) {
      if (this.dateRange.get('start').value) {
        const startDate = moment(this.dateRange.get('start').value).format('YYYY-MM-DD');
        customFilter.push({ type: 'field', field: 'closed_datetime', match: '>=', value: startDate });
      }
      if (this.dateRange.get('end').value) {
        const endDate = moment(this.dateRange.get('end').value).add(1, 'days').format('YYYY-MM-DD');
        customFilter.push({ type: 'field', field: 'closed_datetime', match: '<', value: endDate });
      }
    } else {
      // not closed date range
      if (this.dateRange.get('start').value) {
        const startDate = moment(this.dateRange.get('start').value).format('YYYY-MM-DD');
        customFilter.push({ type: 'field', field: 'created_datetime', match: '>=', value: startDate });
      }
      if (this.dateRange.get('end').value) {
        const endDate = moment(this.dateRange.get('end').value).add(1, 'days').format('YYYY-MM-DD');
        customFilter.push({ type: 'field', field: 'created_datetime', match: '<', value: endDate });
      }
    }
    return customFilter;
  }

  get statusIds() {
    return WorkOrderStatus;
  }

  get unAssignedWorkOrderFilter(): APIFilter[] {
    const whereClause = [
      ...this.baseWorkOrderFilter,
      {
        type: 'field',
        field: 'status_id',
        value: `${WorkOrderStatus.CLOSED}`,
        match: COMPARE.NOT_EQUAL,
      },
      {
        type: 'field',
        field: 'status_id',
        value: `${WorkOrderStatus.ON_HOLD}`,
        match: COMPARE.NOT_EQUAL,
      },
      {
        type: 'field',
        field: 'status_id',
        value: `${WorkOrderStatus.PLANNED}`,
        match: COMPARE.NOT_EQUAL,
      },
      { type: 'field', field: 'assigned_user_id', value: 'null' },
      ...this.createdDateRangeWorkOrderFilter,
      ...this.topicWorkOrderFilter,
      ...this.buildingWorkOrderFilter,
      ...this.floorWorkOrderFilter,
      ...this.requesterWorkOrderFilter,
    ];
    return this.cleanFilterOption([
      ...this.getWhereCondition(whereClause),
      ...this.userWorkerOrderFilter,
      ...this.tagsWorkOrderFilter,
    ]);
  }

  get topicWorkOrderFilter() {
    const topicFilter = [];
    if (this.apiWorkOrderFilter?.topics?.length) {
      const topicIds = this.apiWorkOrderFilter?.topics.join('^');
      topicFilter.push({ type: 'field', field: 'topic_id', value: topicIds });
    }
    return topicFilter;
  }

  get assigneeWorkOrderFilter() {
    const assigneFilter = [];
    if (this.apiWorkOrderFilter?.assignees?.length) {
      const assigneeIds = this.apiWorkOrderFilter?.assignees.join('^');
      assigneFilter.push({ type: 'field', field: 'assigned_user_id', value: assigneeIds });
    }
    return assigneFilter;
  }

  get requesterWorkOrderFilter() {
    const requestersFilter = [];
    if (this.apiWorkOrderFilter?.requesters?.length) {
      const requesterIds = this.apiWorkOrderFilter?.requesters.join('^');
      requestersFilter.push({ type: 'field', field: 'requester_id', value: requesterIds });
    }
    return requestersFilter;
  }

  get buildingWorkOrderFilter() {
    const buildingFilter = [];
    if (this.apiWorkOrderFilter?.buildings?.length) {
      const buildingIds = this.apiWorkOrderFilter?.buildings.join('^');
      buildingFilter.push({ type: 'field', field: 'building_id', value: buildingIds });
    }
    return buildingFilter;
  }

  get floorWorkOrderFilter() {
    const floorFilter = [];
    if (this.apiWorkOrderFilter?.floors?.length) {
      const floorIds = this.apiWorkOrderFilter?.floors.join('^');
      floorFilter.push({ type: 'field', field: 'floor_id', value: floorIds });
    }
    return floorFilter;
  }

  get tagsWorkOrderFilter() {
    const tagsFilter = [];
    let tagIds = '';
    if (this.apiWorkOrderFilter?.tags?.length) {
      if (this.apiWorkOrderFilter?.tags.indexOf(-666) > -1) {
        // no tag tag
        tagIds = this.allTags.map((t) => t.id).join(',tag_ids!=');
        tagsFilter.push({ type: 'operator', value: 'AND' });
        tagsFilter.push({ type: 'operator', value: '(' });
        tagsFilter.push({ type: 'field', field: 'tag_ids', value: null });
        tagsFilter.push({ type: 'operator', value: 'OR' });
        tagsFilter.push({ type: 'field', field: 'tag_ids', match: '!=', value: `${tagIds}` });
        tagsFilter.push({ type: 'operator', value: ')' });
      } else {
        tagIds = this.apiWorkOrderFilter?.tags.join(',tag_ids=');
        tagsFilter.push({ type: 'operator', value: 'AND' });
        tagsFilter.push({ type: 'field', field: 'tag_ids', value: `${tagIds}` });
      }
    }
    return tagsFilter;
  }

  get createdDateRangeWorkOrderFilter(): APIFilter[] {
    const dateFilter: APIFilter[] = [];
    if (this.dateRange.get('start').value) {
      const startDate = moment(this.dateRange.get('start').value).format('YYYY-MM-DD');
      dateFilter.push({ type: 'field', field: 'created_datetime', match: '>=', value: startDate });
    }
    if (this.dateRange.get('end').value) {
      const endDate = moment(this.dateRange.get('end').value).add(1, 'days').format('YYYY-MM-DD');
      dateFilter.push({ type: 'field', field: 'created_datetime', match: '<', value: endDate });
    }
    return dateFilter;
  }

  get myFiltersApplied() {
    return [
      this.apiWorkOrderFilter.assignedToCurrentUser,
      this.apiWorkOrderFilter.requestedByCurrentUser,
      this.apiWorkOrderFilter.createdByCurrentUser,
    ].filter((item) => item);
  }

  get userWorkerOrderFilter() {
    const myFilters = [
      this.apiWorkOrderFilter.assignedToCurrentUser,
      this.apiWorkOrderFilter.requestedByCurrentUser,
      this.apiWorkOrderFilter.createdByCurrentUser,
    ];
    let updatedFilter = [];
    const userFilter = [
      { type: 'operator', value: 'AND' },
      { type: 'operator', value: '(' },
      { type: 'field', field: 'requester_id', value: `${this.apiWorkOrderFilter.requestedByCurrentUser || null}` },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'assigned_user_id', value: `${this.apiWorkOrderFilter.assignedToCurrentUser || null}` },
      { type: 'operator', value: 'OR' },
      { type: 'field', field: 'created_by_id', value: `${this.apiWorkOrderFilter.createdByCurrentUser || null}` },
      { type: 'operator', value: ')' },
    ];

    if (this.myFiltersApplied?.length === myFilters?.length) {
      updatedFilter = userFilter;
    } else if (this.myFiltersApplied?.length > 1) {
      let index = 0;
      for (const filter of userFilter) {
        if (
          !['null', 'OR'].includes(filter.value) ||
          (filter.value === 'OR' &&
            userFilter[index + 1]?.value === this.authService.currentUser?.id?.toString() &&
            userFilter[index - 1]?.value === this.authService.currentUser?.id?.toString())
        ) {
          updatedFilter.push(filter);
        }
        index++;
      }
    } else if (this.myFiltersApplied?.length === 1) {
      for (const filter of userFilter) {
        if (['AND', this.authService.currentUser?.id?.toString()].includes(filter.value)) {
          updatedFilter.push(filter);
        }
      }
    }
    return updatedFilter;
  }

  get workspace() {
    return this.moduleService.workspace;
  }

  // All search fields a legal sort field, but can be extended here
  get workOrderSortFields(): string[] {
    // can add more fields here
    return [
      ...this._workOrderSearchFields,
      'created_datetime',
      'due_date',
      'priority_id',
      'latest_update_created_datetime',
    ];
  }

  private _changeApiFilters(updatedFilters: WorkOrderListFilter) {
    this.apiWorkOrderFilter = { ...this.apiWorkOrderFilter, ...updatedFilters };
  }

  public closeAside() {
    this.drawer.close();
  }

  public async resetFilters(updateWorkOrders = true) {
    this.selectedTopics.reset();
    this.selectedAssignees.reset();
    this.selectedRequesters.reset();
    // if (this.selectedFloors.enabled) {
    this.selectedFloors.reset();
    // }
    this.selectedBuildings.reset();
    this.selectedTags.setValue({ tags: [] });
    this.dateRange.reset();
    // clear search
    this.search.setValue('');

    // remove the asignee and topic filters
    this._changeApiFilters({ topics: [], assignees: [], buildings: [], floors: [], tags: [], requesters: [] });

    if (updateWorkOrders) {
      await this._goToFirstPageAndRefresh(false);
    }
  }

  private async _getWorkOrderData(first_load = true, isFiltering = true): Promise<void> {
    this.isFiltering = isFiltering;
    if (this.getDataSubscription) {
      this.getDataSubscription.unsubscribe();
    }

    this.getDataSubscription = this.workOrderService
      .getWorkOrdersAndCursor(
        this.workOrderFields,
        this.apiFilter,
        first_load ? null : this._cursor,
        first_load ? this._totalCachedWorkOrders : this.pageSize,
        this.fieldToSortBy,
        this.sortDirection
      )
      .subscribe((workOrdersData) => {
        // this sets  the total for WO
        this.workOrderTotalCount = workOrdersData.count;

        // set the number of pages cached
        this._cachedPages += Math.ceil(workOrdersData.workOrders.length / this.pageSize);

        // set the cursor
        this._cursor = workOrdersData.cursor;

        this.allWorkOrders = first_load
          ? this._processWorkOrders(workOrdersData.workOrders)
          : [...this.allWorkOrders, ...(this._processWorkOrders(workOrdersData.workOrders) || [])];
      });

    this.workOrderService.getWorkOrders(this.workOrderFilterFields, this.apiFilter).subscribe((workOrders) => {
      this.allWorkOrdersForFilters = workOrders;

      // Updates the items that can be filtered
      if (
        this.filtersApplied ||
        this.myFiltersApplied?.length ||
        (!this.apiWorkOrderFilter.topics?.length &&
          !this.apiWorkOrderFilter?.assignees.length &&
          !this.apiWorkOrderFilter?.buildings.length &&
          !this.apiWorkOrderFilter?.requesters.length)
      ) {
        if (!this.apiWorkOrderFilter.topics?.length) {
          this._setTopicList();
        }
        if (!this.apiWorkOrderFilter?.assignees.length) {
          this._setAssigneeList();
        }
        if (!this.apiWorkOrderFilter?.requesters.length) {
          this._setRequesterList();
        }

        if (!this.apiWorkOrderFilter?.buildings.length) {
          this._setBuildingList();
        }
      }
      this.isFiltering = false;
    });
  }

  private _getWorkOrderSearchFilter(searchTerm: string): APIFilter[] {
    const lastIndexOfDash = searchTerm.lastIndexOf('-');
    const buildingCode = searchTerm.slice(0, lastIndexOfDash);
    const floor = searchTerm.slice(lastIndexOfDash + 1);
    const searchTerms = searchTerm?.split(' ').filter((term: string) => term);
    const searchTermsCount = searchTerms.length;

    const buildingAndNumericalFloorCombo =
      buildingCode && !isNaN(parseInt(floor?.trim(), 10))
        ? [
            { type: 'operator', value: '(' },
            {
              field: 'building_code',
              type: 'field',
              value: buildingCode?.toLowerCase()?.trim(),
            },
            { type: 'operator', value: 'AND' },
            {
              field: 'floor_name',
              type: 'field',
              value: `${parseInt(floor.trim(), 10)}`,
              match: COMPARE.ANY,
            },

            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
            { type: 'operator', value: '(' },
            {
              field: 'building_code',
              type: 'field',
              value: buildingCode?.toLowerCase()?.trim(),
            },
            { type: 'operator', value: 'AND' },
            {
              field: 'floor_code',
              type: 'field',
              value: `${parseInt(floor.trim(), 10)}`,
              match: COMPARE.ANY,
            },

            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
          ]
        : [];

    const buildingAndAlphaFloorCombo =
      buildingCode && isNaN(parseInt(floor?.trim(), 10)) && floor
        ? [
            { type: 'operator', value: '(' },
            {
              field: 'building_code',
              type: 'field',
              value: buildingCode?.toLowerCase()?.trim(),
            },
            { type: 'operator', value: 'AND' },
            {
              field: 'floor_name',
              type: 'field',
              value: floor?.toLowerCase()?.trim(),
              match: COMPARE.ANY,
            },

            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
            { type: 'operator', value: '(' },
            {
              field: 'building_code',
              type: 'field',
              value: buildingCode?.toLowerCase()?.trim(),
            },
            { type: 'operator', value: 'AND' },
            {
              field: 'floor_code',
              type: 'field',
              value: floor?.toLowerCase()?.trim(),
              match: COMPARE.ANY,
            },

            { type: 'operator', value: ')' },
            { type: 'operator', value: 'OR' },
          ]
        : [];
    const filter = [
      ...(this.apiWorkOrderFilter?.notAssigned
        ? this.unAssignedWorkOrderFilter
        : this.cleanFilterOption(this.assignedWorkOrderFilter)),
    ];
    if (filter[0]) filter.push({ type: 'operator', value: 'AND' });
    return [
      ...filter,
      ...[
        { type: 'operator', value: '(' },
        ...buildingAndNumericalFloorCombo,
        ...buildingAndAlphaFloorCombo,
        ...searchTerms
          ?.map((value: string) =>
            this._workOrderSearchFields?.reduce((previousFilters: APIFilter[], currentField: string, index: number) => {
              const fieldFilter = {
                type: 'field',
                field: currentField,
                value: value.replace(/-$/, ''),
                match: COMPARE.ANY,
              };
              if (index !== 0) {
                return [...previousFilters, { type: 'operator', value: 'OR' }, fieldFilter];
              }
              return [...previousFilters, fieldFilter];
            }, [])
          )
          ?.reduce(
            (previousFilters: APIFilter[], currentFilters: APIFilter[], index: number) =>
              index !== searchTermsCount - 1
                ? [...previousFilters, ...currentFilters, { type: 'operator', value: 'OR' }]
                : [...previousFilters, ...currentFilters],
            []
          ),
        { type: 'operator', value: ')' },
      ],
    ];
  }

  private async _getWorkOrderStatus() {
    const workOrderStatuses =
      (await this.workOrderService
        .getWorkOrderStatuses(['id', 'name'], null, null, 'id', Order.ASC, true)
        .toPromise()) || [];

    this.workOrderStatuses = workOrderStatuses.filter((status) => status.id !== WorkOrderStatus.READY_FOR_PICKUP);
  }

  private async _goToFirstPageAndRefresh(displayProgressIndicator = false) {
    if (!displayProgressIndicator) {
      this.isFiltering = true;
    }

    this._resetIndex();

    // bust the cache
    this.allWorkOrders = [];
    this._cachedPages = 0;
    // reset cursor
    this._cursor = '';

    // reset search bool
    this._isSearching = false;
    this.isTypingSearchTerm = false;
    await this.refresh(displayProgressIndicator);
  }

  private _processWorkOrders(workOrders: WorkOrder[]): WorkOrder[] {
    // map through work orders and pick out the latest update
    const workOrderUpdates = [];
    for (const workOrder of workOrders) {
      if (workOrder.updates.length === 1) {
        // the work order updates to be passed on
        workOrderUpdates.push(...workOrder.updates);
        // for the latest update
        workOrder.update = workOrder.updates[0];
      } else if (workOrder.updates.length > 1) {
        // pick the newest
        const orderedUpdates = workOrder.updates.sort((a, b) => {
          return moment(moment(b.created_datetime)).diff(moment(a.created_datetime));
        });
        workOrder.update = orderedUpdates[0];
        workOrderUpdates.push(...workOrder.updates);
      }

      // afterwards, sort by date, set the default message view to less
      this.workOrderUpdates = workOrderUpdates
        .sort((a, b) => {
          return moment(moment(b.created_datetime)).diff(moment(a.created_datetime));
        })
        .map((workOrderUpdate: WorkOrderUpdate) => {
          workOrderUpdate.collapseMessageView = true;
          workOrderUpdate.showCollapseMessageViewButton = false;
          return workOrderUpdate;
        });

      // add a sortCode
      const sortCode = Number(workOrder?.code?.replace(/wo/i, '')?.replace(/-/i, ''));
      if (sortCode) {
        workOrder.sortCode = sortCode;
      }
    }
    return workOrders;
  }

  private _resetIndex() {
    this.startIndex = 0;
    this.endIndex = this.pageSize;
    this.paginator?.firstPage();
  }

  private _setPreferences() {
    const preferences = this.preferences.currentValue;
    this.apiWorkOrderFilter.notAssigned = preferences.notAssigned;

    // If not assigned clear status Id
    if (this.apiWorkOrderFilter?.notAssigned) {
      this.apiWorkOrderFilter.statusId = undefined;
    } else {
      this.apiWorkOrderFilter.statusId = preferences.statusId;
    }
    this.apiWorkOrderFilter.assignedToCurrentUser = preferences.assignedToCurrentUser;
    this.apiWorkOrderFilter.requestedByCurrentUser = preferences.requestedByCurrentUser;
    this.apiWorkOrderFilter.createdByCurrentUser = preferences.createdByCurrentUser;
    this.sortDirection = preferences.sortDirection;
    this.fieldToSortBy = preferences.fieldToSortBy;

    // local storage sort field buster
    if (!this.workOrderSortFields.includes(this.fieldToSortBy)) {
      this.updateSortByField(ORDER_FIELD.CODE);
    }
  }

  private _subscribeToSearch() {
    this._searchSubscription = this.search.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        map((value: string) => {
          this.apiWorkOrderFilter.searchTerm = value;
          if (value) {
            this.isTypingSearchTerm = true;
            this._isSearching = true;
            this.workOrderService
              .searchWorkOrders(
                this.workOrderFields,
                this._getWorkOrderSearchFilter(value.toLowerCase().trim()),
                null,
                this.pageSize * this._SEARCH_CACHE, // So we can get extra data
                this.fieldToSortBy,
                this.sortDirection
              )
              .subscribe((result) => {
                this.workOrderTotalCount = result.count;
                this.allWorkOrders = result.workOrders;
                this.allWorkOrders = this._processWorkOrders(result.workOrders);
                this._cursor = result.cursor;
                this.isTypingSearchTerm = false;
              });
            this.workOrderService
              .getWorkOrders(this.workOrderFilterFields, this._getWorkOrderSearchFilter(value.toLowerCase().trim()))
              .subscribe((workOrders) => {
                this.allWorkOrdersForFilters = workOrders;
                this._setFilteredLists();
              });
          } else {
            if (this._isSearching) {
              this._goToFirstPageAndRefresh();
            }
          }
        })
      )
      .subscribe();
  }

  public get dateRangeStart() {
    return this.dateRange.get('start');
  }

  public get dateRangeEnd() {
    return this.dateRange.get('end');
  }

  public async resetDateRange() {
    this.dateRange.get('start').reset();
    this.dateRange.get('end').reset();
    await this._getWorkOrderData(true);
  }

  public clearSearchString() {
    if (this._isSearching) {
      this._isSearching = false;
      this.search.setValue('');
      this.apiWorkOrderFilter.searchTerm = '';
    }
    this._goToFirstPageAndRefresh(true);
  }

  public async pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
    // Scroll to top
    const element = document.querySelector('#main-work-order-list-wrapper');
    element.scrollTop = 0;
    // If it's searching, it has about SEARCH CACHE times cached data
    // if the cursor is null, we have cached all the results
    // refresh, if the direction has changed
    if (
      this.cachedSortDirection !== this.sortDirection ||
      (this._cachedPages <= event.pageIndex + 1 && !this._isSearching && this._cursor !== null)
    ) {
      // fetch a bunch of new data
      await this.refresh();
    } else if (this._cursor !== null) {
      // load more data in the background, until, we have loaded everything, then do nothing
      await this._getWorkOrderData(false, false);
    }
  }

  public async refresh(displayProgressIndicator = false) {
    this.cachedSortDirection = this.sortDirection; // to maintain the same direction when doing background caching
    if (!this.workOrderStatuses) {
      await this._getWorkOrderStatus();
    }

    if (typeof this.workspace !== 'undefined') {
      if (displayProgressIndicator) {
        this.progressIndicatorService.openAwaitIndicatorModal();
        this.progressIndicatorService.updateStatus();
      }
      await this.getWorkOrderStatusCounts();

      // load work orders
      await this._getWorkOrderData(true, false);

      if (displayProgressIndicator) {
        this.closeProgressIndicator();
      }
    }
  }

  private closeProgressIndicator() {
    setTimeout(() => {
      if (this.getDataSubscription?.closed) {
        this.progressIndicatorService.close();
        this.isLoading = false;
      } else {
        this.closeProgressIndicator();
      }
    }, 500);
  }

  // Gets the count of each project status, so they can be displayed in the filter buttons
  private async getWorkOrderStatusCounts() {
    // base dispatch, shows all, others don't
    const baseWorkOrderFilter = this.cleanFilterOption(this.baseWorkOrderFilter);
    const userWorkerOrderFilter = this.cleanFilterOption(this.userWorkerOrderFilter);

    this.unassignedWorkOrderCount = await this.workOrderService
      .getWorkOrdersCount(this.cleanFilterOption(this.unAssignedWorkOrderFilter))
      .toPromise();

    for (const workOrderStatus of this.workOrderStatuses) {
      let workOrderStatusFilter =
        workOrderStatus.id !== -1
          ? [
              ...baseWorkOrderFilter,
              baseWorkOrderFilter.length > 0 ? { type: 'operator', value: 'AND' } : null,

              { type: 'field', field: 'status_id', value: `${workOrderStatus.id}` },

              userWorkerOrderFilter.length > 0 ? { type: 'operator', value: 'AND' } : null,
              ...userWorkerOrderFilter,
            ]
          : [
              ...baseWorkOrderFilter,
              baseWorkOrderFilter.length > 0 ? { type: 'operator', value: 'AND' } : null,
              ...userWorkerOrderFilter,
            ];

      workOrderStatusFilter = this.cleanFilterOption(workOrderStatusFilter.filter((v) => v != null));
      this.workOrderStatusCounts[workOrderStatus.id] = await this.workOrderService
        .getWorkOrdersCount(workOrderStatusFilter)
        .toPromise();
    }
  }

  cleanFilterOption(filter: APIFilter[]): APIFilter[] {
    //If first filter command is the AND operator without any preceding condition.
    let size = filter.length;
    if (size > 0 && filter[0].type == 'operator' && filter[0].value == 'AND') {
      filter.shift();
    }

    size = filter.length;
    if (size > 0 && filter[size - 1].type == 'operator' && filter[size - 1].value == 'AND') {
      filter.pop();
    }
    return filter;
  }

  getWhereCondition(whereClause: APIFilter[]) {
    const andClause = { type: 'operator', value: 'AND' };
    let whereCondition = [];
    for (const condition of whereClause) {
      whereCondition.push(condition);
      whereCondition.push(andClause);
    }
    return this.cleanFilterOption(whereCondition);
  }

  public updateSortByField(field: ORDER_FIELD) {
    if (field === this.fieldToSortBy) {
      this.sortDirection = this.sortDirection === Order.DESC ? Order.ASC : Order.DESC;
    } else {
      this.sortDirection = Order.ASC;
    }
    this.fieldToSortBy = field;
    this.preferences.setPartialValue({ sortDirection: this.sortDirection, fieldToSortBy: this.fieldToSortBy });

    // only refetch if the total cached worked order is greater than the work order count
    if (this.workOrderTotalCount > this._totalCachedWorkOrders) {
      this._goToFirstPageAndRefresh();
    }
  }

  public updateNotAssignedFilter() {
    // filter_work_orders_by_not_assigned
    this.apiWorkOrderFilter.statusId = null;
    this.apiWorkOrderFilter.notAssigned = true;
    this.apiWorkOrderFilter.assignedToCurrentUser = null;

    this.preferences.setPartialValue({
      statusId: this.apiWorkOrderFilter.statusId,
      notAssigned: this.apiWorkOrderFilter.notAssigned,
    });
    this.updateFilter();
  }

  public updateWorkspaces(workspaceIds: number[]) {
    this.apiWorkOrderFilter.workspaceIds = workspaceIds;
    this.updateFilter();
  }

  public changeFilterStatus(filterStatusId: number) {
    if (this.apiWorkOrderFilter.notAssigned) {
      this.apiWorkOrderFilter.notAssigned = false;
      this.preferences.setPartialValue({ notAssigned: this.apiWorkOrderFilter.notAssigned });
    }

    this.apiWorkOrderFilter.statusId = filterStatusId;
    this.preferences.setPartialValue({ statusId: this.apiWorkOrderFilter.statusId });
    this.updateFilter();
  }

  public async filterByCurrentUser(field: MyWorkOrderFilters) {
    const deselectAll = !!this.myFiltersApplied?.length;
    const fieldsToUpdate =
      field === MyWorkOrderFilters.ToggleAll
        ? [
            MyWorkOrderFilters.AssignedToUser,
            MyWorkOrderFilters.CreatedByUser,
            MyWorkOrderFilters.RequestedByUser,
          ].filter((f) => (deselectAll && this.apiWorkOrderFilter[f]) || (!deselectAll && !this.apiWorkOrderFilter[f]))
        : [field];

    for (const fieldToUpdate of fieldsToUpdate) {
      this.apiWorkOrderFilter[fieldToUpdate]
        ? (this.apiWorkOrderFilter[fieldToUpdate] = null)
        : (this.apiWorkOrderFilter[fieldToUpdate] = this.authService.getLoggedInUser().id);

      this.preferences.setPartialValue({ [fieldToUpdate]: this.apiWorkOrderFilter[fieldToUpdate] });
    }

    await this._getWorkOrderData();
  }

  public formatDate(date: Date): string {
    return moment(date).format('ddd, MMM DD, YYYY').toString();
  }

  public formatDateTime(date: Date): string {
    return moment(date).format('llll').toString();
  }

  public isOverdue(date: Date): boolean {
    if (moment() > moment(date)) {
      return true;
    } else {
      return false;
    }
  }

  public formatCreatedDate(date: Date): string {
    // return moment(date).format('ddd,  MMM DD, YYYY • h:mm a').toString();
    return moment(date).format('ddd,  MMM DD, YYYY').toString();
  }

  /**r
   * Force Pipe Refresh
   */
  public async updateFilter() {
    // reset any filters
    await this.resetFilters(false);

    this.apiWorkOrderFilter = { ...this.apiWorkOrderFilter };
    if (this._isSearching) {
      this.clearSearchString();
    } else {
      await this._goToFirstPageAndRefresh(true);
    }
  }

  openNewWorkOrderDialog() {
    this.openDialog = true;
    this.dialog
      .open(WorkOrderDialogComponent, {
        width: '780px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((createdWorkOrder) => {
        this.openDialog = false;
        if (createdWorkOrder) {
          this.snackbar
            .open('Work Order Created!', 'View Work Order', { duration: 8000 })
            .onAction()
            .subscribe(() => {
              this.router.navigateByUrl(`/work-orders/${createdWorkOrder.id}`);
            });
          // reset statusId and bust the cache
          this.apiWorkOrderFilter.statusId = WorkOrderStatus.ACTIVE;
          this._goToFirstPageAndRefresh();
        }
      });
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  // ------------------------------------------------------------------
  // exporting functionality
  // ------------------------------------------------------------------

  public async exportData() {
    this.progressIndicatorService.openAwaitIndicatorModal();
    this.progressIndicatorService.updateStatus('Exporting Data..');

    const data = await this.getExportData();

    // get the filter options
    let workspace;
    if (this.isDispatch) {
      if (this.apiWorkOrderFilter?.workspaceIds.length === 1) {
        workspace =
          ExportService.getWorkspaceNameFromEnum(Workspace, this.apiWorkOrderFilter.workspaceIds[0]).toLowerCase() +
          '_';
      } else if (this.apiWorkOrderFilter?.workspaceIds.length === 0) {
        workspace = `all_workspaces_`;
      } else if (this.apiWorkOrderFilter?.workspaceIds.length > 1) {
        workspace = `multiple_workspaces_`;
      }
    } else {
      workspace = this.moduleService.workspace?.name?.toLowerCase() + '_';
    }
    const sortField = this.fieldToSortBy ? `_${this.fieldToSortBy.replace(/\./g, '_')}` : null;

    this.exportService.exportDataWithConfirmation(
      data,
      `${workspace || ''}work_order_list_export_${moment().format('MM/DD/YY')}${sortField || ''}.csv`,
      'Confirm Data Export',
      `Data export will use the currently selected filter settings. Are you sure you wish to continue?`
    );
    this.progressIndicatorService.close();
  }

  // format the current displayed data in csv
  private async getExportData() {
    // since this page is now paginated, load all WOs that would be visible
    let filteredWorkOrders = this.allWorkOrders;
    if (this.allWorkOrders?.length >= this._totalCachedWorkOrders) {
      filteredWorkOrders = await this.workOrderService
        .getWorkOrders(this.workOrderFields, this.apiFilter, null, this.fieldToSortBy, this.sortDirection)
        .toPromise();
    }

    const dataToExport = this.sortPipe.transform(filteredWorkOrders, this.fieldToSortBy, this.sortDirection);
    const dataToReturn: string[] = [
      'Work Order #, Building, Floor, Suite, Department, Date Opened, Date Closed, Days Open, Status, Assigned To, Requested By, Group, Category, Topic, CMS WO#, Requestor Type',
    ];

    for (const entry of dataToExport) {
      const dateOpened = entry.created_datetime ? moment(entry.created_datetime).format('MM/DD/YYYY HH:mm:ss') : null;
      const dateClosed = entry.closed_datetime ? moment(entry.closed_datetime).format('MM/DD/YYYY HH:mm:ss') : null;
      let daysOpen = dateClosed
        ? moment(entry.closed_datetime).startOf('day').diff(moment(entry.created_datetime).startOf('day'))
        : moment().startOf('day').diff(moment(entry.created_datetime).startOf('day'));
      daysOpen = Math.floor(daysOpen / (1000 * 60 * 60 * 24));
      const assignedTo = entry.assigned_user
        ? `${entry.assigned_user.first_name || ''} ${entry.assigned_user.last_name || ''}`
        : null;
      const requestedBy = entry?.requester
        ? `${entry.requester.first_name || ''} ${entry.requester.last_name || ''}`
        : null;

      const topicGroup = entry?.topic?.topic_group?.name ?? null;

      const topicCategory = entry?.topic?.topic_category?.name ?? null;

      const topic = entry?.topic?.name ?? null;

      const cms_work_order_id = entry?.cms_work_order_id;

      // sanitize and push the data
      dataToReturn.push(
        this.exportService.sanitizeItems([
          entry.code || '-',
          entry.building?.code || '-',
          entry.floor?.code || '-',
          entry.suite?.name || '-',
          entry.department?.name || '-',
          dateOpened || '-',
          dateClosed || '-',
          daysOpen || '-',
          entry.status?.name || '-',
          assignedTo || '-',
          requestedBy || '-',
          // entry.summary || '-',
          topicGroup || '-',
          topicCategory || '-',
          topic || '-',
          cms_work_order_id || '-',
          entry?.requester?.user_type_name || '-',
        ])
      );
    }
    return dataToReturn;
  }

  deselectAllTags() {
    this.selectedTags.setValue({ tags: [] });
    this.tagsChanged();
  }
}
