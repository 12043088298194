<app-base-dialog [title]="'Add or Remove'" (closeDialog)="close()"></app-base-dialog>
<div data-test-id="user-select-modal" id="user-select-modal" class="user-select-modal project-modal modal-wide">
  <div class="row dialog-form">
    <div class="col">
      <!-- Search form -->
      <mat-form-field appearance="outline" class="full-width">
        <mat-icon class="dkblue" matPrefix>search</mat-icon>
        <input
          matInput
          matNativeControl
          #search="matInput"
          [formControl]="searchInputField"
          placeholder="Search People"
          autocomplete="off"
        />

        <mat-icon *ngIf="searchInput && !isTypingSearchTerm" (click)="clearSearchInput()" class="gray pointer" matSuffix
          >cancel</mat-icon
        >
        <mat-progress-spinner
          matSuffix
          color="accent"
          *ngIf="searchInput && isTypingSearchTerm"
          mode="indeterminate"
          diameter="20"
        ></mat-progress-spinner>
      </mat-form-field>
    </div>
    <div class="col-4 pl-0" *ngIf="!onlyShowAllowedUserTypes">
      <mat-form-field appearance="outline" class="full-width">
        <mat-select [value]="selectedSearchUserId.value" [formControl]="selectedSearchUserId">
          <mat-option *ngFor="let ut of userTypes" [value]="ut.value">{{ ut.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex flex-column">
    <h6 class="dkgray font-weight-normal m-0 mt-1 mb-2 ml-3">
      {{ getUserSelectionNumberText() }}
    </h6>
    <mat-selection-list class="full-width" (scroll)="getNextBatch($event)">
      <ng-container *ngIf="userStream | async as preDeSelectedUsers">
        <!-- PRESELECTED-->
        <!-- <mat-list-option
          *ngFor="let preSelectedUser of preSelectedUsers"
          [value]="preSelectedUser"
          [selected]="userIsSelected(preSelectedUser)"
          (click)="toggleUserSelection(preSelectedUser)"
          [disabled]="preSelectedUser?.canNotDeselect || notAllowed || cantBeChanged(preSelectedUser)"
        > -->
        <mat-list-option
          *ngFor="let preSelectedUser of preSelectedUsers"
          [value]="preSelectedUser"
          [selected]="userIsSelected(preSelectedUser)"
          (click)="toggleUserSelection(preSelectedUser)"
          [disabled]="canNotDeselectPreselectedUser(preSelectedUser)"
        >
          <div class="d-flex align-items-center">
            <app-profile-thumbnail class="d-inline-block" [userId]="preSelectedUser.id"></app-profile-thumbnail>
            <span class="mr-auto py-2">
              <p class="mb-0 dkblue text-truncate font-weight-semibold mt-1 lh-20">
                {{ preSelectedUser.first_name }} {{ preSelectedUser.last_name }}
                <!-- <small class="dkblue opacity8 ml-1 text-truncate">{{ user.email }}</small> -->
              </p>
              <p class="dkgray text-truncate mb-0 mb-1 lh-20">
                {{
                  preSelectedUser.department_name
                    ? preSelectedUser.department_name
                    : preSelectedUser.company_name
                    ? preSelectedUser.company_name
                    : preSelectedUser.email
                }}
              </p>
            </span>
          </div>
        </mat-list-option>
        <hr
          class="my-2"
          *ngIf="preSelectedUsers.length > 0 && preDeSelectedUsers.length > 0 && !searchInput && !isTypingSearchTerm"
        />
        <!--PRE-UNSELECTED-->
        <mat-list-option
          *ngFor="let user of preDeSelectedUsers | userList: excludeUserType; let i = index; trackBy: trackByIndex"
          [value]="user"
          [selected]="userIsSelected(user)"
          (click)="toggleUserSelection(user)"
          [disabled]="notAllowed || cantBeChanged(user)"
        >
          <div class="d-flex align-items-center">
            <app-profile-thumbnail class="d-inline-block" [userId]="user.id"></app-profile-thumbnail>
            <span class="mr-auto py-2">
              <p class="mb-0 dkblue text-truncate font-weight-semibold mt-1 lh-20">
                {{ user.first_name }} {{ user.last_name }}
                <!-- <small class="dkblue opacity8 ml-1 text-truncate">{{ user.email }}</small> -->
              </p>
              <p class="dkgray text-truncate mb-0 mb-1 lh-20">
                {{ user.department_name ? user.department_name : user.company_name ? user.company_name : user.email }}
              </p>
            </span>

            <h6 *ngIf="!searchUserTypeId" class="mb-0 ml-3 pl-3">
              <span
                *ngIf="user.user_type_id === userType.Staff"
                class="px-1 br-4 white bg-orange font-weight-semibold d-inline-block align-middle"
                >S</span
              >
              <span
                *ngIf="user.user_type_id === userType.Tenant"
                class="px-1 br-4 white bg-gray font-weight-semibold d-inline-block align-middle"
                >T</span
              >
              <span
                *ngIf="user.user_type_id === userType.Vendor"
                class="px-1 br-4 white bg-ltblue font-weight-semibold d-inline-block align-middle"
                >V</span
              >
            </h6>
          </div>
        </mat-list-option>
        <div *ngIf="isFetching" class="row">
          <div class="col d-flex justify-content-center">
            <mat-progress-spinner matSuffix color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
          </div>
        </div>
        <div *ngIf="!isFetching" class="no-items-panel">
          <div class="no-items-panel-content text-center pb-5">
            <p class="m-0 mb-3 dkgray font-weight-normal mt-4 lh-24 mx-5">
              If the person you're looking for is not listed here, consider changing the filter above or check the
              directory to confirm the account exists.
            </p>
          </div>
        </div>
      </ng-container>
    </mat-selection-list>
  </div>
</div>
<div class="row mt-3 align-items-center">
  <div class="col d-flex">
    <button *ngIf="createUser?.guestUser" (click)="createUserType()" mat-stroked-button color="primary" type="button">
      + Guest Attendee
    </button>
  </div>
  <div class="col-auto text-right">
    <button mat-button *ngIf="!processing" (click)="close()" class="gray mr-2">Cancel</button>
    <button
      mat-flat-button
      color="success"
      [disabled]="selectedUsers.length < 1 && selectStyleIsAddOnly"
      (click)="submit()"
    >
      {{ data.title ? data.title : 'Submit' }}
    </button>
  </div>
</div>
