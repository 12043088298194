import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort';
import * as moment from 'moment';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DispatchRequestComponent } from 'src/app/components';
import { DISPATCH_FILTER_KEY } from 'src/app/enums';
import {
  AuthService,
  DispatchService,
  ModalService,
  NotificationService,
  ProgressIndicatorService,
  SearchService,
  SidenavLinksService,
  SidenavService,
  SocketService,
} from 'src/app/services';
import { DispatchDraft, DispatchFilter, DispatchItem, Request, SubmittedType, WorkOrder } from 'src/app/types';

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss'],
})
export class DispatchComponent implements OnInit, OnDestroy {
  @ViewChild(DispatchRequestComponent) private _dispatchRequest: DispatchRequestComponent;
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('drawer') drawer: any;

  public asideOpen = false;
  public dispatchDrafts: DispatchDraft[] = [];
  public dispatchFormGroup = this._fb.group({});
  public divWidth: number;
  public endIndex: number;
  public filter: DispatchFilter = {
    view: 'today',
    submitted: null,
    page: 0,
    search: '',
    order: 'created_datetime',
    direction: 'desc',
  };
  public searching = false;
  public filterKeys = DISPATCH_FILTER_KEY;
  public fieldToSortBy: string;
  public items: DispatchItem[] = [];
  public isLoading = false;
  public total = 0;
  public newRequestor: any;
  public pageSizeOptions: number[] = [5, 10, 20, 100, 200];
  public requests: Request[] = [];
  public searchTerm = new FormControl('');
  public showClosed = false;
  public sortDirection: SortDirection;
  public startIndex = 0;
  public workOrders: WorkOrder[] = [];

  // CountFields
  public activeWorkOrdersCount = 0;
  public closedWorkOrdersCount = 0;
  public draftsCount = 0;
  public noCommsCount = 0;
  public onHoldWorkOrdersCount = 0;
  public readyForPickupWorkOrdersCount = 0;
  public pendingRequestsCount = 0;
  public todaysItemsCount = 0;
  public transferredCount = 0;

  constructor(
    private _authService: AuthService,
    private _dispatchService: DispatchService,
    private _fb: FormBuilder,
    private _modalService: ModalService,
    public notificationService: NotificationService,
    private _progressIndicatorService: ProgressIndicatorService,
    public searchService: SearchService,
    private _sidenavService: SidenavService,
    private _sidenavLinksService: SidenavLinksService,
    private _socketService: SocketService
  ) {}

  async ngOnInit() {
    this.endIndex = this.pageSize;
    setTimeout(async () => {
      this._sidenavLinksService.selectLink(this._sidenavLinksService.dispatch);
    });

    this.searchTerm.valueChanges
      .pipe(
        debounceTime(500),
        switchMap(() => {
          this.searching = true;
          this.filter.search = this.searchTerm.value;
          return this._dispatchService.getDispatch(this.filter);
        })
      )
      .subscribe((result) => {
        this.items = result.items;
        this.total = result.count;
        this.searching = false;
        this._goToFirstPage();
      });

    this._setPreferences();

    await this.refresh();

    // Define what to do when new dispatch item comes in via web socket
    this._socketService.socket.on('dispatchItem', async (receivedPacket) => {
      if (receivedPacket.created_by_id !== +this._authService.currentUser.id) {
        this.refresh();
        if (+this._dispatchRequest?.dispatchItem?.id === +receivedPacket.id && this.asideOpen) {
          if (receivedPacket.event === 'delete') {
            this._modalService
              .openConfirmationDialog({
                titleBarText: 'Warning: Item Deleted',
                descriptionText:
                  'Another user has deleted the item you are currently editing. The drawer will now close.',
                hideCancelButton: true,
                confirmationButtonText: 'OK',
              })
              .subscribe(() => {
                this.closeItem();
              });
          } else if (receivedPacket.event === 'update') {
            this._modalService
              .openConfirmationDialog({
                titleBarText: 'Warning: Item Updated',
                descriptionText:
                  'Another user has updated the item you are currently editing. If you save your changes, it will discard their changes and save yours instead.',
                hideCancelButton: true,
                confirmationButtonText: 'OK',
              })
              .subscribe();
          }
        }
      }
    });

    this.drawer.closedStart.subscribe(() => {
      this.dispatchRequest.scrollToTop();
    });
  }

  ngOnDestroy(): void {
    this._socketService?.socket?.removeEventListener('dispatchItem');
  }

  get dispatchRequest(): DispatchRequestComponent {
    return this._dispatchRequest;
  }

  get displayCount(): number {
    return (
      (this.pendingRequestsCount || 0) +
      (this.onHoldWorkOrdersCount || 0) +
      (this.activeWorkOrdersCount || 0) +
      (this.draftsCount || 0) +
      (this.readyForPickupWorkOrdersCount || 0)
    );
  }

  get hasASelctedItem(): boolean {
    return this._dispatchRequest?.dispatchItem ? true : false;
  }

  get pageSize(): number {
    return this.pageSizeOptions[2];
  }
  get startPage(): number {
    return this?.startIndex || 1;
  }
  get isSidenavClosed(): boolean {
    return this._sidenavService?.isSidenavClosed;
  }

  private _addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    preferences[key] = value;
    localStorage.setItem('preferences', JSON.stringify(preferences));
    return preferences;
  }

  private _closeProgress() {
    this.isLoading = false;
    this._progressIndicatorService.close();
  }

  private async _getCounts() {
    const counts = await this._dispatchService.getDispatchCounts().toPromise();
    this.closedWorkOrdersCount = counts.closedWorkOrders;
    this.activeWorkOrdersCount = counts.activeWorkOrders;
    this.onHoldWorkOrdersCount = counts.onHoldWorkOrders;
    this.readyForPickupWorkOrdersCount = counts.readyForPickupWorkOrders;
    this.draftsCount = counts.allDrafts;
    this.noCommsCount = counts.noCommWorkOrders;
    this.pendingRequestsCount = counts.pendingRequests;
    this.todaysItemsCount = counts.todaysWorkOrders + counts.todaysRequests;
    this.transferredCount = counts.transferredWorkOrders + counts.transferredRequests;
  }

  private _goToFirstPage() {
    this.paginator?.firstPage();
  }

  // opens the create draft sidebar
  private _openItem() {
    this.asideOpen = true;
  }

  private _openProgress() {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus('Loading...');
  }

  private _setPreferences() {
    const preferences = JSON.parse(localStorage.getItem('preferences'));
    if (preferences) {
      this.filter.order = preferences.sort_dispatch_by_field || null;
      this.filter.direction = preferences.dispatch_sort_direction || null;
      this.filter.view = preferences.dispatch_view || null;
      this.filter.submitted = preferences.dispatch_submitted || null;
    }
  }

  public clearEditandCreate() {
    this._dispatchRequest.clearEdit();
    this.create();
  }

  public clearSearch() {
    this.searchTerm.setValue('');
  }

  public closeItem() {
    // this._getDivWidth();
    this.asideOpen = false;
    this.drawer.close();
  }

  public create() {
    if (this.asideOpen && !this._dispatchRequest.dispatchItem) {
      // that means, a fresh create is in progress, so clear it
      this._dispatchRequest.clear();
    } else {
      this._openItem();
    }
  }

  public async editItem(item: DispatchItem) {
    this._openItem();
    this._openProgress();
    await this._dispatchRequest.editItem(item);
    this._closeProgress();
  }

  // Turns a date object into a readable format
  public formatSubmittedDate(date: Date): string {
    return moment(date).format('ddd,  MMM D YYYY • h:mm a').toString();
  }

  // Filters the item array by the time argument and type. Draft, Request, Workspace
  public async getItemsByView(view) {
    this.searchTerm.setValue('', { emitEvent: false });
    this.filter.view = view;
    this._addToPreferences('dispatch_view', view);
    this._openProgress();
    await this.refresh();
    this._closeProgress();
    this._goToFirstPage();
  }

  public getSearchPlaceholder() {
    let timeframeName;
    switch (this.filter.submitted) {
      case 'day':
        timeframeName = `Today's`;
        break;
      case 'week':
        timeframeName = `This Week's`;
        break;
      case 'month':
        timeframeName = `This Month's`;
        break;
    }
    let statusName = timeframeName ? '' : 'All';
    let typeName;

    if (['today', 'allActive'].indexOf(this.filter?.view) >= 0) {
      typeName = 'Items';
    } else if (['pendingRequests'].indexOf(this.filter?.view) >= 0) {
      typeName = 'Pending Requests';
    } else if (['dispatchDrats'].indexOf(this.filter?.view) >= 0) {
      typeName = 'Drafts';
    } else {
      typeName = 'Work Orders';
      if (this.filter?.view === 'activeWO') {
        statusName = 'Active';
      } else if (this.filter?.view === 'onHold') {
        statusName = 'On Hold';
      } else if (this.filter?.view === 'readyForPickup') {
        statusName = 'Ready for Pickup';
      } else if (this.filter?.view === 'noComm') {
        statusName = '3+ Days No Comm';
      }
    }
    return `Search${timeframeName ? ` ${timeframeName}` : ''}${statusName ? ` ${statusName}` : ''}${
      this.filter?.view === 'transferred' ? ` Transferred` : ''
    }${typeName ? ` ${typeName}` : ''}`;
  }

  public itemListBackgroundColor(item = null) {
    if (item.type === 'dispatch_draft') {
      return 'bg-shade-orange';
    } else if (item.module_id === 2) {
      return 'bg-shade-red';
    }
  }

  public pageChange(event) {
    this.startIndex = event.pageIndex * event.pageSize;
    this.endIndex = this.startIndex + event.pageSize;
    this.filter.page = event.pageIndex;
    this.refresh();
    const element = document.querySelector('#main');
    element.scrollIntoView();
  }

  public async refresh() {
    await this._getCounts();
    this.filter.search = this.searchTerm.value;
    const result = await this._dispatchService.getDispatch(this.filter).toPromise();
    this.items = result.items;
    this.total = result.count;
  }

  public async reloadEditItem({ itemId, topic_type, isNewRequest }) {
    await this.refresh();
    if (!isNewRequest) {
      this._dispatchRequest.editItem(
        this.items
          .filter((filtererItem) => filtererItem.type === topic_type)
          .find((foundItem) => foundItem.id === itemId)
      );
    }
  }

  public updateSubmitted(value: SubmittedType) {
    this.filter.submitted = value;
    this._addToPreferences('dispatch_submitted', value);
    this.refresh();
  }

  // Updates and then saves the current sort field argument to local storage
  public updateSortByField(field) {
    if (field === this.filter.order) {
      this.filter.direction = this.filter.direction === 'desc' ? 'asc' : 'desc';
      this._addToPreferences('dispatch_sort_direction', this.filter.direction);
    }
    this.filter.order = field;
    this._addToPreferences('sort_dispatch_by_field', this.filter.order);
    this.refresh();
  }
}
