import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { round } from 'lodash';
import { CoverLetterDialogComponent } from 'src/app/components';
import { PebFileType } from 'src/app/enums';
import { DateService, FileService, ModalService, ProjectService, TaskActionsService } from 'src/app/services';
import { ProjectTenantService } from '../../services';
import { PEBSection } from '../../types';

@Component({
  selector: 'app-peb-section-details-drawer',
  templateUrl: './peb-section-details-drawer.component.html',
  styleUrls: ['./peb-section-details-drawer.component.scss'],
})
export class PEBSectionDetailsDrawerComponent implements OnInit {
  @Input() canEdit: boolean;
  @Output() closeDrawer = new EventEmitter();
  @Output() editSection = new EventEmitter();
  @Output() deleteSection = new EventEmitter();
  @Output() submitForTenantReview = new EventEmitter();
  @Output() openTenantReviewTask = new EventEmitter();
  @Output() updateApprovalProcesses = new EventEmitter();
  @Output() saveCoverLetterDescription = new EventEmitter();
  @Output() uploadBubbleDrawingEvent = new EventEmitter();
  @Output() uploadReimbursementAgreementEvent = new EventEmitter();
  @Output() uploadSubleaseAgreementEvent = new EventEmitter();
  @Output() uploadExhibitBEvent = new EventEmitter();
  @Output() uploadAmortizationScheduleEvent = new EventEmitter();
  @Output() removeBubbleDrawingEvent = new EventEmitter();
  @Output() removeReimbursementEvent = new EventEmitter();
  @Output() removeSubleaseContractEvent = new EventEmitter();
  @Output() removeExhibitBEvent = new EventEmitter();
  @Output() removeAmortizationScheduleEvent = new EventEmitter();

  loading = false;
  isEditing = false;
  editingRentalRate = false;
  editingCirculationFactor = false;
  section: PEBSection;

  constructor(
    private projectService: ProjectService,
    private dateService: DateService,
    private fileService: FileService,
    private projectTenantService: ProjectTenantService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private taskActionsService: TaskActionsService,
    private fb: FormBuilder
  ) {}

  sectionFormGroup: FormGroup = this.fb.group({
    rental_rate: [null],
    circulation_factor: [null],
  });

  get rentalRate() {
    return this.sectionFormGroup.get('rental_rate');
  }

  get circulationFactor() {
    return this.sectionFormGroup.get('circulation_factor');
  }

  ngOnInit(): void {}

  load(section: PEBSection) {
    this.loading = true;
    this.rentalRate.setValue(section.rental_rate ?? 0);
    this.circulationFactor.setValue(section.circulation_factor ?? 0);
    this.section = section;
    this.section.id = this.section.tenant_id;
    this.loading = false;
  }

  async delete(section) {
    const res = await this.modalService
      .openConfirmationDialog({
        titleBarText: 'Delete Section',
        headerText: 'Delete Section',
        descriptionText: `Are you sure you want to delete this section? This cannot be undone.`,
        confirmationButtonText: 'Delete Section',
      })
      .toPromise();
    if (res) {
      this.deleteSection.emit(section);
      this.close();
    }
  }

  edit(section) {
    this.editSection.emit(section);
    this.close();
  }

  calculateYearlyRentalRate() {
    const yearlyRentalRate =
      Number(this.section.rental_rate) *
      (this.section.occupied_sqft + this.section.occupied_sqft * (this.section.circulation_factor * 0.01));
    this.section.yearlyRentalRate = round(yearlyRentalRate, 2);
  }

  async saveRentalRate() {
    await this.projectTenantService
      .updateProjectTenant(this.section.tenant_id, { rental_rate: this.rentalRate.value })
      .toPromise();
    this.section.rental_rate = this.rentalRate.value;
    this.calculateYearlyRentalRate();
    this.editingRentalRate = false;
  }

  async discardRentalRate() {
    this.rentalRate.setValue(this.section.rental_rate);
    this.editingRentalRate = false;
  }

  async saveCirculationFactor() {
    await this.projectTenantService
      .updateProjectTenant(this.section.tenant_id, { circulation_factor: this.circulationFactor.value })
      .toPromise();
    this.section.circulation_factor = this.circulationFactor.value;
    this.section.osf_w_circ = (+this.section.occupied_sqft ?? 0) * (1 + 0.01 * (+this.section.circulation_factor ?? 0));
    this.editingCirculationFactor = false;
  }

  async discardCirculationFactor() {
    this.circulationFactor.setValue(this.section.circulation_factor);
    this.editingCirculationFactor = false;
  }

  submitForReview() {
    this.submitForTenantReview.emit(this.section);
  }

  openCoverLetter(): void {
    const defaultCoverLetterText = `Enclosed is the Preliminary Estimated Budget for project ${this.projectService.currentSelectedProject.code} - ${this.projectService.currentSelectedProject.title}. Before any work can begin, please review, approve and sign the attached Preliminary Estimated Budget and Reimbursement agreement. Also, please indicate the funding source for this project. Once these items have been signed and approved, please upload them to the 1CALL System. We will review the documents to ensure all information required has been received and then coordinate with you regarding our next steps.`;
    const COVER_LETTER_TEXT = this.section.peb_cover_letter_text || defaultCoverLetterText;
    const coverLetterDialog = this.dialog.open(CoverLetterDialogComponent, {
      width: '700px',
      data: {
        title: 'Preliminary Estimated Budget',
        tenant: {
          first_name: this.section.representative_first_name,
          tenant_id: this.section.tenant_id,
          type_id: this.section.type_id,
          cover_letter_text: COVER_LETTER_TEXT,
        },
        pmInfo: this.pmInfo,
        architectInfo: this.architectInfo,
      },
    });

    coverLetterDialog.afterClosed().subscribe(async (tenant) => {
      if (tenant?.id) {
        if (!this.section.peb_cover_letter_text || tenant.cover_letter_text !== COVER_LETTER_TEXT) {
          this.saveCoverLetterDescription.emit({ section: this.section, tenant: tenant });
        }
      }
    });
  }

  openReviewTask() {
    this.openTenantReviewTask.emit(this.section.tenant_approval_task_id || this.section.saved_tenant_approval_task_id);
  }

  get PebFileType() {
    return PebFileType;
  }

  get pmInfo(): { name: string; title: string; email: string; phone: string } {
    const first = this.projectService.currentSelectedProject.project_manager_first_name
      ? this.projectService.currentSelectedProject.project_manager_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.project_manager_last_name
      ? this.projectService.currentSelectedProject.project_manager_last_name
      : '';
    const email = this.projectService.currentSelectedProject.project_manager_email
      ? this.projectService.currentSelectedProject.project_manager_email
      : '';
    const phone = this.projectService.currentSelectedProject.project_manager_office_phone
      ? this.projectService.currentSelectedProject.project_manager_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Project Manager',
      email,
      phone,
    };
  }

  get architectInfo(): { name: string; title: string; email: string; phone: string } {
    if (!this.projectService.currentSelectedProject.architect_first_name) {
      return null;
    }
    const first = this.projectService.currentSelectedProject.architect_first_name
      ? this.projectService.currentSelectedProject.architect_first_name
      : 'Not Given';
    const last = this.projectService.currentSelectedProject.architect_last_name
      ? this.projectService.currentSelectedProject.architect_last_name
      : '';
    const email = this.projectService.currentSelectedProject.architect_email
      ? this.projectService.currentSelectedProject.architect_email
      : '';
    const phone = this.projectService.currentSelectedProject.architect_office_phone
      ? this.projectService.currentSelectedProject.architect_office_phone
      : '';
    return {
      name: `${first} ${last}`,
      title: 'Architect',
      email,
      phone,
    };
  }

  uploadBubbleDrawing() {
    this.uploadBubbleDrawingEvent.emit(this.section);
  }

  async uploadReimbursementAgreement() {
    this.uploadReimbursementAgreementEvent.emit(this.section);
  }

  async uploadSubleaseAgreement() {
    this.uploadSubleaseAgreementEvent.emit(this.section);
  }

  async uploadExhibitB() {
    this.uploadExhibitBEvent.emit(this.section);
  }

  async uploadAmortizationSchedule() {
    this.uploadAmortizationScheduleEvent.emit(this.section);
  }

  public removeBubbleDrawing(fileId: number) {
    this.removeBubbleDrawingEvent.emit({ section: this.section, fileId: fileId });
  }

  public removeReimbursement() {
    this.removeReimbursementEvent.emit(this.section);
  }

  public removeSubleaseContract() {
    this.removeSubleaseContractEvent.emit(this.section);
  }

  public removeExhibitB() {
    this.removeExhibitBEvent.emit(this.section);
  }

  public removeAmortizationSchedule() {
    this.removeAmortizationScheduleEvent.emit(this.section);
  }

  public numberWithCommas(x: number): string {
    if (x == null) {
      return '';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public async createTask(fileType: PebFileType) {
    /*
    Uncomment this when we are ready to create tasks specific to each file type.
    const taskData = this.taskActionsService.getPebFileTaskData(fileType);
    await this.modalService.openCreateTaskModal(taskData).toPromise();
     */

    const task = await this.modalService.openCreateTaskModal().toPromise();
    if (task?.id) {
      const sectionData = {
        [fileType]: task.id,
      };

      await this.projectTenantService.updateProjectTenant(this.section.tenant_id, sectionData).toPromise();

      this.section[fileType] = task.id;
    }
  }

  public async viewTask(taskId: number) {
    await this.modalService.openViewTaskModal(taskId).toPromise();
  }

  close() {
    this.closeDrawer.emit();
  }
}
