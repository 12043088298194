<div id="request" data-test-id="request" class="request container p-5">
  <header class="mb-4">
    <div class="row align-items-center">
      <div class="col">
        <h3 id="title" class="dkblue font-weight-semibold m-0" data-id-test="selected-request-type-id-headline">
          Project Request {{ request?.code }}
        </h3>
      </div>
      <div class="col-auto text-right">
        <!-- <button mat-button color="accent" type="button" class="ml-3" (click)="downloadRequestFile(pdf, request.code)">
          Download PDF
        </button> -->
      </div>
    </div>
  </header>
  <main>
    <div class="row">
      <section id="request-main" class="col-lg-8">
        <div class="m-portlet">
          <div class="m-portlet__body p-4">
            <div class="row mb-5">
              <div class="col badges d-flex flex-column">
                <div class="d-flex align-items-center badges mb-5">
                  <p
                    class="bg-primary white pt-1 pb-1 px-2 mr-3 br-5 font-weight-semibold text-uppercase"
                    *ngIf="request?.workspace?.name"
                  >
                    {{ request.workspace?.name }}
                  </p>
                  <a
                    href="#"
                    target="_blank"
                    *ngIf="request?.parent_bid_package_id"
                    routerLink="/projects/{{ request?.parent_bid_package_project_id }}"
                    class="text-decoration-none pointer white"
                  >
                    <p class="bg-accent white pt-1 pb-1 px-2 mr-3 br-5 font-weight-semibold d-flex align-items-center">
                      <mat-icon class="white mr-2 mat-small-icon"> assignment_turned_in </mat-icon>
                      <span class="hover-u"> PJ-{{ request?.parent_bid_package_project_code }} </span>
                    </p>
                  </a>
                </div>
                <h2
                  *ngIf="isConstruction && (request?.request_type_id === 2 || request?.request_type_id === 1)"
                  data-id-test="selected-request-type-headline"
                  class="dkblue m-0 mb-2 font-weight-semibold"
                >
                  {{ request?.request_type_name }} Request
                </h2>
                <h2 class="dkblue m-0 mb-2 font-weight-semibold" *ngIf="request?.short_description">
                  {{ request.short_description }}
                </h2>
                <h4 class="dkgray m-0 font-weight-normal" *ngIf="request?.topic">
                  {{ request.topic.topic_category?.topic_group?.name }} > {{ request.topic.topic_category?.name }} >
                  {{ request.topic.name }}
                </h4>
              </div>
            </div>
            <div class="row mb-5">
              <div
                [ngClass]="{
                  col: !isConstruction,
                  'col-lg-3': isConstruction,
                  'col-md-6': isConstruction
                }"
              >
                <h6 class="dkblue m-0 font-weight-semibold mb-2 d-flex align-items-center">
                  <span class="mr-3"> Submitted </span>
                  <mat-icon class="method-icon">
                    {{ request?.request_method?.icon }}
                  </mat-icon>
                </h6>
                <h5 class="dkblue m-0 font-weight-normal" *ngIf="!isConstruction">
                  {{ request?.created_datetime | date: 'EEEE, MMMM dd, yyyy • h:mmaa' }}
                </h5>
                <h5 class="dkblue m-0 font-weight-normal" *ngIf="isConstruction">
                  {{ request?.created_datetime | date: 'MM/dd/yyyy' }}
                </h5>
              </div>
              <div class="col-4 mb-3" *ngIf="isConstruction && request?.budget_description">
                <h6 class="mb-2 dkblue font-weight-semibold">Est. Cost</h6>
                <h5 class="dkblue m-0 font-weight-normal">{{ request?.budget_description }}</h5>
              </div>
              <div class="col-2 mb-3" *ngIf="isConstruction">
                <h6 class="mb-2 dkblue font-weight-semibold" *ngIf="request?.start_date">Start</h6>
                <h5 class="dkblue m-0 font-weight-normal" *ngIf="request?.start_date">
                  {{ request.start_date | date: 'MM/dd/yyyy' }}
                </h5>
                <h6 class="mb-2 dkblue font-weight-semibold" *ngIf="request?.lease_term">Term</h6>
                <h5 class="dkblue m-0 font-weight-normal" *ngIf="request?.lease_term">
                  {{ request?.lease_term }}
                </h5>
              </div>
              <div class="col-2 mb-3" *ngIf="isConstruction">
                <h6 class="mb-2 dkblue font-weight-semibold" *ngIf="request?.end_date">Move-In</h6>
                <h5 class="dkblue m-0 font-weight-normal" *ngIf="request?.end_date">
                  {{ request?.end_date | date: 'MM/dd/yyyy' }}
                </h5>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-2 mb-3">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">
                  <span *ngIf="request?.request_type_id === 1">Preferred </span> Building
                </h6>
                <h5 class="dkblue m-0 font-weight-normal">{{ request?.building?.code }}-{{ request?.floor?.code }}</h5>
              </div>
              <div class="col-2 mb-3">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">
                  <span *ngIf="request?.request_type_id === 1">Preferred </span> Suite
                </h6>
                <h5 class="dkblue m-0 font-weight-normal">
                  {{ request?.suite?.name || '-' }}
                </h5>
              </div>
              <div class="col-4 mb-3">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Department</h6>
                <h5 class="dkblue m-0 font-weight-normal">
                  {{ request?.department?.name || '-' }}
                </h5>
              </div>
              <div class="col-4">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Room # / Landmark</h6>
                <h5 class="dkblue m-0 font-weight-normal">
                  {{ landmark }}
                </h5>
              </div>
            </div>
            <!-- <div class="row mb-5">
              <div class="col">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Request Method</h6>
                <h5 class="dkblue m-0 font-weight-normal">{{ request?.request_method?.name || '-' }}</h5>
              </div>
            </div> -->
            <!-- <div *ngIf="formTemplate" class="m-portlet__body item pointer p-0">
              <div class="row m-0">
                <div class="col p-4 item">
                  <div class="row" *ngFor="let s of formTemplate.sections; index as sectionIndex">
                    <div
                      class="col-3"
                      [ngClass]="{
                        'p-4': elementIndex < 4,
                        'pl-4 pr-4 pb-4 pt-2': elementIndex >= 4
                      }"
                      *ngFor="let e of s.elements; index as elementIndex"
                    >
                      <h6 class="m-0 dkblue font-weight-semibold mb-2">
                        {{ e.label }}
                      </h6>
                      <h5 class="m-0 dkblue font-weight-normal">
                        {{ e.value?.name || e.value?.label || e.value }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row mb-5" *ngIf="request?.request_type_id === 1">
              <div class="col-lg-8 room-list">
                <h6 class="mb-2 dkblue font-weight-semibold">New Space Needs</h6>
                <div class="d-flex flex-column mb-3" *ngFor="let r of rooms">
                  <h5 class="dkblue m-0 font-weight-normal">
                    <strong>{{ r.quantity }} </strong> {{ r.room_type.name }}{{ r.quantity === 1 ? '' : 's' }}
                  </h5>
                  <h6 class="dkgray font-weight-normal" *ngIf="r.comments">
                    {{ r.comments }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Summary</h6>
                <h5
                  class="m-0 comments dkblue font-weight-normal"
                  [innerHTML]="request?.summary ? request?.summary : 'Not Given'"
                ></h5>
              </div>
            </div>
            <div class="row mb-5" *ngIf="files && files.length > 0">
              <div class="col">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Attachments</h6>
                <!-- <mat-chip-list class="d-inline-block align-middle">
                  <app-file-chip *ngFor="let file of files" [file]="file" [downloadable]="true"></app-file-chip>
                </mat-chip-list> -->
                <app-file-chip-list *ngIf="files?.length" [files]="files" [diameter]="40"></app-file-chip-list>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-auto">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Requestor</h6>
                <button
                  mat-stroked-button
                  type="button"
                  color="accent"
                  class="contact mr-3 mb-3 p-2 pr-4 d-flex align-items-center text-left"
                  (click)="openUserProfileDialog(request?.requester?.id)"
                >
                  <app-profile-thumbnail
                    class="d-inline-block align-middle mr-3"
                    [width]="34"
                    [height]="34"
                    [userId]="request?.requester?.id"
                  ></app-profile-thumbnail>
                  <span class="d-inline-block align-middle">
                    <p class="m-0 ltblue font-weight-semibold">
                      {{ request?.requester?.first_name }} {{ request?.requester?.last_name }}
                    </p>
                    <!-- <h6 class="m-0 dkgray font-weight-normal">
                      {{ request?.requester?.department?.name }}
                    </h6> -->
                  </span>
                </button>
              </div>
              <div class="col">
                <h6 class="dkblue m-0 font-weight-semibold mb-2">Followers</h6>
                <button
                  mat-stroked-button
                  type="button"
                  color="accent"
                  class="contact mr-3 mb-3 p-2 pr-4 align-items-center text-left"
                  *ngFor="let c of contacts"
                  (click)="openUserProfileDialog(c?.id)"
                >
                  <app-profile-thumbnail
                    class="d-inline-block align-middle mr-3"
                    [width]="34"
                    [height]="34"
                    [userId]="c.id"
                  ></app-profile-thumbnail>
                  <span class="d-inline-block align-middle">
                    <p class="m-0 ltblue font-weight-semibold">{{ c.first_name }} {{ c.last_name }}</p>
                    <!-- <h6 class="m-0 dkgray font-weight-normal">
                      {{ c.department?.name }}
                    </h6> -->
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="request-review" class="review-form col-lg-4" *ngIf="isWorkspaceStaff">
        <div
          class="m-portlet"
          *ngIf="
            !isWorkspaceAdmin ||
            [RequestStatusEnum.ACCEPTED, RequestStatusEnum.CONVERTED, RequestStatusEnum.REJECTED].indexOf(
              request?.status?.id
            ) > -1
          "
        >
          <div
            class="m-portlet__body p-4"
            [ngClass]="{
              'bg-shade-green': request?.status?.id === RequestStatusEnum.ACCEPTED,
              'bd-shade-red': request?.status?.id === RequestStatusEnum.REJECTED
            }"
          >
            <!-- IF APPROVED OR REJECTED -->
            <div class="row align-items-center">
              <div class="col">
                <i
                  class="material-icons mr-2 d-inline-block align-middle"
                  [ngClass]="{
                    dkgreen:
                      [RequestStatusEnum.ACCEPTED, RequestStatusEnum.CONVERTED].indexOf(request?.status?.id) > -1,
                    dkred: request?.status?.id === RequestStatusEnum.REJECTED
                  }"
                >
                  {{
                    request?.status?.id === RequestStatusEnum.ACCEPTED
                      ? 'check'
                      : request?.status?.id === RequestStatusEnum.CONVERTED
                      ? 'check_circle'
                      : request?.status?.id === RequestStatusEnum.REJECTED
                      ? 'close'
                      : null
                  }}
                </i>
                <h5
                  class="m-0 d-inline-block align-middle"
                  [ngClass]="{
                    gray: request?.status?.id === RequestStatusEnum.PENDING,
                    dkgreen:
                      [RequestStatusEnum.ACCEPTED, RequestStatusEnum.CONVERTED].indexOf(request?.status?.id) > -1,
                    dkred: request?.status?.id === RequestStatusEnum.REJECTED
                  }"
                >
                  {{
                    request?.status?.id === RequestStatusEnum.PENDING
                      ? 'Pending'
                      : request?.status?.id === RequestStatusEnum.ACCEPTED
                      ? 'Accepted'
                      : request?.status?.id === RequestStatusEnum.CONVERTED
                      ? 'Converted'
                      : request?.status?.id === RequestStatusEnum.REJECTED
                      ? 'Rejected'
                      : null
                  }}
                </h5>
              </div>
              <div class="col-auto" *ngIf="isWorkspaceAdmin">
                <!-- UNDO IF APPROVED OR REJECTED -->
                <!-- HIDE IF CONVERTED -->
                <button
                  mat-button
                  color="accent"
                  style="line-height: 30px"
                  (click)="changeRequestStatus(RequestStatusEnum.PENDING)"
                  *ngIf="[RequestStatusEnum.ACCEPTED, RequestStatusEnum.REJECTED].indexOf(request?.status?.id) > -1"
                >
                  Undo
                </button>
                <button (click)="is_expanded = !is_expanded" mat-button color="primary" class="py-0 px-2">
                  <mat-icon *ngIf="is_expanded">expand_more</mat-icon>
                  <mat-icon *ngIf="!is_expanded">chevron_right</mat-icon>
                </button>
              </div>
            </div>
            <div *ngIf="is_expanded" class="mt-2 statuses-container">
              <div
                *ngFor="let rsl of request?.request_status_log"
                class="status-container d-flex justify-content-between align-items-center"
              >
                <div class="dkblue">
                  <span class="font-weight-semibold"
                    >{{ rsl?.updated_by?.first_name }} {{ rsl?.updated_by?.last_name }}</span
                  >
                  <div class="status-time dkgray">
                    {{ rsl?.created_datetime | date: 'MM/dd/yyyy - h:mma' | lowercase }}
                  </div>
                </div>
                <div class="status">{{ rsl?.updated_status?.name }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- IF APPROVED -->
        <div *ngIf="request?.status?.id === RequestStatusEnum.ACCEPTED" class="m-portlet">
          <div class="m-portlet__body p-4">
            <!-- <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">
                  Type
                </h6>
                <div class="row">
                  <div class="col">
                    DEFAULT TO PROJ BUT ALLOW SWITCH TO WO
                    <mat-button-toggle-group class="full-width" name="Type" aria-label="Type">
                      <mat-button-toggle value="Project">Project</mat-button-toggle>
                      <mat-button-toggle value="Work Order">Work Order</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row mb-3">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Project Manager</h6>
                <div class="row">
                  <div class="col">
                    <!-- CHOOSE WORKSPACE PM -->
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.project_manager_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option
                          *ngFor="let pm of projectManagers"
                          (click)="changeProjectManager(pm)"
                          [value]="pm.id"
                          >{{ pm.first_name }} {{ pm.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="isConstruction">
              <div class="col">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <h6 class="m-0 dkblue font-weight-semibold">Architect</h6>
                  </div>
                  <div class="col text-right">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      [checked]="!!request?.is_architect_required"
                      (change)="changeArchitectRequired()"
                    >
                      <div class="dkgray">{{ request?.is_architect_required ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select
                        [(ngModel)]="request.architect_id"
                        [disabled]="!isWorkspaceAdmin || !request?.is_architect_required"
                      >
                        <mat-option *ngFor="let arch of architects" (click)="changeArchitect(arch)" [value]="arch.id"
                          >{{ arch.first_name }} {{ arch.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">{{ request?.workspace?.name }} Manager</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.workspace_manager_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option
                          *ngFor="let wm of workspaceManagers"
                          (click)="changeWorkspaceManager(wm)"
                          [value]="wm.id"
                          >{{ wm.first_name }} {{ wm.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">{{ request?.workspace?.name }} Account Coordinator</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.account_coordinator_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option
                          *ngFor="let ac of accountCoordinators"
                          (click)="changeUser('account_coordinator_id', ac.id)"
                          [value]="ac.id"
                        >
                          {{ ac.first_name }} {{ ac.last_name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="needsDFSManager">
              <div class="col">
                <div class="d-flex d-flex align-items-center mb-2">
                  <h6 class="m-0 dkblue font-weight-semibold mr-auto">Chief Technology Officer</h6>
                  <div class="d-flex align-items-center" *ngIf="canToggleDFSManager">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      [checked]="!!request?.is_dfs_required"
                      (change)="changeDFSRequired()"
                    >
                      <div class="dkgray">{{ request?.is_dfs_required ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select
                        [(ngModel)]="request.dfs_id"
                        [disabled]="!isWorkspaceAdmin || !request?.is_dfs_required"
                      >
                        <mat-option
                          *ngFor="let dfs of dfsManagers"
                          (click)="changeUser('dfs_id', dfs.id)"
                          [value]="dfs.id"
                          >{{ dfs.first_name }} {{ dfs.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="isOneCallWorkspace">
              <div class="col">
                <div class="d-flex d-flex align-items-center mb-2">
                  <h6 class="m-0 dkblue font-weight-semibold mr-auto">1CALL Director</h6>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.one_call_director_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option
                          *ngFor="let oneCallDirector of oneCallDirectors"
                          (click)="changeUser('one_call_director_id', oneCallDirector.id)"
                          [value]="oneCallDirector.id"
                          >{{ oneCallDirector.first_name }} {{ oneCallDirector.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Inventory Control Officer</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.ico_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option *ngFor="let ico of icos" (click)="changeUser('ico_id', ico.id)" [value]="ico.id"
                          >{{ ico.first_name }} {{ ico.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Business Systems Director</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.bsd_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option *ngFor="let bsd of bsds" (click)="changeUser('bsd_id', bsd.id)" [value]="bsd.id"
                          >{{ bsd.first_name }} {{ bsd.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Chief Facilities Management Officer</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.cfmo_id" [disabled]="!isWorkspaceAdmin">
                        <mat-option
                          *ngFor="let cfmo of cfmos"
                          (click)="changeUser('cfmo_id', cfmo.id)"
                          [value]="cfmo.id"
                          >{{ cfmo.first_name }} {{ cfmo.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Short Description</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                      <input
                        matInput
                        class="font-weight-semibold"
                        (focusout)="changeShortDescription(shortDescription)"
                        placeholder="Enter Short Description"
                        [(ngModel)]="shortDescription"
                        maxlength="254"
                        [disabled]="!isWorkspaceAdmin"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Task Templates</h6>
                <div class="d-flex align-items-center mb-3" *ngIf="request.project_template_ids === '[8]'">
                  <h6 class="m-0 dkgray font-weight-normal">
                    No Milestones or Tasks will be added to this Project on conversion. If you need a task template, add
                    one below.
                  </h6>
                </div>
                <div
                  class="m-portlet mt-2 mb-2 br-5 bg-shade-gray"
                  *ngFor="let t of selectedTemplates; index as i"
                  [hidden]="t.is_hidden"
                >
                  <div class="m-portlet__body p-2 d-flex align-items-center">
                    <span class="d-flex flex-column mr-auto">
                      <h6 class="m-0 dkblue font-weight-semibold">
                        {{ t.name }}
                      </h6>
                      <h6 class="m-0 dkgray font-weight-normal mr-auto" style="font-size: 0.9rem">
                        {{ t.milestoneCount }} Milestone{{ t.milestoneCount === 1 ? '' : 's' }},
                        {{ t.taskCount }} Task{{ t.taskCount === 1 ? '' : 's' }}
                      </h6>
                    </span>
                    <button
                      mat-button
                      class="gray lh-22 pl-2 pr-2"
                      *ngIf="request.status_id !== RequestStatusEnum.CONVERTED && isWorkspaceAdmin && t.is_removable"
                      (click)="removeTemplate(i)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
                <button
                  mat-stroked-button
                  color="success"
                  class="full-width"
                  *ngIf="isWorkspaceAdmin && !showTemplateSelect && availableTemplates && availableTemplates.length > 0"
                  (click)="showTemplateSelect = true; templateSelect.open()"
                >
                  + Task Template
                </button>
                <mat-form-field appearance="outline" class="full-width" [hidden]="!showTemplateSelect">
                  <mat-select matNativeControl #templateSelect>
                    <mat-option></mat-option>
                    <mat-option *ngFor="let t of availableTemplates" (click)="addTemplate(t)">{{ t.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  *ngIf="
                    request?.status?.id === RequestStatusEnum.ACCEPTED &&
                    allUsersAreSelected &&
                    !loaders.convertRequest &&
                    selectedTemplates &&
                    selectedTemplates.length > 0
                  "
                  mat-flat-button
                  color="success"
                  class="full-width"
                  (click)="validationErrors.valid ? convertToProject() : (showValidationErrors = true)"
                  [disabled]="!isWorkspaceAdmin"
                  type="button"
                >
                  <!-- SHOULD SAY PROJECT OR WORK ORDER -->
                  Convert to Project
                </button>
                <button mat-flat-button *ngIf="loaders.convertRequest" color="success" class="full-width" type="button">
                  <span><i class="fas fa-circle-notch fa-spin ml-3"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- IF CONVERTED -->
        <div *ngIf="request?.status?.id === RequestStatusEnum.CONVERTED" class="m-portlet">
          <div class="m-portlet__body p-4">
            <!-- <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">
                  Type
                </h6>
                <div class="row">
                  <div class="col">
                    DEFAULT TO PROJ BUT ALLOW SWITCH TO WO
                    <mat-button-toggle-group class="full-width" name="Type" aria-label="Type">
                      <mat-button-toggle value="Project">Project</mat-button-toggle>
                      <mat-button-toggle value="Work Order">Work Order</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Project Manager</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.project_manager_id" disabled>
                        <mat-option
                          *ngFor="let pm of projectManagers"
                          (click)="changeProjectManager(pm)"
                          [value]="pm.id"
                          >{{ pm.first_name }} {{ pm.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isConstruction">
              <div class="col">
                <div class="row d-flex align-items-center mb-2">
                  <div class="col">
                    <h6 class="m-0 dkblue font-weight-semibold">Architect</h6>
                  </div>
                  <div class="col text-right">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      [checked]="!!request?.is_architect_required"
                      (change)="changeArchitectRequired()"
                      [disabled]="true"
                    >
                      <div class="dkgray">{{ request?.is_architect_required ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.architect_id" disabled>
                        <mat-option *ngFor="let arch of architects" (click)="changeArchitect(arch)" [value]="arch.id"
                          >{{ arch.first_name }} {{ arch.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">{{ request.workspace?.name }} Manager</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.workspace_manager_id" disabled>
                        <mat-option
                          *ngFor="let wm of workspaceManagers"
                          (click)="changeWorkspaceManager(wm)"
                          [value]="wm.id"
                          >{{ wm.first_name }} {{ wm.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">{{ request.workspace?.name }} Account Coordinator</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.account_coordinator_id" disabled>
                        <mat-option *ngFor="let ac of accountCoordinators" [value]="ac.id"
                          >{{ ac.first_name }} {{ ac.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="needsDFSManager">
              <div class="col">
                <div class="d-flex align-items-center mb-2">
                  <h6 class="m-0 dkblue font-weight-semibold mr-auto">Chief Technology Officer</h6>
                  <div class="d-flex align-items-center" *ngIf="canToggleDFSManager">
                    <mat-slide-toggle
                      *ngIf="authService.isStaffOnAnyModule"
                      color="accent"
                      labelPosition="before"
                      [checked]="!!request?.is_dfs_required"
                      [disabled]="true"
                    >
                      <div class="dkgray">{{ request?.is_dfs_required ? 'Needed' : 'Not Needed' }}</div>
                    </mat-slide-toggle>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.dfs_id" disabled>
                        <mat-option
                          data-id-test="convert-request-to-project-select-project-manager-option"
                        ></mat-option>
                        <mat-option *ngFor="let dfs of dfsManagers" [value]="dfs.id"
                          >{{ dfs.first_name }} {{ dfs.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="isOneCallWorkspace">
              <div class="col">
                <div class="d-flex align-items-center mb-2">
                  <h6 class="m-0 dkblue font-weight-semibold mr-auto">1CALL Director</h6>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.one_call_director_id" disabled>
                        <mat-option
                          data-id-test="convert-request-to-project-select-project-manager-option"
                        ></mat-option>
                        <mat-option *ngFor="let oneCallDirector of oneCallDirectors" [value]="oneCallDirector.id"
                          >{{ oneCallDirector.first_name }} {{ oneCallDirector.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Inventory Control Officer</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.ico_id" disabled>
                        <mat-option
                          data-id-test="convert-request-to-project-select-project-manager-option"
                        ></mat-option>
                        <mat-option *ngFor="let ico of icos" [value]="ico.id"
                          >{{ ico.first_name }} {{ ico.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" *ngIf="!isConstruction">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Business Systems Director</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.bsd_id" disabled>
                        <mat-option
                          data-id-test="convert-request-to-project-select-project-manager-option"
                        ></mat-option>
                        <mat-option *ngFor="let bsd of bsds" [value]="bsd.id"
                          >{{ bsd.first_name }} {{ bsd.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Chief Facilities Management Officer</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="request">
                      <mat-select [(ngModel)]="request.cfmo_id" disabled>
                        <mat-option
                          data-id-test="convert-request-to-project-select-project-manager-option"
                        ></mat-option>
                        <mat-option *ngFor="let cfmo of cfmos" [value]="cfmo.id"
                          >{{ cfmo.first_name }} {{ cfmo.last_name }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Short Description</h6>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline" class="full-width">
                      <input
                        matInput
                        placeholder="Enter Short Description"
                        [(ngModel)]="shortDescription"
                        maxlength="254"
                        [disabled]="true"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <h6 class="m-0 dkblue font-weight-semibold mb-2">Task Templates</h6>
                <div class="d-flex align-items-center mb-3" *ngIf="request.project_template_ids === '[8]'">
                  <h6 class="m-0 dkgray font-weight-normal">This project was created with no task templates.</h6>
                </div>
                <div
                  class="m-portlet mt-2 mb-2 br-5 bg-shade-gray"
                  *ngFor="let t of selectedTemplates; index as i"
                  [hidden]="t.is_hidden"
                >
                  <div class="m-portlet__body p-2 d-flex align-items-center">
                    <span class="d-flex flex-column mr-auto">
                      <h6 class="m-0 dkblue font-weight-semibold">
                        {{ t.name }}
                      </h6>
                      <h6 class="m-0 dkgray font-weight-normal mr-auto" style="font-size: 0.9rem">
                        {{ t.milestoneCount }} Milestone{{ t.milestoneCount === 1 ? '' : 's' }},
                        {{ t.taskCount }} Task{{ t.taskCount === 1 ? '' : 's' }}
                      </h6>
                    </span>
                  </div>
                </div>

                <!-- <div
                  class="m-portlet mt-2 mb-2 br-5 bg-shade-gray"
                  *ngFor="let t of selectedTemplates; index as i"
                >
                  <div class="m-portlet__body p-2 d-flex align-items-center">
                    <h6 class="m-0 dkblue font-weight-semibold mr-auto pl-2">
                      {{ t.name }}
                    </h6>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button mat-flat-button color="accent" class="full-width" (click)="goToProject()">
                  <!-- SHOULD SAY PROJECT OR WORK ORDER -->
                  View Project
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="request?.status?.id === RequestStatusEnum.PENDING && isWorkspaceAdmin" class="m-portlet">
          <div class="m-portlet__body p-4">
            <div class="row">
              <div class="col pr-2">
                <button
                  mat-stroked-button
                  color="warn"
                  class="full-width bg-shade-orange"
                  style="border: 1px solid white"
                  (click)="rejectRequest()"
                >
                  Reject Request
                </button>
              </div>
              <div class="col pl-2">
                <button
                  mat-flat-button
                  color="success"
                  class="full-width"
                  (click)="changeRequestStatus(RequestStatusEnum.ACCEPTED)"
                >
                  Accept Request
                </button>
              </div>
            </div>
            <ng-container *ngIf="!request?.work_order_id">
              <div class="row mt-4 mb-4">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold mb-2">Project not quite the right fit?</h6>
                  <button mat-stroked-button style="background-color: #4b7cdd; color: white" (click)="convertRequest()">
                    {{
                      request?.converted_from_wo_id
                        ? 'Reactivate WO-' + request?.converted_from_wo_id
                        : 'Convert to Work Order'
                    }}
                  </button>
                </div>
              </div>
              <div class="horizontal-line">
                <hr />
                <span class="dkblue font-weight-semibold">OR</span>
              </div>
              <div class="row mt-4 mb-3">
                <div class="col">
                  <h6 class="m-0 dkblue font-weight-semibold mb-2">Send request to another Workspace</h6>
                  <div class="row">
                    <div class="col">
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-select [(ngModel)]="workspaceToReroute">
                          <mat-option *ngFor="let w of workspaces" [value]="w">{{ w.name }} </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button
                    mat-stroked-button
                    color="accent"
                    class="full-width"
                    [disabled]="!workspaceToReroute"
                    (click)="transferToWorkspace(workspaceToReroute)"
                  >
                    Send to {{ workspaceToReroute?.name || 'Workspace' }}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <!-- IF REJECTED -->
        <div *ngIf="request?.status?.id === RequestStatusEnum.REJECTED && request?.work_order_id" class="m-portlet">
          <div class="m-portlet__body p-4">
            <div class="row mb-4 text-center">
              <div class="col">
                <p class="dkblue m-0">
                  This request has been converted to a Work order, please use the link below to navigate there.
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <a
                  [href]="'/work-orders/' + request.work_order_id"
                  mat-flat-button
                  color="accent"
                  class="full-width mb-3"
                  style="text-decoration: none"
                >
                  WO-{{ request?.work_order_id }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</div>

<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break">
    <div *ngIf="request" class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header p-4">
        <div class="row align-items-top">
          <div class="col-3">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-4 mb-2">
                <label class="main-label"> REQUEST ID </label>
                <p>
                  {{ request?.code }}
                </p>
              </div>
              <div class="col-4 mb-2">
                <label class="main-label"> SUBMITTED </label>
                <p>
                  {{ request?.created_datetime | date: 'dd MMMM yyyy' }}
                </p>
              </div>
              <div *ngIf="isConstruction" class="col-4">
                <label class="main-label"> ESTIMATED COST </label>
                <p>
                  {{ request.budget_description }}
                </p>
              </div>
            </div>
            <div class="row">
              <span *ngIf="isConstruction">
                <div *ngIf="request.request_type_id != 2" class="col-4 mb-2">
                  <label class="main-label"> TERM </label>
                  <p>
                    {{ request.lease_term }}
                  </p>
                </div>
                <div *ngIf="request.request_type_id === 2" class="col-4 mb-2">
                  <label class="main-label"> Start </label>
                  <p>
                    {{ request.start_date | date: 'MM/dd/yyyy' }}
                  </p>
                </div>
                <div class="col-4">
                  <label class="main-label"> MOVE IN </label>
                  <p>
                    {{ request.end_date | date: 'MM/dd/yyyy' }}
                  </p>
                </div>
              </span>
              <div *ngIf="request.request_type_name == 'Renovation'" class="col-4 mb-2">
                <label class="main-label"> LOCATION </label>
                <p>
                  Suite {{ request?.suite?.name }} <br />
                  {{ request?.department?.name }} <br />
                  {{ request?.building?.name }} <br />
                  Floor {{ request?.floor?.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section class="form-title ml-4 mr-4">
        <div class="row">
          <div class="col">
            <h1 class="black font-weight-bold m-0">Request</h1>
            <small>
              <span *ngIf="request.status_id === RequestStatusEnum.CONVERTED"> Converted to Project </span>
              <span *ngIf="request.status_id === RequestStatusEnum.ACCEPTED"> Accepted </span>
              <span *ngIf="request.status_id === RequestStatusEnum.REJECTED"> Rejected </span>
            </small>
          </div>
        </div>
      </section>
      <section class="form-info-section ml-4 mr-4">
        <div class="row">
          <div class="col-12">
            <div class="row mb-4">
              <div class="col">
                <label class="main-label">PROJECT SUMMARY</label>
                <p class="m-0">
                  {{ request?.summary }}
                </p>
              </div>
            </div>
            <div class="row margin-tb">
              <div class="col-3">
                <label class="main-label"> Requester </label>
                <p>
                  {{ request.requester?.first_name + ' ' + request.requester?.last_name }}
                  <br />
                  <small>
                    {{ request.requester?.department?.name }}
                  </small>
                </p>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <label class="main-label"> Contacts </label>
                  </div>
                </div>
                <div class="row">
                  <div *ngFor="let c of contacts" class="col-3">
                    <p>
                      {{ c.first_name }} {{ c.last_name }}
                      <br />
                      <small>
                        {{ c.department?.name }}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-tb mt-4" *ngIf="filesHasImages()">
              <div class="col">
                <label class="main-label row mb-2">Attachments</label>
                <div class="row">
                  <span class="col-auto" *ngFor="let file of files">
                    <img *ngIf="file.base64" [src]="file.base64" [height]="100" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
