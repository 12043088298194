import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourceType, TaskAccessoryType, TaskReviewStatus } from '../../enums';
import {
  AuthService,
  DateService,
  DisplayReviewersService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
} from '../../services';
import { ReviewChain, Task, TaskAccessoryData, UhatFileReference, User } from '../../types';
import { ProjectConstruction } from '../../workspaces/construction/types';

@Component({
  selector: 'app-request-review-dialog',
  templateUrl: './request-review-dialog.component.html',
  styleUrls: ['./request-review-dialog.component.scss'],
})
export class RequestReviewDialogComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private dateService: DateService,
    public dialogRef: MatDialogRef<RequestReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private displayReviewersService: DisplayReviewersService,
    private fileService: FileService,
    private modalService: ModalService,
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private snackbar: MatSnackBar
  ) {}

  public accessoryData: TaskAccessoryData;
  public assignedUserId: number;
  public project: ProjectConstruction;
  public task: Task;
  public reviewFiles: UhatFileReference[];
  public reviewChain: ReviewChain[];
  public disableReviewOptions: boolean;
  public reviewType: 'Update' | 'Add';
  public selectedFile: UhatFileReference;
  private reviewFilesWereInitiallyEmpty = false;

  async ngOnInit() {
    this.task = this.data?.task || {};
    this.project =
      this.projectService.currentSelectedProject ||
      (this.task?.project_id && (await this.projectService.getProjectById(this.task.project_id).toPromise())) ||
      null;
    this.accessoryData =
      (this.task?.id && this.task?.accessory_data && JSON.parse(this.task.accessory_data)) || this.data?.accessoryData;
    this.reviewFiles = this.accessoryData?.reviewFiles || [];
    if (!this.reviewFiles.length) {
      this.reviewFilesWereInitiallyEmpty = true;
    }
    this.selectedFile = this.reviewFiles[0] || null;
    this.reviewChain = this.accessoryData?.reviewChain || [];
    this.reviewType = this.reviewChain?.length ? 'Update' : 'Add';
    const reviewIsStarted = !!this.reviewChain?.find((reviewer) => reviewer.status !== 0);
    this.disableReviewOptions = reviewIsStarted || this.data?.isReviewersLocked;
    this.reviewChain = await this.displayReviewersService.addUserInfoToReviewChain(this.reviewChain);
  }

  get ResourceType() {
    return ResourceType;
  }

  get isTaskReview() {
    return !this.accessoryData || this.accessoryData.type === TaskAccessoryType.Other;
  }

  async openUploadFileModal(uploadedFiles = null) {
    if (uploadedFiles?.linkedFiles?.length) {
      for (const file of uploadedFiles.linkedFiles) {
        if (!this.reviewFiles.find((f) => (f.file_id || f.id) === (file.file_id || file.id))) {
          await this.fileService.linkFile(file.file_id, this.project?.id, ResourceType.Project).toPromise();
          this.reviewFiles.push(file);
        }
      }
    } else {
      const data = {
        parentResourceType: ResourceType.Project,
        parentResourceId: this.project?.id,
        allowComment: false,
        preSelectedTags: [],
        project_id: this.project?.id,
        computerFiles: uploadedFiles?.computerFiles,
        filteredFiles: this.reviewFiles,
      };
      const files = await this.modalService.openFileAttachmentDialog(data).toPromise();

      if (files) {
        this.reviewFiles = [...this.reviewFiles, ...files];
      }
    }

    if (!this.selectedFile && this.reviewFiles?.length) {
      this.updateSelectedFile(this.reviewFiles[0]);
    }
  }

  public removeFile(file) {
    if (this.disableReviewOptions) {
      return;
    }

    this.reviewFiles = this.reviewFiles.filter((f) => (f.file_id || f.id) !== (file.file_id || file.id));
    if ((file.file_id || file.id) === (this.selectedFile?.file_id || this.selectedFile?.id)) {
      if (!this.reviewFiles.length) {
        this.updateSelectedFile(null);
      } else {
        this.updateSelectedFile(this.reviewFiles[0]);
      }
    }
  }

  updateSelectedFile(file) {
    this.selectedFile = file;
  }

  async addReviewers() {
    const internalReviewers = this.displayReviewersService.getInternalReviewers(null, this.project);
    const selectedReviewers: User[] = internalReviewers.selectedReviewers;

    this.reviewChain.forEach((reviewer) => {
      const index = selectedReviewers.findIndex((user) => user.id === reviewer.id);
      if (index !== -1) {
        selectedReviewers[index].is_selected = true;
      } else {
        const selectedUser = {
          id: reviewer.id,
          first_name: reviewer.first_name,
          last_name: reviewer.last_name,
          title: reviewer.title,
          is_selected: true,
        };
        selectedReviewers.push(selectedUser);
      }
    });

    const data = {
      title: 'Add Reviewers',
      preSelectedUsers: selectedReviewers,
      checkIfSelected: true,
      allowedUserTypeIds: [1],
      onlyShowAllowedUserTypes: true,
      hasProject: true,
      createUser: { title: 'Guest', guestUser: false },
    };
    await this.modalService
      .openUserSelectModal(data)
      .toPromise()
      .then(({ selectedUsers, deSelectedUsers }) => {
        if (selectedUsers || deSelectedUsers) {
          this.reviewChain = this.reviewChain.filter(
            (reviewer) => !deSelectedUsers?.find((user) => user.id === reviewer.id)
          );
          selectedUsers.forEach((user) => {
            if (!this.reviewChain.find((reviewer) => reviewer.id === user.id)) {
              this.reviewChain.push({
                id: user.id,
                status: TaskReviewStatus.Pending,
                first_name: user.first_name,
                last_name: user.last_name,
                title: user.title,
              });
            }
          });

          this.assignedUserId = this.reviewChain[0].id;
        }
      });
  }

  public toggleSignature(reviewer) {
    reviewer.needs_signature = reviewer.needs_signature ? 0 : 1;
  }

  public removeReviewer(reviewerId: number) {
    if (this.disableReviewOptions) {
      return;
    }

    this.reviewChain = this.reviewChain.filter((reviewer) => reviewer.id !== reviewerId);
  }

  public async openUserProfilePreview(userId) {
    await this.modalService.openUserProfileDialog(userId).toPromise();
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.reviewChain, event.previousIndex, event.currentIndex);
    if (this.reviewChain?.length) {
      if (!this.assignedUserId || this.assignedUserId !== this.reviewChain[0].id) {
        this.assignedUserId = this.reviewChain[0].id;
      }
    }
  }

  public updateTurnaround(turnaround) {
    this.task.review_turnaround = turnaround;
  }

  public async submit() {
    const { isValid, warning } = this.isValid();
    if (isValid) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus(`${this.reviewType === 'Update' ? 'Updating' : 'Adding'} Review`);
      let reviewChain = this.reviewChain;

      if (this.task?.id) {
        // removes the fields used only for display in a way that will make adding additional fields possible
        reviewChain = this.displayReviewersService.removeUserInfoFromReviewChain(this.reviewChain);
      }

      const reviewFiles =
        this.reviewFiles.map((file) =>
          file.file_id || file.id ? { id: file.file_id || file.id, name: file.name } : file
        ) || [];
      let accessoryData = {
        type: this.accessoryData?.type ?? TaskAccessoryType.Other,
        isReviewItem: true,
        reviewCreator: this.accessoryData?.reviewCreator || this.authService.currentUser.id,
        reviewChain,
        reviewFiles,
      };

      if (this.accessoryData) {
        accessoryData = { ...this.accessoryData, ...accessoryData };
      }

      const taskToUpdate: Task = {
        id: this.task?.id,
        assigned_user_id: this.assignedUserId,
        accessory_data: JSON.stringify(accessoryData),
        review_turnaround: this.task?.review_turnaround,
        due_date: this.dateService.addWeekdays(this.task?.review_turnaround ?? 1).format('YYYY-MM-DD'),
      };

      const task =
        (taskToUpdate?.id && (await this.projectService.updateTask(taskToUpdate).toPromise())) || taskToUpdate;

      if (this.task?.id) {
        const filesHaveChanged =
          this.reviewFiles?.length !== this.accessoryData?.reviewFiles?.length || this.compareFiles();
        if (filesHaveChanged) {
          const message = `Attached ${this.reviewFiles.length} file${
            this.reviewFiles.length === 1 ? '' : 's'
          } to the task.`;
          const newNote = await this.projectService.createNote(ResourceType.Task, task?.id, message).toPromise();
          await this.fileService.addFilesToNote(newNote, task?.id, [], this.reviewFiles);
        }
      }
      this.progressIndicatorService.close();
      this.dialogRef.close({ task, accessoryData });
    } else {
      this.snackbar.open(warning);
    }
  }

  public cancel(): void {
    this.dialogRef.close({ task: null, accessoryData: null });
  }

  private compareFiles() {
    let filesAreDifferent = false;
    this.accessoryData?.reviewFiles?.forEach((file) => {
      if (!this.reviewFiles.find((f) => f.id === file.id)) {
        filesAreDifferent = true;
      }
    });
    return filesAreDifferent;
  }

  isValid() {
    let isValid = true;
    let warning = '';
    let needsComma = false;
    let fieldsCount = 0;

    if (!this.task?.review_turnaround && this.task?.review_turnaround !== 0) {
      isValid = false;
      warning += 'Review Turnaround';
      needsComma = true;
      fieldsCount++;
    }
    if (!this.reviewChain.length) {
      isValid = false;
      warning += `${needsComma ? ',' : ''} Reviewers`;
      needsComma = true;
      fieldsCount++;
    }
    if (!this.reviewFilesWereInitiallyEmpty && !this.reviewFiles.length) {
      isValid = false;
      warning += `${needsComma ? ',' : ''} Review Files`;
      fieldsCount++;
    }

    warning = `Field${fieldsCount > 1 ? 's' : ''} required: ${warning}`;
    return { isValid, warning };
  }
}
