import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { orderBy } from 'lodash';
import * as moment from 'moment';
import { ViewTaskDialogComponent } from 'src/app/components';
import { ArfStatus } from 'src/app/enums';
import { AuthService, HomeService, ProjectTaskService, SidenavLinksService, SidenavService } from 'src/app/services';
import { Project, Task, User, WorkOrder } from 'src/app/types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('mainScreen', { static: true }) elementView: ElementRef;
  @ViewChild('taskPaginator', { static: false }) taskPaginator: any;
  @ViewChild('workOrderPaginator', { static: false }) workOrderPaginator: any;
  @ViewChild('projectPaginator', { static: false }) projectPaginator: any;
  isReAssigningUserAndTaskId: { isReAssigningUser: boolean; taskId: number };
  loading: boolean;
  asideSubscription: any;
  closeTaskSubscription: any;
  isSidebarClosed: boolean;
  timeOfDay: string;
  currentUser: User;
  projects: Project[];
  myProjects: Project[] = [];
  myWorkOrders: WorkOrder[] = [];
  myTaskCount: number;
  myProjectCount: number;
  myProjectFilteredCount: number;
  myWorkOrderCount: number;
  myWorkOrderFilteredCount: number;
  myReviewCount: number;
  myTasks: any[] = [];
  pageSize = 15;
  pageSizeOptions = [15, 30, 60];
  perfectScrollbar;

  expandTasksSection = true;
  expandWorkOrdersSection = true;
  expandProjectsSection = true;

  // local storage properties
  shownSection;
  workOrderSortProperty: string;
  workOrderSortDirection: 'asc' | 'desc';
  projectSortProperty: string;
  projectSortDirection: 'asc' | 'desc';
  selectedTaskType;
  selectedTaskTimespan;
  selectedTaskRole;
  selectedWorkOrderTimespan;
  selectedWorkOrderRole;
  selectedProjectStatus;
  selectedProjectRole;
  tasksPageSize;
  workOrdersPageSize;
  projectsPageSize;

  timespanOptions = [
    { value: 'all', menuLabel: 'All Due Dates', selectedLabel: 'All Due Dates', icon: 'event' },
    { value: 'overdue', menuLabel: 'Overdue', selectedLabel: 'Overdue', icon: 'event' },
    {
      value: '7',
      menuLabel: 'Next 7 Days',
      selectedLabel: 'Due in 7 Days',
      icon: 'event',
      isDefault: true,
    },
    { value: '14', menuLabel: 'Next 2 Weeks', selectedLabel: 'Due in 2 Weeks', icon: 'event' },
    { value: '30', menuLabel: 'Next 30 Days', selectedLabel: 'Due in 30 Days', icon: 'event' },
    { value: 'none', menuLabel: 'No Due Date', selectedLabel: 'No Due Date', icon: 'event' },
  ];
  taskTypeOptions = [
    { value: 'all', menuLabel: 'All Task Types', icon: 'assignment_turned_in', isDefault: true },
    { value: 'action', menuLabel: 'Action Tasks', icon: 'assignment_late' },
    { value: 'review', menuLabel: 'Review Tasks', icon: 'library_add_check' },
  ];
  taskRoleOptions = [
    { value: 'all', menuLabel: 'All Task Roles', icon: 'assignment_turned_in', isDefault: true },
    { value: 'assigned', menuLabel: 'Assigned To Me', icon: 'how_to_reg' },
    { value: 'following', menuLabel: 'Following Only', icon: 'group' },
  ];
  workOrderRoleOptions = [
    { value: 'all', menuLabel: 'All Work Orders', icon: 'description', isDefault: true },
    { value: 'requested', menuLabel: 'My Requested', icon: 'assignment_late' },
    { value: 'following', menuLabel: 'Following Only', icon: 'group' },
  ];
  projectStatusOptions = [
    { value: null, menuLabel: 'All', icon: 'language' },
    { value: '1', menuLabel: 'Active', icon: 'label_important', isDefault: true },
    { value: '2', menuLabel: 'Planned', icon: 'next_plan' },
    { value: '3', menuLabel: 'On Hold', icon: 'flag' },
  ];
  projectRoleOptions = [
    { value: 'all', menuLabel: 'All Project Roles', icon: 'assignment_turned_in', isDefault: true },
    { value: 'requested', menuLabel: 'My Requested', icon: 'assignment_late' },
    { value: 'following', menuLabel: 'Following Only', icon: 'group' },
  ];

  get isStaffOnAnyModule() {
    return this.authService.isStaffOnAnyModule;
  }

  constructor(
    private sidenavService: SidenavService,
    public authService: AuthService,
    private homeService: HomeService,
    private dialog: MatDialog,
    private sidenavLinksService: SidenavLinksService,
    private taskService: ProjectTaskService
  ) {
    this.isSidebarClosed = sidenavService.isSidenavClosed;
    this.asideSubscription = sidenavService.sidenavVisibilityChange.subscribe((value) => {
      this.isSidebarClosed = value;
    });
  }

  async ngOnInit() {
    // this.perfectScrollbar = new PerfectScrollbar(document.querySelector('#home'));
    setTimeout(async () => {
      this.sidenavLinksService.selectLink(this.sidenavLinksService.home);
    });

    const hour = moment().hour();
    if (hour < 12) {
      this.timeOfDay = 'morning';
    } else if (hour < 18) {
      this.timeOfDay = 'afternoon';
    } else {
      this.timeOfDay = 'evening';
    }
    this.currentUser = this.authService.getLoggedInUser();

    const preferences = JSON.parse(localStorage.getItem('preferences'));
    this.shownSection = preferences?.home_shown_section || 'all';
    this.workOrderSortProperty = preferences?.home_work_order_sort_property || 'code';
    this.workOrderSortDirection = preferences?.home_work_order_sort_direction || 'asc';
    this.projectSortProperty = preferences?.home_project_sort_property || 'code';
    this.projectSortDirection = preferences?.home_project_sort_direction || 'asc';
    this.tasksPageSize = preferences?.home_tasks_page_size || this.pageSizeOptions[0];
    this.projectsPageSize = preferences?.home_projects_page_size || this.pageSizeOptions[0];
    this.workOrdersPageSize = preferences?.home_work_orders_page_size || this.pageSizeOptions[0];
    if (this.isStaffOnAnyModule) {
      this.selectTaskTimespan(
        preferences?.home_selected_task_timespan
          ? this.timespanOptions.find((o) => o.value === preferences.home_selected_task_timespan) ||
              this.timespanOptions[0]
          : this.timespanOptions.find((o) => o.isDefault) || this.timespanOptions[0],
        false
      );
      this.selectWorkOrderTimespan(
        preferences?.home_selected_work_order_timespan
          ? this.timespanOptions.find((o) => o.value === preferences.home_selected_work_order_timespan) ||
              this.timespanOptions[0]
          : this.timespanOptions.find((o) => o.isDefault) || this.timespanOptions[0],
        false
      );
      this.workOrderRoleOptions.splice(1, 0, {
        value: 'assigned',
        menuLabel: 'Assigned To Me',
        icon: 'how_to_reg',
      });
      this.projectRoleOptions.splice(1, 0, {
        value: 'assigned',
        menuLabel: 'Assigned To Me',
        icon: 'how_to_reg',
      });
    } else {
      this.selectTaskTimespan(this.timespanOptions[0], false);
      this.selectWorkOrderTimespan(this.timespanOptions[0], false);
    }
    this.selectTaskRole(
      preferences?.home_selected_task_role
        ? this.taskRoleOptions.find((o) => o.value === preferences.home_selected_task_role) || this.taskRoleOptions[0]
        : this.taskRoleOptions.find((o) => o.isDefault) || this.taskRoleOptions[0],
      false
    );
    this.selectWorkOrderRole(
      preferences?.home_selected_work_order_role
        ? this.workOrderRoleOptions.find((o) => o.value === preferences.home_selected_work_order_role) ||
            this.workOrderRoleOptions[0]
        : this.workOrderRoleOptions.find((o) => o.isDefault) || this.workOrderRoleOptions[0],
      false
    );
    this.selectTaskType(
      preferences?.home_selected_task_type
        ? this.taskTypeOptions.find((o) => o.value === preferences.home_selected_task_type) || this.taskTypeOptions[0]
        : this.taskTypeOptions.find((o) => o.isDefault) || this.taskTypeOptions[0],
      false
    );
    this.selectProjectStatus(
      preferences?.home_selected_project_status
        ? this.projectStatusOptions.find((o) => o.value === preferences.home_selected_project_status) ||
            this.projectStatusOptions[0]
        : this.projectStatusOptions.find((o) => o.isDefault) || this.projectStatusOptions[0],
      false
    );
    this.selectProjectRole(
      preferences?.home_selected_project_role
        ? this.projectRoleOptions.find((o) => o.value === preferences.home_selected_project_role) ||
            this.projectRoleOptions[0]
        : this.projectRoleOptions.find((o) => o.isDefault) || this.projectRoleOptions[0],
      false
    );
    this.refresh();

    this.closeTaskSubscription = this.taskService.closeTask.subscribe(({ taskId, projectId }) => {
      const currentProject = this.projects.find((project) => project.id === projectId);
      this.removeTask(currentProject, taskId);
    });
  }

  ngOnDestroy(): void {
    if (this.asideSubscription) {
      this.asideSubscription.unsubscribe();
    }
    if (this.closeTaskSubscription) {
      this.closeTaskSubscription.unsubscribe();
    }
  }

  hasClosedStandaloneARF(task: any) {
    return task.project_id == null && task?.arf?.status_id === ArfStatus.Closed;
  }

  async refresh(taskView?: boolean) {
    this.loading = true;
    const getTasks = taskView === undefined ? ['all', 'tasks', 'reviews'].indexOf(this.shownSection) > -1 : taskView;
    const getWorkOrders = taskView === undefined ? ['all', 'workOrders'].indexOf(this.shownSection) > -1 : false;
    const getProjects = taskView === undefined ? ['all', 'projects'].indexOf(this.shownSection) > -1 : taskView;
    const data = await this.homeService
      .getHomeData(
        getTasks,
        getWorkOrders,
        getProjects,
        this.selectedTaskTimespan?.value,
        this.selectedTaskType?.value,
        this.selectedTaskRole?.value,
        this.selectedWorkOrderTimespan?.value,
        this.selectedWorkOrderRole?.value,
        this.selectedProjectStatus?.value,
        this.selectedProjectRole?.value
      )
      .toPromise();
    const projects = data?.projects || [];
    const workOrders = data?.workOrders || [];

    const myProjects = projects.filter(
      (p) =>
        ((['all', 'assigned'].indexOf(this.selectedProjectRole.value) > -1 && p.amAssigned) ||
          (['all', 'requested'].indexOf(this.selectedProjectRole.value) > -1 && p.requested) ||
          (['all', 'following'].indexOf(this.selectedProjectRole.value) > -1 && p.following) ||
          (['all', 'access'].indexOf(this.selectedProjectRole.value) > -1 && p.hasAccess)) &&
        (!this.selectedProjectStatus.value || p.status_id === +this.selectedProjectStatus.value)
    );

    if (!this.authService.isVendorOnAnyProject && !this.authService.isStaffOnAnyModule) {
      data.myProjectCount = myProjects.length;
    }

    // set these after loading the data and filtering to prevent the UI from flashing
    // this.projects = projects;
    this.myTasks = data.tasks?.filter((t: any) => !this.hasClosedStandaloneARF(t)) || [];
    this.myProjects = myProjects;
    taskView ? null : (this.myWorkOrders = workOrders);
    this.myTaskCount = data.myTaskCount;
    this.myProjectCount = data.myProjectCount;
    this.myWorkOrderCount = data.myWorkOrderCount;
    this.myReviewCount = data.myReviewCount;
    this.myProjectFilteredCount = this.myProjects.length;
    this.myWorkOrderFilteredCount = this.myWorkOrders.length;

    taskView ? null : this.sortWorkOrders(this.workOrderSortProperty || 'code', true);
    this.sortProjects(this.projectSortProperty || 'code', true);

    this.loading = false;
  }

  get myTaskFilteredCount() {
    return this.myTasks?.length || 0;
  }

  public get isWorkspaceStaff(): boolean {
    return this.authService.isUserWorkspaceStaff();
  }

  get myReviewFilteredCount() {
    return this.myTasks.filter((t) => !!t.accessory_data)?.length || 0;
  }

  selectSection(sectionType: string) {
    this.shownSection = sectionType;
    this.editLocalStoragePreferences('home_shown_section', this.shownSection);
    if (['all', 'tasks'].indexOf(sectionType) > -1) {
      this.selectTaskType(
        this.taskTypeOptions.find((o) => o.value === 'all'),
        false
      );
    } else if (sectionType === 'reviews') {
      this.selectTaskType(
        this.taskTypeOptions.find((o) => o.value === 'review'),
        false
      );
    }
    this.refresh();
  }

  selectTaskType(type, refresh = true) {
    this.selectedTaskType = type;
    this.editLocalStoragePreferences('home_selected_task_type', type.value);
    if (refresh) {
      this.refresh(true);
    }
  }

  selectTaskTimespan(timespan, refresh = true) {
    this.selectedTaskTimespan = timespan;
    this.editLocalStoragePreferences('home_selected_task_timespan', timespan.value);
    if (refresh) {
      this.refresh(true);
      this.taskPaginator?.firstPage();
    }
  }

  selectTaskRole(option, refresh = true) {
    this.selectedTaskRole = option;
    this.editLocalStoragePreferences('home_selected_task_role', option.value);
    if (refresh) {
      this.refresh(true);
      this.taskPaginator?.firstPage();
    }
  }

  selectWorkOrderTimespan(timespan, refresh = true) {
    this.selectedWorkOrderTimespan = timespan;
    this.editLocalStoragePreferences('home_selected_work_order_timespan', timespan.value);
    if (refresh) {
      this.refresh();
      this.workOrderPaginator?.firstPage();
    }
  }

  selectWorkOrderRole(option, refresh = true) {
    this.selectedWorkOrderRole = option;
    this.editLocalStoragePreferences('home_selected_work_order_role', option.value);
    if (refresh) {
      this.refresh();
      this.workOrderPaginator?.firstPage();
    }
  }

  selectProjectStatus(status, refresh = true) {
    this.selectedProjectStatus = status;
    this.editLocalStoragePreferences('home_selected_project_status', status.value);
    if (refresh) {
      this.refresh();
      this.projectPaginator?.firstPage();
    }
  }

  selectProjectRole(option, refresh = true) {
    this.selectedProjectRole = option;
    this.editLocalStoragePreferences('home_selected_project_role', option.value);
    if (refresh) {
      this.refresh();
      this.projectPaginator?.firstPage();
    }
  }

  sortWorkOrders(property: string, noFlip = false) {
    if (this.workOrderSortProperty === property) {
      if (!noFlip) {
        this.workOrderSortDirection = this.workOrderSortDirection === 'asc' ? 'desc' : 'asc';
      }
    } else {
      this.workOrderSortProperty = property;
      this.workOrderSortDirection = 'asc';
    }
    this.editLocalStoragePreferences('home_work_order_sort_property', this.workOrderSortProperty);
    this.editLocalStoragePreferences('home_work_order_sort_direction', this.workOrderSortDirection);
    if (property.toLowerCase() === 'assigned_user_id') {
      const notAssignedWorkOrders =
        this.myWorkOrders?.filter((workOrder: WorkOrder) => !workOrder[property.split('.')[0]]) || [];
      this.myWorkOrders = orderBy(
        this.myWorkOrders?.filter((workOrder: WorkOrder) => workOrder[property.split('.')[0]]),
        [
          (workOrder: WorkOrder) => workOrder.assigned_user?.first_name.toLowerCase(),
          (workOrder: WorkOrder) => workOrder.assigned_user?.last_name.toLowerCase(),
        ],
        [this.workOrderSortDirection, this.workOrderSortDirection]
      );
      this.myWorkOrders.push(...notAssignedWorkOrders);
    } else if (
      this.workOrderSortDirection === 'desc' &&
      (property.toLowerCase() === 'priority.id' || property.toLowerCase() === 'due_date')
    ) {
      const workOrdersWithNoSetProperty =
        this.myWorkOrders?.filter((workOrder: WorkOrder) => !workOrder[property.split('.')[0]]) || [];
      this.myWorkOrders = orderBy(
        this.myWorkOrders?.filter((workOrder: WorkOrder) => workOrder[property.split('.')[0]]),
        property,
        this.workOrderSortDirection
      );
      this.myWorkOrders.push(...workOrdersWithNoSetProperty);
    } else {
      this.myWorkOrders = orderBy(
        this.myWorkOrders,
        property === 'location' ? ['building.code', 'floor.sequence'] : property,
        property === 'location'
          ? [this.workOrderSortDirection, this.workOrderSortDirection]
          : this.workOrderSortDirection
      );
    }
  }

  sortProjects(property: string, noFlip = false) {
    if (this.projectSortProperty === property) {
      if (!noFlip) {
        this.projectSortDirection = this.projectSortDirection === 'asc' ? 'desc' : 'asc';
      }
    } else {
      this.projectSortProperty = property;
      this.projectSortDirection = 'asc';
    }
    this.editLocalStoragePreferences('home_project_sort_property', this.projectSortProperty);
    this.editLocalStoragePreferences('home_project_sort_direction', this.projectSortDirection);
    let sortProperty;
    if (property === 'location') {
      sortProperty = ['building.code', 'floor.sequence'];
    } else if (property === 'short_description') {
      sortProperty = (p) => this.getProjectShortDescription(p);
    } else {
      sortProperty = property;
    }
    this.myProjects = orderBy(
      this.myProjects,
      sortProperty,
      property === 'location' ? [this.projectSortDirection, this.projectSortDirection] : this.projectSortDirection
    );
  }

  getProjectShortDescription(project) {
    if (project.title) {
      return project.title;
    } else if (project?.request?.topic) {
      return `${project.request?.topic?.topic_category?.topic_group?.name} > ${project.request?.topic?.topic_category?.name} > ${project.request?.topic?.name}`;
    } else {
      return `Project ${project.code}`;
    }
  }

  private editLocalStoragePreferences(key: string, value: any) {
    const preferenceString = localStorage.getItem('preferences');
    const preferences = preferenceString ? JSON.parse(preferenceString) || {} : {};
    preferences[key] = value;
    localStorage.setItem('preferences', JSON.stringify(preferences));
  }

  private _updateProjectTask(updatedTask: Task) {
    // update the project task with the new task
    this.projects = this.projects?.map((project: Project) => {
      if (+project.id === +updatedTask?.project_id) {
        const tasks = project.tasks?.map((task: Task) => {
          if (+task?.id === +updatedTask.id) {
            return updatedTask;
          }
          return task;
        });
        // Maybe go through the keys and only return the original keys
        return { ...project, tasks };
      }
      return project;
    });
  }

  private _decreaseTotals(task: Task) {
    this.myTaskCount--;

    if (task.accessory_data?.length) {
      this.myReviewCount--;
    }
  }

  private _increaseTotals(task: Task) {
    this.myTaskCount++;

    if (task.accessory_data?.length) {
      this.myReviewCount++;
    }
  }

  // Opens up the selected task using the view task dialog
  public async viewTask(task: Task) {
    // this will track the status id just in case it changes

    const previousTaskStatusId = task.status_id;
    const dialog = this.dialog.open(ViewTaskDialogComponent, {
      width: '900px',
      data: {
        taskId: task.id,
      },
      autoFocus: false,
    });

    dialog.componentInstance.taskChanged.subscribe((updatedTask) => {
      this._updateProjectTask(updatedTask);
    });

    dialog.componentInstance.reviewChanged.subscribe(async (updatedTask: Task) => {
      this._updateProjectTask(updatedTask);
    });

    // this one updates the nested modals
    this.taskService.taskReviewChanged.subscribe((reviewedTask: Task) => {
      this._updateProjectTask(reviewedTask);
    });

    dialog.componentInstance.taskFollowingChanged.subscribe(
      async (followingTaskEvent: { task: Task; action: 'follow' | 'unfollow' }) => {
        const followingTaskProject = this.projects.find(
          (followingProjectItem: Project) => +followingProjectItem.id === +followingTaskEvent.task.project_id
        );

        const projectIndex = this.projects.findIndex((foundProject) => +foundProject.id === task.project_id);

        if (+followingTaskEvent.task.assigned_user_id === +this.authService.currentUser.id) {
          return; // don't need to filter
        }

        if (+projectIndex >= 0) {
          // either follow or unfolllow, we don't want duplicates
          followingTaskProject.tasks = followingTaskProject?.tasks.filter((t: Task) => +t?.id !== +task?.id);

          if (followingTaskEvent.action === 'follow') {
            // this happens when you unfollow and then change your mind
            // just add the task back
            followingTaskProject.tasks = [...(followingTaskProject?.tasks || []), followingTaskEvent.task];
            this._increaseTotals(task);
          } else {
            this._decreaseTotals(task);
          }
          this.projects[projectIndex] = followingTaskProject;
        }
      }
    );
    // spinner initiator
    dialog.componentInstance.startHomeTaskViewSpinner.subscribe(
      ({ isReAssigningUser, taskId }) => (this.isReAssigningUserAndTaskId = { isReAssigningUser, taskId })
    );

    // task assignment from the view dialog
    dialog.componentInstance.taskAssignmentChanged.subscribe(async (assignedTaskEvent) => {
      this.taskAssignmentChanged(task, assignedTaskEvent);
      this.isReAssigningUserAndTaskId = undefined;
    });

    dialog.afterClosed().subscribe(async (res) => {
      // check if anything changed
      const updatedProject = this.projects?.find((p) => task.project_id === +p.id);
      const updatedTask = updatedProject?.tasks?.find((t) => +t.id === +task.id);

      if (updatedTask) {
        updatedTask.previousTaskStatusId = previousTaskStatusId;

        this.taskUpdate();
      }
      this.refresh();
    });
  }

  public isOverdue(date: Date): boolean {
    if (moment() > moment(date)) {
      return true;
    } else {
      return false;
    }
  }

  // handles re-assignments
  public taskAssignmentChanged(task: Task, updatedTask: Task) {
    // combine assinged use id and task follower ids
    const assingUserId = +task.assigned_user_id ? [+task.assigned_user_id] : [];
    // get all the task followers
    const followerIds = [
      ...assingUserId,
      ...(task?.followers?.map((mappedTaskFollower: User) => +mappedTaskFollower?.id) || []),
    ];

    if (followerIds.includes(+this.authService?.currentUser?.id)) {
      // include task
      this.taskUpdate();
      this._increaseTotals(task);
    } else {
      const taskProject = this.projects.find((taskProjectItem: Project) => +taskProjectItem.id === +task.project_id);

      const projectIndex = this.projects.findIndex((foundProject) => +foundProject.id === +taskProject.id);
      // exclude task
      taskProject.tasks = taskProject?.tasks.filter((t: Task) => +t?.id !== +task?.id);
      this._decreaseTotals(task);
      this.projects[projectIndex] = taskProject;
    }
  }

  public taskUpdate() {
    this.refresh(true);
  }

  public removeTask(project?: Project, taskId?: number) {
    if (project && taskId) {
      const task = project.tasks.find((t) => t.id === taskId);
      project.tasks = project.tasks.filter((t) => t.id !== taskId);
      project.myOverdueTasksCount = project.tasks.filter(
        (projectTask) => moment(projectTask.due_date) < moment()
      ).length;

      this.myTaskCount--;

      if (task.accessory_data?.length) {
        this.myReviewCount--;
      }
    } else {
      void this.refresh(true);
    }
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  handleTasksPageEvent(event, paginator: HTMLElement) {
    paginator.scrollIntoView({ behavior: 'smooth' });
    this.editLocalStoragePreferences(`home_tasks_page_size`, event.pageSize);
    const preferences = JSON.parse(localStorage.getItem('preferences') || '{}');
    this.tasksPageSize = preferences?.home_tasks_page_size || this.tasksPageSize;
  }

  handleWorkOrdersPageEvent(event, paginator) {
    paginator.scrollIntoView({ behavior: 'smooth' });
    this.editLocalStoragePreferences(`home_work_orders_page_size`, event.pageSize);
    const preferences = JSON.parse(localStorage.getItem('preferences') || '{}');
    this.workOrdersPageSize = preferences?.home_work_orders_page_size || this.workOrderPaginator;
  }

  handleProjectsPageEvent(event, paginator) {
    paginator.scrollIntoView({ behavior: 'smooth' });
    this.editLocalStoragePreferences(`home_projects_page_size`, event.pageSize);
    const preferences = JSON.parse(localStorage.getItem('preferences') || '{}');
    this.projectsPageSize = preferences?.home_projects_page_size || this.projectsPageSize;
  }
}
