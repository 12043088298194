<div class="subcomponent products-form container-fluid p-4">
  <header class="row align-items-center">
    <div class="col-auto">
      <h4 class="m-0 dkblue font-weight-semibold">Purchasing</h4>
    </div>
    <div class="col text-right">
      <button mat-button [matMenuTriggerFor]="help_menu" color="accent" type="button" class="ml-3">ARF Help</button>
      <button mat-button (click)="openCostCodeHelpDialog()" color="accent" type="button" class="ml-3">
        Cost Code Help
      </button>
      <button
        mat-stroked-button
        color="accent"
        class="ml-3 bg-white"
        *ngIf="projectTenants && projectTenants.length > 0"
        (click)="openNewCustomerDialog()"
      >
        + Tenant Purchase
      </button>
      <button (click)="openProductListDialog()" mat-stroked-button color="accent" class="bg-white ml-3">
        Manage Products
      </button>
      <mat-menu #help_menu="matMenu" xPosition="before" style="max-width: 300px">
        <div class="d-flex flex-column p-3">
          <h5 class="dkblue font-weight-semibold mb-3">Purchasing Process</h5>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">1.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Add a Tenant or select UHAT Managed</p>
              <p class="dkgray font-weight-normal mb-0">UHAT Managed will set UHAT as the Tenant.</p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">2.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Add any needed products to the list</p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">3.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Switch over to Bid View to manage bids and reviews</p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">4.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Add Bids as necessary</p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">5.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Submit Budget for Tenant Review</p>
              <p class="dkgray font-weight-normal mb-0">
                This budget can be sent with budget totals OR bid prices. Staff Budget Review can be added if needed.
                *This is not the ARF and will need to be completed before any ARFs can be created.
              </p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">6.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Create ARF(s) for review</p>
              <p class="dkgray font-weight-normal mb-0">
                A Bid Price will need to be selected for each product before ARFs can be created. Once each product has
                a bid price selected, the 'Create ARFs' button will appear and the review process can begin.
              </p>
            </span>
          </span>
          <span class="d-flex align-items-top mb-3">
            <p class="dkblue font-weight-semibold mb-0 mr-2">7.</p>
            <span class="d-flex flex-column">
              <p class="dkblue font-weight-semibold mb-0">Finalize Budget & Award Bids</p>
              <p class="dkgray font-weight-normal mb-0">
                Once all ARFs are approved, the project's budget can be finalized and project access will be granted to
                the supplier companies.
              </p>
            </span>
          </span>
        </div>
      </mat-menu>
    </div>
  </header>
  <div class="mt-4 py-5 full-width text-center" *ngIf="projectTenants && projectTenants.length === 0">
    <h4 *ngIf="!project?.workspace_manager_id" class="m-0 dkblue font-weight-semibold mt-4 mb-4 pointer">
      {{
        isWorkspaceStaff
          ? ' Please add a ' + (project?.module?.name || 'Workspace') + ' Manager to continue'
          : ' Please contact the project admin to continue.'
      }}
    </h4>
    <h4 *ngIf="project?.workspace_manager_id" class="m-0 dkblue font-weight-semibold mt-4 mb-4 pointer">
      Who will be paying for this project?
    </h4>
    <button
      (click)="openProjectDetailsDialog()"
      *ngIf="!project?.workspace_manager_id && isWorkspaceStaff"
      mat-stroked-button
      color="primary"
      tyle="button"
      class="mr-3 bg-white py-4 px-5 br-6"
    >
      <h5 class="mb-1">Add {{ project?.module?.name || 'Workspace' }} Manager</h5>
    </button>
    <button
      *ngIf="project?.workspace_manager_id"
      (click)="openNewCustomerDialog()"
      mat-stroked-button
      color="primary"
      tyle="button"
      class="mr-3 bg-white py-4 px-5 br-6"
    >
      <h5 class="mb-1">Add a Tenant</h5>
      <h6 class="m-0 font-weight-normal">(Tenant will reimburse)</h6>
    </button>
    <button
      *ngIf="project?.workspace_manager_id"
      (click)="addUHATManaged()"
      mat-stroked-button
      color="primary"
      tyle="button"
      class="mr-3 bg-white py-4 px-5 br-6"
    >
      <h5 class="mb-1">UHAT Managed</h5>
      <h6 class="m-0 font-weight-normal">(UHAT is Purchasing)</h6>
    </button>
  </div>
  <mat-tab-group
    *ngIf="projectTenants && projectTenants.length > 0"
    mat-align-tabs="start"
    [(selectedIndex)]="selectedTenantIndex"
    (selectedTabChange)="tenantTabChange($event)"
    animationDuration="0ms"
  >
    <mat-tab [label]="getTenantName(tenant)" *ngFor="let tenant of projectTenants">
      <div class="row">
        <!-- PRODUCT CONTAINER -->
        <main class="col">
          <div class="border rounded-corners mb-4 p-3">
            <div class="d-flex font-weight-semibold mb-2">
              <div class="col-7">Customer</div>
              <div class="col-2">Products</div>
              <div class="col">Total Purchase Price</div>
            </div>
            <div class="d-flex">
              <div class="col-7 d-flex flex-row align-items-center pl-3 pr-5">
                <app-profile-thumbnail
                  [width]="40"
                  [height]="40"
                  [userId]="tenantRep?.id"
                  class="follower mr-3"
                ></app-profile-thumbnail>
                <span class="d-flex flex-column mr-5">
                  <p class="dkblue font-weight-semibold m-0 lh-18">
                    {{ tenantRep?.first_name }} {{ tenantRep?.last_name }}
                  </p>
                  <p class="font-weight-normal dkgray m-0 lh-18">
                    {{ getTenantName(tenant) }}
                  </p>
                </span>
                <small
                  *ngIf="isAdmin && selectedTenant?.id === tenant?.id && !cantMakeChanges"
                  (click)="changeTenantContact(tenant)"
                  class="hover-u accent pointer"
                >
                  Change
                </small>
              </div>
              <div class="col-2">
                <h3 class="font-weight-normal dkblue m-0">
                  {{ tenant?.project_products?.length || 0 }}
                </h3>
              </div>
              <div class="col">
                <h3 class="font-weight-normal dkblue m-0">
                  {{ getGrandTotal(tenant) | currency: 'USD' }}
                </h3>
              </div>
            </div>
          </div>
          <div class="m-portlet mb-4">
            <div class="row bg-shade-ltblue border-bottom border-gray align-items-center m-0">
              <div class="col-auto" style="width: 40px">#</div>
              <div class="col-5 py-3 px-3 d-flex">
                <h6 class="dkblue font-weight-semibold m-0">Product / Description</h6>
              </div>
              <div class="col-1 py-3 pl-0 pr-4 d-flex text-right">
                <h6 class="dkblue font-weight-semibold m-0">Qty</h6>
              </div>
              <div class="col-2 py-3 pl-0 pr-4 d-flex text-right">
                <h6 class="dkblue font-weight-semibold m-0">Bids</h6>
              </div>
              <div class="col-2 py-3 pl-0 pr-4 text-right">
                <h6 class="dkblue font-weight-semibold mb-0 mr-auto">Each</h6>
              </div>
              <div class="col py-3 pl-0 pr-4 text-right">
                <h6 class="dkblue font-weight-semibold mb-0 mr-auto">Subtotal</h6>
              </div>
            </div>
            <!-- PRODUCT CONTAINER -->
            <div
              id="projectProduct"
              class="ease"
              cdkDropList
              [cdkDropListData]="tenant.project_products"
              (cdkDropListDropped)="drop($event, tenant.id)"
              cdkDropListOrientation="vertical"
              *ngIf="costCodes"
            >
              <div
                cdkDrag
                [cdkDragData]="p"
                *ngFor="let p of tenant?.project_products || []; let ppi = index; let last = last"
                class="m-portlet__body product-form item p-0 ease bg-white"
                [cdkDragDisabled]="cantMakeChanges"
              >
                <app-project-product
                  #productComponent
                  [cdkDragDisabled]="true"
                  [produckRank]="p.rank"
                  [products]="allProducts"
                  [productIndex]="ppi"
                  [projectProduct]="p"
                  [tenant]="tenant"
                  [costCodes]="costCodes"
                  [selectedQuotes]="tenant.selected_quotes"
                  (projectProductCreated)="projectProductUpdated($event, p, tenant)"
                  (projectProductUpdated)="projectProductUpdated($event, p, tenant)"
                  (projectProductDeleted)="projectProductDeleted($event, tenant, ppi)"
                  (productListChanged)="getProducts()"
                  (selectedQuoteItemChanged)="selectQuoteItem($event)"
                  [module]="project.module"
                ></app-project-product>
                <div *ngIf="!cantMakeChanges" style="margin-top: -50px" class="pb-1">
                  <button cdkDragHandle mat-icon-button color="primary" type="button" class="gray draggable">
                    <mat-icon>open_with</mat-icon>
                  </button>
                </div>
                <div
                  class="draggable m-portlet__body product-form item ease product-placeholder"
                  *cdkDragPlaceholder
                ></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col px-5">
              <button
                mat-flat-button
                color="success"
                class="lh-30 px-3 mr-3"
                (click)="addProjectProduct(tenant)"
                [disabled]="cantMakeChanges"
              >
                Add Line Item
              </button>
              <button
                *ngIf="tenant.project_products.length > 0"
                class="lh-30 px-3"
                mat-button
                color="accent"
                (click)="downloadBudget()"
              >
                Download Budget PDF
              </button>
            </div>
            <div class="col-6">
              <div *ngIf="tenant?.project_products.length > 0" class="m-portlet">
                <div class="m-portlet__body p-4">
                  <div class="d-flex align-items-center m-0">
                    <h4 class="dkblue font-weight-semibold mb-0 mr-auto">Total Purchase Price</h4>
                    <h4 class="dkblue font-weight-semibold mb-0 ml-auto">
                      {{ getGrandTotal(tenant) | currency: 'USD' }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <!-- SUMMARY PANE -->
        <aside class="col-auto">
          <div class="d-flex mb-4 mt-2 bg-shade-red p-3 br-10" *ngIf="reviewProcessMessage">
            <mat-icon class="red mr-2">error</mat-icon>
            <p class="m-0 dkblue font-weight-semibold flex-wrap">
              {{ reviewProcessMessage }}
            </p>
          </div>
          <div class="m-portlet mb-3">
            <div
              class="m-portlet__body d-flex align-items-center p-3"
              (click)="toggleSection('bids')"
              [ngClass]="{ 'item-last': tenant?.quotes?.length === 0 || !sectionToggles.bids }"
            >
              <mat-icon color="accent" class="mr-2" style="font-size: 25px; height: 25px"> request_quote </mat-icon>
              <span class="d-flex align-items-center mr-auto pointer">
                <h5 class="dkblue font-weight-semibold m-0 lh-30">Supplier Bids</h5>
                <mat-icon class="gray ml-1" *ngIf="tenant?.quotes?.length > 0">{{
                  sectionToggles.bids ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                }}</mat-icon>
              </span>
              <button
                mat-flat-button
                (click)="$event.stopPropagation(); openQuoteDialog(tenant)"
                [disabled]="
                  (cantMakeChanges &&
                    (!tenant.budget_approval_process.noARFReviewTasksStarted ||
                      !tenant.budget_approval_process?.tenantTaskIsComplete)) ||
                  allProductsAreInStock
                "
                color="success"
                type="button"
                class="ml-auto lh-30 pl-3 pr-3"
              >
                + Bid
              </button>
            </div>
            <div *ngIf="sectionToggles.bids && tenant?.quotes?.length > 0" class="m-portlet__body px-3 pb-3 pt-0">
              <div
                class="d-flex flex-column p-2 pl-3 mt-2 border border-gray br-5 align-items-center"
                *ngFor="let quote of sectionToggles.bids ? tenant.quotes : []; let last = last"
                [ngClass]="{ 'item-last': last }"
              >
                <div class="d-flex full-width align-items-center">
                  <div class="text-truncate mr-auto">
                    <h6 (click)="viewQuote(quote)" class="m-0 ltblue hover-u font-weight-semibold text-truncate mb-0">
                      {{ quote.company?.name }}
                    </h6>
                    <h6 class="font-weight-normal gray mb-0" *ngIf="quote?.description">
                      {{ quote?.description }}
                    </h6>
                    <h6 class="font-weight-normal gray mb-0">
                      Total Bid: {{ +quote?.subtotal + +quote?.tax | currency: 'USD' }}
                    </h6>
                  </div>
                  <p class="ml-4 mb-0 dkblue font-weight-semibold">
                    {{ productService.getQuoteAwardedSubtotal(tenant, quote) | currency: 'USD' }}
                  </p>
                  <mat-icon [matMenuTriggerFor]="bid_menu" class="ml-2 pointer" color="accent">more_vert</mat-icon>
                  <mat-menu #bid_menu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      (click)="openQuoteDialog(tenant, quote)"
                      [disabled]="
                        (!tenant.budget_approval_process?.tenantTaskIsComplete ||
                          !tenant.budget_approval_process.noARFReviewTasksStarted) &&
                        cantMakeChanges
                      "
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit Bid</span>
                    </button>
                    <button mat-menu-item [disabled]="cantMakeChanges" (click)="deleteQuote(tenant, quote)">
                      <mat-icon>delete</mat-icon>
                      <span>Delete Bid</span>
                    </button>
                    <button
                      mat-menu-item
                      [matMenuTriggerFor]="view_attachments"
                      *ngIf="getQuoteFiles(quote).length > 0"
                    >
                      <mat-icon>attach_file</mat-icon>
                      <span>View Files</span>
                    </button>
                    <mat-menu #view_attachments="matMenu" xPosition="before">
                      <div *ngFor="let file of getQuoteFiles(quote)">
                        <button mat-menu-item [matMenuTriggerFor]="attachment_menu">
                          <mat-icon>description</mat-icon>
                          <span>{{ file.name }}</span>
                        </button>
                        <mat-menu #attachment_menu="matMenu" xPosition="before">
                          <button (click)="previewFile(file)" mat-menu-item>
                            <mat-icon>find_in_page</mat-icon>
                            <span>View</span>
                          </button>
                          <button (click)="downloadFile(file)" mat-menu-item>
                            <mat-icon>get_app</mat-icon>
                            <span class="pr-2">Download</span>
                          </button>
                          <mat-divider></mat-divider>
                          <button
                            (click)="editFile(file)"
                            mat-menu-item
                            *ngIf="isWorkspaceStaff"
                            [disabled]="cantMakeChanges"
                          >
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>
                        </mat-menu>
                      </div>
                    </mat-menu>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="m-portlet mb-3" *ngIf="tenant.type_id !== TenantType.Internal">
            <div class="m-portlet__body d-flex flex-column p-3">
              <div class="d-flex align-items-center">
                <mat-icon color="accent" class="mr-2" style="font-size: 25px; height: 25px"> upload_file </mat-icon>
                <h5
                  class="m-0 dkblue font-weight-semibold text-truncate mr-auto"
                  (click)="
                    viewTask(tenant.budget_tenant_approval_task_id || tenant.budget_tenant_saved_approval_task_id)
                  "
                >
                  Budget Review
                </h5>
                <button
                  mat-button
                  color="warn"
                  class="ml-auto lh-30 px-3"
                  type="button"
                  *ngIf="cantMakeChanges"
                  (click)="makeProductsEditable(tenant)"
                  [disabled]="!isAdmin"
                >
                  Make a Change
                </button>
                <button
                  *ngIf="
                    selectedTenant?.id === tenant?.id &&
                    !cantMakeChanges &&
                    (tenant.budget_tenant_approval_task_id ||
                      tenant.budget_tenant_saved_approval_task_id ||
                      tenant.budget_approval_task_id ||
                      tenant.budget_saved_approval_task_id)
                  "
                  mat-flat-button
                  color="warn"
                  class="ml-4 lh-30"
                  type="button"
                  (click)="submitForReview(tenant)"
                  [disabled]="
                    (!tenant.budget_approval_process?.noARFReviewTasksStarted &&
                      !tenant.budget_approval_process?.allProductsAssigned) ||
                    !isAdmin ||
                    !reviewCanStart
                  "
                >
                  Resubmit
                </button>
                <mat-icon [matMenuTriggerFor]="tenant_menu" class="ml-2 pointer" color="accent">more_vert</mat-icon>
                <mat-menu #tenant_menu="matMenu" class="mt-2" xPosition="before">
                  <button
                    mat-menu-item
                    (click)="submitApproval(tenant)"
                    *ngIf="!tenant.budget_approval_task_id && !tenant.budget_saved_approval_task_id"
                    [disabled]="!tenant.project_products?.length || !isAdmin || !!cantMakeChanges"
                  >
                    <mat-icon>assignment_turned_in</mat-icon>
                    <span>Staff Review</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="deleteStaffReview(tenant)"
                    *ngIf="tenant.budget_saved_approval_task_id"
                    [disabled]="!tenant.project_products?.length || !isAdmin"
                  >
                    <mat-icon>delete</mat-icon>
                    <span>Remove Staff Review</span>
                  </button>
                  <button
                    *ngIf="tenant.cover_letter_required"
                    (click)="openCoverLetter(tenant)"
                    mat-menu-item
                    [disabled]="!!cantMakeChanges || !isAdmin"
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Edit Cover Letter</span>
                  </button>
                  <button (click)="toggleCoverLetter(tenant)" mat-menu-item [disabled]="!!cantMakeChanges || !isAdmin">
                    <mat-icon>description</mat-icon>
                    <span>{{ tenant.cover_letter_required ? 'Remove' : 'Add' }} Cover Letter</span>
                  </button>
                </mat-menu>
              </div>
            </div>
            <div class="m-portlet__body px-3 pb-3 pt-0">
              <div class="d-flex flex-column">
                <span
                  class="red d-flex pl-4 ml-3"
                  *ngIf="
                    !tenant.budget_tenant_approval_task_id &&
                    !tenant.budget_tenant_saved_approval_task_id &&
                    !tenant.project_products?.length
                  "
                >
                  <mat-icon class="red" style="font-size: 18px">error</mat-icon>
                  Add products to continue
                </span>

                <div class="d-flex flex-column p-2 mt-2 border border-gray br-5" *ngIf="tenant.cover_letter_required">
                  <div class="d-flex align-items-center full-width">
                    <mat-icon color="accent" class="mr-2">description</mat-icon>
                    <p class="m-0 ltblue font-weight-semibold text-truncate hover-u">Cover Letter</p>
                    <button
                      mat-button
                      color="accent"
                      class="ml-auto lh-26 px-2"
                      (click)="openCoverLetter(tenant)"
                      [disabled]="cantMakeChanges || !isAdmin"
                    >
                      Edit
                    </button>
                    <mat-icon [matMenuTriggerFor]="cover_letter_menu" class="ml-2 pointer" color="accent"
                      >more_vert</mat-icon
                    >
                    <mat-menu #cover_letter_menu="matMenu" class="mt-2" xPosition="before">
                      <button
                        (click)="toggleCoverLetter(tenant)"
                        mat-menu-item
                        [disabled]="cantMakeChanges || !isAdmin"
                      >
                        <mat-icon>description</mat-icon>
                        <span>{{ tenant.cover_letter_required ? 'Remove' : 'Add' }} Cover Letter</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div
                  class="d-flex flex-column p-2 mt-2 border border-gray br-5"
                  *ngIf="tenant.budget_approval_task_id || tenant.budget_saved_approval_task_id"
                >
                  <div class="d-flex align-items-center full-width">
                    <mat-icon *ngIf="tenant.budget_saved_approval_task_id" class="mr-2 gray">
                      radio_button_unchecked
                    </mat-icon>
                    <mat-icon
                      *ngIf="
                        tenant.budget_approval_task_id &&
                        tenant.budget_approval_process?._staffReview?._approvalTask?.status_id === TaskStatus.Complete
                      "
                      class="mr-2 green"
                    >
                      check_circle
                    </mat-icon>
                    <app-profile-thumbnail
                      *ngIf="
                        tenant.budget_approval_task_id &&
                        tenant.budget_approval_process?._staffReview?._approvalTask?.status_id !== TaskStatus.Complete
                      "
                      [noBorder]="true"
                      [matTooltip]="
                        tenant.budget_approval_process?._staffReview?._approvalTask?.assigned_user?.first_name +
                        ' ' +
                        tenant.budget_approval_process?._staffReview?._approvalTask?.assigned_user?.last_name
                      "
                      class="mr-2 d-inline-block align-middle"
                      [height]="24"
                      [width]="24"
                      [userId]="tenant.budget_approval_process?._staffReview?._approvalTask?.assigned_user?.id"
                    ></app-profile-thumbnail>
                    <p
                      (click)="viewTask(tenant.budget_approval_task_id || tenant.budget_saved_approval_task_id)"
                      class="m-0 ltblue font-weight-semibold text-truncate hover-u"
                    >
                      Staff Review
                    </p>
                    <p class="ml-auto mb-0 dkblue font-weight-semibold">
                      {{ getGrandTotal(tenant) | currency: 'USD' }}
                    </p>
                  </div>
                </div>
                <div
                  class="d-flex flex-column p-2 mt-2 border border-gray br-5"
                  *ngIf="tenant.budget_tenant_approval_task_id || tenant.budget_tenant_saved_approval_task_id"
                >
                  <div class="d-flex align-items-center full-width">
                    <mat-icon *ngIf="tenant.budget_tenant_saved_approval_task_id" class="mr-2 gray">
                      radio_button_unchecked
                    </mat-icon>
                    <mat-icon
                      *ngIf="
                        tenant.budget_tenant_approval_task_id &&
                        tenant.budget_approval_process?._tenantReview?._approvalTask?.status_id === TaskStatus.Complete
                      "
                      class="mr-2 green"
                    >
                      check_circle
                    </mat-icon>
                    <app-profile-thumbnail
                      *ngIf="
                        tenant.budget_tenant_approval_task_id &&
                        tenant.budget_approval_process?._tenantReview?._approvalTask?.status_id !== TaskStatus.Complete
                      "
                      [noBorder]="true"
                      [matTooltip]="
                        tenant.budget_approval_process?._tenantReview?._approvalTask?.assigned_user?.first_name +
                        ' ' +
                        tenant.budget_approval_process?._tenantReview?._approvalTask?.assigned_user?.last_name
                      "
                      class="mr-2 d-inline-block align-middle"
                      [height]="24"
                      [width]="24"
                      [userId]="tenant.budget_approval_process?._tenantReview?._approvalTask?.assigned_user?.id"
                    ></app-profile-thumbnail>
                    <p
                      (click)="
                        viewTask(tenant.budget_tenant_approval_task_id || tenant.budget_tenant_saved_approval_task_id)
                      "
                      class="m-0 ltblue font-weight-semibold text-truncate hover-u"
                    >
                      Tenant Review
                    </p>
                    <p class="ml-auto mb-0 dkblue font-weight-semibold">
                      {{ getGrandTotal(tenant) | currency: 'USD' }}
                    </p>
                  </div>
                </div>
                <div
                  *ngIf="!tenant.budget_tenant_approval_task_id && !tenant.budget_tenant_saved_approval_task_id"
                  class="d-flex align-items-center mt-3"
                >
                  <button
                    mat-flat-button
                    color="success"
                    class="lh-30 mr-3"
                    type="button"
                    (click)="submitApproval(tenant, false)"
                    [disabled]="
                      !tenant.project_products?.length ||
                      !isAdmin ||
                      !reviewCanStart ||
                      (tenant.budget_approval_process?._staffReview?._approvalTask &&
                        tenant.budget_approval_process?._staffReview?._approvalTask?.status_id !== TaskStatus.Complete)
                    "
                  >
                    Submit To Tenant
                  </button>
                  <button
                    mat-stroked-button
                    color="accent"
                    class="lh-30 d-flex align-items-center pr-2"
                    type="button"
                    [disabled]="!isAdmin"
                    [matMenuTriggerFor]="add_menu"
                    *ngIf="!tenant.budget_approval_process.hasStaffTask || !tenant.cover_letter_required"
                  >
                    Add
                    <mat-icon style="margin-top: -1px">arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #add_menu="matMenu" class="mt-2" xPosition="after">
                    <button *ngIf="!tenant.cover_letter_required" (click)="toggleCoverLetter(tenant)" mat-menu-item>
                      <mat-icon>add_box</mat-icon>
                      <span>Cover Letter</span>
                    </button>
                    <button
                      mat-menu-item
                      (click)="submitApproval(tenant)"
                      *ngIf="!tenant.budget_approval_process.hasStaffTask"
                      [disabled]="!tenant.project_products?.length || !isAdmin"
                    >
                      <mat-icon>assignment_turned_in</mat-icon>
                      <span>Staff Review</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div
            class="m-portlet mb-3 p-3"
            *ngIf="
              tenant?.selected_quotes?.length &&
              !allProductsAreInStock &&
              (tenant.budget_tenant_approval_task_id ||
                tenant.budget_tenant_saved_approval_task_id ||
                this.selectedTenant?.type_id === TenantType.Internal)
            "
          >
            <div class="d-flex align-items-center mb-3" [ngClass]="{ 'item-last': !sectionToggles.arfs }">
              <div class="d-flex align-items-center full-width">
                <span class="d-flex align-items-center mr-auto pointer" (click)="toggleSection('arfs')">
                  <mat-icon color="accent" class="mr-1" style="font-size: 25px; height: 25px"> request_quote </mat-icon>
                  <h5 class="dkblue font-weight-semibold m-0 lh-30">ARFs</h5>
                  <mat-icon class="gray ml-1" *ngIf="tenant?.selected_quotes?.length > 0">{{
                    sectionToggles.arfs ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                  }}</mat-icon>
                </span>
                <button
                  *ngIf="
                    tenant.budget_approval_process.allProductsAssigned &&
                    !canSubmitArfs &&
                    !tenant.budget_approval_process?.isFinalized &&
                    (((!tenant.budget_approval_task_id ||
                      tenant.budget_approval_process._staffReview?.isTaskComplete) &&
                      tenant.budget_approval_process.tenantTaskIsComplete &&
                      tenant.type_id !== TenantType.Internal) ||
                      (tenant.type_id === TenantType.Internal && !cantMakeChanges && !allArfsAreCreated))
                  "
                  (click)="openSolicitationDialog()"
                  mat-flat-button
                  color="primary"
                  class="ml-auto lh-30"
                >
                  <span class="pulse-dot-success"></span>
                  Award Bids
                </button>
              </div>
              <div class="text-right">
                <div
                  *ngIf="
                    (selectedTenant?.id === tenant?.id &&
                      tenant.type_id === TenantType.Internal &&
                      !allArfsAreCompleted) ||
                    tenant.budget_approval_process.isFinalized
                  "
                >
                  <button
                    mat-button
                    color="warn"
                    class="bg-white lh-30"
                    type="button"
                    *ngIf="cantMakeChanges && tenant.type_id === TenantType.Internal"
                    (click)="makeProductsEditable(tenant)"
                    [disabled]="!isAdmin"
                  >
                    Make a Change
                  </button>
                  <button
                    *ngIf="selectedTenant?.id === tenant?.id && !cantMakeChanges && canSubmitArfs && !noArfsInReview"
                    mat-flat-button
                    color="warn"
                    class="lh-30"
                    type="button"
                    (click)="submitForReview(tenant)"
                    [disabled]="!isAdmin"
                  >
                    Submit For Review
                  </button>
                </div>
                <button
                  *ngIf="
                    selectedTenant?.id === tenant?.id &&
                    cantMakeChanges &&
                    (tenant.budget_approval_process?._tenantReview?.isTaskComplete ||
                      tenant.type_id === TenantType.Internal) &&
                    canFinalize &&
                    !tenant.budget_approval_process?.isFinalized
                  "
                  mat-flat-button
                  color="primary"
                  class="lh-30"
                  type="button"
                  (click)="budgetApproval(tenant)"
                  [disabled]="!isAdmin"
                >
                  Finalize Purchase
                </button>
              </div>
            </div>
            <div
              class="red d-flex pl-4 ml-3 mb-3"
              *ngIf="
                noArfsAreCreated &&
                !tenant.budget_approval_process.tenantTaskIsComplete &&
                tenant.type_id !== TenantType.Internal
              "
            >
              <mat-icon class="red mr-2" style="font-size: 18px">error</mat-icon>
              Tenant Approval must be complete before sending the ARFs for review
            </div>
            <div
              class="red d-flex pl-4 ml-3 mb-3"
              *ngIf="
                !noArfsAreCreated &&
                tenant.budget_approval_process._tenantReview &&
                !tenant.budget_approval_process.tenantTaskIsComplete &&
                tenant.type_id !== TenantType.Internal
              "
            >
              <mat-icon class="red mr-2" style="font-size: 18px">error</mat-icon>
              Tenant Approval must be complete before making changes to ARFs
            </div>
            <div
              class="red d-flex ml-4 mb-3"
              *ngIf="
                noArfsAreCreated &&
                tenant.budget_approval_process._tenantReview &&
                tenant.budget_approval_process.tenantTaskIsComplete &&
                tenant.type_id !== TenantType.Internal &&
                !tenant.budget_approval_process.allProductsAssigned
              "
            >
              <mat-icon class="red" style="font-size: 18px">error</mat-icon>
              Select Supplier Bid Prices to continue
            </div>
            <div
              class="d-flex flex-column p-2 mt-2 border border-gray br-5"
              *ngFor="let quote of sectionToggles.arfs ? tenant.selected_quotes : []; let i = index"
            >
              <div class="d-flex align-items-center">
                <mat-icon *ngIf="quote.arf_saved_approval_task_id" class="mr-2 align-middle gray">
                  radio_button_unchecked
                </mat-icon>
                <mat-icon
                  *ngIf="quote.arf_approval_task_id && quote?.task?.status_id === TaskStatus.Complete"
                  class="mr-2 align-middle green"
                >
                  check_circle
                </mat-icon>
                <app-profile-thumbnail
                  *ngIf="quote.arf_approval_task_id && quote?.task?.status_id !== TaskStatus.Complete"
                  [noBorder]="true"
                  [matTooltip]="quote?.task?.assigned_user?.first_name + ' ' + quote?.task?.assigned_user?.last_name"
                  class="mr-2 d-inline-block align-middle"
                  [height]="24"
                  [width]="24"
                  [userId]="quote?.task?.assigned_user?.id"
                ></app-profile-thumbnail>
                <div>
                  <p
                    class="m-0 ltblue pointer font-weight-semibold hover-u text-truncate mr-3 mt-1 lh-16"
                    (click)="viewTask(quote.task?.id)"
                  >
                    ARF: {{ quote?.company?.name }}
                  </p>
                  <small class="ml-1 dkgray" *ngIf="quote?.description">{{ quote?.description }}</small>
                </div>
                <p
                  *ngIf="
                    (quote?.arf_approval_task_id &&
                      (!quote?.arf_approval_task?.is_locked || tenant.type_id === TenantType.Internal)) ||
                    tenant.budget_approval_process?.isFinalized
                  "
                  class="ml-auto mb-0 dkblue font-weight-semibold"
                >
                  {{ productService.getQuoteAwardedSubtotal(tenant, quote) | currency: 'USD' }}
                </p>
                <mat-icon
                  *ngIf="
                    (quote?.arf_approval_task_id &&
                      (!quote?.arf_approval_task?.is_locked || tenant.type_id === TenantType.Internal)) ||
                    tenant.budget_approval_process?.isFinalized
                  "
                  [matMenuTriggerFor]="arf_menu"
                  class="ml-2 pointer"
                  color="accent"
                  >more_vert</mat-icon
                >
                <mat-menu #arf_menu="matMenu" xPosition="before">
                  <button
                    mat-menu-item
                    [disabled]="!cantMakeChanges || tenant.budget_approval_process?.isFinalized"
                    (click)="resetArfReview(tenant, quote)"
                  >
                    <mat-icon>refresh</mat-icon>
                    <span>Reset Arf</span>
                  </button>
                  <button mat-menu-item [disabled]="!!cantMakeChanges" (click)="editArf(tenant, quote)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Arf</span>
                  </button>
                  <button mat-menu-item *ngIf="quote?.arf_approval_task?.is_locked" (click)="unlockArfTask(quote)">
                    <mat-icon>lock_open_right</mat-icon>
                    <span>Unlock Task</span>
                  </button>
                  <button *ngIf="isAppAdminOrFinanceManager" mat-menu-item (click)="reUploadArfPdf(quote)">
                    <mat-icon>upload_file</mat-icon>
                    <span>Re-upload ARF PDF</span>
                  </button>
                </mat-menu>

                <button
                  *ngIf="!quote?.arf_approval_task_id && !quote?.arf_saved_approval_task_id && canSubmitArfs"
                  mat-flat-button
                  color="primary"
                  class="ml-auto lh-26 px-2"
                  type="button"
                  (click)="submitARFApproval(tenant, quote)"
                  [disabled]="
                    !tenant.budget_approval_process.allProductsAssigned ||
                    (!!tenant.budget_approval_task_id &&
                      !tenant.budget_approval_process._staffReview?.isTaskComplete) ||
                    (!tenant.budget_approval_process.tenantTaskIsComplete && tenant.type_id !== TenantType.Internal) ||
                    (tenant.type_id === TenantType.Internal && !cantMakeChanges && !noArfsInReview) ||
                    !isAdmin ||
                    !reviewCanStart
                  "
                >
                  Submit
                </button>
                <button
                  *ngIf="quote?.arf_saved_approval_task_id"
                  mat-flat-button
                  color="primary"
                  class="ml-auto lh-26 px-2"
                  type="button"
                  (click)="submitRevision(quote, true, true)"
                  [disabled]="
                    (tenant.type_id !== TenantType.Internal &&
                      (!tenant.budget_approval_process.tenantTaskIsComplete ||
                        (tenant.budget_approval_process?.hasStaffTask &&
                          !tenant.budget_approval_process.staffTaskIsComplete) ||
                        !tenant.budget_approval_process.allProductsAssigned)) ||
                    (tenant.type_id === TenantType.Internal && !quote?.task?.is_locked) ||
                    !isAdmin ||
                    !reviewCanStart
                  "
                >
                  Resubmit
                </button>
                <button
                  *ngIf="
                    quote?.arf_approval_task_id &&
                    quote.arf_approval_task?.is_locked &&
                    tenant.type_id !== TenantType.Internal &&
                    !tenant.budget_approval_process?.isFinalized
                  "
                  mat-button
                  color="primary"
                  class="ml-auto lh-26 px-2"
                  type="button"
                  (click)="unlockArfTask(quote)"
                  [disabled]="
                    (tenant.type_id !== TenantType.Internal &&
                      (!tenant.budget_approval_process.tenantTaskIsComplete ||
                        (tenant.budget_approval_process?.hasStaffTask &&
                          !tenant.budget_approval_process.staffTaskIsComplete))) ||
                    !cantMakeChanges ||
                    !tenant.budget_approval_process.allProductsAssigned
                  "
                >
                  Unlock
                </button>
              </div>
            </div>
          </div>
          <div class="m-portlet mb-3 p-3" *ngIf="selectedSolicitations?.length">
            <div class="d-flex align-items-center mb-3" [ngClass]="{ 'item-last': !sectionToggles.solicitations }">
              <div class="d-flex align-items-center full-width">
                <span class="d-flex align-items-center mr-auto pointer" (click)="toggleSection('solicitations')">
                  <mat-icon color="accent" class="mr-1" style="font-size: 25px; height: 25px"> request_quote </mat-icon>
                  <h5 class="dkblue font-weight-semibold m-0 lh-30">Solicitations</h5>
                  <mat-icon class="gray ml-1" *ngIf="selectedSolicitations?.length > 0">{{
                    sectionToggles.solicitations ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                  }}</mat-icon>
                </span>
              </div>
            </div>
            <div
              class="d-flex flex-column p-2 mt-2 border border-gray br-5"
              *ngFor="let s of sectionToggles.solicitations ? selectedSolicitations : []; let i = index"
            >
              <div class="d-flex align-items-center">
                <p
                  class="m-0 ltblue pointer font-weight-semibold hover-u mr-auto mt-1"
                  (click)="openSolicitationDialog(s)"
                >
                  Solicitation {{ i + 1 }}
                </p>
                <mat-icon [matMenuTriggerFor]="arf_menu" class="ml-2 pointer" color="primary">more_vert</mat-icon>
                <mat-menu #arf_menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="openSolicitationDialog(s)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div
            class="m-portlet mb-3 p-3"
            *ngIf="
              allProductsAreInStock &&
              (tenant.budget_tenant_approval_task_id ||
                tenant.budget_tenant_saved_approval_task_id ||
                this.selectedTenant?.type_id === TenantType.Internal)
            "
          >
            <div class="d-flex align-items-center" [ngClass]="{ 'item-last': !sectionToggles.arfs }">
              <div class="col pl-0">
                <span class="d-flex align-items-center mr-auto pointer">
                  <mat-icon color="accent" class="mr-2" style="font-size: 25px; height: 25px"> inventory </mat-icon>
                  <h5 class="dkblue font-weight-semibold m-0 lh-30">
                    <!-- {{ tenant.budget_approval_process?.isFinalized ? 'Finalized' : 'Unfinalized' }} -->
                    In Stock Purchase
                  </h5>
                  <mat-icon class="gray ml-1" *ngIf="tenant?.selected_quotes?.length > 0">{{
                    sectionToggles.arfs ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
                  }}</mat-icon>
                </span>
              </div>
              <div class="text-right">
                <div
                  *ngIf="
                    selectedTenant?.id === tenant?.id && tenant.type_id === TenantType.Internal && !allArfsAreCompleted
                  "
                >
                  <button
                    mat-button
                    color="warn"
                    class="bg-white lh-30"
                    type="button"
                    *ngIf="cantMakeChanges"
                    (click)="makeProductsEditable(tenant)"
                    [disabled]="!isAdmin"
                  >
                    Make a Change
                  </button>
                </div>
                <button
                  *ngIf="
                    selectedTenant?.id === tenant?.id &&
                    ((cantMakeChanges && tenant.budget_approval_process?._tenantReview?.isTaskComplete) ||
                      (tenant.type_id === TenantType.Internal && allProductsAreInStock)) &&
                    !tenant.budget_approval_process?.isFinalized
                  "
                  mat-flat-button
                  color="primary"
                  class="lh-30"
                  type="button"
                  (click)="budgetApproval(tenant)"
                  [disabled]="!isAdmin"
                >
                  Finalize Purchase
                </button>
              </div>
            </div>
          </div>
          <button
            *ngIf="tenant.budget_approval_process?.isFinalized"
            mat-flat-button
            color="primary"
            type="button"
            disabled
            class="full-width"
          >
            Purchase Finalized
          </button>
        </aside>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- EXPORT -->
<div id="kendo-content">
  <kendo-pdf-export #pdf paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <!-- <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p> -->
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4 pt-2">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
            <!-- <img
              *ngIf="project?.module?.id === 4 || project?.module?.id === 5"
              id="headerLogo_1call"
              src="../../assets/img/1call_logo.png"
            /> -->
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-8 pr-0">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project?.code }} | {{ project?.building_code }}-{{ project?.floor_code }} | {{ project?.title }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  <small>
                    {{ project && project.project_manager_first_name + ' ' + project.project_manager_last_name }}
                  </small>
                </p>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project?.building_code }}-{{ project?.floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ !project.end_date ? 'Not Set' : (project?.end_date | date: 'd MMMM yyyy') }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> {{ project?.module?.name }} Manager </label>
                <p>
                  <small>
                    {{ project && project.workspace_manager_first_name + ' ' + project.workspace_manager_last_name }}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-approval mb-4">
        <div class="row align-items-top">
          <div class="col-3">
            <label class="main-label m-0">Your Review</label>
            <div class="mt-2">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Approve this Budget</p>
            </div>
            <div class="mt-3">
              <span class="form-checkbox mr-2 mb-1 d-inline-block align-middle"> </span>
              <p class="m-0 black d-inline-block align-middle">Request a Change</p>
            </div>
          </div>
          <div class="col">
            <label class="main-label m-0">Your Name (Print)</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Your Signature</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
          <div class="col">
            <label class="main-label m-0">Funding Source</label>
            <div class="full-width border-bottom border-dark pb-3 mb-2"></div>
            <label class="main-label">Chart Field Spread (If Applicable)</label>
            <div style="padding-top: 1px" class="full-width border-bottom border-dark pb-3"></div>
          </div>
        </div>
      </section>
      <div>
        <section class="form-title">
          <div class="d-flex">
            <p class="bg-gray pl-2 pr-2 white br-5 d-inline-block lh-20 mb-2">
              {{ project?.module?.name }}
            </p>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h1 class="black m-0">
                <b>Budget</b>
              </h1>
              <!-- <p class="m-0">
                    Prepared for:
                    <b>
                      {{
                        exportingTenant
                          ? exportingTenant.type_id === 3
                            ? 'UHAT'
                            : exportingTenant.tenant_name
                          : ''
                      }}
                    </b>
                  </p> -->
            </div>
          </div>
        </section>
        <section class="form-table form-bid-package">
          <section class="border-bottom-black">
            <div class="row">
              <div class="col-12">
                <div class="row form-table-header mt-0 mb-0 pt-0 pb-0 align-items-center">
                  <div class="col-1 text-center pl-0 pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Item </label>
                  </div>
                  <div class="col-6 pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Product </label>
                  </div>
                  <div class="col-1 text-left pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> QTY </label>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Unit Price </label>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <label class="main-label m-0 p-0 text-uppercase"> Total </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- PRODUCT LINE ITEMS PER GROUP -->
            <div
              class="row"
              *ngFor="
                let product of selectedTenant?.project_products | productFilter: null:runProductFilter;
                let i = index
              "
            >
              <div class="col-12">
                <div class="row form-table-line-item pt-1 pb-1">
                  <div class="col-1 text-center pl-0 pr-0">
                    <p class="m-0">
                      <!-- Product Number group n# + .(product number) -->
                      {{ i + 1 }}
                    </p>
                  </div>
                  <div class="col-6 pr-0">
                    <p class="m-0 d-block text-wrap">
                      <!-- Product Name -->
                      {{ product.product?.name || product?.name }}
                    </p>
                    <p class="m-0 d-block dkgray text-wrap">
                      <!-- Product Description -->
                      {{ product?.description }}
                    </p>
                  </div>
                  <div class="col-1 text-left pr-0">
                    <p class="m-0 font-weight-bold">
                      {{ product.quantity }}
                    </p>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <p
                      class="m-0 font-weight-bold"
                      [ngClass]="{
                        'unit-price-hundredths-padding': !isThirdDecimalNonZero(productInfo(product, 'unit_price'))
                      }"
                    >
                      {{
                        productInfo(product, 'unit_price')
                          | USDollar: (isThirdDecimalNonZero(productInfo(product, 'unit_price')) ? 3 : 2):true
                      }}
                    </p>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <p class="m-0 font-weight-bold">
                      {{ productInfo(product, 'total_price') | USDollar: 2:true }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- SHIPPING IF SHIPPING IS ON AWARDED QUOTE FOR GROUP -->
            <div class="row" *ngIf="selectedTenant?.shipping_budget">
              <div class="col-12">
                <div class="row form-table-line-item pt-1 pb-1 align-items-center">
                  <div class="col-1 pl-0 pr-0">
                    <p class="m-0"></p>
                  </div>
                  <div class="col-4 pr-0">
                    <p class="m-0 d-block text-truncate">
                      <!-- Product Name -->
                      Shipping
                    </p>
                  </div>
                  <div class="col-5 text-right pr-0 d-flex">
                    <span class="m-0 dkgray ml-auto" style="font-size: 5pt">
                      <i>*Shipping costs subject to change on final billing</i>
                    </span>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <p class="m-0 font-weight-bold">
                      {{ selectedTenant?.shipping_budget | USDollar: 2:true }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- SALES TAX IF SALES TAX IS ON AWARDED QUOTE FOR GROUP -->
            <div class="row" *ngIf="selectedTenant?.tax_bid_total || selectedTenant?.tax_budget_total">
              <div class="col-12">
                <div class="row form-table-line-item pt-1 pb-1 align-items-center">
                  <div class="col-1 pl-0 pr-0">
                    <p class="m-0"></p>
                  </div>
                  <div class="col-4 pr-0">
                    <p class="m-0 d-block text-truncate">
                      <!-- Product Name -->
                      Sales Tax
                    </p>
                  </div>
                  <div class="col-5 text-right pr-0 d-flex">
                    <span class="m-0 dkgray ml-auto" style="font-size: 5pt">
                      <i>*Sales Tax subject to change on final billing</i>
                    </span>
                  </div>
                  <div class="col-2 text-right pr-0">
                    <p class="m-0 font-weight-bold">
                      {{ selectedTenant?.tax_bid_total || selectedTenant?.tax_budget_total | USDollar: 2:true }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section class="form-totals form-bid-package">
          <div class="row m-0 pt-3">
            <div class="col pl-0">
              <p class="m-0 black">
                <b>Total Project Budget</b>
              </p>
            </div>
            <div class="col-2 text-right pr-0">
              <p class="m-0 black">
                <b>
                  {{ getGrandTotal(selectedTenant) | USDollar: 2:true }}
                </b>
              </p>
            </div>
          </div>
        </section>
        <section id="comments" class="mt-5 mb-5">
          <div class="row">
            <div class="col">
              <label class="main-label m-0">Comments</label>
              <div class="row m-0 border-bottom border-dark pb-3"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
              <div class="row m-0 border-bottom border-dark pb-4"></div>
            </div>
          </div>
        </section>
        <!-- <div *ngIf="1 < PEBsToExport.length - 1" class="page-break"></div> -->
      </div>
    </div>
  </kendo-pdf-export>
</div>
<app-arf-export
  #arf
  [project]="project"
  [selectedTenant]="selectedTenant"
  [selectedQuote]="selectedTenant?.selected_quotes[selectedQuotesIndex]"
  [include]="include"
  [runProductFilter]="runProductFilter"
>
</app-arf-export>
<div id="kendo-content">
  <kendo-pdf-export #coverLetter paperSize="Letter" forcePageBreak=".page-break" [margin]="{ bottom: 50 }">
    <div class="form-print-pdf" id="print-content">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <section class="form-footer full-width p-4">
          <div class="row align-items-center">
            <div class="col-1">
              <img id="form-footer-logo" src="../../assets/img/main_logo.png" alt="" />
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                <span class="accent"> 1call.cloud </span>
                <br />
                A service of University Hospital Authority & Trust
              </p>
            </div>
            <div class="col pl-0">
              <p class="dkgray m-0">
                1000 NE 13th St, Nicholson Tower, STE 6900
                <br />
                Oklahoma City, OK 73104
              </p>
            </div>
            <div class="col-2 pl-0">
              <p class="dkgray m-0">
                ph 405.271.8001 <br />
                fx 405.271.1301
              </p>
            </div>
            <div class="col-auto">
              <p class="dkgray m-0">
                {{ getCurrentTime() }} <br />
                {{ getCurrentDate() }}
              </p>
            </div>
            <div class="col-auto text-right">
              <p class="dkgray m-0">{{ pageNum }} of {{ totalPages }} <br /><br /></p>
            </div>
          </div>
        </section>
      </ng-template>

      <section class="form-header">
        <div class="row align-items-top">
          <div class="col-4">
            <img id="headerLogo" src="../../assets/img/uhat_logo_forms.png" />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-4">
                <label class="main-label mt-0"> Project </label>
                <p class="text-truncate">
                  {{ project.code }} | {{ project.building_code }}-{{ project.floor_code }} | {{ project.title }}
                </p>
              </div>
              <!-- <div class="col-4">
                <label class="main-label mt-0"> Location </label>
                <p>{{ project.building_code }}-{{ project.floor_code }}</p>
              </div> -->
              <div class="col-4">
                <label class="main-label mt-0"> Project Manager </label>
                <p>
                  {{ project.project_manager_first_name }}
                  {{ project.project_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <!-- <label class="main-label mt-0">
                  Total SF
                </label>
                <p>
                  {{
                    project.square_footage ? numberWithCommas(project.square_footage) : 'Not Given'
                  }}
                </p> -->
              </div>
              <div class="col-4">
                <label class="main-label mt-0"> Estimated Completion </label>
                <p>
                  {{ !project.end_date ? 'Not Set' : (project.end_date | date: 'd MMMM yyyy') }}
                </p>
              </div>
              <div class="col-4">
                <label class="main-label mt-0">
                  {{ project?.module?.name }}
                </label>
                <p>
                  {{ project.workspace_manager_first_name }}
                  {{ project.workspace_manager_last_name }}
                  &nbsp;
                  <small> UHAT </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-9 ml-auto pr-5">
            <div class="row mb-4 mt-5">
              <div class="col">
                <h4 class="m-0 font-weight-bold">Budget Review</h4>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <label class="main-label mt-0"> Prepared For </label>
                <p class="m-0">
                  <b>{{ currentTenantInfo.name }}</b
                  ><br />
                  <span>{{ currentTenantInfo.title }}</span
                  ><br />
                  <span>{{ currentTenantInfo.department }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <p class="mb-2">{{ currentTenantInfo.first_name }},</p>
                <p [innerHTML]="selectedTenant?.budget_cover_letter_text" class="m-0" style="white-space: pre-wrap"></p>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <!-- PROJECT: PROJECT MANAGER -->
                <p class="m-0 d-flex flex-column">
                  <b>{{ pmInfo.name }}</b>
                  <span>{{ pmInfo.title }}</span>
                  <span>{{ pmInfo.email }}</span>
                  <span>{{ pmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <!-- PROJECT: WORKSPACE MANAGER -->
                <p class="m-0 d-flex flex-column" *ngIf="wmInfo">
                  <b>{{ wmInfo.name }}</b>
                  <span>{{ wmInfo.title }}</span>
                  <span>{{ wmInfo.email }}</span>
                  <span>{{ wmInfo.phone | phoneNumber }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-pdf-export>
</div>
