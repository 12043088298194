export enum ApplicationRole {
  AppAdmin = 1,
  ModuleAdmin = 2,
  ProjectRequester = 5,
  VendorAdmin = 6,
  VendorUser = 7,
  ArchitectAdmin = 8,
  ProjectArchitect = 9,
  WorkspaceManager = 10,
  ProjectManager = 11,
  Staff = 12,
  ProjectVendor = 14,
  AuthorizedRequester = 16,
  ProjectEngineer = 17,
  ModuleArchitect = 18,
  ModuleProjectManager = 19,
  ModuleEngineer = 20,
  ChiefFacilitiesManagementOfficer = 21,
  ProjectContact = 22,
  CustomerProjectRepresentative = 23,
  OUPDUser = 26,
  DirectorOfFacilitySolutions = 28,
  InventoryControlOfficer = 29,
  BusinessSystemsDirector = 30,
  OUFIT = 31,
  ARManager = 32,
  CEO = 34,
  AccountCoordinator = 35,
  CreedEditor = 36,
  BuildingsDepartmentsSuitesManager = 37,
  ProfileEditor = 38,
  ExtSecurity = 39,
  WorkspaceTopicEditor = 40,
  CompanyEditor = 41,
  FinanceManager = 42,
  OneCallDirector = 43,
  OneCallBSD = 44,
  UHATBSD = 45,
  OneCallAdminBSD = 46,
  UHATAdminBSD = 47,
  DataAnalyst = 49,
  DirectoryManager = 50,
  CFO = 51,
}
