import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { orderBy, remove } from 'lodash';
import * as moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import { ViewTaskDialogComponent } from 'src/app/components';
import {
  NotificationType as NotificationTypeEnum,
  ProjectStatus,
  ResourceType,
  TaskReviewStatus,
  TaskStatus,
} from 'src/app/enums';
import {
  AppRoutingService,
  AuthService,
  MeetingService,
  ModalService,
  NotificationService,
  ProjectService,
  ProjectTaskService,
  RequestService,
  SidenavLinksService,
  SidenavService,
  SocketService,
  UserService,
  WorkOrderService,
} from 'src/app/services';
import { APIFilter, Notification, User } from 'src/app/types';

@Component({
  selector: 'app-notification-overlay-panel',
  templateUrl: './notification-overlay-panel.component.html',
  styleUrls: ['./notification-overlay-panel.component.scss'],
})
export class NotificationOverlayPanelComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private sidenavService: SidenavService,
    private sidenavLinksService: SidenavLinksService,
    public notificationService: NotificationService,
    private userService: UserService,
    public authService: AuthService,
    private router: Router,
    private routingService: AppRoutingService,
    private socketService: SocketService,
    private projectService: ProjectService,
    private workOrderService: WorkOrderService,
    private meetingService: MeetingService,
    private requestService: RequestService,
    private taskService: ProjectTaskService,
    private snackbar: MatSnackBar,
    private modalService: ModalService
  ) {}

  currentUser: User;
  notifications: {
    read: {
      list: Notification[];
      cursor: string;
      count: number;
      open: boolean;
      loading: boolean;
    };
    unread: {
      list: Notification[];
      cursor: string;
      count: number;
      open: boolean;
      loading: boolean;
    };
  } = {
    read: {
      list: [],
      cursor: null,
      count: 0,
      open: false,
      loading: false,
    },
    unread: {
      list: [],
      cursor: null,
      count: 0,
      open: true,
      loading: false,
    },
  };
  pageSize = 20;
  notificationFields: string[] = [
    'type{id,background_color,icon,icon_color,header_is_from_user_name,header,subject}',
    'for_user{id}',
    'from_user{id,first_name,last_name}',
    'parent_id',
    'parent',
    'message',
    'is_read',
    'is_my_action',
    'created_datetime',
  ];
  notificationSound: HTMLAudioElement;
  myActions = false;
  myActionsUnreadCount = 0;
  generalUnreadCount = 0;
  updatingNotificationStatus = false;
  isLoading = true;
  public updatingNotificationMenuItem = false;

  async ngOnInit() {
    const notificationList = document.querySelector('#notification-list');
    const perfectScrollbar = new PerfectScrollbar(notificationList);
    perfectScrollbar.update();

    await this.refresh();
    // Load Notification Sound
    this.notificationSound = new Audio();
    this.notificationSound.src = '../../../assets/audio/notification.mp3';
    this.notificationSound.load();

    // Define what to do when new notification comes in via web socket
    this.socketService.socket.on('notification', async (notification: Notification) => {
      if (notification.for_user_id === this.currentUser?.id) {
        this.notificationSound.play().catch((err) => {
          console.warn(`Could not play sound for notification ${notification?.id}: ${err.message}`);
        });
        await this.notificationService.updateUnreadNotificationCount().toPromise();
        const newNotification = await this.notificationService
          .getNotificationById(notification.id, this.notificationFields)
          .toPromise();
        if (!this.myActions || newNotification.is_my_action === (this.myActions ? 1 : 0)) {
          this.notifications.unread.list.unshift(newNotification);
          this.extendNotifications(this.notifications.unread.list);
        }

        // update the notification counts only
        await this.updateCounts();
      }
    });

    this.socketService.socket.on('notification_deleted', async (parentId) => {
      parentId = Number(parentId);

      const updatedReadList = this.notifications.read.list.filter((n) => n.parent_id !== parentId);
      const updatedUnreadList = this.notifications.unread.list.filter((n) => n.parent_id !== parentId);
      if (!parentId || (!this.isLoading && (updatedReadList?.length <= 10 || updatedUnreadList?.length <= 10))) {
        await this.refresh();
      } else {
        const removedNotifications = [...this.notifications.read.list, ...this.notifications.unread.list].filter(
          (n) => n.parent_id === parentId
        );
        this.notifications.read.list = updatedReadList;
        this.notifications.unread.list = updatedUnreadList;

        for (const notification of removedNotifications) {
          if (!notification.is_read) {
            if (notification.is_my_action) {
              this.myActionsUnreadCount--;
            } else {
              this.generalUnreadCount--;
            }
          }
        }
      }
    });
  }

  async refresh() {
    this.isLoading = true;
    await this.notificationService.updateUnreadNotificationCount().toPromise();
    this.currentUser = this.authService.getLoggedInUser();
    if (this.currentUser && this.currentUser.id) {
      // load read and unread notifications
      await this.loadNotifications(true);
      await this.loadNotifications(false);
      await this.updateCounts();
    }

    this.isLoading = false;
  }

  async updateCounts() {
    const myActionNotificationFilters: APIFilter[] = [
      { type: 'field', field: 'for_user_id', value: this.currentUser.id.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_my_action', value: '1' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_read', value: '0' },
    ];
    const generalNotificationFilters: APIFilter[] = [
      { type: 'field', field: 'for_user_id', value: this.currentUser.id.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_my_action', value: '0' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_read', value: '0' },
    ];
    const myActionsUnreadResponse = await this.notificationService
      .getNotifications(myActionNotificationFilters, [], 1)
      .toPromise();
    this.myActionsUnreadCount = myActionsUnreadResponse.count ?? 0;
    const generalUnreadResponse = await this.notificationService
      .getNotifications(generalNotificationFilters, [], 1)
      .toPromise();
    this.generalUnreadCount = generalUnreadResponse.count;
  }

  async loadNotifications(isRead: boolean, getNextPage: boolean = false) {
    const notifications = this.notifications[isRead ? 'read' : 'unread'];
    notifications.loading = true;
    const cursor = getNextPage ? notifications.cursor : null;
    const notificationFilters: APIFilter[] = [
      { type: 'field', field: 'for_user_id', value: this.currentUser.id.toString() },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_read', value: isRead ? '1' : '0' },
    ];
    if (this.myActions) {
      notificationFilters.push({ type: 'operator', value: 'AND' });
      notificationFilters.push({
        type: 'field',
        field: 'is_my_action',
        value: this.myActions ? '1' : '0',
      });
    }
    const notificationResult = await this.notificationService
      .getNotifications(
        notificationFilters,
        this.notificationFields,
        !cursor && notifications.list.length > this.pageSize ? notifications.list.length : this.pageSize,
        cursor
      )
      .toPromise();

    const newNotifications = notificationResult.data.notifications;
    notifications.list = cursor ? notifications.list.concat(newNotifications) : newNotifications;
    notifications.cursor = notificationResult.next;
    notifications.count = notificationResult.count;
    notifications.list = this.extendNotifications(notifications.list);

    // set the show message to false
    notifications?.list?.forEach((workOrderUpdate: Notification) => {
      workOrderUpdate.collapseMessageView = true;
      workOrderUpdate.showCollapseMessageViewButton = false;
    });

    notifications.list = orderBy(notifications.list, (n) => n.created_datetime, 'desc');
    notifications.loading = false;
  }

  extendNotifications(notifications: Notification[]): Notification[] {
    this.setDateHeaders(notifications);
    for (const n of notifications) {
      // fill in data from notification type
      if (n.type) {
        // Applies to all notification types
        if (n.type?.header_is_from_user_name) {
          if (n.from_user?.id) {
            n.header = `${n.from_user.first_name} ${n.from_user.last_name}`;
          } else {
            n.header = 'Someone';
          }
        }
        if (!n.type?.background_color) {
          n.type.background_color = `gray`;
        }

        // TODO: don't show items without parents
        // TODO: unfollow menu item: for tasks or WOs only

        // Applies to only certain notification types

        // icon
        n.type.icon = n.type.icon ?? 'assignment_turned_in';
        n.type.icon_color = n.type.icon_color ?? n.type.background_color ?? 'ltblue'; // this might be overridden below
        switch (n.type.id) {
          case NotificationTypeEnum.AssignedProject:
          case NotificationTypeEnum.ProjectPhaseChanged:
            switch (n.parent?.status_id) {
              case ProjectStatus.ACTIVE:
                n.type.icon = 'label_important';
                n.type.icon_color = 'green';
                break;
              case ProjectStatus.PLANNED:
                n.type.icon = 'next_plan';
                n.type.icon_color = 'ltblue';
                break;
              case ProjectStatus.ON_HOLD:
                n.type.icon = 'flag';
                n.type.icon_color = 'red';
                break;
              case ProjectStatus.CLOSED:
                n.type.icon = 'done_all';
                break;
              default:
                n.type.icon = 'assignment_turned_in';
                break;
            }
            break;
          case NotificationTypeEnum.AssignedTask:
          case NotificationTypeEnum.TaskReminderSent:
          case NotificationTypeEnum.TaskAssignedToCeo:
          case NotificationTypeEnum.CreatedTaskNote:
            let accessoryDataString;
            let taskStatusId;
            if (n.type.id === NotificationTypeEnum.AssignedTask || NotificationTypeEnum.TaskReminderSent) {
              accessoryDataString = n.parent?.accessory_data;
              taskStatusId = n.parent?.status_id;
            } else if (n.type.id === NotificationTypeEnum.CreatedTaskNote) {
              accessoryDataString = n.parent?.task_accessory_data;
              taskStatusId = n.parent?.task_status_id;
            }
            const accessoryData = accessoryDataString ? JSON.parse(accessoryDataString) : null;
            const approvalStatus = accessoryData?.reviewChain?.find(
              (review) => review.status === TaskReviewStatus.Pending || review.status === TaskReviewStatus.Rejected
            );
            const approvalStatusId = approvalStatus?.status;
            if (approvalStatusId) {
              switch (approvalStatusId) {
                case TaskReviewStatus.Pending:
                  n.type.icon = 'pending';
                  n.type.icon_color = 'gray';
                  break;
                case TaskReviewStatus.Rejected:
                  n.type.icon = 'cancel';
                  n.type.icon_color = 'red';
                  break;
              }
            } else {
              switch (taskStatusId) {
                case TaskStatus.Open:
                  n.type.icon = 'radio_button_unchecked';
                  break;
                case TaskStatus.OnHold:
                  n.type.icon = 'flag';
                  n.type.icon_color = 'red';
                  break;
                case TaskStatus.Complete:
                  n.type.icon = 'check_circle';
                  n.type.icon_color = 'green';
                  break;
                case TaskStatus.Pending:
                  n.type.icon = 'pending';
                  n.type.icon_color = 'gray';
                  break;
                default:
                  n.type.icon = 'assignment_turned_in';
              }
            }
            break;
        }

        // title
        switch (n.type.id) {
          case NotificationTypeEnum.AssignedProject:
          case NotificationTypeEnum.ProjectPhaseChanged:
          case NotificationTypeEnum.ProjectStatusChangedToActive:
          case NotificationTypeEnum.ProjectStatusChangedToOnHold:
          case NotificationTypeEnum.ProjectStatusChangedToClosed:
          case NotificationTypeEnum.RequestConverted:
            n.title = n.parent?.title ?? 'PRJ ' + n.parent?.code ?? 'Untitled Project';
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.id);
            };
            n.resourceLink = `/projects/${n.parent?.id}`;
            break;
          case NotificationTypeEnum.AssignedTask:
          case NotificationTypeEnum.TaskReminderSent:
          case NotificationTypeEnum.TaskAssignedToCeo:
          case NotificationTypeEnum.TaskStatusChangedToToDo:
          case NotificationTypeEnum.TaskStatusChangedToOnHold:
          case NotificationTypeEnum.TaskStatusChangedToPending:
          case NotificationTypeEnum.TaskStatusChangedToComplete:
          case NotificationTypeEnum.TaskStatusChangedToRejected:
            n.title = n.parent?.title ?? n.parent?.code ?? 'Untitled Task';
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Task, n.parent?.id);
            };
            break;
          case NotificationTypeEnum.RequestReceived:
          case NotificationTypeEnum.RequestRejected:
          case NotificationTypeEnum.WorkOrderConverted:
            n.title = n.parent?.short_description || `${n?.parent?.request_type_name || ''} Request`;
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Request, n.parent?.id);
            };
            break;
          case NotificationTypeEnum.Reminder:
            n.title = n.parent?.description ?? 'Untitled Reminder';
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              this.router.navigateByUrl(`/reminders`);
            };
            n.resourceLink = `/reminders`;
            break;
          case NotificationTypeEnum.Awarded61Bid:
          case NotificationTypeEnum.Awarded74Bid:
            n.title = 'Your Bid has been awarded';
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(
                ResourceType.Project,
                n.parent?.project_id,
                n.type?.id === NotificationTypeEnum.Awarded61Bid
                  ? 'bids'
                  : NotificationTypeEnum.Awarded74Bid
                  ? 'purchasing'
                  : ''
              );
            };
            const projectBidType =
              n.type?.id === NotificationTypeEnum.Awarded61Bid
                ? 'bids'
                : NotificationTypeEnum.Awarded74Bid
                ? 'purchasing'
                : '';
            n.resourceLink = `/projects/${n.parent?.project_id}/${projectBidType}`;
            break;
          case NotificationTypeEnum.ApprovedChangeOrder:
            n.title = 'Change Order approved';
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id, 'change-orders');
            };
            n.resourceLink = `/projects/${n.parent?.project_id}/change-orders`;
            break;
          case NotificationTypeEnum.CreatedTaskNote:
            n.title = n.parent?.task_title ?? n.parent?.task_code ?? 'Untitled Task';
            n.titleIsLinked = !!n.parent?.task_id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Task, n.parent?.task_id);
            };
            break;
          case NotificationTypeEnum.Processed61Invoice:
          case NotificationTypeEnum.Processed74Invoice:
            n.title = `Invoice ${n.parent?.number} Processed`;
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id, 'invoices');
            };
            n.resourceLink = n.parent?.ARF_id
              ? `/purchasing/arfs/${n.parent?.ARF_id}`
              : `/projects/${n.parent?.project_id}/invoices`;
            break;
          case NotificationTypeEnum.Rejected61Invoice:
          case NotificationTypeEnum.Rejected74Invoice:
            n.title = `Invoice ${n.parent?.number} Rejected`;
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id, 'invoices');
            };
            n.resourceLink = `/projects/${n.parent?.project_id}/invoices`;
            break;
          case NotificationTypeEnum.Deleted61Invoice:
          case NotificationTypeEnum.Deleted74Invoice:
            n.title = `Invoice ${n.parent?.number} Deleted`;
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id, 'invoices');
            };
            n.resourceLink = `/projects/${n.parent?.project_id}/invoices`;
            break;
          case NotificationTypeEnum.CreatedRFI:
          case NotificationTypeEnum.AssignedRFI:
          case NotificationTypeEnum.ClosedRFI:
          case NotificationTypeEnum.UpdatedRFI:
            n.title = n.parent?.description;
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id, 'rfi');
            };
            n.resourceLink = `/projects/${n.parent?.project_id}/rfi`;
            break;
          case NotificationTypeEnum.WorkOrderStatusChangedToActive:
          case NotificationTypeEnum.WorkOrderStatusChangedToOnHold:
          case NotificationTypeEnum.WorkOrderStatusChangedToClosed:
          case NotificationTypeEnum.WorkOrderStatusChangedToReadyForPickup:
          case NotificationTypeEnum.AssignedWorkOrder:
          case NotificationTypeEnum.RequestConvertedToWO:
            n.title = n.parent?.title ?? n.parent?.code ?? 'Untitled Work Order';
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.WorkOrder, n.parent?.id);
            };
            n.resourceLink = `/work-orders/${n.parent?.id}`;
            break;
          case NotificationTypeEnum.CreatedWorkOrderUpdate:
            n.title = n.parent?.work_order_title ?? n.parent?.work_order_code ?? 'Untitled Work Order';
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.WorkOrder, n.parent?.work_order_id);
            };
            n.resourceLink = `/work-orders/${n.parent?.work_order_id}`;
            break;
          case NotificationTypeEnum.AddedAsMeetingAttendee:
            n.title = n.parent?.meeting_title ?? 'Untitled Meeting';
            n.titleIsLinked = true;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Meeting, n.parent?.meeting_id);
            };
            n.resourceLink = `/meetings/${n.parent?.meeting_id}`;
            break;
          case NotificationTypeEnum.CreatedProjectUpdate:
            n.title =
              n.parent?.project_title ??
              (n.parent?.project_code ? `PRJ ${n.parent?.project_code}` : 'Untitled Project');
            n.titleIsLinked = !!n.parent?.id;
            n.routeToTitle = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id);
            };
            n.resourceLink = `/projects/${n.parent?.project_id}`;
            break;
          case NotificationTypeEnum.ArfCostCodeUpdated:
            n.title = n.parent?.title || '';
            n.resourceLink = `/purchasing/arfs/${n.parent?.id}`;
            break;
          case NotificationTypeEnum.ProjectCostCodeUpdated:
            n.title = n.parent?.title || '';
            n.resourceLink = `/projects/${n.parent?.id}/purchasing`;
            break;
        }

        // message
        switch (n.type.id) {
          case NotificationTypeEnum.AssignedProject:
            n.message = n.parent?.request_topic_name;
            break;
          case NotificationTypeEnum.ProjectStatusChangedToActive:
          case NotificationTypeEnum.ProjectStatusChangedToOnHold:
          case NotificationTypeEnum.ProjectStatusChangedToClosed:
          case NotificationTypeEnum.RequestReceived:
          case NotificationTypeEnum.RequestRejected:
          case NotificationTypeEnum.RequestApproved:
          case NotificationTypeEnum.RequestConverted:
          case NotificationTypeEnum.WorkOrderConverted:
            n.message = n.parent?.request_topic_id
              ? `${n.parent?.request_topic_group_name} > ${n.parent?.request_topic_category_name} > ${n.parent?.request_topic_name}`
              : null;
            break;
          case NotificationTypeEnum.AssignedTask:
          case NotificationTypeEnum.TaskReminderSent:
          case NotificationTypeEnum.TaskAssignedToCeo:
          case NotificationTypeEnum.TaskStatusChangedToToDo:
          case NotificationTypeEnum.TaskStatusChangedToOnHold:
          case NotificationTypeEnum.TaskStatusChangedToPending:
          case NotificationTypeEnum.TaskStatusChangedToComplete:
          case NotificationTypeEnum.TaskStatusChangedToRejected:
            n.message = n.parent?.due_date
              ? 'Due: ' + moment(n.parent?.due_date).format('dddd, MMMM D YYYY')
              : 'No Due Date';
            break;
          case NotificationTypeEnum.Reminder:
            n.message = n.parent?.due_datetime
              ? moment(n.parent?.due_datetime).format('dddd, MMMM D YYYY [at] h:mm A')
              : n.parent?.reminder_datetime
              ? moment(n.parent?.reminder_datetime).format('dddd, MMMM D YYYY [at] h:mm A')
              : 'No Due Datetime or Reminder Datetime';
            break;
          case NotificationTypeEnum.Awarded61Bid:
            n.message = `Bid Package${n.parent?.bid_package_number || n.parent?.bid_package_code ? ' ' : ''}${
              n.parent?.bid_package_number ?? n.parent?.bid_package_code
            }${n.parent?.bid_package_trade_name ? ' - ' : ''}${n.parent?.bid_package_trade_name}`;
            break;
          case NotificationTypeEnum.ApprovedChangeOrder:
            n.message = n.parent?.short_description ?? 'Untitled Change Order';
            break;
          case NotificationTypeEnum.CreatedTaskNote:
          case NotificationTypeEnum.CreatedWorkOrderUpdate:
          case NotificationTypeEnum.CreatedProjectUpdate:
            n.message = n.parent?.message ?? 'No Message';
            break;
          case NotificationTypeEnum.Processed61Invoice:
          case NotificationTypeEnum.Processed74Invoice:
          case NotificationTypeEnum.Deleted61Invoice:
          case NotificationTypeEnum.Deleted74Invoice:
            n.message = n.parent?.title ?? 'Untitled Invoice';
            break;
          case NotificationTypeEnum.Rejected61Invoice:
          case NotificationTypeEnum.Rejected74Invoice:
            n.message = n.parent?.review_comment ?? 'No Review Comment';
            break;
          case NotificationTypeEnum.CreatedRFI:
          case NotificationTypeEnum.AssignedRFI:
          case NotificationTypeEnum.ClosedRFI:
          case NotificationTypeEnum.UpdatedRFI:
            // n.message = n.parent?.message ?? n.parent?.description;
            break;
          case NotificationTypeEnum.WorkOrderStatusChangedToActive:
          case NotificationTypeEnum.WorkOrderStatusChangedToOnHold:
          case NotificationTypeEnum.WorkOrderStatusChangedToClosed:
          case NotificationTypeEnum.WorkOrderStatusChangedToReadyForPickup:
          case NotificationTypeEnum.AssignedWorkOrder:
          case NotificationTypeEnum.RequestConvertedToWO:
            n.message = n.parent?.topic_id
              ? `${n.parent?.topic_group_name} > ${n.parent?.topic_category_name} > ${n.parent?.topic_name}`
              : null;
            break;
          case NotificationTypeEnum.AddedAsMeetingAttendee:
            n.message = n.parent?.meeting_start_datetime
              ? moment(n.parent?.meeting_start_datetime).format('dddd, MMMM D YYYY [at] h:mm A')
              : 'No Meeting Datetime';
            break;
          case NotificationTypeEnum.Awarded74Bid:
            n.message = `Bid${n.parent?.code ? ` ${n.parent?.code}` : ''}`;
            break;
        }

        // parent
        switch (n.type.id) {
          case NotificationTypeEnum.AssignedProject:
          case NotificationTypeEnum.ProjectPhaseChanged:
          case NotificationTypeEnum.ProjectStatusChangedToActive:
          case NotificationTypeEnum.ProjectStatusChangedToOnHold:
          case NotificationTypeEnum.ProjectStatusChangedToClosed:
            n.parentIcon = `assignment_turned_in`;
            n.parentColor = 'ltblue';
            n.parentString = `PRJ ${n.parent?.code}${n.parent?.building_code ? ' | ' : ''}${
              n.parent?.building_code ?? ''
            }${n.parent?.building_code && n.parent?.floor_code ? `-${n.parent?.floor_code}` : ''}${
              n.parent?.title || n.parent?.code ? ' | ' : ''
            }${n.parent?.title ?? n.parent?.code ?? ''}`;
            n.parentIsLinked = !!n.parent?.id;
            n.routeToParent = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.id);
            };
            break;
          case NotificationTypeEnum.AssignedTask:
          case NotificationTypeEnum.TaskReminderSent:
          case NotificationTypeEnum.TaskAssignedToCeo:
          case NotificationTypeEnum.TaskStatusChangedToToDo:
          case NotificationTypeEnum.TaskStatusChangedToOnHold:
          case NotificationTypeEnum.TaskStatusChangedToPending:
          case NotificationTypeEnum.TaskStatusChangedToComplete:
          case NotificationTypeEnum.TaskStatusChangedToRejected:
          case NotificationTypeEnum.Awarded61Bid:
          case NotificationTypeEnum.ApprovedChangeOrder:
          case NotificationTypeEnum.CreatedTaskNote:
          case NotificationTypeEnum.Processed61Invoice:
          case NotificationTypeEnum.Rejected61Invoice:
          case NotificationTypeEnum.CreatedRFI:
          case NotificationTypeEnum.AssignedRFI:
          case NotificationTypeEnum.ClosedRFI:
          case NotificationTypeEnum.UpdatedRFI:
          case NotificationTypeEnum.Awarded74Bid:
          case NotificationTypeEnum.Processed74Invoice:
          case NotificationTypeEnum.Rejected74Invoice:
          case NotificationTypeEnum.Deleted61Invoice:
          case NotificationTypeEnum.Deleted74Invoice:
          case NotificationTypeEnum.CreatedProjectUpdate:
            n.parentIcon = `assignment_turned_in`;
            n.parentColor = 'ltblue';
            n.parentString = `PRJ ${n.parent?.project_code}${n.parent?.project_building_code ? ' | ' : ''}${
              n.parent?.project_building_code ?? ''
            }${
              n.parent?.project_building_code && n.parent?.project_floor_code ? `-${n.parent?.project_floor_code}` : ''
            }${n.parent?.project_title || n.parent?.project_code ? ' | ' : ''}${
              n.parent?.project_title ?? n.parent?.project_code ?? ''
            }`;
            n.parentIsLinked = !!n.parent?.project_id;
            n.routeToParent = async () => {
              await this.navigateByResource(ResourceType.Project, n.parent?.project_id);
            };
            n.parentLink = `/projects/${n.parent?.project_id}`;
            break;
          case NotificationTypeEnum.WorkOrderStatusChangedToActive:
          case NotificationTypeEnum.WorkOrderStatusChangedToOnHold:
          case NotificationTypeEnum.WorkOrderStatusChangedToClosed:
          case NotificationTypeEnum.WorkOrderStatusChangedToReadyForPickup:
          case NotificationTypeEnum.AssignedWorkOrder:
          case NotificationTypeEnum.RequestConvertedToWO:
            n.parentIcon = `description`;
            n.parentColor = 'orange';
            n.parentString = `${n.parent?.code}${n.parent?.building_code ? ' | ' : ''}${n.parent?.building_code}${
              n.parent?.building_code && n.parent?.floor_code ? `-${n.parent?.floor_code}` : ''
            }${n.parent?.title || n.parent?.code ? ' | ' : ''}${n.parent?.title ?? n.parent?.code ?? ''}`;
            n.parentIsLinked = !!n.parent?.id;
            n.routeToParent = async () => {
              await this.navigateByResource(ResourceType.WorkOrder, n.parent?.id);
            };
            n.parentLink = `/work-orders/${n.parent?.id}`;
            break;
          case NotificationTypeEnum.CreatedWorkOrderUpdate:
            n.parentIcon = `description`;
            n.parentColor = 'orange';
            n.parentString = `${n.parent?.work_order_code}${n.parent?.work_order_building_code ? ' | ' : ''}${
              n.parent?.work_order_building_code
            }${
              n.parent?.work_order_building_code && n.parent?.work_order_floor_code
                ? `-${n.parent?.work_order_floor_code}`
                : ''
            }${n.parent?.work_order_title || n.parent?.work_order_code ? ' | ' : ''}${
              n.parent?.work_order_title ?? n.parent?.work_order_code ?? ''
            }`;
            n.parentIsLinked = !!n.parent?.work_order_id;
            n.routeToParent = async () => {
              await this.navigateByResource(ResourceType.WorkOrder, n.parent?.work_order_id);
            };
            n.parentLink = `/work-orders/${n.parent?.work_order_id}`;
            break;
        }

        // menu
        switch (n.type.id) {
          case NotificationTypeEnum.TaskStatusChangedToToDo:
          case NotificationTypeEnum.TaskStatusChangedToOnHold:
          case NotificationTypeEnum.TaskStatusChangedToPending:
          case NotificationTypeEnum.TaskStatusChangedToComplete:
          case NotificationTypeEnum.TaskStatusChangedToRejected:
            if (n.parent?.id && n.parent?.follower_ids) {
              const followerIds = JSON.parse(n.parent?.follower_ids);
              if (followerIds?.indexOf(this.currentUser?.id) > -1) {
                const unfollowLabel = 'Unfollow Task';
                n.type.menuItems = [
                  {
                    label: unfollowLabel,
                    icon: 'person_remove',
                    function: async () => {
                      await this.taskService.removeFollowerFromTask(n.parent?.id, this.currentUser?.id).toPromise();
                      this.snackbar.open('Task Unfollowed');
                      remove(n.type?.menuItems ?? [], (m) => m?.label === unfollowLabel);
                    },
                  },
                ];
              }
            }
            break;
          case NotificationTypeEnum.CreatedTaskNote:
            if (n.parent?.task_id && n.parent?.task_follower_ids) {
              const followerIds = JSON.parse(n.parent?.task_follower_ids);
              if (followerIds?.indexOf(this.currentUser?.id) > -1) {
                const unfollowLabel = 'Unfollow Task';
                n.type.menuItems = [
                  {
                    label: unfollowLabel,
                    icon: 'person_remove',
                    function: async () => {
                      await this.taskService
                        .removeFollowerFromTask(n.parent?.task_id, this.currentUser?.id)
                        .toPromise();
                      this.snackbar.open('Task Unfollowed');
                      remove(n.type?.menuItems ?? [], (m) => m?.label === unfollowLabel);
                    },
                  },
                ];
              }
            }
            break;
          case NotificationTypeEnum.WorkOrderStatusChangedToActive:
          case NotificationTypeEnum.WorkOrderStatusChangedToOnHold:
          case NotificationTypeEnum.WorkOrderStatusChangedToClosed:
          case NotificationTypeEnum.WorkOrderStatusChangedToReadyForPickup:
          case NotificationTypeEnum.AssignedWorkOrder:
          case NotificationTypeEnum.RequestConvertedToWO:
            if (n.parent?.id && n.parent?.follower_ids) {
              const followerIds = JSON.parse(n.parent?.follower_ids);
              if (followerIds?.indexOf(this.currentUser?.id) > -1) {
                const unfollowLabel = 'Unfollow Work Order';
                n.type.menuItems = [
                  {
                    label: unfollowLabel,
                    icon: 'person_remove',
                    function: async () => {
                      await this.workOrderService
                        .updateWorkOrderFollower(n.parent?.id, {
                          follower_id: this.currentUser?.id,
                          action: 'remove',
                        })
                        .toPromise();
                      this.snackbar.open('Work Order Unfollowed');
                      remove(n.type?.menuItems ?? [], (m) => m?.label === unfollowLabel);
                    },
                  },
                ];
              }
            }
            break;
          case NotificationTypeEnum.CreatedWorkOrderUpdate:
            if (n.parent?.work_order_id && n.parent?.work_order_follower_ids) {
              const followerIds = JSON.parse(n.parent?.work_order_follower_ids);
              if (followerIds?.indexOf(this.currentUser?.id) > -1) {
                const unfollowLabel = 'Unfollow Work Order';
                n.type.menuItems = [
                  {
                    label: unfollowLabel,
                    icon: 'person_remove',
                    function: async () => {
                      await this.workOrderService
                        .updateWorkOrderFollower(n.parent?.work_order_id, {
                          follower_id: this.currentUser?.id,
                          action: 'remove',
                        })
                        .toPromise();
                      this.snackbar.open('Work Order Unfollowed');
                      remove(n.type?.menuItems ?? [], (m) => m?.label === unfollowLabel);
                    },
                  },
                ];
              }
            }
            break;
          case NotificationTypeEnum.CreatedProjectUpdate:
            if (n.parent?.project_follower_ids) {
              const followerIds = JSON.parse(n.parent?.project_follower_ids);
              if (followerIds?.indexOf(this.currentUser?.id) > -1) {
                const unfollowLabel = 'Unfollow Project';
                n.type.menuItems = [
                  {
                    label: unfollowLabel,
                    icon: 'person_remove',
                    function: async () => {
                      await this.projectService
                        .updateFollowers(n.parent?.project_id, {
                          followers: [this.currentUser.id],
                          action: 'remove',
                        })
                        .toPromise();
                      this.snackbar.open('Project Unfollowed');
                      remove(n.type?.menuItems ?? [], (m) => m?.label === unfollowLabel);
                    },
                  },
                ];
              }
            }
        }

        // reply
        switch (n.type.id) {
          case NotificationTypeEnum.CreatedTaskNote:
            if (n.parent?.task_id) {
              n.showReplyAndAcknowledge = true;
              n.replyFunction = async () => {
                await this.navigateByResource(ResourceType.Task, n.parent?.task_id);
              };
            }
            break;
          case NotificationTypeEnum.CreatedWorkOrderUpdate:
            if (n.parent?.work_order_id) {
              n.showReplyAndAcknowledge = true;
              n.replyFunction = async () => {
                await this.navigateByResource(ResourceType.WorkOrder, n.parent?.work_order_id);
              };
              n.menuLink = `/work-orders/${n.parent?.work_order_id}`;
            }
            break;
          case NotificationTypeEnum.CreatedProjectUpdate:
            if (n.parent?.project_id) {
              n.showReplyAndAcknowledge = true;
              n.replyFunction = async () => {
                await this.navigateByResource(ResourceType.Project, n.parent?.project_id);
              };
              n.menuLink = `/projects/${n.parent?.project_id}`;
            }
            break;
        }
      }
    }
    return notifications;
  }

  private setDateHeaders(notifications) {
    let day;
    const dayFormat = 'MMM D, YYYY';
    for (const n of notifications) {
      n.day_label = null;
      if (n.parent && typeof n.parent === 'string') {
        n.parent = JSON.parse(n.parent);
      }
      if (!n.formatted_created_datetime) {
        n.formatted_created_datetime = moment(n.created_datetime).format(dayFormat);
      }
      if (n.formatted_created_datetime !== day) {
        n.day_label =
          moment().format(dayFormat) === n.formatted_created_datetime
            ? 'Today'
            : moment().add(-1, 'days').format(dayFormat) === n.formatted_created_datetime
            ? 'Yesterday'
            : n.formatted_created_datetime;
      }
      day = n.formatted_created_datetime;
    }
  }

  public closeNotificationPanel() {
    this.notificationService.notificationPanelIsOpen = false;
  }

  public isPanelOpen(): boolean {
    return this.notificationService.notificationPanelIsOpen;
  }

  public isSidenavOpen(): boolean {
    return !this.sidenavService.isSidenavClosed;
  }

  getProfileThumbnailUrl(userId: number): string {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  async markAllRead() {
    this.notificationService.markAllNotificationsAsRead(this.myActions).subscribe();
    const notificationsToMarkRead = this.myActions
      ? this.notifications.unread.list.filter((n) => n.is_my_action)
      : this.notifications.unread.list;
    for (const n of notificationsToMarkRead) {
      n.is_read = true;
    }
    this.notifications.read.list = [...this.notifications.read.list, ...notificationsToMarkRead];
    this.notifications.unread.list = this.myActions
      ? this.notifications.unread.list.filter((n) => !n.is_my_action)
      : [];
    this.notifications.read.list = orderBy(this.notifications.read.list, (n) => n.created_datetime, 'desc');
    this.setDateHeaders(this.notifications.read.list);
    this.setDateHeaders(this.notifications.unread.list);
    if (this.myActions) {
      this.notifications.unread.count = this.notifications.unread.count - this.myActionsUnreadCount;
      this.notifications.read.count = this.notifications.read.count + this.myActionsUnreadCount;
      this.myActionsUnreadCount = 0;
    } else {
      this.notifications.read.count = this.notifications.read.count + this.notifications.unread.count;
      this.notifications.unread.count = 0;
      this.myActionsUnreadCount = 0;
      this.generalUnreadCount = 0;
    }
    this.notificationService.unreadNotificationCount = this.generalUnreadCount + this.myActionsUnreadCount;
  }

  async updateNotificationStatus(notification: Notification, is_read) {
    if (!this.updatingNotificationStatus && notification.is_read !== is_read) {
      this.updatingNotificationStatus = true;
      this.notificationService.updateNotification(notification.id, { is_read }, []).subscribe();
      // manually edit notifications to avoid refresh lag
      notification.is_read = is_read;
      if (is_read) {
        this.notifications.read.list.push(notification);
        this.notifications.read.list = orderBy(this.notifications.read.list, (n) => n.created_datetime, 'desc');
        remove(this.notifications.unread.list, (n) => n.id === notification.id);
        this.notifications.read.count++;
        this.notifications.unread.count--;
      } else {
        this.notifications.unread.list.push(notification);
        this.notifications.unread.list = orderBy(this.notifications.unread.list, (n) => n.created_datetime, 'desc');
        remove(this.notifications.read.list, (n) => n.id === notification.id);
        this.notifications.read.count--;
        this.notifications.unread.count++;
      }
      this.setDateHeaders(this.notifications.read.list);
      this.setDateHeaders(this.notifications.unread.list);
      if (notification.is_my_action) {
        this.myActionsUnreadCount += is_read ? -1 : 1;
      } else {
        this.generalUnreadCount += is_read ? -1 : 1;
      }
      this.notificationService.unreadNotificationCount = this.myActionsUnreadCount + this.generalUnreadCount;
      this.updatingNotificationStatus = false;
    }
  }

  private async navigateByResource(resourceTypeId: number, resourceId: number, subroute?: string) {
    if (resourceTypeId && resourceId) {
      switch (resourceTypeId) {
        case ResourceType.Project:
          await this.router.navigateByUrl(`/projects/${resourceId}${subroute ? `/${subroute}` : ``}`);
          break;
        case ResourceType.Request:
          await this.router.navigateByUrl(`/requests/${resourceId}${subroute ? `/${subroute}` : ``}`);
          break;
        case ResourceType.Task:
          // await this.routingService.gotoProjectTask(resourceId);
          this.dialog.open(ViewTaskDialogComponent, {
            data: { taskId: resourceId },
            autoFocus: false,
          });
          break;
        case ResourceType.Meeting:
          await this.router.navigateByUrl(`/meetings/${resourceId}${subroute ? `/${subroute}` : ``}`);
          break;
        case ResourceType.WorkOrder:
          await this.router.navigateByUrl(`/work-orders/${resourceId}${subroute ? `/${subroute}` : ``}`);
          break;
      }
      this.verifyRefresh(resourceTypeId);
    }
  }

  private verifyRefresh(resourceTypeId) {
    if (!resourceTypeId) {
      return;
    }

    const wo_type_ids = [ResourceType.Note, ResourceType.WorkOrder, ResourceType.WorkOrderUpdate];
    const project_type_ids = [
      ResourceType.Project,
      ResourceType.Phase,
      ResourceType.Milestone,
      ResourceType.Task,
      ResourceType.BidPackage,
      ResourceType.Bid,
      ResourceType.ProposalRequest,
      ResourceType.ChangeOrder,
      ResourceType.AsBuilt,
      ResourceType.Note,
      ResourceType.Addendum,
      ResourceType.Invoice,
      ResourceType.Timeline,
      ResourceType.Punchlist,
      ResourceType.RFI,
      ResourceType.RFIComment,
      ResourceType.FileApproval,
      ResourceType.FileApprovalItem,
      ResourceType.BidRequirement,
      ResourceType.FileSelectedItem,
      ResourceType.Solicitation,
      ResourceType.ProjectUpdate,
      ResourceType.ProjectProduct,
      ResourceType.Quote,
    ];
    const meeting_type_ids = [ResourceType.Meeting, ResourceType.Agenda];
    const request_type_ids = [ResourceType.Request];

    // refresh project if the parent_type_id relates to project info
    if (project_type_ids.includes(resourceTypeId)) {
      // 02/21/2023 commented this out to fix issue with duplicate calls in project-overview
      // this.projectService.refreshNeeded$.next();
    } else if (wo_type_ids.includes(resourceTypeId)) {
      this.workOrderService.refreshNeeded$.next();
    } else if (meeting_type_ids.includes(resourceTypeId)) {
      this.meetingService.refreshNeeded$.next();
    } else if (request_type_ids.includes(resourceTypeId)) {
      this.requestService.refreshNeeded$.next();
    }
  }

  async loadMoreNotifications(isRead: boolean) {
    await this.loadNotifications(isRead, true);
  }

  async toggleMyActions(showMyActions) {
    const valueChanged = this.myActions !== showMyActions;
    this.myActions = showMyActions;
    if (valueChanged) {
      await this.refresh();
    }
  }

  logout() {
    this.sidenavLinksService.selectLink(null);
    this.notificationService.notificationPanelIsOpen = false;
    this.authService.logout().subscribe();
  }

  public async updateNotification(
    evt: Event,
    menuItem: Notification['menuItems'][0],
    notification: Notification
  ): Promise<void> {
    evt.stopPropagation();
    this.updatingNotificationMenuItem = true;
    await menuItem.function();
    await this.updateNotificationStatus(notification, 1);
    this.updatingNotificationMenuItem = false;
  }

  public async openUserProfileDialog(userId: number): Promise<void> {
    if (userId) {
      await this.modalService.openUserProfileDialog(userId).toPromise();
    }
  }
}
