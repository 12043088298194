import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditorComponent, FileAttachmentDialogComponent } from 'src/app/components';
import { ResourceType, TaskReviewStatus, UserType } from 'src/app/enums';
import { AuthService, ProjectService, ProjectTaskService } from 'src/app/services';
import { UhatFileReference } from 'src/app/types';
import { ChangeOrder } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-view-change-order-dialog',
  templateUrl: './view-change-order-dialog.component.html',
  styleUrls: ['./view-change-order-dialog.component.scss'],
})
export class ViewChangeOrderDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  private changeOrderFields: string[] = [
    'code',
    'local_index',
    'proposal_request_id',
    'proposal_request_code',
    'proposal_request_local_index',
    'short_description',
    'summary',
    'trade_name',
    'company_name',
    'status_id',
    'cost_change',
    'time_change_amount',
    'time_change_unit',
    'funding_source_fee_id',
    'funding_source_fee_name',
    'funding_source_name',
    'files',
    'project_id',
    'approval_datetime',
    'wm_arch_task_id',
    'tenant_task_id',
    'cfmo_task_id',
    'funding_source_fee_funding_source_id',
    'funding_source_fee_funding_source_abbreviation',
    'proposal_request{reason_name,reason_option{name}}',
  ];

  public myUserId: number;

  public id: number;

  public isReviewItem: boolean;

  public approvalStatus: TaskReviewStatus = TaskReviewStatus.Pending;

  public fileLoading: boolean;

  public changeOrder: ChangeOrder = {
    code: '0000000',
    proposal_request_code: 'Loading..',
    funding_source_fee_id: null,
    funding_source_fee_name: null,
    funding_source_name: 'Loading..',
    files: [],
    cost_change: 0,
    cost_change_type: 'Loading..',
    short_description: 'Loading..',
    summary: 'Loading..',
  };
  public attachedFiles: UhatFileReference[] = [];

  constructor(
    public dialogRef: MatDialogRef<ViewChangeOrderDialogComponent>,
    public authService: AuthService,
    public projectService: ProjectService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private snackbar: MatSnackBar,
    private taskService: ProjectTaskService
  ) {}

  async ngOnInit() {
    this.myUserId = this.authService.getLoggedInUser()?.id;
    this.id = this.data.changeOrderId;
    this.isReviewItem = this.data.isReviewItem || false;

    // Determine if a ChangeOrder object or only the Id was passed in. If only the id then we want to load the change order data.
    if (this.data.changeOrder) {
      this.id = this.data.changeOrder.id;
    }
    await this.loadChangeOrderData();
    if (this.changeOrder?.status_id === 2 || this.isReviewItem) {
      this.fileLoading = true;
      const taskId =
        this.changeOrder.cfmo_task_id || this.changeOrder?.tenant_task_id || this.changeOrder.wm_arch_task_id;
      // since sometimes this errors for Suppliers, will remove files from being viewed by Supplier in the dialog
      if (taskId && +this.authService?.currentUser?.user_type_id !== +UserType.Vendor) {
        const task = await this.projectService.getTaskById(taskId, ['accessory_data']).toPromise();
        const accessoryData = task.accessory_data && JSON.parse(task.accessory_data);
        this.changeOrder.files =
          (accessoryData?.reviewFiles?.length && accessoryData.reviewFiles) ||
          (await this.taskService.getLatestTaskActivityNote(taskId)) ||
          this.changeOrder.files;
      }
      this.fileLoading = false;
    }

    this._updateApprovalCommentValidation();
  }

  get TaskReviewStatus() {
    return TaskReviewStatus;
  }

  get approvalComment(): AbstractControl {
    return this._editor_component.content;
  }

  private _updateApprovalCommentValidation() {
    if (this.approvalStatus === TaskReviewStatus.Rejected) {
      this.approvalComment.setValidators([Validators.required]);
      this.approvalComment.updateValueAndValidity();
    } else {
      this.approvalComment.clearValidators();
      this.approvalComment.updateValueAndValidity();
    }
  }

  openFileAttachmentDialog() {
    const maxFiles = 10;
    // since we dont 'allowComment', this just links the files to the parent and the additionalParents
    this.dialog
      .open(FileAttachmentDialogComponent, {
        data: {
          parentResourceType: ResourceType.Project,
          parentResourceId: this.changeOrder.project_id,
          allowComment: false,
          // additionalParents,
          maxFiles,
          project_id: this.changeOrder.project_id,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((resultData) => {
        if (resultData) {
          const files: UhatFileReference[] = resultData;
          for (const file of files) {
            if (this.attachedFiles.find((f) => f.id === file.id) == null) {
              this.attachedFiles.push(file);
            }
          }
        }
      });
  }

  submitApproval() {
    this.dialogRef.close({
      approvalStatus: this.approvalStatus,
      approvalComment: this.approvalComment.value,
      attachedFiles: this.attachedFiles,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private async loadChangeOrderData() {
    const orders = await this.projectService
      .getChangeOrders([{ type: 'field', field: 'id', value: this.id?.toString() }], this.changeOrderFields)
      .toPromise();

    if (orders && orders.length > 0) {
      this.changeOrder = orders[0];
    } else {
      this.snackbar.open(
        'Change Order no longer exists. Please submit an error report if this change order should exist.'
      );
      this.dialogRef.close();
    }
  }

  public isValid(): boolean {
    return (
      [TaskReviewStatus.Approved, TaskReviewStatus.NoteMarkings].includes(this.approvalStatus) ||
      (this.approvalStatus === TaskReviewStatus.Rejected && this.approvalComment.value)
    );
  }

  public updateStatus(status: TaskReviewStatus) {
    this.approvalStatus = status;
    this._updateApprovalCommentValidation();
  }
}
