<div #mainScreen (window:resize)="onResize($event)" class="project container-fliud p-4">
  <div class="row">
    <main class="col-lg-8">
      <section class="d-flex flex-column">
        <div class="d-flex mb-2">
          <h5 class="m-0 dkblue font-weight-bold">Project Details</h5>
          <button
            mat-button
            color="accent"
            class="ml-3 lh-28 pl-3 pr-3 ml-auto"
            (click)="openProjectDetailsDialog()"
            *ngIf="isStaff"
          >
            <span *ngIf="isWorkspaceStaff">Edit Details</span>
            <span *ngIf="!isWorkspaceStaff">View Details</span>
          </button>
        </div>
        <div class="m-portlet">
          <div class="m-portlet__body p-0">
            <div class="row m-0">
              <div class="col item p-4">
                <div *ngIf="project?.parent" id="parent-project" [routerLink]="['/projects/' + project?.parent.id]">
                  <span>Parent Project</span><span><mat-icon>link</mat-icon>{{ parentProject }}</span>
                </div>
                <h4
                  class="gray m-0 mb-2 font-weight-semibold pointer align-items-center d-inline-block"
                  (click)="editProjectTitle()"
                  *ngIf="!overview?.title && !isEditingProjectTitle"
                >
                  Add Short Description
                  <mat-icon *ngIf="isStaff" class="gray ml-1" style="font-size: 16px; height: 16px">edit</mat-icon>
                </h4>
                <h4
                  class="dkblue m-0 mb-2 font-weight-semibold pointer align-items-center d-inline-block"
                  (click)="editProjectTitle()"
                  *ngIf="overview?.title && !isEditingProjectTitle"
                >
                  {{ overview?.title }}
                  <mat-icon *ngIf="isStaff" class="gray ml-1" style="font-size: 16px; height: 16px">edit</mat-icon>
                </h4>
                <div id="titleInput" class="full-width mb-1" *ngIf="isEditingProjectTitle">
                  <mat-form-field class="full-width" appearance="outline">
                    <input
                      placeholder="Enter Project Title"
                      autoFocus
                      id="project-title-input"
                      class="font-weight-semibold dkblue"
                      data-test-id="project-title-input"
                      matInput
                      required="true"
                      type="text"
                      [formControl]="projectTitle"
                    />
                    <mat-error *ngIf="projectTitle?.errors?.required">Short Description is required</mat-error>
                  </mat-form-field>
                  <div class="d-flex flex-row-reverse">
                    <button
                      mat-flat-button
                      (click)="updateProjectTitle()"
                      color="accent"
                      type="button"
                      class="px-2 lh-24 ml-2"
                    >
                      Save Changes
                    </button>
                    <button mat-button type="button" class="gray px-2 lh-24" (click)="cancelEditingProjectTitle()">
                      Cancel
                    </button>
                  </div>
                </div>
                <h5 *ngIf="overview?.topic?.name" class="dkgray mb-4 font-weight-normal">
                  {{ overview?.topic_group_name }} > {{ overview?.topic_category_name }} >
                  {{ overview?.topic_name }}
                </h5>
                <h6
                  *ngIf="!overview?.topic?.name"
                  (click)="openProjectDetailsDialog()"
                  class="d-flex align-items-center red pointer m-0 mb-4 font-weight-semibold"
                >
                  <i class="fas fa-exclamation-circle mr-2"></i><span class="hover-u">Click here to add topic</span>
                </h6>

                <div class="row mt-4">
                  <div class="col-12">
                    <h6 class="m-0 dkblue font-weight-semibold mb-2 d-flex align-items-center">
                      <span class="mr-3"> Created </span>
                      <mat-icon class="method-icon">
                        {{ requestMethod?.icon }}
                      </mat-icon>
                    </h6>
                    <p class="m-0 font-weight-normal dkblue">
                      <span> {{ formattedOpenDate }} • {{ formattedOpenTime }} </span>
                    </p>
                  </div>
                </div>
                <div *ngIf="createdByName" class="d-flex align-items-center mb-1">
                  <app-profile-thumbnail
                    [noBorder]="true"
                    class="mr-2"
                    [height]="18"
                    [width]="18"
                    [userId]="project?.created_by_id"
                  ></app-profile-thumbnail>
                  <p class="m-0 dkblue font-weight-normal lh-18">
                    <span>{{ createdByName }}</span>
                  </p>
                </div>

                <div class="row mt-4">
                  <div class="col">
                    <span (click)="editProjectSummary()" class="d-inline-block align-items-center pointer mb-2">
                      <h6 class="m-0 dkblue font-weight-semibold mb-0 mr-2 d-inline-block align-middle">Summary</h6>
                      <mat-icon
                        *ngIf="isStaff"
                        class="gray d-inline-block align-middle"
                        style="font-size: 16px; height: 16px"
                        >edit</mat-icon
                      >
                    </span>
                    <p
                      [hidden]="isEditingProjectSummary"
                      class="m-0 dkblue font-weight-normal"
                      [innerHTML]="overview?.scope_of_work"
                      appTextCollapseExpand
                      maxHeight="197"
                      (heightExceededEvent)="showCollapseDetailViewButton = $event"
                      [showMore]="!collapseDetailView"
                    ></p>
                    <p
                      id="collapse-detail-view-button"
                      class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2"
                      *ngIf="showCollapseDetailViewButton === true && !isEditingProjectSummary"
                      (click)="collapseDetailView = !collapseDetailView"
                    >
                      <i class="fas {{ collapseDetailView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
                      {{ collapseDetailView === true ? 'Show More' : 'Show Less' }}
                    </p>
                    <span [hidden]="!isEditingProjectSummary">
                      <app-editor
                        #projectSummaryEditor
                        placeholder="Enter Project Description"
                        class="full-width"
                        required_content="Project Summary is Required"
                      ></app-editor>

                      <div class="d-flex align-items-center flex-row-reverse position-absolute save-buttons">
                        <button
                          mat-flat-button
                          [disabled]="!projectSummaryEditor?.content?.value"
                          (click)="updateProjectSummary()"
                          color="accent"
                          type="button"
                          class="px-2 lh-24 ml-2"
                        >
                          Save Changes
                        </button>
                        <button
                          mat-button
                          type="button"
                          class="gray px-2 lh-24"
                          (click)="cancelEditingProjectSummary()"
                        >
                          Cancel
                        </button>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="m-portlet__body p-0">
            <div class="row m-0">
              <div class="col p-4 item">
                <div class="row">
                  <div class="col-2">
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Building</h6>
                    <h5 class="m-0 font-weight-normal dkblue">
                      {{ overview?.building?.code }}-{{ overview?.floor?.code }}
                    </h5>
                  </div>
                  <div class="col-2">
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Suite</h6>
                    <h5 class="m-0 font-weight-normal dkblue">
                      {{ overview?.suite?.name || '-' }}
                    </h5>
                  </div>
                  <div class="col-5">
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Department</h6>
                    <h5 class="m-0 font-weight-normal dkblue">
                      {{ overview?.department?.name || '-' }}
                    </h5>
                  </div>
                  <div class="col-3">
                    <h6 class="m-0 dkblue font-weight-semibold mb-2">Room # / Landmark</h6>
                    <h5 class="m-0 font-weight-normal dkblue">
                      {{ overview?.landmark || '-' }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="m-portlet__body p-0">
            <div class="row m-0">
              <div class="col-6 p-4 item item-last border-right border-gray d-flex flex-column">
                <div class="d-flex align-items-top mb-1">
                  <h6 class="m-0 dkblue font-weight-semibold mr-auto">Requested By</h6>
                  <button
                    *ngIf="requester?.id && isStaff"
                    mat-stroked-button
                    [disabled]="project?.status_id === 3"
                    (click)="changeProjectRequester()"
                    color="accent"
                    class="ml-3 lh-28 pl-3 pr-3 bg-white"
                  >
                    Change
                  </button>
                </div>
                <span class="d-flex align-items-top mt-1">
                  <button
                    *ngIf="!requester?.id && isStaff"
                    [disabled]="project?.status_id === 3"
                    mat-flat-button
                    (click)="changeProjectRequester()"
                    color="accent"
                    class="mt-3"
                  >
                    Add Requester
                  </button>
                  <app-profile-info
                    [user]="requester"
                    [additionalUserFields]="['title', 'department_name']"
                    [disableApiCall]="true"
                  ></app-profile-info>
                </span>
              </div>
              <div class="col-6 p-4 item item-last d-flex flex-column">
                <h6 class="m-0 dkblue font-weight-semibold mb-3">Project Manager</h6>
                <app-profile-info
                  [user]="projectManager"
                  [additionalUserFields]="['title', 'department_name']"
                ></app-profile-info>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="updates" class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2">
          <h5 class="m-0 dkblue font-weight-bold">Updates</h5>
          <button
            *ngIf="isStaff || isTenant"
            (click)="addProjectUpdate()"
            mat-flat-button
            color="success"
            class="lh-28 pl-3 pr-3 ml-auto"
          >
            + Update
          </button>
        </div>

        <div *ngFor="let u of projectUpdates" class="project-update m-portlet mb-3">
          <div class="m-portlet__body pt-3 pr-4 pl-4 pb-4">
            <div class="row align-items-top">
              <div class="col d-flex flex-column pl-3">
                <div class="d-flex align-items-center">
                  <app-profile-info
                    class="mr-auto"
                    [user]="{
                      id: u.created_by_id,
                      first_name: u.created_by_first_name,
                      last_name: u.created_by_last_name
                    }"
                    [date]="u.created_datetime"
                  ></app-profile-info>
                  <p
                    class="mb-0 mr-3 font-italic"
                    [ngClass]="{ green: u?.notify_followers === 1, gray: u?.notify_followers === 0 }"
                  >
                    <small>
                      <i *ngIf="u?.notify_followers === 1" class="fa fa-check mr-1"></i>
                      {{ u?.notify_followers === 1 ? 'Notifications Sent' : 'Notifications Not Sent' }}
                    </small>
                  </p>
                  <p
                    *ngIf="u.project_health_type_id || u.is_original_request"
                    class="mb-0 pl-2 pr-2 br-4 mr-2 bg-green white font-weight-semibold d-inline-block align-middle"
                    [ngClass]="{
                      'bg-ltblue': u.is_original_request,
                      'bg-green': u.project_health_type_id === 1,
                      'bg-orange': u.project_health_type_id === 2,
                      'bg-red': u.project_health_type_id === 3
                    }"
                    style="font-size: 11px; line-height: 20px"
                  >
                    {{ u.is_original_request ? 'Original Request' : u.project_health_type_name }}
                  </p>
                  <p
                    *ngIf="u.access.indexOf(2) > -1 && u.access.indexOf(3) > -1"
                    class="
                      mb-0
                      pl-2
                      pr-2
                      br-4
                      mr-2
                      white
                      bg-gray
                      opacity8
                      font-weight-semibold
                      d-inline-block
                      align-middle
                    "
                    style="font-size: 11px; line-height: 20px"
                  >
                    Everyone
                  </p>
                  <p
                    *ngIf="isStaff && u.access.indexOf(2) === -1 && u.access.indexOf(3) === -1"
                    class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-orange font-weight-semibold d-inline-block align-middle"
                    style="font-size: 11px; line-height: 20px"
                  >
                    UHAT/1Call Only
                  </p>
                  <!-- <p *ngIf="u.access.indexOf(2) > -1 && u.access.indexOf(3) === -1" class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-green font-weight-semibold d-inline-block align-middle"
                    style="font-size: 11px; line-height: 20px;">
                    Tenants
                  </p>
                  <p *ngIf="u.access.indexOf(3) > -1 && u.access.indexOf(2) === -1" class="mb-0 pl-2 pr-2 br-4 mr-2 white bg-ltblue font-weight-semibold d-inline-block align-middle"
                    style="font-size: 0.9rem; line-height: 22px;">
                    Suppliers
                  </p> -->
                  <button
                    [matMenuTriggerFor]="update_menu"
                    mat-icon-button
                    color="accent"
                    type="button"
                    [disabled]="!u.id || u.created_by_id !== currentUser.id"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #update_menu="matMenu" xPosition="before">
                    <button
                      mat-menu-item
                      *ngIf="u.id && u.created_by_id === currentUser.id"
                      (click)="editProjectUpdate(u)"
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit Update</span>
                    </button>
                    <button
                      mat-menu-item
                      *ngIf="u.id && u.created_by_id === currentUser.id && isStaff"
                      (click)="deleteUpdate(u)"
                    >
                      <mat-icon>delete</mat-icon>
                      <span>Delete Update</span>
                    </button>
                  </mat-menu>
                </div>
                <div class="d-flex flex-column update-content mt-3">
                  <div
                    class="ml-5 dkblue update-content"
                    [innerHTML]="u.message"
                    appTextCollapseExpand
                    maxHeight="100"
                    (heightExceededEvent)="u.showCollapseMessageViewButton = $event"
                    [showMore]="!u.collapseMessageView"
                  ></div>
                  <p
                    id="collapse-detail-view-button"
                    class="lh-18 hover-u pointer ltblue m-0 font-weight-normal mt-2 ml-5"
                    *ngIf="u.showCollapseMessageViewButton === true"
                    (click)="u.collapseMessageView = !u.collapseMessageView"
                  >
                    <i class="fas {{ u.collapseMessageView === true ? 'fa-angle-down' : 'fa-angle-up' }}  mr-1"></i>
                    {{ u.collapseMessageView === true ? 'Show More' : 'Show Less' }}
                  </p>
                  <app-file-chip-list
                    class="ml-5 pt-3"
                    *ngIf="u?.files?.length"
                    [files]="u?.files"
                    [diameter]="40"
                  ></app-file-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <aside class="col-lg-4">
      <section *ngIf="projectKeyControls?.length && isStaff" class="d-flex flex-column">
        <div class="d-flex mb-2">
          <h5 class="m-0 dkblue font-weight-bold mr-auto">Key Controls</h5>
          <!-- <button (click)="viewKeyControls()" mat-button color="accent" class="lh-28 px-3">
            View
          </button> -->
        </div>
        <div class="m-portlet cursor-pointer">
          <div
            *ngFor="let projectKeyControl of projectKeyControls; let i = index"
            class="m-portlet__body item d-flex align-items-center pr-3 py-2 pl-2"
            (click)="viewKeyControls(projectKeyControl.id)"
          >
            <app-key-control-icon-badge [task]="projectKeyControl?.task" class="mr-2"></app-key-control-icon-badge>
            <div class="d-flex flex-column mr-auto">
              <p class="m-0 font-weight-semibold dkblue hover-u pointer lh-16">
                {{ projectKeyControl?.key_control_template?.key_control_name }}
              </p>
              <!-- DATE COMPLETED W/ NAME -->
              <p class="dkgray mb-1" *ngIf="projectKeyControl?.task?.status_id === TASK_STATUS.Complete">
                Completed on {{ formatDate(projectKeyControl?.completed_datetime) }} by
                <span
                  class="ltblue hover-u pointer"
                  (click)="$event.stopPropagation(); openUserProfilePreview(projectKeyControl?.completed_by?.id)"
                >
                  {{ projectKeyControl?.completed_by?.first_name }}
                  {{ projectKeyControl?.completed_by?.last_name }}</span
                >
              </p>
            </div>
            <app-profile-thumbnail
              *ngFor="let reviewer of projectKeyControl?.key_control_reviewers"
              height="32"
              width="32"
              class="mr-1"
              [userId]="reviewer.id"
              [overlayIconOpacity]="1"
              [overlayIcon]="+reviewer.status === +TASK_REVIEW_STATUS.Approved ? 'check' : 'sync'"
              [overlayIconColor]="'white'"
              [overlayIconBackground]="+reviewer.status === +TASK_REVIEW_STATUS.Approved ? 'bg-green' : 'bg-red'"
              (click)="$event.stopPropagation(); openUserProfilePreview(reviewer.id)"
              [hidden]="!reviewer.status"
            >
            </app-profile-thumbnail>
          </div>
        </div>
      </section>
      <section class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2">
          <h5 class="m-0 dkblue font-weight-bold mb-2 mr-auto">Budget</h5>
        </div>
        <div class="m-portlet">
          <div class="m-portlet__body p-4">
            <!-- <div id="budget" *ngIf="projectHasCAPX">
              <div id="header">
                <mat-chip>Board Approved Budget </mat-chip>
                <mat-chip *ngFor="let fy of filteredYears"
                  >FY{{ fy.fiscal_year }}
                  <button matChipRemove (click)="fy.selected = false">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <mat-icon [matMenuTriggerFor]="menu" class="fy-filter">sort</mat-icon>
                <mat-menu #menu="matMenu">
                  <button *ngFor="let fy of capxBudgets" mat-menu-item (click)="fyFilter($event, fy)">
                    <mat-checkbox (click)="$event.preventDefault()" [checked]="fy.selected" color="primary">
                      FY{{ fy.fiscal_year }}
                    </mat-checkbox>
                  </button>
                </mat-menu>
              </div>
              <div id="budget-totals">
                <h1>{{ overview?.awarded_quote_total | currency }} / {{ totalCAPX[0] | currency }}</h1>
                <span>Used</span>
              </div>
              <div id="budget-progress">
                <div id="budget-progress-percentage" [ngStyle]="{ width: totalCAPX[1] + '%' }"></div>
              </div>
              <div id="labels">
                <div style="background-color: #4b7cdd"></div>
                <span>Approved Cost</span>
                <div style="background-color: #24d19e"></div>
                <span>Processed</span>
                <button mat-button id="edit-budget" color="primary" (click)="openProjectDetailsDialog()">
                  Edit CAPX Budget
                </button>
              </div>
            </div> -->
            <div class="row mb-3">
              <div class="col pr-3" [routerLink]="['/projects/' + projectId + '/purchasing']">
                <div
                  matRipple
                  class="
                    small-stat
                    item item-last
                    d-flex
                    aligh-items-start
                    flex-column
                    border border-gray
                    br-10
                    full-width
                    pointer
                  "
                >
                  <p class="mb-auto dkblue font-weight-semibold">Approved Cost</p>
                  <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                  <h4 class="m-0 dkblue font-weight-normal">
                    {{ overview?.awarded_quote_total | currency: 'USD' }}
                  </h4>
                </div>
              </div>
              <div class="col pl-3" [routerLink]="['/projects/' + projectId + '/invoices']">
                <div
                  matRipple
                  class="
                    small-stat
                    item item-last
                    d-flex
                    aligh-items-start
                    flex-column
                    border border-gray
                    br-10
                    full-width
                    pointer
                  "
                >
                  <p class="mb-auto dkblue font-weight-semibold">Processed Total</p>
                  <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                  <h4 class="m-0 dkblue font-weight-normal">
                    {{ overview?.invoices_paid_amount | currency: 'USD' }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col pl-3" [routerLink]="['/projects/' + projectId + '/invoices']">
                <div
                  matRipple
                  class="
                    small-stat
                    item item-last
                    d-flex
                    aligh-items-start
                    flex-column
                    border border-gray
                    br-10
                    full-width
                    pointer
                  "
                >
                  <p class="mb-auto dkblue font-weight-semibold">Pending Invoices</p>
                  <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                  <h4 class="m-0 dkblue font-weight-normal">
                    {{ overview?.invoices_unreceived_count + overview?.invoices_count - overview?.invoices_paid_count }}
                  </h4>
                </div>
              </div>
              <div class="col pr-3" [routerLink]="['/projects/' + projectId + '/invoices']">
                <div
                  matRipple
                  class="
                    small-stat
                    item item-last
                    d-flex
                    aligh-items-start
                    flex-column
                    border border-gray
                    br-10
                    full-width
                    pointer
                  "
                >
                  <p class="mb-auto dkblue font-weight-semibold">Processed Invoices</p>
                  <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                  <h4 class="m-0 dkblue font-weight-normal">
                    {{ overview?.invoices_paid_count }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="d-flex flex-column">
        <div class="d-flex mb-2">
          <h5 class="m-0 dkblue font-weight-bold mr-auto">Tasks</h5>
        </div>
        <div class="m-portlet">
          <div class="m-portlet__body p-4">
            <section class="mb-5">
              <div class="row mb-3">
                <div class="col">
                  <h5 class="m-0 font-weight-semibold dkblue">Status</h5>
                </div>
                <div class="col-auto text-right">
                  <button
                    mat-button
                    [class.mat-flat-button]="taskStatusFilter === 'all'"
                    class="ml-2 pl-2 pr-2 lh-22"
                    color="accent"
                    type="button"
                    (click)="changeTaskStatusFilter('all')"
                  >
                    All
                  </button>
                  <button
                    mat-button
                    [class.mat-flat-button]="taskStatusFilter === 'mine'"
                    class="ml-2 pl-2 pr-2 lh-22"
                    color="accent"
                    type="button"
                    (click)="changeTaskStatusFilter('mine')"
                  >
                    Mine
                  </button>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col pr-3">
                  <div
                    matRipple
                    (click)="openOverviewDialog('To-Do')"
                    class="small-stat d-flex aligh-items-start flex-column border border-gray br-10 full-width pointer"
                  >
                    <p class="mb-auto dkblue font-weight-semibold">To-Do</p>
                    <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                    <h2 class="m-0 gray">
                      {{ shownStatusTasks?.todo }}
                    </h2>
                  </div>
                </div>
                <div class="col pl-3">
                  <div
                    matRipple
                    (click)="openOverviewDialog('Coming Due')"
                    class="small-stat d-flex aligh-items-start flex-column border border-gray br-10 full-width pointer"
                  >
                    <p class="mb-auto dkblue font-weight-semibold">Coming Due</p>
                    <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                    <h2 class="m-0 orange">
                      {{ shownStatusTasks?.coming_due }}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col pr-3">
                  <div
                    matRipple
                    (click)="openOverviewDialog('Overdue')"
                    class="small-stat d-flex aligh-items-start flex-column border border-gray br-10 full-width pointer"
                  >
                    <p class="mb-auto dkblue font-weight-semibold">Overdue</p>
                    <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                    <h2 class="m-0 red">
                      {{ shownStatusTasks?.overdue }}
                    </h2>
                  </div>
                </div>
                <div class="col pl-3">
                  <div
                    matRipple
                    (click)="openOverviewDialog('Complete')"
                    class="small-stat d-flex aligh-items-start flex-column border border-gray br-10 full-width pointer"
                  >
                    <p class="mb-auto dkblue font-weight-semibold">Complete</p>
                    <mat-icon class="dkblue">keyboard_arrow_right</mat-icon>
                    <h2 class="m-0 green">
                      {{ shownStatusTasks?.complete }}
                    </h2>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="row mb-3">
                <div class="col">
                  <h5 class="m-0 font-weight-semibold dkblue">Assigned To</h5>
                </div>
                <div class="col-auto text-right">
                  <button
                    mat-button
                    color="accent"
                    class="pl-2 pr-2 lh-22"
                    type="button"
                    (click)="openOverviewDialog(null, true)"
                  >
                    View All
                  </button>
                </div>
              </div>
              <div
                class="row pt-2 pb-2 ml-0 item align-items-center pointer"
                (click)="openOverviewDialog('To-Do', false, u)"
                *ngFor="let u of shownUserTasks; let i = index"
                [hidden]="i > 2"
              >
                <div class="col-auto pl-0">
                  <app-profile-thumbnail class="d-inline-block align-middle mr-3" [userId]="u.id">
                  </app-profile-thumbnail>
                </div>
                <div class="col pl-0">
                  <p class="m-0 ltblue font-weight-semibold">{{ u.first_name }} {{ u.last_name }}</p>
                </div>
                <div class="col-auto">
                  <h6 class="m-0 mr-2 dkblue font-weight-semibold">
                    {{ u.task_count }}
                  </h6>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </aside>
  </div>
</div>
