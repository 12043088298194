import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
import { AppEnvironment } from './app-environment-interface';

export const environment: AppEnvironment = {
  production: true,
  showDevBanner: true,
  serviceHost: 'https://dev.271call.com',
  socketHost: 'https://dev.271call.com',
  sentry: {
    dsn: 'https://2aff92e559b14479f7004fb81423f7ff@o4508399619211264.ingest.us.sentry.io/4508400001744896',
    environment: 'dev',
    replayIntegrationOptions: { maskAllText: false },
    replaysOnErrorSampleRate: 0.005,
    replaysSessionSampleRate: 0,
    tracePropagationTargets: ['https://dev.271call.com/api', 'wss://dev.271call.com/socket.io'],
    tracesSampleRate: 0.1,
  },
};
