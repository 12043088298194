import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { exportPDF } from '@progress/kendo-drawing';
import { saveAs } from 'file-saver';
import { orderBy, sortBy, sumBy } from 'lodash';
import * as moment from 'moment';
import { ViewTaskDialogComponent } from 'src/app/components';
import { ApplicationRole, BidPackageStatus, FileAction, InvoiceStatus, ProjectStatus, Workspace } from 'src/app/enums';
import {
  AuthService,
  FileService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  UpdateReviewerService,
} from 'src/app/services';
import { Invoice, UhatFileReference } from 'src/app/types';
import { ViewChangeOrderDialogComponent } from 'src/app/workspaces/construction/components';
import { PEBItemType } from 'src/app/workspaces/construction/enums';
import { PEBFinalizationError } from 'src/app/workspaces/construction/models';
import { BidPackage, BudgetItems, ChangeOrder, ProjectConstruction } from 'src/app/workspaces/construction/types';

@Component({
  selector: 'app-project-budget',
  templateUrl: './project-budget.component.html',
  styleUrls: ['./project-budget.component.scss'],
})
export class ProjectBudgetComponent implements OnInit {
  @Input() project: ProjectConstruction;
  @Input() projectId: number;
  @Input() disableInit = false;
  @ViewChild('budgetReport', { static: true }) budgetReport;
  @ViewChild('budget', { static: true }) elementView: ElementRef;

  loading = false;
  divWidth: number;
  displayData = true;
  invoicesAreLoading = false;
  loadingBudgetReportExport = false;
  collapseAll = true;
  showHeader = true;
  hideRejected = false;
  selectedView: 'list' | 'vendor' | 'investor';
  budgetData;
  budgetItems: BudgetItems;
  currentWorkspace: number;
  invoices;
  pendingAndProcessedInvoiceFiles: UhatFileReference[] = [];
  managementFee = 0;
  sectionsCount = 0;
  collapsedSectionsCount = 0;
  BidPackageStatus = BidPackageStatus;
  Workspace = Workspace;

  invoiceStatusInfo = [
    {
      count: 0,
      name: 'New',
      statusIds: [InvoiceStatus.New],
    },
    {
      count: 0,
      name: 'Unpaid',
      statusIds: [InvoiceStatus.Received, InvoiceStatus.Approved],
    },
    {
      count: 0,
      name: 'Paid',
      statusIds: [InvoiceStatus.ReadyForPayment],
    },
    {
      count: 0,
      name: 'Rejected',
      statusIds: [InvoiceStatus.Rejected],
    },
    {
      count: 0,
      name: 'All',
      statusIds: [],
    },
  ];
  selectedInvoiceStatus = this.invoiceStatusInfo[this.invoiceStatusInfo.length - 1];
  private projectFields = [
    'id',
    'code',
    'title',
    'module',
    'module_id',
    'building_code',
    'floor_code',
    'project_manager_id',
    'project_manager_first_name',
    'project_manager_last_name',
    'workspace_manager_id',
    'cfmo_id',
    'architect_id',
    'architect_first_name',
    'architect_last_name',
    'square_footage',
    'end_date',
    'status_id',
  ];
  private invoiceFields = [
    'id',
    'title',
    'project_id',
    'bid_package_id',
    'change_order_id',
    'change_order_local_index',
    'change_order_short_description',
    'change_order{approval_datetime}',
    'number',
    'invoice_date',
    'total',
    'retainage',
    'shipping',
    'tax',
    'status_id',
    'approval_task_id',
    'approval_task{accessory_data}',
    'approval_task_accessory_data',
    'tenant_id',
    'tenant_name',
    'is_internally_funded',
    'is_contingency_funded',
    'is_gencon_funded',
    'is_retainage',
    'review_comment',
    'processed_by_id',
    'processed_by{first_name,last_name,title}',
    'processed_datetime',
    'created_by_id',
    'created_datetime',
    'quote_id',
    'received_date',
    'files',
    'review_user_id',
    'review_user_first_name',
    'review_user_last_name',
    'status_name',
    'approval_task_status_id',
    'revision',
    'trade_name',
    'trade_allows_nonbid_invoices',
    'company_id',
    'company_name',
    'bid_contact_id',
    'bid_contact_first_name',
    'bid_contact_last_name',
    'bid_contact_email',
    'bid_contact_office_phone',
    'bid_contact_cell_phone',
    'change_order_code',
    'timeframe_id',
    'timeframe_name',
    'company_name',
    'arf_invoice_amounts{sub_cost_code_budget{code,label,cost_code{label,code}},amount}',
  ];

  constructor(
    private progressIndicatorService: ProgressIndicatorService,
    private projectService: ProjectService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private modalService: ModalService,
    private dialog: MatDialog,
    private fileService: FileService,
    private updateReviewersService: UpdateReviewerService,
    private ViewChangeOrderDialog: MatDialog
  ) {}

  async ngOnInit() {
    if (!this.disableInit) {
      if (this.isWorkspaceStaff) {
        this.selectedView = 'vendor';
      } else {
        this.selectedView = 'list';
      }
      await this.refresh();
    }
  }

  onResize(event) {
    this.getDivWidth();
  }

  getDivWidth() {
    this.divWidth = this.elementView.nativeElement.offsetWidth;
  }

  async refresh(showLoader = true) {
    this.getDivWidth();
    this.loading = true;

    if (showLoader) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading Project Budget..');
    }

    let project =
      (this.projectId === this.project?.id && this.project) ||
      (this.projectId &&
        this.projectService.currentSelectedProject?.id === this.projectId &&
        this.projectService.currentSelectedProject) ||
      (!this.projectId && this.projectService.currentSelectedProject);
    if (!project) {
      project = await this.projectService.getProjectById(this.projectId, this.projectFields).toPromise();
    }

    this.currentWorkspace = project.module_id;
    this.project = project;

    if (this.isWorkspaceStaff) {
      this.budgetData = await this.projectService
        .getBudgetData(this.project?.id, this.currentWorkspace, null)
        .toPromise();

      if (showLoader) {
        this.budgetItems = await this.projectService
          .getBudgetItems(this.project?.id, this.currentWorkspace)
          .toPromise();
      }

      const consultantBidPackages = [];
      const nonConsultantBidPackages = [];
      // splits bids into consultant and non consultant - Engineering bids vs none
      for (const bidPackage of this.budgetItems.bid_packages) {
        if (bidPackage.trade?.is_consultant) {
          consultantBidPackages.push(bidPackage);
        } else {
          nonConsultantBidPackages.push(bidPackage);
        }
      }

      // sort by number
      const sortedConsultantBidPackages = orderBy(consultantBidPackages ?? [], (bp: BidPackage) => bp.number);

      const sortedNonConsultantBidPackages = orderBy(nonConsultantBidPackages, (bp: BidPackage) =>
        isNaN(Number(bp.number)) ? bp.number : Number(bp.number)
      );

      // combine/replace
      this.budgetItems.bid_packages = sortedConsultantBidPackages.concat(sortedNonConsultantBidPackages);

      this.sectionsCount = this.budgetItems?.bid_packages?.length || 0 + this.budgetItems?.tenants?.length || 0;
      this.invoices = this.budgetItems?.invoices;

      for (const bp of this.budgetItems?.bid_packages || []) {
        bp.can_close = true;
        let invoiceCount = 0;
        for (const co of bp.change_orders) {
          invoiceCount += co.invoices?.length || 0;
          if (bp.can_close) {
            bp.can_close =
              !!co.invoices?.length &&
              !co.invoices.find((i) =>
                [InvoiceStatus.New, InvoiceStatus.Received, InvoiceStatus.Approved].includes(i.status_id)
              );
          }
        }

        if (!invoiceCount && bp.can_close) {
          bp.can_close = false;
        }
      }

      const constructionBudgetTrustTotal = sumBy(
        this.budgetData?.tenants ?? [],
        (t: any) => +t.constructionBudgetTrustTotal
      );
      let managementFeeTotal = 0;
      for (const t of this.budgetItems?.tenants ?? []) {
        managementFeeTotal += t.cb_management_fee_subtotal;
        const foundTenant = this.budgetData.tenants.find((bdt) => bdt.id === t.id);
        t.expectedAmount = 0;
        for (const fs of t?.funding_sources ?? []) {
          if (!fs.peb_item_type_id) {
            if (t.type_id === 3) {
              fs.expectedAmount = constructionBudgetTrustTotal;
            } else {
              fs.expectedAmount =
                (+foundTenant.constructionBudgetTenantTotal ?? 0) + (+foundTenant.nonFeeChangeOrderTotal ?? 0);
            }
          } else if (fs.peb_item_type_id === PEBItemType.Contingency) {
            fs.expectedAmount = +foundTenant.contingencyUsed ?? 0; // tenant's contingency used
          } else if (fs.peb_item_type_id === PEBItemType.GeneralConditions) {
            fs.expectedAmount = +foundTenant.generalConditionsUsed ?? 0; // tenant's gen con used
          }
          fs.balanceToFinish = +fs.expectedAmount - +fs.billedAmount;
          t.expectedAmount += +fs.expectedAmount;
        }
        t.balanceToFinish = sumBy(t.funding_sources, (fs: any) => fs.balanceToFinish);
      }
      this.managementFee =
        this.budgetData.managementFeesTotal === undefined ? managementFeeTotal : this.budgetData.managementFeesTotal;

      // reset files
      this.pendingAndProcessedInvoiceFiles = [];

      // sort by company_id (natural asc) and then by id (desc, since lower ids were created first)
      const sortedInvoices = sortBy(
        this?.invoices ?? [],
        (invoice) => invoice.company_id,
        (invoice) => -invoice.id
      );

      for (const invoice of sortedInvoices) {
        if (
          invoice?.status_id === InvoiceStatus.Received ||
          invoice?.status_id === InvoiceStatus.Approved ||
          invoice?.status_id === InvoiceStatus.ReadyForPayment
        ) {
          this.pendingAndProcessedInvoiceFiles.push(...(invoice?.files ?? []));
        }
      }
    } else {
      this.invoices = await this.projectService
        .getInvoices([{ type: 'field', field: 'project_id', value: this.project?.id?.toString() }], this.invoiceFields)
        .toPromise();
      this.invoices = sortBy(this.invoices, (i) => [
        !!i.change_order_id,
        i.change_order_local_index,
        moment(i.invoice_date).unix(),
        i.id,
      ]);
    }

    for (const s of this.invoiceStatusInfo) {
      s.count = +this.invoices.filter((i) => (s.statusIds.length === 0 ? true : s.statusIds.indexOf(i.status_id) > -1))
        ?.length;
    }

    if (showLoader) {
      this.progressIndicatorService.close();
    }

    this.loading = false;
  }

  public getFundingSourceName(invoice: Invoice) {
    const tenantName = invoice.is_internally_funded ? `UHAT` : invoice.tenant_name;
    if (invoice.is_internally_funded) {
      return tenantName;
    } else if (invoice.is_contingency_funded) {
      return `${tenantName}: Contingency`;
    } else if (invoice.is_gencon_funded) {
      return `${tenantName}: General Conditions`;
    }
  }

  showChangeOption(invoice: Invoice) {
    return (
      ([InvoiceStatus.New, InvoiceStatus.Rejected].includes(invoice.status_id) &&
        this.userIsPartOfAwardedCompany(invoice)) ||
      this.isProjectAdmin
    );
  }

  public userIsPartOfAwardedCompany(invoice) {
    const currentUser = this.authService.getLoggedInUser();
    return invoice.company_id === currentUser?.company_id;
  }

  // TODO selectedTab code
  public changeTab(event) {
    const tabIndex: number = event.index;
  }

  startInvoiceLoading(value) {
    this.invoicesAreLoading = true;
    this.displayData = false;
    this.selectedView = value;
    setTimeout(() => {
      this.displayData = true;
    }, 100);
  }

  endInvoiceLoading() {
    this.invoicesAreLoading = false;
  }

  get isWorkspaceStaff() {
    return this.authService.isUserWorkspaceStaff(this.project?.module_id);
  }

  public get isProjectAdmin() {
    return this.authService.isProjectAdmin(this.project?.id, this.project?.module_id);
  }

  public get canCreateInvoices() {
    return (
      this.isProjectAdmin ||
      this.authService.currentUserIsOfProjectRole(ApplicationRole.ProjectVendor, this.project?.id) ||
      this.authService.currentUserIsOfProjectRole(ApplicationRole.ProjectEngineer, this.project?.id) ||
      this.isWorkspaceStaff
    );
  }

  get FileAction() {
    return FileAction;
  }

  get InvoiceStatus() {
    return InvoiceStatus;
  }

  public get tenantInvestment(): number {
    return (
      (+this.budgetData?.tenantConstructionBudgetTradesTotal || 0) +
      (+this.budgetData?.tenantNonFeeChangeOrderTotal || 0) +
      (+this.budgetData?.tenantContingencyUsed || 0) +
      (+this.budgetData?.tenantGeneralConditionsUsed || 0) -
      (+this.budgetData?.tenantAllowanceTotal || 0)
    );
  }

  public get trustInvestment(): number {
    return (
      (+this.budgetData?.trustConstructionBudgetTradesTotal || 0) +
      (+this.budgetData?.trustNonFeeChangeOrderTotal || 0) +
      (+this.budgetData?.trustContingencyUsed || 0) +
      (+this.budgetData?.trustGeneralConditionsUsed || 0) +
      (+this.budgetData?.tenantAllowanceTotal || 0)
    );
  }

  public get totalInvestment(): number {
    return this.tenantInvestment + this.trustInvestment;
  }

  public selectStatus(status) {
    this.selectedInvoiceStatus = status;
  }

  async createInvoice() {
    if (+this.project.status_id === +ProjectStatus.CLOSED) {
      this.modalService
        .openConfirmationDialog({
          titleBarText: 'New Invoice',
          headerText: 'Reactivate Project',
          descriptionText:
            'This project has been closed. If you have access to reactivate it, please do so before adding a new Invoice. <br/><br/>If you are a supplier, please contact the project manager to reactivate the project.',
          hideConfirmationButton: true,
          cancelButtonText: 'Close',
        })
        .subscribe();
    } else {
      this.modalService.openNewInvoiceModal(this.currentWorkspace).subscribe(async () => {
        await this.invoiceChanged();
      });
    }
  }

  get closedBidPackagesBalanceToFinish(): number {
    let total = 0;
    for (const bp of this.budgetItems?.bid_packages || []) {
      total += (bp.status_id === BidPackageStatus.CLOSED && bp?.balanceToFinish) || 0;
    }

    return total;
  }

  updateBidPackageStatus(bidPackage: BidPackage): void {
    const bidPackageIndex = this.budgetItems?.bid_packages.findIndex((bp) => bp.id === bidPackage?.id);
    this.budgetItems.bid_packages.splice(bidPackageIndex, 1, bidPackage);
  }

  viewInvoice(invoice: Invoice): void {
    this.modalService.openViewInvoiceModal(invoice.id, this.currentWorkspace);
  }
  async exportBudgetReport() {
    // only add invoices if they exists and its a construction work space
    if (this.currentWorkspace === Workspace.Construction) {
      this.modalService
        .openConfirmationChoiceDialog({
          title: 'Download Budget Report',
          heading: 'Add invoice files?',
          subHeading:
            'Would you like to add all invoice files to this export? Warning: Adding invoice files will cause a longer download time.',
          option2: { text: 'Budget Report Only', value: 0 },
          option1: { text: 'Add Invoice Files', value: 1 },
        })
        .subscribe(async (answer) => {
          // do nothing if no answew
          if (answer === undefined) {
            return;
          }
          // generalizing construction propmt, but if they have no invoice, we still process the budget without invoices
          if (answer && this.pendingAndProcessedInvoiceFiles?.length) {
            const invoiceFiles = [];

            this.loadingBudgetReportExport = true;
            const budgetReportGroup = await this.budgetReport.export().catch((error) => {
              this.snackbar.open(`Error occurred!`);
            });

            const budgetReportBase64 = (await exportPDF(budgetReportGroup)).replace('data:application/pdf;base64,', '');
            const budgetReportByteCharacters = atob(budgetReportBase64);
            const budgetReportData = new Array(budgetReportByteCharacters.length);
            for (let i = 0; i < budgetReportByteCharacters.length; i++) {
              budgetReportData[i] = budgetReportByteCharacters.charCodeAt(i);
            }

            const report: { file: Blob; name: string } = {
              file: new Blob([new Uint8Array(budgetReportData)]),
              name: `Budget_Report_PRJ${this.project.code}_add_invoices.pdf`,
            };
            const invoiceIDs = this.pendingAndProcessedInvoiceFiles.map((i) => i.id);
            let combinedFile;
            try {
              combinedFile = await this.fileService.combinePdfsOnBackend(invoiceIDs, report).toPromise();
            } catch (e) {
              const errorSnack = this.snackbar.open(e.error.message, 'Close', { duration: undefined });
              errorSnack.onAction().subscribe(async () => {
                this.snackbar.dismiss();
              });
              return;
            }
            saveAs(new Blob([new Uint8Array(combinedFile.data)]), `Budget_Report_PRJ${this.project.code}.pdf`);

            this.snackbar.open('Budget report has been downloaded');
            this.loadingBudgetReportExport = false;
          } else {
            this.loadingBudgetReportExport = true;
            await this.budgetReport.saveAs(`Budget_Report_PRJ${this.project.code}.pdf`).catch((error) => {
              if (error instanceof PEBFinalizationError) {
                this.snackbar.open(`All tenant's PEBs must be finalized!`);
              }
            });
            this.loadingBudgetReportExport = false;
          }
        });
    } else {
      this.loadingBudgetReportExport = true;
      await this.budgetReport.saveAs(`Budget_Report_PRJ${this.project.code}.pdf`).catch((error) => {
        if (error instanceof PEBFinalizationError) {
          this.snackbar.open(`All tenant's PEBs must be finalized!`);
        }
      });
      this.loadingBudgetReportExport = false;
    }
  }

  public viewTask(taskId: number) {
    const dialogRef = this.dialog.open(ViewTaskDialogComponent, {
      data: {
        taskId,
      },
      autoFocus: false,
    });
    dialogRef.componentInstance.reviewChanged.subscribe((data) => {});
  }

  public toggleItemView(item) {
    item.hideInvoices = !item.hideInvoices;

    if (item.hideInvoices) {
      this.collapsedSectionsCount++;
    } else {
      this.collapsedSectionsCount--;
    }

    if (!item.hideInvoices && !this.collapseAll) {
      this.collapseAll = true;
    }

    if (item.hideInvoices && this.collapsedSectionsCount === this.sectionsCount) {
      this.collapseAll = false;
    }
  }

  public toggleAllView() {
    this.budgetItems?.bid_packages?.forEach((bp) => {
      bp.hideInvoices = this.collapseAll;
    });

    this.budgetItems?.tenants?.forEach((tenant) => {
      tenant.hideInvoices = this.collapseAll;
    });

    this.collapsedSectionsCount = this.collapseAll ? this.sectionsCount : 0;
    this.collapseAll = !this.collapseAll;
  }

  invoiceChanged() {
    this.refresh();
  }
  public viewChangeOrder(changeOrder: ChangeOrder) {
    this.ViewChangeOrderDialog.open(ViewChangeOrderDialogComponent, {
      width: '480px',
      disableClose: true,
      data: { changeOrder },
    });
  }
}
